import React from 'react';
import '../css/OurNetwork.css';
import { Helmet } from 'react-helmet';
import GoogleTag from './GoogleTag';

const OurNetwork = () => {
  const cities = [
    {
        name: 'Pune',
        imgSrc: '/images/Our-network/Our Network 600x400-03.jpg',
        description: 'Explore Pune’s vibrant culture and history while traveling with us.',
    },
    {
        name: 'Mumbai',
        imgSrc: '/images/Our-network/Our Network 600x400-02.jpg',
        description: 'Experience the energy of Mumbai, from its streets to its skyline.',
    },
    {
        name: 'Nashik',
        imgSrc: '/images/Our-network/Our Network 600x400-05.jpg',
        description: 'Discover Nashik, the wine capital of India, rich in tradition and beauty.',
    },
    {
        name: 'Outstation',
        imgSrc: '/images/Our-network/Our Network 600x400-01.jpg',
        description: 'Visit Shirdi for a peaceful spiritual retreat and receive Sai Baba’s blessings.',
    },
    {
        name: 'Kolhapur',
        imgSrc: '/images/Our-network/Our Network 600x400-04.jpg',
        description: 'Savor Kolhapur’s rich culinary heritage, vibrant culture, and historical sites.',
    },
    {
        name: 'Goa',
        imgSrc: '/images/Our-network/Our Network 600x400px 1-01.jpg',
        description: 'Escape to Goa for stunning beaches, vibrant nightlife, and rich heritage.',
    },
    {
        name: 'Ajanta Ellora',
        imgSrc: '/images/Our-network/Our Network 600x400px 1-02.jpg',
        description: 'Discover the Ajanta and Ellora Caves, marvels of ancient rock-cut architecture and artistry.',
    },
    {
        name: 'Mahabaleshwar',
        imgSrc: '/images/Our-network/Our Network 600x400px 1-03.jpg',
        description: 'Experience Mahabaleshwar’s lush hills, scenic views, and refreshing climate.',
    },
    {
        name: 'Shirdi',
        imgSrc: '/images/Our-network/Our Network 600x400px 1-2.jpg',
        description: 'Visit Shirdi, a spiritual haven and home to the revered Sai Baba Temple.',
    }
];

  return (
    <div>
     <GoogleTag/>
    
    <Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

    <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title">Our Network</h1>
              
            </div>
        </div>
    </section>

      <section className="our-network bluee py-0">
        <div className="container">
          <div className="network-cards ">
            {cities.map((city, index) => (
              <div className="network-card" key={index}>
                <img src={city.imgSrc} alt={city.name} className="network-image" />
                <div className="network-card-content ">
                  <h4>{city.name}</h4>
                  <p>{city.description}</p>
                </div>
               
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurNetwork;
