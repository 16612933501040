import React from 'react';

const BusRatesTable = () => {
  const ratesData = [
    { seater: "4+1", vehicle: "Dzire, Etios, Xcent", rate: "₹3600", perKm: "₹12 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Ertiga", rate: "₹4200", perKm: "₹14 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Kia Carens", rate: "₹4500", perKm: "₹15 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Tourist Special Innova", rate: "₹5100", perKm: "₹17 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Innova Crysta", rate: "₹5700", perKm: "₹19 per km", driverDA: "₹200" },
    { seater: "13 Seater", vehicle: "Tempo Traveller 13 Seater", rate: "On Call", perKm: "Non A/C- ₹25, A/C- ₹30", driverDA: "₹500" },
    { seater: "17 Seater", vehicle: "Tempo Traveller 17 Seater", rate: "On Call", perKm: "Non A/C- ₹25, A/C- ₹30", driverDA: "₹500" },
    { seater: "20, 28, 32, 50 Seater", vehicle: "Minibus 20, 28, 32, 50 Seater", rate: "On Call", perKm: "On Call Rate", driverDA: "₹500" },
  ];

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px auto"
  };

  const thStyle = {
    backgroundColor: "#EE1623",
    color: "white",
    padding: "8px",
    border: "1px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    border: "1px solid red",
    textAlign: "center",
    color: "black"
  };

  const trEvenStyle = {
    backgroundColor: "#f2f2f2"
  };

  const handleWhatsAppClick = (vehicle) => {
    const message = `Hi, I would like to inquire about the rates for the ${vehicle}.`;
    const url = `https://wa.me/+919923879009?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className='container p-4'>
      <div className='table-responsive'>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Outstation Capacity</th>
              <th style={thStyle}>Type Of Vehicle</th>
              <th style={thStyle}>Outstation Rates</th>
              <th style={thStyle}>Outstation Per Day (300km)</th>
              <th style={thStyle}>Per Day Driver D.A.</th>
              <th style={thStyle}>Book Now</th>
            </tr>
          </thead>
          <tbody>
            {ratesData.map((row, index) => (
              <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
                <td style={tdStyle}>{row.seater}</td>
                <td style={tdStyle}>{row.vehicle}</td>
                <td style={tdStyle}>{row.rate}</td>
                <td style={tdStyle}>{row.perKm}</td>
                <td style={tdStyle}>{row.driverDA}</td>
                <td style={tdStyle}>
                  <button
                    onClick={() => handleWhatsAppClick(row.vehicle)} // Pass the specific vehicle to the function
                    style={{ cursor: 'pointer', padding: '8px', backgroundColor: '#EE1623', borderRadius: '10px', color: 'white' }}
                  >
                    Book Now
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BusRatesTable;
