
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdidarshantaxi() {



  const cardData =
  {
    keyword: ' Shirdi Darshan Taxi ',
    heading: 'Shirdi Darshan Taxi ',
    headingDescription: 'When it comes to exploring Shirdi, the spiritual home of Sai Baba, Saitirth Travels offers the finest taxi services to enhance your pilgrimage experience. We understand that your journey is as important as your destination. Our commitment to providing comfortable, reliable, and seamless travel ensures that you can focus on your spiritual journey while we take care of your transportation needs.',

    top: 'Top Places to Visit Shirdi with Saitirth Travels:',

    topPlaces: [
        {
            "title": "1. Sai Baba Samadhi Mandir",
            "description": "This is the main attraction in Shirdi and a place of immense spiritual significance. The Samadhi Mandir houses the tomb of Sai Baba and is known for its beautiful architecture and serene atmosphere. Devotees from around the world come to pay their respects and experience the peace that surrounds this sacred site. The daily rituals, including the morning Aarti, create a deeply spiritual ambiance."
        },
        {
            "title": "2. Dwarkamai",
            "description": "A significant site for Sai Baba devotees, Dwarkamai was the mosque where Baba spent a major part of his life. The mosque is adorned with various offerings made by devotees and features the sacred fire that Baba lit. Visitors can reflect on Baba's teachings and experience the tranquil environment that embodies his spirit."
        },
        {
            "title": "3. Chavadi",
            "description": "Located close to Dwarkamai, Chavadi is another important site associated with Sai Baba. It served as Baba's residence every alternate night and features a unique architecture with beautiful pillars. The Chavadi is also the site of the evening Aarti, where devotees gather to chant and pray, enhancing the spiritual experience."
        },
        {
            "title": "4. Shri Saibaba Sansthan Temple",
            "description": "Managed by the Shri Saibaba Sansthan Trust, this temple complex offers various facilities for pilgrims. The temple is beautifully maintained and offers services for devotees, including free meals and accommodation. The atmosphere is filled with devotion, making it a great place for reflection and prayer."
        },
        {
            "title": "5. Lendi Baug",
            "description": "A beautiful garden that was frequented by Sai Baba, Lendi Baug is known for its peaceful ambiance and lush greenery. The garden features a sacred neem tree and a small well, which adds to the charm of the place. Visitors can take leisurely walks amidst nature while soaking in the spiritual energy of the site."
        },
        {
            "title": "6. Gurusthan",
            "description": "This site holds a significant place in Sai Baba’s life as it is where he was first spotted in Shirdi. A small shrine with a big neem tree marks this sacred spot, where Baba is believed to have meditated. The calm and serene environment allows visitors to connect with their spirituality."
        },
        {
            "title": "7. Khandoba Temple",
            "description": "Located a short distance from the main Sai Baba temple, Khandoba Temple is dedicated to Lord Khandoba, a form of Lord Shiva. The temple is a beautiful structure that attracts both devotees and tourists. It is a perfect spot to gain insights into the local culture and traditions."
        },
        {
            "title": "8. Siddhivinayak Temple",
            "description": "This temple is dedicated to Lord Ganesha and is known for its stunning architecture and peaceful environment. Many devotees visit this temple before heading to the main Sai Baba temple to seek blessings. The vibrant surroundings and the chanting of devotees create an uplifting atmosphere."
        },
        {
            "title": "9. Shri Sai Baba Museum",
            "description": "Located near the main temple, this museum showcases the life and teachings of Sai Baba through photographs and artifacts. Visitors can learn about Baba's life, his miracles, and his profound impact on the lives of millions. The museum serves as a great educational experience for those seeking to deepen their understanding of Sai Baba's legacy."
        },
        {
            "title": "10. Local Markets",
            "description": "Explore the vibrant local markets around Shirdi to find unique souvenirs, religious artifacts, and local delicacies. Engaging with local artisans and vendors adds a personal touch to your visit, allowing you to take home a piece of Shirdi's rich culture and spirituality."
        }
    ],    
        
    
    "services": [
        {
            "name": "Shirdi to Mumbai Cab Service",
            "description": "When traveling from Shirdi to Mumbai, choosing a reliable cab service is crucial for a comfortable journey. At SaitirthTours & Travels, we pride ourselves on offering top-notch transportation solutions. Our cabs are meticulously maintained, ensuring passenger safety and comfort. With a fleet that includes modern, air-conditioned vehicles, our experienced drivers are dedicated to making your journey pleasant and stress-free. Enjoy the scenic views along the way while we take care of your transportation needs."
        },
        {
            "name": "Cab from Shirdi to Mumbai",
            "description": "Our cab service from Shirdi to Mumbai is designed to provide a seamless travel experience. We prioritize timely arrivals, navigating the best routes to ensure you reach your destination on schedule. Our professional drivers are well-versed in local traffic patterns, allowing them to choose the fastest and safest paths. With our commitment to punctuality, you can relax and enjoy your ride without any worries about delays."
        },
        {
            "name": "Shirdi Mumbai Taxi Service",
            "description": "Our Shirdi to Mumbai taxi service caters to all types of travelers, offering a fleet of comfortable, air-conditioned vehicles equipped with modern amenities. Whether traveling solo or in a group, we provide reliable transportation with a commitment to customer satisfaction. Each vehicle is equipped with GPS for navigation and safety, ensuring you arrive at your destination efficiently. Experience personalized service tailored to your specific travel requirements."
        },
        {
            "name": "Taxi from Shirdi to Mumbai",
            "description": "Booking a taxi from Shirdi to Mumbai is easy with our online platform. Reserve your cab in advance and enjoy a stress-free journey with our trained drivers and well-maintained vehicles. Our booking process is straightforward, allowing you to select your preferred vehicle type and schedule. We also offer 24/7 customer support to assist you with any inquiries or changes to your itinerary."
        },
        {
            "name": "Shirdi to Mumbai Car Rental",
            "description": "Our car rental options from Shirdi to Mumbai provide flexibility and convenience. Choose from a wide selection of vehicles to suit your travel needs, ensuring a comfortable and enjoyable ride. Whether you need a compact car for a solo trip or a larger vehicle for a group, we have options that fit every budget. Our rental services include comprehensive insurance coverage, allowing you to drive with peace of mind."
        },
        {
            "name": "Shirdi to Mumbai Taxi Fare",
            "description": "At SaitirthTours & Travels, we offer competitive and transparent pricing for your trip from Shirdi to Mumbai. Contact us for an accurate fare estimate based on distance, vehicle type, and travel duration. Our pricing structure is straightforward, with no hidden fees, allowing you to budget effectively for your journey. We also provide special discounts for early bookings and group travels."
        },
        {
            "name": "Shirdi to Mumbai Cab Booking",
            "description": "Our easy cab booking system allows you to reserve your ride from Shirdi to Mumbai in just a few clicks. Our team is ready to assist with any special requests to ensure a comfortable journey. You can customize your booking with preferences like vehicle type, pick-up time, and any additional services you may require, such as child seats or extra luggage space."
        },
        {
            "name": "Affordable Cab Shirdi to Mumbai",
            "description": "Our affordable cab options from Shirdi to Mumbai cater to a range of budgets without compromising quality. Enjoy reliable transportation with transparent pricing and no hidden charges. We believe in providing value for your money while maintaining high standards of service. Whether you're traveling for business or leisure, we have the right vehicle for you."
        },
        {
            "name": "Best Cab for Shirdi to Mumbai",
            "description": "For the best cab service from Shirdi to Mumbai, choose SaitirthTours & Travels. Our fleet includes a range of vehicles and professional drivers trained to ensure a safe, comfortable experience. We focus on delivering exceptional customer service, from the moment you book until you reach your destination. Our drivers are not only skilled at navigating the roads but are also courteous and knowledgeable about the area."
        },
        {
            "name": "Shirdi to Mumbai Cab Rate",
            "description": "Our cab rates from Shirdi to Mumbai are competitive and straightforward, helping you budget effectively. Reach out to our customer service for a detailed rate breakdown. We offer various payment options, including cash, credit/debit cards, and mobile wallets, making it easy for you to settle your fare."
        },
        {
            "name": "Cab from Mumbai to Shirdi",
            "description": "Our cab service from Mumbai to Shirdi ensures a comfortable and enjoyable return journey. We prioritize punctuality, making sure you arrive on time for your schedule. Whether you're returning home or visiting the Sai Baba Temple again, our reliable service ensures a smooth trip back with our friendly drivers."
        },
        {
            "name": "Shirdi to Mumbai Cab One Way",
            "description": "Our one-way cab service from Shirdi to Mumbai provides flexibility and convenience. Book easily and expect the same high-quality service as a round trip. This option is perfect for those who need a ride in one direction, allowing you to travel without the need for a return journey."
        },
        {
            "name": "Shirdi to Mumbai Cab Service",
            "description": "Our cab service from Shirdi to Mumbai includes modern vehicles and experienced drivers for a safe and comfortable travel experience. We strive to offer you the best service at competitive prices, ensuring that your journey is as enjoyable as the destination."
        },
        {
            "name": "Shirdi to Mumbai Airport Cab",
            "description": "Our Shirdi to Mumbai airport cab service ensures timely arrival for your flight. We monitor schedules and plan routes to help you avoid delays. Our drivers are familiar with all major airports and will assist you with your luggage, ensuring a smooth transition from vehicle to terminal."
        },
        {
            "name": "Shirdi to Mumbai International Airport Cab Service",
            "description": "For international travelers, our cab service from Shirdi to Mumbai International Airport is dependable and convenient, ensuring timely arrival for international flights. Our drivers are well-trained in airport procedures and will make your journey hassle-free, allowing you to focus on your travel plans."
        },
        {
            "name": "Shirdi to Navi Mumbai Cab Service",
            "description": "Our Shirdi to Navi Mumbai cab service offers comfort with knowledgeable drivers and a selection of vehicles to meet your travel needs. Whether you're heading to a meeting or visiting family, our service is tailored for efficiency and ease, ensuring a smooth ride."
        },
        {
            "name": "Shirdi to Thane Cab Booking",
            "description": "Our Shirdi to Thane cab booking service is reliable and tailored to your needs, ensuring a stress-free and safe journey. We provide a variety of vehicle options and competitive rates, making it easy for you to secure transportation to this bustling city."
        },
        {
            "name": "Shirdi to Panvel Cab Booking",
            "description": "Book your cab from Shirdi to Panvel with ease. We offer well-maintained vehicles and professional drivers for a smooth ride. Our booking system is user-friendly, allowing you to schedule your trip at your convenience."
        },
        {
            "name": "Shirdi to Siddhivinayak Taxi Fare",
            "description": "Our taxi fare from Shirdi to Siddhivinayak is competitive and transparent, allowing you to budget effectively for your trip. We provide clear pricing information upfront, ensuring no surprises along the way."
        },
        {
            "name": "Shirdi to Mumbai Travel Packages",
            "description": "Our Shirdi to Mumbai travel packages include cab services, accommodation options, and guided tours for a comprehensive travel experience. Tailored to meet your specific needs, these packages ensure that every aspect of your journey is taken care of, from transportation to sightseeing."
        },
        {
            "name": "Shirdi to Mumbai Transportation",
            "description": "SaitirthTours & Travels offers a range of transportation solutions from Shirdi to Mumbai, ensuring you arrive safely and comfortably. Whether you need a simple cab ride or a more comprehensive travel plan, our services are designed with your convenience in mind."
        },
        {
            "name": "Shirdi to Mumbai Local Tours",
            "description": "Enhance your journey with our local tours from Shirdi to Mumbai, offering guided experiences to explore the best attractions in Mumbai. Our knowledgeable guides will share insights and stories, making your sightseeing experience both educational and enjoyable."
        }
    ],
    "tableData": [
        ["- Shirdi Darshan Taxi", "- Taxi for Shirdi Darshan"],
        ["- Shirdi Temple Taxi Service", "- Sai Baba Temple Taxi Shirdi"],
        ["- SaitirthTours & Travels Shirdi", "- Shirdi Darshan Cab"],
        ["- Taxi for Shirdi Sightseeing", "- Shirdi Local Taxi Tour"],
        ["- Shirdi Temple Tour Taxi", "- Taxi from Shirdi to Temples"],
        ["- Shirdi Temple Visit Taxi", "- Pune to Shirdi Darshan Package"],
        ["- Kalyan to Shirdi Darshan Booking", "- Mumbai to Shirdi Darshan Package"],
        ["- 17 Seater Tempo Traveller on Rent in Aurangabad for Shirdi Darshan", "- Shirdi Local Sightseeing Packages"],
        ["- Shirdi Cab Service Package", "- Shirdi Temple Tours"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Comprehensive Tour Experience:",
            "WhyChoosedescription": "Our Shirdi Darshan taxi service is designed to provide you with an all-inclusive experience, visiting all significant attractions in Shirdi. From the iconic Sai Baba Temple to other important religious sites, our itineraries are meticulously planned to ensure you have ample time for worship, reflection, and exploration. We also provide recommendations for local eateries and shopping spots, enriching your overall experience in this holy town."
        },
        {
            "WhyChooseheading": "Knowledgeable Drivers:",
            "WhyChoosedescription": "Our drivers are not only skilled behind the wheel but are also knowledgeable about the local area. They offer valuable insights and historical context during your visit, enhancing your experience by sharing stories and information about the places you will see. This personal touch allows you to connect more deeply with the spiritual significance of your journey, making your experience both educational and fulfilling."
        },
        {
            "WhyChooseheading": "Affordable Pricing:",
            "WhyChoosedescription": "We provide competitive rates for our taxi services, ensuring you receive great value without compromising on quality. Our transparent pricing model includes all applicable fees, so you can budget your trip effectively. We offer various packages that cater to different needs, including group discounts and special rates for longer bookings, ensuring you get the best deal for your Shirdi darshan."
        },
        {
            "WhyChooseheading": "Comfort and Safety:",
            "WhyChoosedescription": "Our fleet of well-maintained vehicles ensures a comfortable and safe journey, allowing you to focus on your spiritual experience. Each vehicle is regularly inspected and equipped with safety features, including seat belts and GPS tracking. We prioritize your comfort, providing amenities such as air conditioning and spacious interiors, ensuring a relaxing ride even during longer trips."
        },
        {
            "WhyChooseheading": "Flexible Booking Options:",
            "WhyChoosedescription": "Booking your Shirdi Darshan taxi is easy, with options available online or through our customer support. We offer a user-friendly booking platform that allows you to choose your vehicle, schedule, and customize your itinerary to suit your preferences. Our flexible cancellation policies accommodate changes in your travel plans, so you can adjust your itinerary without hassle."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any inquiries and ensure your journey goes smoothly. Whether you have questions about your booking, need travel advice, or require assistance during your trip, our friendly support staff is just a call away. We strive to provide prompt and effective solutions to enhance your travel experience, ensuring that you feel supported every step of the way."
        }
    ]
        
  }


  const faqData = [
    {
      question: "What is included in the Shirdi Darshan taxi service?",
      answer: "Our service includes transportation to all major temples and attractions in Shirdi, along with the option for personalized itineraries based on your preferences."
    },
    {
      question: "How long does a typical Shirdi Darshan tour take?",
      answer: "A complete Shirdi Darshan tour usually takes about 6 to 8 hours, depending on the places you wish to visit."
    },
    {
      question: "Are there additional charges for the taxi service?",
      answer: "We maintain transparent pricing, but additional charges may apply for extra stops or late-night travel."
    },
    {
      question: "How can I book a Shirdi Darshan taxi?",
      answer: "You can easily book your taxi online through our website or by contacting our customer service team."
    },
    {
      question: "What payment options do you accept?",
      answer: "We accept a variety of payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
      question: "Can I cancel my booking?",
      answer: "Yes, we provide flexible cancellation options. Please review our cancellation policy on the website for further details."
    },
    {
      question: "Are the taxis sanitized before each trip?",
      answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety and comfort."
    },
    {
      question: "Can I request specific destinations for my Shirdi Darshan?",
      answer: "Absolutely! You can customize your itinerary by requesting specific places of interest to visit during your tour."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Sunil Joshi",
      text: "I had a wonderful experience with Saitirth Travels for my Shirdi Darshan. The driver was knowledgeable and friendly, guiding us through all the important spots. The taxi was comfortable and well-maintained. I highly recommend their service for anyone visiting Shirdi!"
    },
    {
      name: "Ms. Meera Patil",
      text: "Saitirth Travels made my Shirdi Darshan trip incredibly special. The booking process was seamless, and our driver was fantastic, sharing interesting stories about the temples. The cab was clean and spacious, making our visit very pleasant. I will definitely choose them again!"
    }
  ];
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Darshan Taxi | SaitirthTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable Shirdi Darshan taxi service with SaitirthTours & Travels. Visit the Sai Baba Temple, enjoy Shirdi sightseeing, and other local temple tours in comfort." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Darshan Taxi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable Shirdi Darshan taxi service with SaitirthTours & Travels. Visit the Sai Baba Temple, enjoy Shirdi sightseeing, and other local temple tours in comfort." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-darshan-taxi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-darshan-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a reliable and affordable Shirdi Darshan taxi service with SaitirthTours & Travels. Visit the Sai Baba Temple, enjoy Shirdi sightseeing, and other local temple tours in comfort.",
            "url": "https://www.saitirthcabs.com/shirdi-darshan-taxi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-darshan-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Darshan Taxi", 
                "Taxi for Shirdi Darshan", 
                "Shirdi Temple Taxi Service", 
                "Sai Baba Temple Taxi Shirdi", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Darshan Cab", 
                "Taxi for Shirdi Sightseeing", 
                "Shirdi Local Taxi Tour", 
                "Shirdi Temple Tour Taxi", 
                "Taxi from Shirdi to Temples", 
                "Shirdi Temple Visit Taxi", 
                "Pune to Shirdi Darshan Package", 
                "Kalyan to Shirdi Darshan Booking", 
                "Mumbai to Shirdi Darshan Package", 
                "17 Seater Tempo Traveller on Rent in Aurangabad for Shirdi Darshan", 
                "Shirdi Local Sightseeing Packages", 
                "Shirdi Cab Service Package"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/23.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdidarshantaxi;