import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    "/images/saitirthaslides/Slide 3-01.jpg",
    "/images/saitirthaslides/Slide 1-01.jpg",
    "/images/saitirthaslides/Slide 1-02.jpg",
    "/images/saitirthaslides/Slide 2-01.jpg",
    "/images/saitirthaslides/Slider Size 1200x450px-01.jpg",
    "/images/saitirthaslides/Slider Size 1200x450px-02.jpg"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
          >
            <img src={image} className="d-block w-100" alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Previous Button */}
      <button
        className="carousel-control-prev"
        type="button"
        onClick={handlePrev}
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      {/* Next Button */}
      <button
        className="carousel-control-next"
        type="button"
        onClick={handleNext}
        data-bs-target="#carouselExample"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;










// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

// const Carousel = () => {
//   return (
//     <div 
//       id="carouselExample" 
//       className="carousel slide" 
//       data-bs-ride="carousel"  
//       data-bs-interval="2000"
//     >
//       <div className="carousel-inner">
//         <div className="carousel-item active">
//           <img src="/images/Saitirthslides/Slide 3-01.jpg" className="d-block w-100" alt="Slide 1" />
//         </div>
//         <div className="carousel-item">
//           <img src="/images/Saitirthslides/Slide 1-01.jpg" className="d-block w-100" alt="Slide 2" />
//         </div>
//         <div className="carousel-item">
//           <img src="/images/Saitirthslides/Slide 1-02.jpg" className="d-block w-100" alt="Slide 3" />
//         </div>
//         <div className="carousel-item">
//           <img src="/images/Saitirthslides/Slide 2-01.jpg" className="d-block w-100" alt="Slide 4" />
//         </div>
//         <div className="carousel-item">
//           <img src="/images/Saitirthslides/Slider Size 1200x450px-01.jpg" className="d-block w-100" alt="Slide 5" />
//         </div>
//         <div className="carousel-item">
//           <img src="/images/Saitirthslides/Slider Size 1200x450px-02.jpg" className="d-block w-100" alt="Slide 6" />
//         </div>
//       </div>
//       <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
//         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Previous</span>
//       </button>
//       <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
//         <span className="carousel-control-next-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Next</span>
//       </button>
//     </div>
//   );
// };

// export default Carousel;
