
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditokohlapur() {



  const cardData =
  {
    keyword: ' Shirdi to Kolhapur Taxi',
    heading: 'Saitirth Travels: Shirdi to Kolhapur Taxi',
    headingDescription: 'When it comes to traveling from Shirdi to Kolhapur, Saitirth Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch taxi services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Kolhapur.',

    top: 'Top Places to Visit in Kolhapur with Saitirth Travels',

  "topPlaces": [
    {
        "title": "1. Mahalaxmi Temple",
        "description": "This famous temple is dedicated to Goddess Mahalaxmi and serves as an important pilgrimage site for Hindus. Renowned for its exquisite architecture and intricate carvings, the temple captivates both devotees and architecture enthusiasts alike. The serene atmosphere and spiritual significance make it a must-visit location for anyone exploring Kolhapur."
    },
    {
        "title": "2. New Palace",
        "description": "Built in the 19th century, the New Palace is a stunning blend of architectural styles surrounded by beautiful gardens. It houses a museum that showcases the history and culture of Kolhapur, offering visitors a glimpse into the royal past of the region. The palace’s grandeur and landscaped surroundings provide an enchanting experience for history and architecture lovers."
    },
    {
        "title": "3. Kolhapur Fort",
        "description": "This historic fort offers insight into the rich heritage of the region. Visitors can explore the fort's ruins and enjoy panoramic views of the surrounding landscape. Its historical significance and architectural features make it a perfect spot for history buffs and adventure seekers alike."
    },
    {
        "title": "4. Rankala Lake",
        "description": "Rankala Lake is a picturesque lake located in the heart of Kolhapur, making it an ideal place for a leisurely stroll or a relaxing boat ride. The serene ambiance, coupled with beautiful sunset views, attracts both locals and tourists. The lakeside is perfect for picnics, offering a tranquil escape from the urban hustle."
    },
    {
        "title": "5. Panhala Fort",
        "description": "Situated on a hill, Panhala Fort is a significant historical site steeped in rich history. The fort offers breathtaking views of the Sahyadri range and is a great spot for trekking and exploring nature. Its scenic trails and historical landmarks make it an appealing destination for adventurers and history enthusiasts."
    },
    {
        "title": "6. Binkhambi Ganesh Mandir",
        "description": "This unique temple dedicated to Lord Ganesha is built in the shape of a traditional chhatri. The temple’s architectural beauty and peaceful environment create a perfect atmosphere for worship and reflection, making it a beautiful place for devotees to connect with their spirituality."
    },
    {
        "title": "7. Maharaja's Palace",
        "description": "The grand Maharaja's Palace showcases the opulent lifestyle of the Kolhapur royals. Visitors can explore the stunning architecture and artifacts within the palace, which reflect the rich history and culture of the region, making it a fascinating visit for history buffs and tourists alike."
    },
    {
        "title": "8. Khasbag Maidan",
        "description": "Khasbag Maidan is a popular recreational area perfect for picnics and outdoor activities. The spacious grounds and lush greenery provide a refreshing escape from the city, making it an ideal spot for families looking to enjoy a day outdoors amidst nature."
    },
    {
        "title": "9. Jijamata Garden",
        "description": "Dedicated to the mother of Chhatrapati Shivaji Maharaj, Jijamata Garden is a well-maintained garden featuring beautiful landscapes, playgrounds for children, and a peaceful ambiance. It is a favorite among families seeking a relaxing day out in nature while enjoying the well-kept surroundings."
    },
    {
        "title": "10. Shahu Palace",
        "description": "The residence of the Kolhapur royal family, Shahu Palace is an architectural marvel set amidst beautiful gardens and courtyards. Visitors can explore the palace and learn about the legacy of the Kolhapur dynasty, making it a significant cultural site worth visiting."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to Kolhapur Taxi Service",
        "description": "Embark on a comfortable journey from Shirdi to Kolhapur with SaitirthTours & Travels. Our reliable and convenient taxi services ensure that you can relax and enjoy the ride, taking in the scenic views along the way. Our well-trained drivers prioritize your safety and comfort, making your travel experience seamless and enjoyable."
    },
    {
        "name": "Shirdi to Kolhapur Cab Fare",
        "description": "Enjoy competitive and transparent cab fares for travel from Shirdi to Kolhapur. We offer budget-friendly options that make your journey affordable while maintaining high-quality service. With clear fare structures, you can plan your trip without worrying about hidden costs."
    },
    {
        "name": "Taxi Fare from Shirdi to Kolhapur",
        "description": "Plan your journey with our affordable and detailed taxi fare information from Shirdi to Kolhapur, ensuring transparency and helping you budget your trip effectively. Our fares are calculated based on distance and vehicle type, giving you the flexibility to choose what suits you best."
    },
    {
        "name": "Shirdi Kolhapur Car Rental",
        "description": "Choose from a variety of rental cars, including sedans, SUVs, and more, to travel conveniently from Shirdi to Kolhapur. Our fleet is well-maintained and suited for all types of travelers, whether you need a compact car for solo travel or a spacious SUV for family trips."
    },
    {
        "name": "Shirdi to Kolhapur One Way Taxi",
        "description": "Our one-way taxi service from Shirdi to Kolhapur allows you to travel with ease, paying only for the distance you cover without any round-trip charges. This option is perfect for those looking to explore Kolhapur without the need to return to Shirdi on the same day."
    },
    {
        "name": "Shirdi to Kolhapur Cab Booking",
        "description": "Book your cab from Shirdi to Kolhapur effortlessly through our online booking platform. Enjoy a seamless travel experience with quick confirmations and reliable service. Our customer support team is also available to assist you with any questions or changes to your booking."
    },
    {
        "name": "Shirdi to Kolhapur Travel by Taxi",
        "description": "Our experienced drivers and well-maintained taxis guarantee a smooth journey from Shirdi to Kolhapur. With local insights and knowledge of the best routes, our drivers ensure you arrive at your destination safely and comfortably while enjoying the journey."
    },
    {
        "name": "Shirdi Kolhapur Cab Rates",
        "description": "Our competitive cab rates make your travel from Shirdi to Kolhapur both affordable and comfortable. We believe in providing great value for your money, offering packages that suit different budgets and preferences, so you can focus on your trip without financial stress."
    },
    {
        "name": "Shirdi to Kolhapur Taxi Fare per km",
        "description": "We offer fair per-kilometer rates for our Shirdi to Kolhapur route, ensuring cost-effective travel. This transparency helps you manage your expenses efficiently, allowing for better planning of your travel budget."
    },
    {
        "name": "Affordable Shirdi Kolhapur Cab",
        "description": "SaitirthTours & Travels provides quality taxi services from Shirdi to Kolhapur at affordable rates. Our options are suitable for all travelers, ensuring everyone can enjoy a comfortable ride. We pride ourselves on our customer satisfaction and commitment to service excellence."
    },
    {
        "name": "Kolhapur to Shirdi Taxi Service",
        "description": "Our taxi service is available for travel from Kolhapur back to Shirdi, making round trips easy and convenient. Enjoy the same level of service and comfort on your return journey, with the assurance of punctuality and professionalism."
    },
    {
        "name": "Shirdi to Jyotiba Taxi Service",
        "description": "Visit the renowned Jyotiba temple near Kolhapur with our specialized Shirdi to Jyotiba taxi service. Our knowledgeable drivers will guide you through this spiritual journey, ensuring you have a fulfilling and hassle-free experience at the temple."
    },
    {
        "name": "Shirdi to Kolhapur Mahalaxmi Temple Cab",
        "description": "Make a devotional visit to the Mahalaxmi Temple in Kolhapur with our reliable cab services. We ensure timely arrivals and comfortable travel for your spiritual experience, allowing you to immerse yourself in the divine atmosphere without any worries."
    },
    {
        "name": "Shirdi to Wai Satara Cab",
        "description": "Enjoy smooth travel from Shirdi to Wai and Satara with our cab services, perfect for sightseeing and pilgrimage. Our drivers are familiar with the attractions in these areas, providing insights and recommendations to enhance your visit."
    },
    {
        "name": "Shirdi to Kolhapur Darshan Cab Booking",
        "description": "Book a dedicated cab for Kolhapur darshan, allowing you to explore all major attractions at your own pace. We cater to your itinerary, ensuring you get the most out of your visit while enjoying a comfortable ride."
    },
    {
        "name": "Shirdi to Kolhapur Ertiga Cab",
        "description": "Our spacious Ertiga cabs are ideal for family trips from Shirdi to Kolhapur, ensuring comfort and convenience for all passengers. With enough space for luggage and personal belongings, travel with ease as you create wonderful memories together."
    },
    {
        "name": "Shirdi to Kolhapur Tempo Traveller on Rent",
        "description": "For group travel, our Tempo Traveller rentals are perfect, providing ample space for a comfortable journey from Shirdi to Kolhapur. Enjoy the freedom to travel together, share experiences, and have a fun-filled trip with friends or family."
    },
    {
        "name": "Tempo Traveller Hire in Shirdi on Rent",
        "description": "Hire a Tempo Traveller in Shirdi for any travel needs, with options available for day trips or long-distance journeys. Our rental service is designed to cater to both casual and group travel requirements, ensuring you have a reliable vehicle at your disposal."
    },
    {
        "name": "Tempo Traveller Hire in Kolhapur on Rent",
        "description": "For convenient travel around Kolhapur, rent a Tempo Traveller with SaitirthTours & Travels for group excursions. Enjoy spacious seating and a comfortable ride as you explore the city's attractions together."
    },
    {
        "name": "Shirdi to Kolhapur Innova on Rent",
        "description": "Our Innova rentals provide a premium travel experience from Shirdi to Kolhapur, suitable for families and small groups. Enjoy modern amenities and spacious interiors, making your journey not only comfortable but also enjoyable."
    },
    {
        "name": "Shirdi Kolhapur Package",
        "description": "Enjoy a customized Shirdi to Kolhapur travel package that covers major attractions, accommodations, and all travel needs. Our packages are tailored to suit your preferences, making your trip memorable and hassle-free."
    },
    {
        "name": "Contact Information",
        "description": "For bookings and more details, reach out to SaitirthTours & Travels at +91 9923879009 / 9922479009. Our friendly team is ready to assist you in planning your trip and ensuring a comfortable journey with our professional taxi services, tailored to meet your specific travel needs."
    }
],
"tableData": [
    ["- Shirdi to Kolhapur Taxi Service", "- Shirdi to Kolhapur Cab Fare"],
    ["- Taxi Fare from Shirdi to Kolhapur", "- Shirdi Kolhapur Car Rental"],
    ["- Shirdi to Kolhapur One Way Taxi", "- Shirdi to Kolhapur Cab Booking"],
    ["- Shirdi to Kolhapur Travel by Taxi", "- Shirdi Kolhapur Cab Rates"],
    ["- Shirdi to Kolhapur Taxi Fare Per KM", "- Affordable Shirdi Kolhapur Cab"],
    ["- Kolhapur to Shirdi Taxi Service", "- Shirdi to Jyotiba Taxi Service"],
    ["- Shirdi to Kolhapur Mahalaxmi Temple Cab", "- SaitirthTours & Travels Shirdi"],
    ["- Shirdi to Wai Satara Cab", "- Shirdi to Kolhapur Darshan Cab Booking"],
    ["- Shirdi to Kolhapur Ertiga Cab", "- Shirdi to Kolhapur Tempo Traveller on Rent"],
    ["- Tempo Traveller Hire in Shirdi on Rent", "- Tempo Traveller Hire in Kolhapur on Rent"],
    ["- Shirdi to Kolhapur Innova on Rent", "- Shirdi Kolhapur Package"]
],
"whychoose": [
    {
        "WhyChooseheading": "Competitive Pricing:",
        "WhyChoosedescription": "Our taxi services from Shirdi to Kolhapur are affordably priced, offering exceptional value without hidden fees. We strive to provide the most cost-effective rates in the market, allowing you to travel comfortably while sticking to your budget. Our transparent pricing model ensures that you know exactly what you’re paying for, and we regularly review our rates to keep them competitive. Enjoy your journey without worrying about unexpected costs, and take advantage of our seasonal discounts and promotional offers."
    },
    {
        "WhyChooseheading": "Comfortable Travel Experience:",
        "WhyChoosedescription": "Our fleet consists of well-maintained vehicles designed to provide a comfortable and spacious travel experience. We prioritize your comfort by ensuring that all our taxis are equipped with modern amenities, including air conditioning, comfortable seating, and plenty of legroom. Our vehicles are regularly serviced to guarantee reliability and cleanliness. Whether you’re making a quick trip or embarking on a long journey, you can count on a relaxing ride that caters to your needs, including options for extra luggage or special requests."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "All our drivers are highly trained professionals with extensive knowledge of the local roads and traffic patterns. They are committed to ensuring your journey is not only safe but also enjoyable. Our drivers maintain a friendly and courteous demeanor, providing local insights and tips along the way. They are trained to handle any situation calmly, ensuring a smooth and pleasant experience throughout your trip. With a focus on punctuality, our drivers will ensure you arrive at your destination on time, every time."
    },
    {
        "WhyChooseheading": "Hassle-Free Booking:",
        "WhyChoosedescription": "Booking your taxi is easy and quick through our user-friendly website or mobile app. You can complete your reservation in just a few clicks, selecting your preferred vehicle type and travel time. Our customer support team is always available to assist you with any questions or special requests, ensuring you feel supported every step of the way. Additionally, we offer the flexibility to modify or cancel your bookings as needed, making your travel plans adaptable and stress-free."
    },
    {
        "WhyChooseheading": "Flexible Scheduling:",
        "WhyChoosedescription": "We understand that travel plans can vary, which is why we offer flexible scheduling options. Our 24/7 service allows you to book your ride at any time that suits your needs. Whether you require an early morning departure, a late-night pickup, or any time in between, we are here to accommodate your schedule. Our commitment to flexibility also extends to our ability to handle last-minute bookings and changes, ensuring that your travel experience remains smooth and efficient, regardless of your plans."
    },
    {
        "WhyChooseheading": "Safety Measures in Place:",
        "WhyChoosedescription": "Your safety is our utmost priority. We have implemented comprehensive safety measures, including regular sanitization of all vehicles, routine inspections, and adherence to health guidelines. Our taxis are equipped with safety features, such as seat belts, first aid kits, and GPS tracking, to ensure a secure environment. Furthermore, our drivers are trained in safety protocols, including defensive driving techniques, to navigate various road conditions confidently. With these measures in place, you can travel with confidence, knowing that your well-being is protected throughout your journey."
    }
]

        
  }

  const faqData = [
    {
        question: "What is the fare for a taxi from Shirdi to Kolhapur?",
        answer: "The fare typically ranges from INR 5,000 to INR 7,000, depending on the vehicle type and current demand. For the latest pricing, please visit our website."
    },
    {
        question: "How long does the journey take?",
        answer: "The journey from Shirdi to Kolhapur usually takes about 7 to 8 hours, depending on traffic and road conditions."
    },
    {
        question: "Are there any extra charges?",
        answer: "Extra charges may apply for additional stops or if you choose a larger vehicle for your trip."
    },
    {
        question: "How can I book a taxi for this route?",
        answer: "You can easily book your taxi through our website or by reaching out to our customer support team for assistance."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept a variety of payment options, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking by contacting customer support or through our website, in line with our cancellation policy."
    },
    {
        question: "Are the taxis cleaned before each trip?",
        answer: "Yes, all our vehicles are thoroughly sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request extra stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mr. Ravi Deshmukh",
        text: "I had a wonderful experience with Saitirth Travels on my trip from Shirdi to Kolhapur. The driver was punctual and professional, and the car was clean and comfortable. Highly recommend!"
    },
    {
        name: "Mrs. Anita Patil",
        text: "Saitirth Travels made our journey to Kolhapur enjoyable and hassle-free. The booking process was straightforward, and our driver was friendly and knowledgeable. Would definitely use their services again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Kolhapur Taxi | SaitirthTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable taxi from Shirdi to Kolhapur with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Kolhapur Taxi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable taxi from Shirdi to Kolhapur with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-kolhapur-taxi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-kolhapur-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a reliable and affordable taxi from Shirdi to Kolhapur with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online.",
            "url": "https://www.saitirthcabs.com/shirdi-to-kolhapur-taxi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-kolhapur-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Kolhapur taxi service",
                "Shirdi to Kolhapur cab fare",
                "Taxi fare from Shirdi to Kolhapur",
                "Shirdi Kolhapur car rental",
                "Shirdi to Kolhapur one way taxi",
                "Shirdi to Kolhapur cab booking",
                "Shirdi to Kolhapur travel by taxi",
                "Shirdi Kolhapur cab rates",
                "Shirdi to Kolhapur taxi fare per km",
                "Affordable Shirdi Kolhapur cab",
                "Kolhapur to Shirdi taxi service",
                "Shirdi to Jyotiba taxi service",
                "Shirdi to Kolhapur Mahalaxmi Temple cab",
                "SaitirthTours & Travels Shirdi",
                "Shirdi to Wai Satara cab",
                "Shirdi to Kolhapur darshan cab booking",
                "Shirdi to Kolhapur Ertiga cab",
                "Shirdi to Kolhapur tempo traveller on rent",
                "Tempo Traveller Hire in Shirdi on rent",
                "Tempo Traveller Hire in Kolhapur on rent",
                "Shirdi to Kolhapur Innova on rent",
                "Shirdi Kolhapur package"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/38.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditokohlapur;