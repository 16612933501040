
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoallindia() {



  const cardData =
  {
    keyword: ' Shirdi to All India Explorer Cab Service',
    heading: 'Saitirth Travels: Shirdi to All India Explorer Cab Service',
    headingDescription: 'Exploring the diverse beauty of India is an adventure that many dream of, and Saitirth Travels is here to make that dream a reality with our Shirdi to All India Explorer cab service. Our reliable and comfortable transportation options ensure that you can explore various destinations across India while enjoying the journey. Whether you are a solo traveler, a family, or a group of friends, we cater to all your travel needs with our professional drivers and well-maintained vehicles.',

    top: 'Top Places to Visit All India Explorer Journey with Saitirth Travels',

    topPlaces: [
        {
            "title": "Taj Mahal, Agra",
            "description": "The iconic Taj Mahal is a UNESCO World Heritage site and one of the Seven Wonders of the World. Built by Mughal Emperor Shah Jahan in memory of his wife, this stunning marble mausoleum is renowned for its exquisite architecture and serene gardens, making it a must-visit."
        },
        {
            "title": "Jaipur, Rajasthan",
            "description": "Known as the Pink City, Jaipur boasts a rich history and vibrant culture. Visit the majestic Amber Fort, City Palace, and Hawa Mahal, and don’t forget to explore the bustling bazaars for traditional handicrafts and jewelry."
        },
        {
            "title": "Varanasi, Uttar Pradesh",
            "description": "One of the oldest cities in the world, Varanasi is a spiritual haven. Experience the mesmerizing Ganga Aarti at Dashashwamedh Ghat, take a boat ride on the Ganges, and explore the narrow alleys filled with temples and shops."
        },
        {
            "title": "Goa",
            "description": "Famous for its stunning beaches, vibrant nightlife, and Portuguese heritage, Goa offers a perfect blend of relaxation and adventure. Enjoy water sports, explore spice plantations, and savor delicious Goan cuisine."
        },
        {
            "title": "Kerala Backwaters",
            "description": "The serene backwaters of Kerala are a unique and tranquil experience. Rent a houseboat to glide through lush green landscapes, coconut groves, and quaint villages, making for an unforgettable getaway."
        },
        {
            "title": "Ranthambore National Park",
            "description": "Known for its majestic tigers, Ranthambore National Park offers thrilling wildlife safaris. Experience the thrill of spotting these magnificent creatures in their natural habitat amidst stunning landscapes."
        },
        {
            "title": "Mysore Palace, Karnataka",
            "description": "A masterpiece of Indo-Saracenic architecture, Mysore Palace is a historical monument that dazzles with its intricate carvings and opulent interiors. Don't miss the grand illumination of the palace at night."
        },
        {
            "title": "Hampi, Karnataka",
            "description": "A UNESCO World Heritage site, Hampi is known for its ancient temples, ruins, and breathtaking landscapes. Explore the rich history and architecture that reflect the grandeur of the Vijayanagara Empire."
        },
        {
            "title": "Leh-Ladakh",
            "description": "Known for its stunning landscapes and unique culture, Leh-Ladakh is a paradise for adventure enthusiasts. Explore monasteries, trek through picturesque valleys, and experience the thrill of high-altitude biking."
        },
        {
            "title": "Rishikesh, Uttarakhand",
            "description": "Known as the Yoga Capital of the World, Rishikesh is famous for its spiritual retreats and adventure activities. Enjoy white-water rafting, attend yoga sessions, and visit the iconic Laxman Jhula."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi to All India Explorer Cab Service",
        "description": "Embark on an unforgettable journey across India with our All India Explorer Cab Service, departing from Shirdi. Experience the convenience of traveling to various states while enjoying comfort and reliability."
    },
    {
        "name": "Explore Pan India Cab Service from Shirdi",
        "description": "Our Pan India cab service allows you to explore numerous destinations effortlessly. Whether you're planning a pilgrimage or a vacation, we've got you covered with our extensive network of cabs."
    },
    {
        "name": "Shirdi to Madhya Pradesh Taxi Service",
        "description": "Travel from Shirdi to Madhya Pradesh with ease! Our taxi service ensures a smooth journey, providing you with skilled drivers and well-maintained vehicles for a comfortable ride."
    },
    {
        "name": "Shirdi to Hyderabad Cabs Taxi Service",
        "description": "Discover the vibrant culture of Hyderabad with our dedicated cab service from Shirdi. We provide flexible booking options and competitive fares to suit your travel needs."
    },
    {
        "name": "Shirdi to Bangalore Cabs Service",
        "description": "Planning a trip to Bangalore? Our cab service offers direct transportation from Shirdi to Bangalore, ensuring you travel safely and comfortably. Book your cab today for a seamless journey!"
    },
    {
        "name": "Shirdi to Goa Cabs Service",
        "description": "Experience the beauty of Goa with our cab service from Shirdi. Enjoy a hassle-free ride to the beaches, vibrant markets, and nightlife of Goa. Your holiday starts with us!"
    },
    {
        "name": "Shirdi to Gujarat Taxi Service",
        "description": "Travel from Shirdi to Gujarat with our reliable taxi service. Explore the cultural heritage and scenic landscapes of Gujarat while enjoying the comfort of our well-equipped cabs."
    },
    {
        "name": "Shirdi to Rajasthan Cabs Booking",
        "description": "Journey to the royal state of Rajasthan with our dedicated cab booking service. Experience the rich history and majestic forts as you travel from Shirdi to various destinations in Rajasthan."
    },
    {
        "name": "Shirdi to Telangana Cabs Booking",
        "description": "Explore the diverse attractions of Telangana with our cab service. We offer seamless transportation from Shirdi to Telangana, ensuring a smooth and enjoyable ride."
    },
    {
        "name": "Pan India Cab Service",
        "description": "With our Pan India cab service, travel anywhere in the country from Shirdi. Our fleet is well-maintained, and our drivers are experienced, making your trip safe and enjoyable."
    },
    {
        "name": "Corporate Car Rental Services in India",
        "description": "We cater to corporate clients with our car rental services across India. Whether for business meetings or employee transportation, we provide reliable and professional services."
    },
    {
        "name": "Shirdi to Karnataka Darshan Package",
        "description": "Join our Karnataka darshan package from Shirdi for a spiritual experience. This package includes transportation to various sacred sites, ensuring a fulfilling pilgrimage."
    },
    {
        "name": "Shirdi Tour Package from Bangalore by Cab",
        "description": "Our special tour package allows you to travel from Bangalore to Shirdi by cab. Enjoy a comfortable journey while exploring the divine attractions of Shirdi."
    },
    {
        "name": "Shirdi to Kerala Cab Packages",
        "description": "Experience the beauty of Kerala with our tailored cab packages from Shirdi. Discover the serene backwaters, lush landscapes, and rich culture of Kerala with our expert drivers."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for all your travel needs from Shirdi to any destination across India. We are committed to providing exceptional service and a memorable travel experience."
    },
    {
        "name": "Shirdi to All India Explorer Cab Service Contact Information",
        "description": "For more information or to book your cab, please contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Let us help you explore India with comfort and ease!"
    }
],

tableData: [
    ["- Shirdi to Madhya Pradesh Taxi Service", "- Shirdi to Hyderabad Cabs Taxi Service"],
    ["- Shirdi to Bangalore Cabs Cab Service", "- Shirdi to Goa Cabs Service"],
    ["- Shirdi to Gujarat Taxi Service", "- Shirdi to Rajasthan Cabs Booking"],
    ["- Shirdi to Telangana Cabs Booking", "- Pan India Cab Service"],
    ["- Corporate Car Rental Services in India", "- Shirdi to Karnataka Darshan Package"],
    ["- Shirdi Tour Package from Bangalore by Cab", "- Shirdi to Kerala Cab Packages"],
    ["- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Comprehensive Travel Options",
      "WhyChoosedescription": "Our All India Explorer Cab Service provides you with the flexibility to travel across multiple destinations, whether you're exploring the vibrant cities of Madhya Pradesh, the scenic beauty of Kerala, or the historical landmarks of Rajasthan. This service allows you to make the most of your time in India, offering a seamless travel experience."
    },
    {
      "WhyChooseheading": "Customizable Itineraries",
      "WhyChoosedescription": "We understand that every traveler is different, which is why we offer fully customizable itineraries. Whether you prefer to visit famous temples, beaches, or hidden gems across India, we tailor your journey to your preferences and ensure you get to see the sights that matter most to you."
    },
    {
      "WhyChooseheading": "Experienced and Knowledgeable Drivers",
      "WhyChoosedescription": "Our drivers are not only skilled in navigating various routes but are also familiar with the key attractions along the way. Whether you are heading to the bustling city of Bangalore or the peaceful landscapes of Goa, our drivers provide insightful commentary and ensure a smooth journey."
    },
    {
      "WhyChooseheading": "Comfort and Safety",
      "WhyChoosedescription": "Your comfort and safety are our top priorities. Our fleet of vehicles is regularly serviced and maintained to provide a smooth, comfortable ride. Whether you are traveling short distances or embarking on a long journey, you can trust our vehicles to offer a relaxing and safe travel experience."
    },
    {
      "WhyChooseheading": "Competitive Pricing",
      "WhyChoosedescription": "We offer competitive pricing with no hidden charges, ensuring you get great value for your travel experience. Our transparent pricing means you can plan your trip without worrying about unexpected costs, while enjoying the comfort and reliability of our service."
    },
    {
      "WhyChooseheading": "Punctual Service",
      "WhyChoosedescription": "We understand the importance of time in travel. Our commitment to punctuality ensures you reach your destinations on time, making sure you don't miss out on your sightseeing plans or important appointments. With us, you can count on timely pickups and drop-offs throughout your journey."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "We provide 24/7 customer support to assist with any queries or modifications to your bookings. Whether you have a last-minute change to your travel schedule or need help during your journey, our customer service team is always available to ensure a hassle-free experience."
    },
    {
      "WhyChooseheading": "Safety and Hygiene",
      "WhyChoosedescription": "Your safety is our top priority. We ensure that all our vehicles are sanitized regularly and adhere to health and safety protocols. Traveling with us means you can enjoy your journey with peace of mind, knowing that we take all necessary precautions to keep you safe and healthy."
    },
    {
      "WhyChooseheading": "Wide Range of Vehicle Options",
      "WhyChoosedescription": "We offer a wide range of vehicles to suit your travel needs, from sedans for solo travelers or couples, to SUVs and larger coaches for families or groups. Choose the vehicle that fits your preferences and enjoy the flexibility of our services, whether you're planning a day trip or a multi-day journey."
    },
    {
      "WhyChooseheading": "Easy Online Booking",
      "WhyChoosedescription": "Our online booking platform is designed to be user-friendly, allowing you to easily reserve your cab and plan your journey from the comfort of your home. With just a few clicks, you can book your All India Explorer Cab and start your adventure with ease."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "What are the destinations included in the All India Explorer service?",
        answer: "Our All India Explorer service covers a variety of popular destinations across India. Please contact us for a detailed list of destinations."
    },
    {
        question: "Can I customize my travel itinerary?",
        answer: "Yes, we encourage customized itineraries to suit your travel preferences. You can discuss your needs with our team when booking."
    },
    {
        question: "How do I book a cab for the All India Explorer service?",
        answer: "You can book your cab through our website, or you may contact our customer service team for assistance."
    },
    {
        question: "Is there a minimum booking duration?",
        answer: "Yes, a minimum booking duration may apply depending on your chosen itinerary. Please inquire for specifics."
    },
    {
        question: "What is the fare structure for the All India Explorer service?",
        answer: "The fare structure varies based on the distance traveled, vehicle type, and duration. Contact us for a personalized quote."
    },
    {
        question: "Are tolls and parking fees included in the fare?",
        answer: "Typically, tolls and parking fees are additional. We will inform you of any expected costs during the booking process."
    },
    {
        question: "Can I make changes to my booking after confirmation?",
        answer: "Yes, changes can be made, but please notify us as soon as possible. Certain terms may apply."
    },
    {
        question: "What types of vehicles are available for this service?",
        answer: "We offer a range of vehicles, including sedans, SUVs, and larger coaches, to accommodate various group sizes."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please contact us for details on our terms."
    },
    {
        question: "How can I contact customer support for assistance?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Amit Verma",
        text: "I recently used the All India Explorer cab service from Saitirth Travels for my trip from Shirdi, and it was fantastic! The cab was comfortable, and the driver was friendly and professional, making our long journey enjoyable. The service was reliable and affordable. I highly recommend Saitirth Travels for anyone looking to explore India!"
    },
    {
        name: "Ms. Pooja Singh",
        text: "My experience with Saitirth Travels for the Shirdi to All India Explorer cab service was outstanding! The booking process was easy, and the vehicle was in great condition. Our driver was punctual and very knowledgeable about the routes. I will definitely choose Saitirth Travels for my future travels across India!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to All India Explorer Cab Service | Pan India Cab Booking & Tour Packages</title>
    <meta name="description" content="Book All India Explorer Cab Service from Shirdi with Saitirth Travels. We offer affordable and reliable taxi services to major destinations like Madhya Pradesh, Goa, Rajasthan, Kerala, and more. Explore our Pan India Cab & Tour Packages." />
    <meta name="keywords" content="Shirdi to All India Explorer Cab Service, Shirdi to Madhya Pradesh Taxi Service, Shirdi to Hyderabad Cabs, Shirdi to Bangalore Cabs, Shirdi to Goa Cabs, Shirdi to Gujarat Taxi Service, Pan India Cab Service, Corporate Car Rental Services, Shirdi to Karnataka Darshan Package, Shirdi Tour Package from Bangalore, Shirdi to Kerala Cab Packages" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to All India Explorer Cab Service | Pan India Cab Booking & Tour Packages" />
    <meta property="og:description" content="Book All India Explorer Cab Service from Shirdi with Saitirth Travels. We provide convenient and affordable cab services to major cities like Madhya Pradesh, Goa, Rajasthan, Kerala, Karnataka, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-all-india-explorer-cab-service" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/pan-india-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book All India Explorer Cab Service from Shirdi with Saitirth Travels. Affordable and reliable taxi services to cities like Madhya Pradesh, Goa, Rajasthan, Kerala, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-to-all-india-explorer-cab-service",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/pan-india-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to All India Explorer Cab Service",
                "Shirdi to Madhya Pradesh Taxi Service",
                "Shirdi to Hyderabad Cabs",
                "Shirdi to Goa Cabs Service",
                "Shirdi to Gujarat Taxi Service",
                "Pan India Cab Service",
                "Corporate Car Rental Services",
                "Shirdi to Karnataka Darshan Package",
                "Shirdi Tour Package from Bangalore",
                "Shirdi to Kerala Cab Packages"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoallindia;