import React from 'react';
import { Helmet } from 'react-helmet';
import GoogleTag from './GoogleTag';

const ContactDetails = () => {
  return (





<div>
<GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

    
<section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title">Contact</h1>
            </div>
        </div>
    </section>



    <section className="contact-details" style={{backgroundColor:'#D10328'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="contact-details__right">
              <div className="sec-title">
                <span className="sub-title text-white">Need any help?</span>
                <h2 className='text-white'>Get in touch with us</h2>
               
              </div>
              <div className="row">
                <div className="col-md-4 text-center py-2">
                  <div className="contact-item align-items-center">
                    <div className="icon">
                      <span className="lnr-icon-phone-plus p-3 bg-dark text-white"></span>
                    </div>
                    <div className="text ml-xs--0 mt-xs-10 pt-3 ">
                      <h4 className='text-white'>Have any question?</h4>
                      <a href="tel:+919923879009" className='text-white'>+91 9923879009</a><br/>
                      <a href="tel:+919923879009" className='text-white'>+91 9923879009</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4  text-center py-3">
                  <div className="contact-item  align-items-start">
                    <div className="icon text-center">
                      <span className="lnr-icon-envelope1 p-3 bg-dark text-white"></span>
                    </div>
                    <div className="text ml-xs--0 mt-xs-10 pt-3">
                      <h4 className='text-white'>Write email</h4>
                      <a href="mailto:booking@saitirthcabs.com" className='text-white'><span>booking@saitirthcabs.com
                      </span></a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center py-2">
                  <div className="contact-item align-items-center">
                    <div className="icon">
                      <span className="lnr-icon-location p-3 bg-dark text-white" ></span>
                    </div>
                    <div className="text ml-xs--0 mt-xs-10 pt-3 text-white">
                      <h4 className='text-white'>Saitirth Travels</h4>
                      Hotel sai jashan Sana and   <br/>
                      son Road Shirdi,  Taluka Rahata <br/>
                       District Ahmednagar, Maharashtra - 423109

                      </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>

<section className="map-section">








<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3133.739794562332!2d74.478748!3d19.771244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc5b9650d6a807%3A0x31d2acca36481c15!2sHotel%20Sai%20Jashan%20-%20Top%20Rank%20%231%20Award%20Winning%20Best%20Hotel%20in%20Shirdi!5e1!3m2!1sen!2sin!4v1733112161906!5m2!1sen!2sin" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


</section>
</div>

  );
};

export default ContactDetails;
