import { useEffect } from "react";

const GoogleTag = () => {
  useEffect(() => {
    // Add the Google Tag Manager script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16802548455";
    document.head.appendChild(script);

    // Initialize the gtag function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-16802548455");
  }, []);

  return null; // No visual output needed
};

export default GoogleTag;
