import React from 'react';
import '../css/Vision.css'; // Ensure you have this CSS file for styling
import { Container, Row, Col, Button } from 'react-bootstrap'; // Import Bootstrap components

const Vision = () => {
  return (
    <section className="vision-section">
      <Container>
        <h2 className="vision-title">Our Vision & Mission</h2>
        
        <Row>
          <Col xs={12} md={6} className="vision-content ">
            <h3 className="vision-header">Vision</h3>
            <p className="vision-description">
              At <strong className='darkcolor'>Saitirth Travels</strong>, our vision is to transform the transportation experience by offering safe, reliable, and comfortable rides tailored to every traveler’s needs. We strive to connect people and places with a seamless travel experience that prioritizes customer satisfaction and safety. Our dedication to innovative technology and sustainable practices ensures that every journey with us is not only enjoyable but also eco-friendly. Join us as we pave the way for a future where travel is efficient, dependable, and accessible to everyone.
            </p>
          </Col>

          <Col xs={12} md={6} className="mission-content">
            <h3 className="mission-header">Mission</h3>
            <p className="mission-description">
              Our mission at <strong className='darkcolor'>Saitirth Travels</strong> is to provide unmatched customer service through prompt and reliable transportation solutions. We are committed to creating a safe and enjoyable travel environment by implementing rigorous driver training and maintaining high-quality vehicles. By valuing customer feedback and continuously enhancing our services, we aim to exceed expectations at every turn. Our goal is to build lasting relationships with our passengers, ensuring that each ride is a memorable and positive experience.
            </p>
          </Col>
        </Row>

      </Container>
    </section>
  );
};

export default Vision;
