import React from 'react';

const newsArticles = [
    {
        date: "January , 2024",
        title: "Lonavala",
        description: "Discover the charm of Lonavala – where the lush hills, serene lakes, and cool breeze create the perfect weekend escape! 🌿🌄"
,
        image: "/images/lona.jfif",
    },
    {
        date: "February , 2024",
        title: "Pune",
        description: "Explore the Heart of Maharashtra! Pune – A blend of rich heritage, vibrant culture, and modern charm.✨ Experience the perfect harmony of tradition and innovation in every corner of this lively city. 🌟",
        image: "/images/pune1.jpeg",
    },
    {
        date: "November , 2024",
        title: "Mahabaleshwar ",
        description: "Discover the tranquility of Mahabaleshwar – nature's perfect escape. 🍃🏞",
        image: "/images/maha.jpg",
    },
    {
        date: "January , 2024",
        title: "Mumbai",
        description: "Experience Mumbai! 🌆 A city of dreams, where tradition meets modernity, and every corner pulses with energy, culture, and innovation.✨",
        image: "/images/mumbai.jpg",
    },
    {
        date: "February , 2024",
        title: "Ajanta Ellora",
        description:"Discover Ajanta Ellora! 🏛️ A marvel of ancient rock-cut caves, intricate art, and rich history that transports you to a bygone era.✨",
        image: "/images/ajanta1.jpg",
    },
    {
        date: "November , 2024",
        title: "Nashik ",
        description:"Explore Nashik! 🍇 A city of divine tranquility, vineyards, and ancient temples, offering a perfect blend of spirituality and nature.",
        image: "/images/nashik.jfif",
    },
];

const NewsSection = () => {
    return (
        <section className="news-section-four pt-0">
            <div className="auto-container ">
                {/* <div className="sec-title text-center p-0"> */}
                    <h2 className="letters-slide-up text-split  text-center">Recent Trips</h2>
                {/* </div> */}
                <div className="row text-white g-0">
                    {newsArticles.map((article, index) => (
                        <div className="news-block-four col-lg-4 col-md- border border-dark  " 
                        style={{backgroundColor:'#EE1623'}}
                        data-aos="fade-up" key={index} data-aos-delay={index * 100}>
                            <div className="inner-box">
                                <figure className="image">
                                    <a href={article.link}>
                                        <img src={article.image} alt="Image" />
                                        <img src={article.image} alt="Image" />
                                    </a>
                                </figure>
                                <div className="content px-2"style={{backgroundColor:'#EE1623'}}>
                                    <ul className="post-meta">
                                        {/* <li>{article.date}</li> */}
                                    </ul>
                                    <h4 className="title ">
                                        <a href={article.link}>{article.title}</a>
                                    </h4>
                                    <div className="text p-2" style={{backgroundColor:'#EE1623'}}>{article.description}</div>
                                    <div className="author-box">
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default NewsSection;
