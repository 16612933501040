
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditomumbaidarshan() {



  const cardData =
  {
    keyword: ' Shirdi to Mumbai Darshan Package',
    heading: 'Saitirth Travels: Shirdi to Mumbai Darshan Package',
    headingDescription: 'Embark on a memorable journey from Shirdi to Mumbai with a comprehensive darshan package designed to offer a comfortable and enriching experience. This tour covers key religious, cultural, and historic landmarks in Mumbai, ensuring a fulfilling visit to the bustling city.',

    top: 'Top Places to Visit in Mumbai with Saitirth Travels',

    topPlaces: [
        {
            "title": "Siddhivinayak Temple",
            "description": "Dedicated to Lord Ganesha, the Siddhivinayak Temple is one of Mumbai's most revered spiritual destinations. Built in 1801, it features a stunning black stone idol of Ganapati and is adorned with intricate carvings and beautiful architecture. Thousands of devotees visit daily to seek blessings, especially during the festival of Ganesh Chaturthi. The temple's vibrant atmosphere and spiritual significance make it a must-visit for anyone exploring Mumbai."
        },
        {
            "title": "Mahalakshmi Temple",
            "description": "This iconic temple is dedicated to Goddess Mahalakshmi and is situated along the Arabian Sea, offering breathtaking views. Constructed in the 19th century, it attracts pilgrims throughout the year, particularly during the Navratri festival when it is beautifully decorated with lights and flowers. Visitors can witness various rituals and partake in the divine ambiance, making it a significant religious site in Mumbai that embodies cultural richness."
        },
        {
            "title": "Haji Ali Dargah",
            "description": "Located on an islet off the coast of Worli, Haji Ali Dargah is a stunning whitewashed mosque and tomb, celebrated for its breathtaking Indo-Islamic architecture. The structure is surrounded by water, creating a serene atmosphere, especially during sunset. Accessible by a narrow walkway that becomes submerged during high tide, it attracts visitors seeking spiritual solace and panoramic views of the Arabian Sea, making it a unique landmark in Mumbai."
        },
        {
            "title": "Gateway of India",
            "description": "A historical monument and symbol of Mumbai, the Gateway of India was built in 1924 to honor King George V and Queen Mary’s visit to India. This grand structure features stunning Indo-Saracenic architecture and is located by the sea, providing a picturesque backdrop for photographs. It's a popular spot for tourists and locals alike, offering boat rides to nearby Elephanta Island and a vibrant atmosphere with street vendors and local artists nearby."
        },
        {
            "title": "Marine Drive",
            "description": "Known as the Queen’s Necklace due to its sparkling night view, Marine Drive is a beautiful promenade stretching along the Arabian Sea. Visitors can enjoy a leisurely walk, bask in the coastal breeze, and take in stunning sunset views over the water. The area is lined with art deco buildings, cafes, and street food stalls, making it a lively spot to experience Mumbai's urban charm and culture, especially during the evening when the city comes alive."
        },
        {
            "title": "Chhatrapati Shivaji Maharaj Terminus (CST)",
            "description": "A UNESCO World Heritage site, CST is one of Mumbai’s architectural marvels, showcasing a blend of Victorian Gothic Revival and Indian architectural styles. Opened in 1887, this historic railway station is not only a functional transit hub but also a stunning example of intricate design, featuring beautiful stained glass, vaulted roofs, and decorative stonework. Visitors can explore its rich history and vibrant atmosphere, making it an essential stop for architecture enthusiasts."
        },
        {
            "title": "Juhu Beach",
            "description": "One of Mumbai’s most popular beaches, Juhu offers a classic experience of local street food, vibrant crowds, and a scenic coastline. Visitors can indulge in famous local snacks like pav bhaji and bhel puri from the numerous stalls lining the beach. Juhu Beach is ideal for relaxation, especially during sunset, where families gather to enjoy the views and children play in the sand. It’s a lively spot that embodies the city's beach culture and charm."
        },
        {
            "title": "Elephanta Caves",
            "description": "Located on Elephanta Island, these UNESCO-listed rock-cut caves feature magnificent sculptures and carvings of Hindu deities, particularly Lord Shiva. Accessible by ferry from the Gateway of India, the caves offer a glimpse into ancient Indian artistry and spirituality. Visitors can explore various caves, including the stunning Main Cave, which houses the iconic Trimurti sculpture. The serene island setting, combined with the historical significance of the caves, makes it a fascinating site for history and art lovers."
        },
        {
            "title": "Chowpatty Beach",
            "description": "Known for its bustling atmosphere and traditional snacks, Chowpatty Beach is a favorite among locals and tourists alike. The beach comes alive in the evenings with food stalls offering famous treats like pav bhaji and bhel puri, creating a lively ambiance. Visitors can relax on the sandy shores, enjoy the lively crowds, and watch the sunset over the Arabian Sea, making it a perfect spot to experience Mumbai's vibrant beach culture."
        },
        {
            "title": "Sanjay Gandhi National Park",
            "description": "This vast green expanse within Mumbai is home to a variety of flora and fauna, including the famous Kanheri Caves that date back to the 1st century BCE. The park offers numerous activities for nature lovers, including trekking, boating, and wildlife spotting. It is a sanctuary for many species of birds and animals, providing a peaceful escape from the city's hustle and bustle. The combination of natural beauty and historical significance makes Sanjay Gandhi National Park a must-visit for anyone looking to connect with nature."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi to Mumbai Sightseeing Tour",
        "description": "Our Shirdi to Mumbai Sightseeing Tour offers an in-depth exploration of the bustling metropolis of Mumbai. On this tour, you’ll visit iconic landmarks such as the Gateway of India, a symbol of Mumbai's colonial past, and Marine Drive, the city's picturesque waterfront. Explore the grandeur of Chhatrapati Shivaji Terminus, a UNESCO World Heritage Site, and take a stroll through the vibrant streets of Colaba and Fort. The tour also includes visits to cultural and spiritual sites such as the Siddhivinayak Temple and Haji Ali Dargah. Our experienced guides will provide fascinating insights into Mumbai’s rich history, diverse culture, and its role as the economic and entertainment hub of India, making your visit both educational and enriching."
    },
    {
        "name": "Shirdi to Mumbai Tour Package",
        "description": "The Shirdi to Mumbai Tour Package offers you a complete package to explore Mumbai’s top attractions, from the colonial heritage to the modern-day metropolis. Whether you’re a solo traveler looking for a quick getaway or a family looking for a more relaxed, immersive experience, our tour package can be customized to suit your needs. The tour includes visits to iconic attractions like the Gateway of India, Elephanta Caves, and Juhu Beach. Additionally, explore local markets like Crawford Market and experience the local street food culture. We offer both one-day and extended tours that can be customized to focus on art, history, shopping, or spirituality."
    },
    {
        "name": "Shirdi to Mumbai Day Tour",
        "description": "Our Shirdi to Mumbai Day Tour is perfect for travelers who have limited time but still want to see the best of what Mumbai has to offer. This tour is designed to maximize your time by covering all the must-see spots in the city, including the famous Marine Drive, the bustling streets of Colaba, and the heritage-rich Chhatrapati Shivaji Maharaj Vastu Sangrahalaya. You will also visit cultural landmarks like the Haji Ali Dargah and Siddhivinayak Temple. The day tour is designed to offer a hassle-free, enriching experience with well-planned transportation, timely pickups, and a knowledgeable guide who will help you navigate the city while ensuring you don’t miss out on its most important landmarks."
    },
    {
        "name": "Best Shirdi to Mumbai Darshan Package",
        "description": "Our Best Shirdi to Mumbai Darshan Package offers an ideal combination of spiritual exploration and sightseeing. This package is designed for those who wish to connect with Mumbai’s spiritual heritage, visiting prominent temples and religious sites such as the Siddhivinayak Temple, Haji Ali Dargah, and the serene Banganga Tank. In addition to the spiritual sites, the package includes a visit to the Gateway of India and other key landmarks that highlight Mumbai’s cultural richness. You’ll be guided by experienced professionals who offer insightful commentary about the significance of each site, ensuring that your spiritual journey is also an enlightening cultural experience."
    },
    {
        "name": "Shirdi to Mumbai Taxi for Sightseeing",
        "description": "Our Shirdi to Mumbai Taxi for Sightseeing service provides a personalized way to explore the city at your own pace. You’ll have the flexibility to stop at your desired locations, whether it's taking a photo at the Gateway of India, spending time at Marine Drive, or visiting the hidden gems of Mumbai’s vibrant streets. Our experienced drivers are well-versed in the city’s traffic patterns, ensuring a smooth and timely journey. You’ll also benefit from an air-conditioned, well-maintained vehicle, providing you with the utmost comfort throughout your tour."
    },
    {
        "name": "Shirdi to Mumbai Car Rental for Darshan",
        "description": "For those looking for a private and comfortable mode of transportation, our Shirdi to Mumbai Car Rental for Darshan service offers the perfect solution. Whether you're visiting for a religious trip or to explore the city’s cultural landmarks, we offer a fleet of vehicles ranging from budget-friendly cars to premium luxury vehicles. The service includes a professional driver who will ensure your journey is smooth and relaxing, making stops at key religious sites like Siddhivinayak Temple, Haji Ali Dargah, and more. Experience the best of both comfort and convenience as you travel in style and peace."
    },
    {
        "name": "Mumbai Darshan from Shirdi",
        "description": "The Mumbai Darshan from Shirdi package offers an all-inclusive, hassle-free journey from Shirdi to Mumbai, with everything arranged for you—from comfortable transportation to sightseeing and meals. Whether you're visiting Mumbai for the first time or have a spiritual or cultural interest in the city, this package covers the city’s most iconic sites. You will visit the Gateway of India, the historic Elephanta Caves, the Siddhivinayak Temple, and Marine Drive, among others. Enjoy a comprehensive Mumbai experience with a knowledgeable guide, while our experienced drivers ensure your comfort and safety throughout your trip."
    },
    {
        "name": "Shirdi to Mumbai Distance for Sightseeing",
        "description": "The distance from Shirdi to Mumbai is approximately 240 kilometers, making it an ideal route for a one-day sightseeing trip or an extended tour. Our services are designed to ensure a smooth and enjoyable journey, whether you're traveling by taxi, car rental, or bus. The route offers beautiful views of the Maharashtra countryside, and our experienced drivers know the best routes to avoid heavy traffic, ensuring you arrive in Mumbai ready to explore the city. With the convenience of comfortable transport and well-planned routes, your journey will be as enjoyable as the destination itself."
    },
    {
        "name": "Shirdi to Mumbai Local Tour Package",
        "description": "Our Shirdi to Mumbai Local Tour Package provides a deeper dive into the city’s rich history, culture, and local experiences. With flexible itineraries, you can visit the architectural masterpieces such as Chhatrapati Shivaji Terminus, the vibrant Dharavi slums to understand the heart of Mumbai's local life, or visit lesser-known temples and cultural spaces. This tour also includes unique experiences like tasting local street food, exploring the colorful markets of Mumbai, or even a Bollywood studio tour. This package is ideal for travelers who want to experience the city in an authentic, off-the-beaten-path manner."
    },
    {
        "name": "Shirdi to Mumbai Innova Crysta",
        "description": "Travel in style and comfort with our Shirdi to Mumbai Innova Crysta service. The Innova Crysta offers ample space, luxurious seating, and modern amenities such as air conditioning, reclining seats, and entertainment options. Ideal for family groups or small corporate tours, this service ensures that you travel in comfort to all the popular attractions of Mumbai, including Siddhivinayak Temple, Gateway of India, and Marine Drive. The vehicle’s spacious interior is perfect for a relaxed journey, whether you're traveling for a day trip or an extended stay in Mumbai."
    },
    {
        "name": "Shirdi to Mumbai Tempo Traveller on Rent",
        "description": "Our Shirdi to Mumbai Tempo Traveller on Rent is the perfect option for large groups or families looking to travel together. With seating capacity for 12 to 20 people, this vehicle provides a comfortable, spacious ride with air conditioning and plenty of luggage space. The Tempo Traveller is ideal for those planning a group tour of Mumbai's cultural landmarks, including the Gateway of India, Elephanta Caves, Siddhivinayak Temple, and Juhu Beach. With a dedicated driver and tour guide, this service ensures a relaxed and enjoyable experience for everyone."
    },
    {
        "name": "Shirdi to Navi Mumbai Cabs",
        "description": "For travelers heading to Navi Mumbai, our Shirdi to Navi Mumbai Cabs service provides a reliable and comfortable transportation option. Navi Mumbai is a growing city with modern infrastructure, and our service ensures a smooth ride to key areas such as Vashi, Belapur, and the exciting Palm Beach Road. Whether you’re heading to a business meeting, a family gathering, or a sightseeing trip, our taxi service ensures you get there in comfort, with professional drivers who are familiar with the best routes to avoid traffic."
    },
    {
        "name": "Shirdi to Mumbai Taxi Service",
        "description": "Our Shirdi to Mumbai Taxi Service is known for its punctuality, reliability, and professionalism. Whether you're traveling for a business trip, a spiritual visit, or a sightseeing excursion, our experienced drivers provide a safe and smooth ride. The service is flexible, allowing you to choose your departure time, and it includes air-conditioned, well-maintained vehicles for your comfort. The route between Shirdi and Mumbai is well known, and our drivers ensure that your journey is stress-free and timely."
    },
    {
        "name": "Shirdi to Mumbai Mini Bus On Rent",
        "description": "Our Shirdi to Mumbai Mini Bus on Rent service is ideal for larger groups, school trips, or corporate outings. With seating for up to 35 people, the mini bus provides a comfortable, spacious environment for group travel. Whether you're planning a tour of Mumbai’s heritage sites, a beach visit, or a corporate outing, our mini bus service ensures that you and your group travel together in comfort. Equipped with modern amenities such as air conditioning and a professional driver, your journey will be smooth, and you can focus on enjoying the sights of Mumbai together."
    },
    {
        "name": "Shirdi to Mumbai Darshan Package Contact Information",
        "description": "To book your Shirdi to Mumbai Darshan Package or for more details about our services, please contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Our team is available to assist you with customizing your itinerary, ensuring you get the most out of your trip to Mumbai. Whether you're looking for a full sightseeing tour, a spiritual journey, or a relaxed family vacation, we are dedicated to providing an unforgettable experience tailored to your preferences. Get in touch today to plan your perfect Mumbai getaway!"
    }
],
"tableData": [
    ["- Shirdi to Mumbai Darshan Package", "- Shirdi to Mumbai Sightseeing Tour"],
    ["- Shirdi to Mumbai Tour Package", "- Shirdi to Mumbai Day Tour"],
    ["- Best Shirdi to Mumbai Darshan Package", "- Shirdi to Mumbai Taxi for Sightseeing"],
    ["- Shirdi to Mumbai Car Rental for Darshan", "- Mumbai Darshan from Shirdi"],
    ["- Shirdi to Mumbai Distance for Sightseeing", "- Shirdi to Mumbai Local Tour Package"],
    ["- Shirdi to Mumbai Innova Crysta", "- Shirdi to Mumbai Tempo Traveller on Rent"],
    ["- Shirdi to Navi Mumbai Cabs", "- Shirdi to Mumbai Taxi Service"],
    ["- Shirdi to Mumbai Mini Bus on Rent", "- SaitirthTours & Travels Shirdi"]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "All-Inclusive Package",
      "WhyChoosedescription": "Our Shirdi to Mumbai Darshan package is designed for an easy, all-in-one experience, covering transportation, guided tours, and entrance fees to iconic attractions, so you don’t have to worry about individual arrangements. This comprehensive package includes everything you need for a fulfilling visit, from reliable pick-up and drop-off to professional guide services. This ensures a seamless journey where you can focus on enjoying each destination. Our team also handles any required tickets and permits, saving you time and making your experience as stress-free as possible. Our all-inclusive packages provide excellent value for an unforgettable day exploring Mumbai’s top sights."
    },
    {
      "WhyChooseheading": "Expert Local Guides",
      "WhyChoosedescription": "Our experienced local guides bring the essence of Mumbai to life with insightful commentary and intriguing stories about each landmark. With deep knowledge of Mumbai's culture, history, and hidden gems, our guides offer much more than just facts. They provide rich narratives and cultural context, making each site visit more meaningful. They can answer any questions you may have, giving you a more personal and connected experience. Our guides know how to navigate busy attractions, maximizing your time and providing a smooth experience. Their expertise adds a unique layer to your journey, transforming a simple sightseeing trip into a memorable cultural immersion."
    },
    {
      "WhyChooseheading": "Comfortable Travel",
      "WhyChoosedescription": "Your comfort is our priority, which is why our vehicles are meticulously maintained, offering spacious seating, climate control, and smooth handling for a relaxing journey. Whether you're traveling alone or with a group, our fleet can accommodate various needs, from luxurious cars to roomy vans. Each vehicle is regularly serviced and sanitized to ensure the highest level of cleanliness and reliability. Our interiors are designed to help you unwind, and we keep refreshments available for long trips. We aim to make your journey comfortable and enjoyable, ensuring you arrive refreshed and ready to explore Mumbai’s vibrant attractions."
    },
    {
      "WhyChooseheading": "Personalized Itineraries",
      "WhyChoosedescription": "We understand that every traveler has unique interests, and our customizable itineraries reflect this. You can tailor your tour based on personal preferences, whether you’re interested in historic landmarks, scenic spots, or culinary experiences. Our team is ready to work with you to create a schedule that fits your priorities and time constraints, allowing you to see more of what you love and less of what you don’t. If you want extra time at a particular stop or wish to add new places to your itinerary, we are flexible and accommodating. Our goal is to provide a travel experience that feels just right for you, allowing for a one-of-a-kind adventure."
    },
    {
      "WhyChooseheading": "Convenient Scheduling",
      "WhyChoosedescription": "Our flexible scheduling options are designed to fit your specific timing needs. With customizable departure times, you can plan your day according to what works best for you, whether it’s an early start to beat the crowds or a relaxed afternoon departure. This flexibility allows you to make the most of your day without feeling rushed. Our team also monitors traffic conditions and peak visiting hours to recommend the best timings for each location. We understand that plans can change, and we strive to accommodate last-minute adjustments to keep your experience smooth and enjoyable."
    },
    {
      "WhyChooseheading": "Safety and Security",
      "WhyChoosedescription": "Your safety is of utmost importance to us. Our vehicles undergo strict, regular maintenance to meet top safety standards, and all drivers are trained in safety protocols and certified to handle long-distance trips. We also provide insurance coverage to ensure a worry-free experience. Our drivers are trained to follow defensive driving techniques, and our cars are equipped with GPS tracking to enhance security. Additionally, we strictly follow hygiene protocols, including regular sanitization, to ensure a safe and clean environment. You can relax knowing that every precaution is taken to provide a secure journey."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "We believe that exploring Mumbai should be accessible to everyone, which is why we offer competitively priced packages that provide great value without sacrificing quality. Our transparent pricing policy ensures there are no hidden fees, allowing you to plan your budget effectively. You get high-quality service, experienced guides, and comfortable travel, all at a reasonable rate. We regularly review our pricing to stay competitive while maintaining the standards our customers expect. Our goal is to deliver an outstanding experience that’s not only memorable but also affordable, making it easy for you to enjoy all Mumbai has to offer."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers and guides are not just experts in transportation but also locals who are passionate about their city. They bring unique perspectives and insights that make your tour more authentic and enjoyable. Whether it’s a famous local eatery, a scenic viewpoint, or a hidden gem, they can suggest spots that offer a deeper connection to Mumbai beyond the usual tourist attractions. Our team’s insider knowledge allows us to take you to places that might not be on the typical itinerary, enriching your experience with the cultural and historical flavor that only a local can provide."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with your booking, answer any questions, or help with adjustments to your itinerary. We understand that travel plans can be unpredictable, and our team is ready to provide assistance whenever you need it. Whether you need help with a last-minute change, have questions about the tour, or need guidance on pick-up details, our friendly and knowledgeable representatives are just a call away. Our commitment to excellent customer service means you can count on us to support you throughout your journey."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "Our commitment to excellence has earned us positive reviews and loyal clients who appreciate our dedication to quality service and memorable experiences. Our clients often share how they value our professional approach, knowledgeable guides, and the seamless way our packages are organized. We constantly strive to improve based on customer feedback, ensuring that each experience meets our high standards. Choosing us means joining a community of satisfied travelers who have trusted us to deliver exceptional service for their journeys. We take pride in our reputation, and our goal is to exceed your expectations."
    }
  ]





        
  }


  const faqData = [
    {
        question: "What attractions are included in the package?",
        answer: "The package typically includes visits to major attractions such as Gateway of India, Marine Drive, Chhatrapati Shivaji Maharaj Terminus, and Siddhivinayak Temple."
    },
    {
        question: "How long is the journey from Shirdi to Mumbai?",
        answer: "The journey takes approximately 6 to 7 hours, depending on traffic, providing ample time for sightseeing upon arrival."
    },
    {
        question: "Is lunch included in the package?",
        answer: "Lunch is not usually included, but we can recommend local restaurants for you to enjoy authentic Mumbai cuisine."
    },
    {
        question: "Can I customize the itinerary?",
        answer: "Yes, we offer customizable itineraries to include additional sites or activities based on your interests and schedule."
    },
    {
        question: "What types of vehicles are used for the journey?",
        answer: "We provide a range of vehicles, including sedans, SUVs, and vans, to accommodate different group sizes comfortably."
    },
    {
        question: "How do I book the Shirdi to Mumbai Darshan package?",
        answer: "You can easily book the package through our website or by contacting our customer service team directly."
    },
    {
        question: "Are group bookings available?",
        answer: "Yes, we can cater to large groups, including corporate outings and family trips, with special arrangements available."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Cancellations can be made with prior notice. Please contact our customer service for assistance with any changes to your booking."
    },
    {
        question: "Is there an age limit for the attractions?",
        answer: "There are no specific age restrictions for visiting the attractions, but please note that some sites may involve walking or climbing."
    },
    {
        question: "How can I reach you for more details?",
        answer: "For more information about our packages, feel free to contact us via phone or email."
    }
];

const testimonials = [
    {
        name: "Mr. Ramesh Iyer",
        text: "Our Mumbai Darshan trip with Saitirth Travels was unforgettable! The driver was punctual and knowledgeable, making the journey enjoyable. The guide's insights made our visit to iconic landmarks truly special. Highly recommend this package!"
    },
    {
        name: "Mrs. Neeta Singh",
        text: "I booked the Shirdi to Mumbai Darshan package for my family, and it was a fantastic experience. The itinerary was well-planned, and our guide was friendly and informative. We had a wonderful time exploring the city!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mumbai Darshan Package | Best Tour Package | SaitirthTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Mumbai Darshan Package with SaitirthTours & Travels. Enjoy a memorable sightseeing tour to Mumbai, including taxi service, car rental, and transportation options." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mumbai Darshan Package | Best Tour Package | SaitirthTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Mumbai Darshan Package with SaitirthTours & Travels. Enjoy a memorable sightseeing tour to Mumbai, including taxi service, car rental, and transportation options." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-mumbai-darshan-package" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-mumbai-darshan-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book an affordable Shirdi to Mumbai Darshan Package with SaitirthTours & Travels. Enjoy a memorable sightseeing tour to Mumbai, including taxi service, car rental, and transportation options.",
            "url": "https://www.saitirthcabs.com/shirdi-to-mumbai-darshan-package",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-mumbai-darshan-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Mumbai Darshan Package",
                "Shirdi to Mumbai Sightseeing Tour",
                "Shirdi to Mumbai Tour Package",
                "Shirdi to Mumbai Day Tour",
                "Best Shirdi to Mumbai Darshan Package",
                "Shirdi to Mumbai taxi for Sightseeing",
                "Shirdi to Mumbai Car Rental for Darshan",
                "Mumbai Darshan from Shirdi",
                "Shirdi to Mumbai Local Tour Package",
                "Shirdi to Mumbai Innova Crysta",
                "Shirdi to Mumbai Tempo Traveller on Rent",
                "Shirdi to Navi Mumbai Cabs",
                "Shirdi to Mumbai Taxi Service",
                "Shirdi to Mumbai Mini bus On Rent",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/66.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomumbaidarshan;