
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Innovacrista() {



  const cardData =
  {
    keyword: ' Innova Crysta Cab on Rent in Shirdi',
    heading: 'Saitirth Travels: Innova Crysta Cab on Rent in Shirdi',
    headingDescription: 'Experience luxury and comfort with Saitirth Travels Innova Crysta cab rental services in Shirdi. Ideal for families, groups, or corporate travelers, the Innova Crysta is renowned for its spacious interiors, advanced features, and reliable performance, making it the perfect choice for your travel needs.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels	',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The Sai Baba Temple is the heart of Shirdi and one of the most significant pilgrimage sites in India. Dedicated to the revered saint Sai Baba, the temple is a magnificent structure that can accommodate thousands of devotees at a time. The main shrine houses a beautiful idol of Sai Baba, often adorned with vibrant flowers and offered aarti (worship) several times a day. The temple complex features spacious courtyards, intricately carved pillars, and a serene atmosphere, allowing devotees to immerse themselves in prayer and reflection. Visitors can participate in various rituals and ceremonies throughout the day, including special events during religious festivals. The evening aarti is particularly enchanting, filled with devotion and spirituality, and draws large crowds who come to witness the fervent expressions of faith."
        },
        {
            "title": "Dwarkamai",
            "description": "Dwarkamai is an ancient mosque that holds immense historical and spiritual significance. It is believed to be where Sai Baba lived for most of his life and performed many miracles. The mosque features the sacred fire, Dhuni, that Baba lit and which still burns to this day, symbolizing the eternal spirit of his teachings. Visitors can admire the intricate calligraphy and photographs adorning the walls, illustrating Baba's life and the many devotees he touched. The tranquil ambiance here provides a perfect setting for meditation and reflection on Baba’s wisdom and teachings, making it a favored spot for spiritual seekers."
        },
        {
            "title": "Chavadi",
            "description": "Located close to Dwarkamai, Chavadi is another significant place associated with Sai Baba. It served as a resting place for Baba, who was known to spend alternate nights here, sharing his wisdom with devoted followers. The structure features a beautiful portrait of Sai Baba, surrounded by an atmosphere of reverence. The wooden architecture, reminiscent of traditional styles, adds to the charm of this location. The annual festival held here attracts numerous visitors, marked by special prayers and celebrations, making it a vibrant site of devotion and community gathering."
        },
        {
            "title": "Lendi Garden",
            "description": "Lendi Garden is a beautiful and serene spot for visitors seeking solace in nature. This garden was once frequented by Sai Baba for meditation and reflection, and it features a variety of plants, trees, and blooming flowers, making it an ideal place for a leisurely stroll or a quiet moment of contemplation. There are benches and shaded areas where visitors can sit and relax, enjoying the soothing sounds of nature. The garden is also home to a lovely well, believed to have been used by Sai Baba himself, adding to its charm as a place of tranquility and peace."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "The Shri Khandoba Mandir is a significant temple dedicated to Lord Khandoba, an incarnation of Lord Shiva. Situated atop a hill, the temple offers panoramic views of the surrounding landscape, making it a popular pilgrimage site. The impressive architecture features intricate carvings and sculptures, reflecting the devotion of its builders. Pilgrims visit this temple, especially during festivals, to seek blessings and participate in vibrant celebrations. Its elevated location not only provides breathtaking views but also creates a serene environment ideal for prayer and reflection."
        },
        {
            "title": "Maruti Mandir",
            "description": "Maruti Mandir is dedicated to Lord Hanuman and is a peaceful temple located near the Sai Baba Temple. It is a favored site among devotees who come to offer prayers and seek blessings for strength and courage. The temple features a serene ambiance, adorned with beautiful idols and traditional decor, creating an inviting atmosphere. Visitors often find this temple to be a perfect spot for quiet reflection and devotion, fostering a sense of inner peace and connection with the divine."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan holds a special place in the hearts of devotees, as it is believed to be where Sai Baba was first discovered by the villagers. The site features a small shrine with a portrait of Sai Baba and a sacred tree where many pilgrims tie threads to fulfill their wishes. The atmosphere is filled with spirituality and devotion, making it a must-visit for anyone seeking a deeper connection with Sai Baba’s teachings. It serves as a reminder of Baba’s humble beginnings and his journey as a spiritual leader."
        },
        {
            "title": "Sai Heritage Village",
            "description": "Sai Heritage Village is a cultural center that showcases the life and teachings of Sai Baba. This attraction features a series of exhibits, including replicas of historical structures, displays of traditional crafts, and interactive experiences for visitors. Guides share captivating stories of Baba’s life, making it an educational experience for all ages. The village aims to preserve the cultural heritage of the region while providing insights into the profound impact of Sai Baba on society, making it an enlightening stop for all visitors."
        },
        {
            "title": "Khandoba Temple",
            "description": "Khandoba Temple, located a short distance from Shirdi, is dedicated to Khandoba, a revered local deity. The temple is renowned for its beautiful architecture and serene surroundings, making it a popular destination for devotees. It attracts many visitors, especially during the annual fairs held in honor of Khandoba, where traditional rituals are performed. Pilgrims can witness these vibrant celebrations and participate in the festive atmosphere, experiencing the rich local culture and spirituality of the area."
        },
        {
            "title": "Sainath Mahal",
            "description": "Sainath Mahal is a guesthouse managed by the Sai Baba Sansthan Trust, providing comfortable accommodation for visitors coming to Shirdi. The mahal is conveniently located, making it easy for pilgrims to access the main attractions. The environment is calm and welcoming, with various facilities to ensure a pleasant stay. Staying at Sainath Mahal offers travelers a unique opportunity to immerse themselves in the spiritual ambiance of Shirdi, fostering a sense of community among fellow pilgrims."
        }
    ],    
            
        
"services": [
    {
        "name": "Innova Crysta Cab on Rent in Shirdi",
        "description": "When it comes to comfortable and spacious travel, the Innova Crysta is an excellent choice for your transportation needs in Shirdi. Our Innova Crysta cab rental service is designed to provide you with a smooth and enjoyable travel experience, whether you are visiting for pilgrimage or leisure."
    },
    {
        "name": "Innova on Rent in Shirdi",
        "description": "SaitirthTours & Travels offers Innova on rent in Shirdi, catering to individuals and families looking for a reliable vehicle. The Innova Crysta is renowned for its robust performance and luxurious interior, making it perfect for both short trips and long journeys."
    },
    {
        "name": "Innova Crysta on Rent in Shirdi MIDC",
        "description": "If you're in the MIDC area and need a spacious vehicle, our Innova Crysta on rent is readily available. Ideal for corporate travel or family outings, the Innova Crysta ensures that everyone travels comfortably with ample luggage space."
    },
    {
        "name": "Innova Crysta Shani Shingnapur",
        "description": "Planning a visit to Shani Shingnapur? Our Innova Crysta cab service provides a convenient and comfortable way to reach this famous temple town. Enjoy the scenic drive while seated in the comfort of our well-maintained Innova Crysta."
    },
    {
        "name": "Innova Crysta Cab Hire in Shirdi Shani Shingnapur",
        "description": "Hire an Innova Crysta cab in Shirdi for your trip to Shani Shingnapur and experience exceptional service with SaitirthTours & Travels. Our professional drivers ensure you arrive safely and on time."
    },
    {
        "name": "Innova Crysta Cab on Rent Shirdi Airport",
        "description": "Arriving at Shirdi Airport? Book an Innova Crysta cab on rent to ensure a hassle-free transfer to your destination. Our airport cab service is designed to meet the needs of all travelers, providing a comfortable ride straight from the airport."
    },
    {
        "name": "Book Innova Cabs in Shirdi",
        "description": "Booking an Innova cab in Shirdi is easy with SaitirthTours & Travels. Our straightforward booking process allows you to reserve your vehicle online, ensuring you have your cab ready upon arrival."
    },
    {
        "name": "Innova Cab for Wedding Car Rental",
        "description": "If you're looking for a wedding car rental, our Innova Crysta is a perfect choice. With its elegant design and spacious seating, it can accommodate your wedding party comfortably, making your special day even more memorable."
    },
    {
        "name": "Innova Cabs for Corporate Employees",
        "description": "We also provide Innova cabs for corporate employees, ensuring that your team travels in style and comfort. Whether it's for business meetings or events, our professional service caters to all your corporate travel needs."
    },
    {
        "name": "Innova Cab Hire in Shirdi Airport",
        "description": "Our Innova cab hire service at Shirdi Airport offers convenience and reliability. Enjoy a smooth transition from your flight to your destination with our dedicated cab service."
    },
    {
        "name": "Innova Car Rental in Shirdi Airport",
        "description": "For travelers looking for flexibility, our Innova car rental service at Shirdi Airport is ideal. Explore the region at your own pace with the comfort of our Innova Crysta."
    },
    {
        "name": "Shirdi to Pune Airport Drop Innova Crysta",
        "description": "If you need a ride from Shirdi to Pune Airport, our Innova Crysta is the best choice. Enjoy a relaxing drive with sufficient space for your luggage and a comfortable journey."
    },
    {
        "name": "Shirdi to Nashik Innova Crysta on Rent",
        "description": "Traveling from Shirdi to Nashik? Rent our Innova Crysta for a smooth journey, ensuring you arrive at your destination relaxed and refreshed."
    },
    {
        "name": "Shirdi to Mumbai Innova Crysta on Rent",
        "description": "Our Innova Crysta rental service is also available for trips from Shirdi to Mumbai. Experience the comfort and reliability of our vehicles as you travel to one of India's busiest cities."
    },
    {
        "name": "Shirdi to Mumbai Airport Innova Crysta",
        "description": "Need transportation to Mumbai Airport? Our Innova Crysta provides a comfortable and reliable option for airport transfers, ensuring you reach on time."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for your Innova Crysta cab rental in Shirdi. We are committed to providing our customers with exceptional service and well-maintained vehicles to enhance your travel experience."
    },
    {
        "name": "Innova Crysta Cab on Rent in Shirdi Contact Information",
        "description": "For bookings or inquiries, contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. We look forward to serving you and making your journey unforgettable!"
    }
],

tableData: [
    ["- Innova Crysta Cab on Rent Shirdi", "- Innova on rent in Shirdi"],
    ["- Innova Crysta on rent in Shirdi MIDC", "- Innova Crysta Shani Shingnapur"],
    ["- Innova Crysta cab hire in Shirdi Shani Shingnapur", "- Innova Crysta cab on rent Shirdi Airport"],
    ["- Book Innova Cabs in Shirdi", "- Innova Cab for Wedding car rental"],
    ["- Innova cabs for corporate employees", "- Innova cab hire in Shirdi Airport"],
    ["- Innova car rental in Shirdi Airport", "- Shirdi to Pune Airport drop Innova Crysta"],
    ["- Shirdi to Nashik Innova Crysta on Rent", "- Shirdi to Mumbai Innova Crysta on Rent"],
    ["- Shirdi to Mumbai Airport Innova Crysta", "- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Comfort and Style",
      "WhyChoosedescription": "The Innova Crysta offers the ideal blend of luxury, comfort, and performance for your travel needs. Whether you're embarking on a pilgrimage, attending a corporate meeting, or exploring tourist attractions, the spacious cabin, plush seating, and smooth ride ensure you travel in style. Its robust build and top-notch features provide an exceptionally comfortable experience on both short and long journeys, making it an ideal choice for travelers seeking a high-end experience."
    },
    {
      "WhyChooseheading": "Affordable Rental Rates",
      "WhyChoosedescription": "At SaitirthTours & Travels, we believe that comfort and quality should not come with a hefty price tag. Our competitive rental rates for the Innova Crysta ensure that you get value for money without compromising on quality. Whether you’re looking for a quick trip or a multi-day rental, we offer flexible pricing that fits within your budget. Enjoy premium services with transparent pricing, and no hidden charges, allowing you to travel comfortably without breaking the bank."
    },
    {
      "WhyChooseheading": "Well-Maintained Vehicles",
      "WhyChoosedescription": "We take pride in our fleet of vehicles, and the Innova Crysta is no exception. All our vehicles undergo regular servicing and are thoroughly inspected to ensure they are in top condition before each trip. Whether it's the engine, air conditioning, or seat upholstery, every aspect of the vehicle is maintained to guarantee a smooth and safe journey. Additionally, our vehicles are sanitized after every trip to ensure you travel in a clean and hygienic environment, providing peace of mind on your journey."
    },
    {
      "WhyChooseheading": "Experienced Drivers Available",
      "WhyChoosedescription": "Our drivers are not only skilled but also experienced in navigating Shirdi and surrounding areas, ensuring that you reach your destination safely and comfortably. They are well-versed with the best routes, local traffic patterns, and popular tourist spots, offering you more than just a ride. For those new to the area, our drivers also provide valuable local insights and suggestions for sightseeing. Your safety and comfort are our priority, and our drivers' professionalism guarantees a smooth experience throughout your journey."
    },
    {
      "WhyChooseheading": "Flexible Rental Packages",
      "WhyChoosedescription": "We understand that each traveler's needs are unique, which is why we offer flexible rental packages that cater to your specific requirements. Whether you need the Innova Crysta for a few hours, a full day, or a longer rental period, we have customizable options to suit your schedule. For those embarking on a pilgrimage, business trip, or family vacation, our rental packages are designed to offer maximum convenience and flexibility, allowing you to plan your trip as per your preferences."
    },
    {
      "WhyChooseheading": "Convenient Booking Process",
      "WhyChoosedescription": "Booking your Innova Crysta with us is hassle-free and straightforward. With a user-friendly online booking system, you can reserve your vehicle in just a few clicks. We also offer assistance via phone for those who prefer a more personalized booking experience. Whether you're planning a last-minute trip or booking in advance, we ensure your vehicle is ready when you need it, eliminating the stress and complexity often associated with car rentals."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "We pride ourselves on providing excellent customer support that is available round the clock to assist with all your inquiries. Our team is here to help with bookings, answer any questions, and accommodate last-minute changes to your itinerary. Whether you need to modify your booking or require any assistance during your journey, we’re always just a phone call away, offering responsive and reliable support whenever you need it."
    },
    {
      "WhyChooseheading": "On-Time Pickup and Drop Service",
      "WhyChoosedescription": "Timeliness is crucial to us, which is why we guarantee on-time pickups and drop-offs for every booking. We understand the importance of punctuality, especially when you have a busy schedule or need to catch a flight. Our drivers ensure that your trip begins and ends on time, and with us, you can rest assured that you will never miss an appointment or important event. We value your time, so we make punctuality a priority in every journey."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What are the rental rates for Swift Dzire in Shirdi?",
        answer: "Rates vary based on the rental duration and itinerary. Contact us for a tailored quote."
    },
    {
        question: "Can I rent a Swift Dzire for a day trip around Shirdi?",
        answer: "Yes, we offer daily rentals and even hourly packages if you have specific short trips in mind."
    },
    {
        question: "Do you offer self-drive options for Swift Dzire?",
        answer: "Currently, our rentals come with professional drivers for a safer experience, but feel free to ask about future self-drive options."
    },
    {
        question: "Is fuel included in the rental cost?",
        answer: "Fuel is typically excluded, but packages with fuel are available upon request."
    },
    {
        question: "Can I extend my booking during the rental period?",
        answer: "Yes, if the vehicle is available, we allow extensions with an adjustment to your booking rate."
    },
    {
        question: "Do you provide pickup and drop services within Shirdi?",
        answer: "Yes, our Swift Dzire rental includes convenient pickup and drop within Shirdi or nearby areas."
    },
    {
        question: "What documents are required to book the Swift Dzire?",
        answer: "A valid ID is required, and if you're booking a self-drive option (when available), you’ll need a driver’s license."
    },
    {
        question: "Is the Swift Dzire suitable for long-distance travel from Shirdi?",
        answer: "Yes, the Swift Dzire is a comfortable choice for long journeys, and we can assist in planning outstation travel."
    },
    {
        question: "Do you offer discounts for extended rentals?",
        answer: "Yes, discounts are available for extended bookings. Contact us for more details."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We offer a flexible cancellation policy. Contact our team to learn about applicable terms."
    }
];

const testimonials = [
    {
        name: "Mr. Rahul Mehta",
        text: "My experience with renting the Innova Crysta from Saitirth Travels in Shirdi was exceptional! The vehicle was spacious, comfortable, and perfect for my family trip. The staff was very accommodating and made the booking process easy. I highly recommend Saitirth Travels for anyone looking for a reliable cab service in Shirdi!"
    },
    {
        name: "Ms. Sneha Verma",
        text: "Renting the Innova Crysta from Saitirth Travels was one of the best decisions I made for my visit to Shirdi. The car was in pristine condition and provided a smooth ride throughout our journey. The customer service was top-notch, and I felt well taken care of. I will definitely choose Saitirth Travels for my future trips!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Innova Crysta Cab on Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Rent an Innova Crysta in Shirdi with SaitirthTours & Travels. Offering Innova Crysta rental services for local and outstation trips, including to Shani Shingnapur, Pune, Nashik, and Mumbai." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Innova Crysta Cab on Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Rent an Innova Crysta in Shirdi with SaitirthTours & Travels. Offering Innova Crysta rental services for local and outstation trips, including to Shani Shingnapur, Pune, Nashik, and Mumbai." />
    <meta property="og:url" content="https://www.saitirthcabs.com/innova-crysta-cab-on-rent-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/innova-crysta-cab-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Rent an Innova Crysta in Shirdi with SaitirthTours & Travels. Offering Innova Crysta rental services for local and outstation trips, including to Shani Shingnapur, Pune, Nashik, and Mumbai.",
            "url": "https://www.saitirthcabs.com/innova-crysta-cab-on-rent-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/innova-crysta-cab-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Innova Crysta Cab on Rent Shirdi",
                "Innova on Rent in Shirdi",
                "Innova Crysta on Rent in Shirdi MIDC",
                "Innova Crysta Shani Shingnapur",
                "Innova Crysta Cab Hire in Shirdi Shani Shingnapur",
                "Innova Crysta Cab on Rent Shirdi Airport",
                "Book Innova Cabs in Shirdi",
                "Innova Cab for Wedding Car Rental",
                "Innova Cabs for Corporate Employees",
                "Innova Cab Hire in Shirdi Airport",
                "Innova Car Rental in Shirdi Airport",
                "Shirdi to Pune Airport Drop Innova Crysta",
                "Shirdi to Nashik Innova Crysta on Rent",
                "Shirdi to Mumbai Innova Crysta on Rent",
                "Shirdi to Mumbai Airport Innova Crysta",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/74.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Innovacrista;