
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdicabs() {



  const cardData =
  {
    keyword: ' Shirdi Cabs',
    heading: 'Saitirth Travels: Shirdi Cabs',
    headingDescription: 'Traveling around Shirdi is made easy with Saitirth Travels reliable cab services. We offer a range of cab options to suit your needs, whether you’re visiting for pilgrimage, tourism, or business. Our professional drivers ensure a safe and comfortable ride as you explore the spiritual heart of Maharashtra.',

    top: 'Top Places to Visit in and Around Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The iconic Sai Baba Temple is the main attraction in Shirdi, drawing millions of devotees from all corners of the world each year. This grand temple complex features stunning architecture, with intricately carved pillars and spacious halls that reflect the divine aura of Sai Baba. The serene atmosphere, combined with the continuous chanting of prayers and the aroma of incense, creates a spiritual ambiance that invites visitors to meditate and connect with their faith. Visitors can also participate in the daily rituals and Aarti, which enhance the spiritual experience and allow devotees to pay homage to Sai Baba."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Located just a short distance from the Sai Baba Temple, Khandoba Mandir is dedicated to Lord Khandoba, a revered deity in the region. This temple is a significant pilgrimage site known for its beautiful surroundings, vibrant festivals, and the panoramic views it offers of the nearby hills. Pilgrims flock to this site to seek blessings and experience the lively atmosphere, especially during the festivals when the temple is adorned with flowers and lights. The rich history and architectural beauty of Khandoba Mandir make it a worthwhile stop for anyone visiting Shirdi."
        },
        {
            "title": "Lendi Baug",
            "description": "Lendi Baug is a tranquil garden where Sai Baba spent a considerable amount of time during his life. This lush garden features a sacred well and is home to a variety of trees that Sai Baba is said to have planted himself. The peaceful ambiance of Lendi Baug offers visitors a perfect escape from the bustle of the temple complex, making it an ideal spot for quiet contemplation or meditation. The serene atmosphere, combined with the natural beauty of the surroundings, creates a rejuvenating experience for all who visit."
        },
        {
            "title": "Dixit Wada Museum",
            "description": "Dixit Wada Museum is a treasure trove of artifacts and photographs that chronicle the life and teachings of Sai Baba. This museum is located in the very Wada (mansion) where Sai Baba lived for many years and features personal belongings, manuscripts, and items used by him. The displays provide a deeper understanding of Sai Baba’s philosophies, his connection with the devotees, and the impact he had on their lives. This educational stop is not only enriching but also serves as a reminder of Sai Baba's teachings of love, compassion, and humility."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan holds immense spiritual significance as the place where Sai Baba is believed to have first appeared to his followers. This sacred site features a small shrine dedicated to Sai Baba and a holy tree, under which devotees often gather for prayer and reflection. The tranquility of Gurusthan, enhanced by the gentle rustling of leaves and the soft sounds of nature, creates a meditative space that invites deep contemplation. Visitors often leave offerings and prayers at this site, making it a pivotal stop for anyone seeking to connect with the spiritual legacy of Sai Baba."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust manages the operations of the Sai Baba Temple and oversees various charitable activities aimed at supporting the local community. Visitors to the trust can learn about its initiatives, including educational programs, healthcare services, and disaster relief efforts, all of which are designed to uplift and assist those in need. The trust’s commitment to service reflects Sai Baba’s teachings of selfless love and community support. This informative visit highlights the ongoing impact of Sai Baba’s legacy in promoting kindness and compassion among the community."
        },
        {
            "title": "Manmad Fort",
            "description": "Manmad Fort is located a short drive from Shirdi and offers a blend of history and natural beauty. The fort's ruins provide insight into the architectural prowess of bygone eras and the strategic importance of the fort in historical times. Visitors can explore the remains of the fort and enjoy panoramic views of the surrounding landscape, which is especially breathtaking during sunset. For history enthusiasts, this site serves as an excellent opportunity to delve into the rich heritage of the region while enjoying a peaceful retreat from the more tourist-heavy areas."
        },
        {
            "title": "Nashik Caves",
            "description": "Located a bit farther from Shirdi, the Nashik Caves are ancient rock-cut caves known for their exquisite carvings and historical significance. Dating back to the 1st century BCE, these caves feature intricate sculptures, Buddhist architecture, and stunning artistry that tell stories from ancient times. Exploring the Nashik Caves offers a fascinating journey into history, making it a perfect day trip for those interested in archaeology and art. The cool interiors of the caves provide a refreshing escape, and the surrounding hills add to the natural beauty of this historical site."
        },
        {
            "title": "Khandala Waterfall",
            "description": "Khandala Waterfall, situated near Shirdi, is a natural marvel that captivates nature lovers and adventure seekers alike. The waterfall cascades down amidst lush greenery, especially vibrant during the monsoon season, creating a picturesque setting ideal for picnics and photography. The area around the waterfall is perfect for trekking, with trails that offer stunning views of the surrounding landscape. This serene spot provides a refreshing escape from the hustle and bustle of Shirdi, making it an ideal location for families and groups looking to enjoy a day in nature."
        },
        {
            "title": "Shirdi Airport",
            "description": "Shirdi Airport is conveniently located about 15 kilometers from the town center, serving as a gateway for travelers visiting this spiritual hub. The airport features modern amenities and provides easy access to various transport options, including taxis that can take you directly to your destination in Shirdi. For those flying in, the airport offers a hassle-free experience, making it a popular entry point for pilgrims and tourists alike. The proximity of the airport to the main attractions ensures that visitors can start their spiritual journey without delay."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi Cabs",
        "description": "Discover the convenience of Shirdi cabs for all your travel needs. Whether you're planning to visit nearby attractions such as the Sai Baba Temple, travel to the airport, or explore the beautiful landscapes surrounding Shirdi, our cab service is ready to provide you with a comfortable and reliable experience. Our vehicles are regularly maintained and equipped with modern amenities to ensure a safe and enjoyable ride. With our trained drivers, you can relax and enjoy the sights without worrying about navigation or traffic."
    },
    {
        "name": "Shirdi to Mumbai Cab",
        "description": "Planning a trip from Shirdi to Mumbai? Our dedicated cab service offers timely pickups and comfortable rides, designed to help you reach your destination efficiently. Experience a stress-free journey with our professional drivers who know the best routes and traffic patterns, ensuring you arrive on time. We offer a variety of vehicles to choose from, so whether you’re traveling solo or with a group, we have the right option for you. Enjoy complimentary refreshments and Wi-Fi access during your ride, making your journey more enjoyable."
    },
    {
        "name": "Manmad to Shirdi Taxi",
        "description": "Traveling from Manmad to Shirdi? Choose our taxi service for a hassle-free journey. Our experienced drivers are familiar with the most efficient routes, allowing you to sit back and relax while we take care of the journey. We prioritize your comfort and safety, providing well-maintained vehicles equipped with all necessary safety features. Enjoy the scenic beauty along the way and make the most of your travel experience with our friendly and professional service."
    },
    {
        "name": "Shirdi Cab Service",
        "description": "We provide a comprehensive Shirdi cab service that caters to all your transportation needs. From local trips around Shirdi to longer outstation journeys, our fleet of well-maintained vehicles is at your disposal. Our commitment to punctuality means you can trust us for timely pickups and drop-offs, and our customer-centric approach ensures that your needs are always met with utmost care. We strive to make every ride memorable, offering personalized service that enhances your travel experience."
    },
    {
        "name": "Taxi Service in Shirdi",
        "description": "Our taxi service in Shirdi is renowned for its punctuality and professionalism. Whether you need transportation for a pilgrimage, a family outing, or a business trip, our fleet is ready to take you wherever you need to go. Each vehicle is meticulously cleaned and serviced to meet our high standards, ensuring that you travel in comfort and style. Our drivers are not only skilled at navigating the area but also friendly and knowledgeable, providing insights about local attractions to enrich your journey."
    },
    {
        "name": "Shirdi to Trimbakeshwar Cab",
        "description": "Visiting Trimbakeshwar? Book our cab service for a comfortable ride from Shirdi to this sacred site. Our experienced drivers will ensure you have a smooth journey, and we offer flexible scheduling to accommodate your travel plans. Enjoy the beautiful landscapes along the route and rest assured that our vehicles are equipped with all necessary amenities for your comfort. We prioritize your peace of mind, making your pilgrimage both enjoyable and spiritually uplifting."
    },
    {
        "name": "Taxi in Shirdi",
        "description": "Looking for a taxi in Shirdi? Our cabs are available for local sightseeing, airport transfers, and more. With our competitive rates and commitment to quality service, we ensure a stress-free travel experience. Our booking process is simple and efficient, allowing you to reserve your cab in just a few clicks. Trust us to provide a safe, reliable, and enjoyable ride as you explore the beauty of Shirdi and its surroundings."
    },
    {
        "name": "Cabs in Shirdi",
        "description": "We offer a wide range of cabs in Shirdi to suit your travel needs. Choose from sedans, SUVs, and larger vehicles for group travel, ensuring that you have the perfect option for your journey. All our vehicles are equipped with comfortable seating, air conditioning, and other amenities to enhance your travel experience. Our friendly drivers are committed to your comfort and safety, making every trip with us a pleasant one."
    },
    {
        "name": "Shirdi to Shani Shingnapur Cab",
        "description": "Travel easily from Shirdi to Shani Shingnapur with our reliable cab service. We provide comfortable transportation to this popular pilgrimage destination, ensuring you arrive relaxed and ready for your visit. Our drivers are not only professional but also knowledgeable about the area, offering insights about local customs and the significance of the sites you will be visiting. Enjoy a seamless travel experience with our prompt and courteous service."
    },
    {
        "name": "Shirdi to Bhimashankar Taxi Fare",
        "description": "Check our competitive taxi fares for trips from Shirdi to Bhimashankar. We aim to provide affordable rates without compromising on the quality of service. Our pricing is transparent, with no hidden charges, so you can travel with confidence. Enjoy the stunning scenery along the way as you embark on this spiritual journey, knowing that we are dedicated to providing you with a comfortable and pleasant ride."
    },
    {
        "name": "Best Taxi Service in Shirdi",
        "description": "Our reputation as the best taxi service in Shirdi is built on customer satisfaction. We focus on providing clean vehicles, courteous drivers, and prompt service. Whether you’re traveling for business or leisure, our commitment to excellence ensures that your experience exceeds your expectations. We continually strive to enhance our services, offering new routes, competitive rates, and the best in customer care."
    },
    {
        "name": "Shirdi to Nasik Trimbakeshwar Taxi Fare",
        "description": "Travel from Shirdi to Nasik and Trimbakeshwar at affordable rates. Our transparent pricing ensures you know what to expect for your journey. We believe in providing excellent service at competitive rates, making your travel plans budget-friendly. Enjoy the scenic route with our experienced drivers who will ensure your comfort and safety throughout the ride."
    },
    {
        "name": "Shirdi to Aurangabad Taxi Fare",
        "description": "Our taxi service offers competitive fares for journeys from Shirdi to Aurangabad. Book with us for a reliable and economical travel experience. Our friendly staff is available to assist you with any questions you may have regarding pricing or service options, ensuring you have all the information you need to make your journey smooth and enjoyable."
    },
    {
        "name": "Shirdi to Manmad Taxi Fare",
        "description": "Find out about our taxi fares for trips from Shirdi to Manmad. We provide affordable rates for all our cab services, ensuring you get excellent value without compromising on quality. Enjoy a smooth and comfortable ride with our professional drivers who are committed to making your travel experience enjoyable and hassle-free."
    },
    {
        "name": "Nagarsol to Shirdi Cab Charges",
        "description": "Traveling from Nagarsol to Shirdi? Our cab charges are reasonable, and we guarantee a pleasant journey with our professional drivers. Our service is designed to be customer-friendly, ensuring that you have a reliable and safe ride every time. Let us take care of your transportation needs as you focus on enjoying your trip."
    },
    {
        "name": "Cab Booking in Shirdi",
        "description": "Booking a cab in Shirdi is simple and straightforward with our online platform. Just select your pickup and drop locations, and reserve your ride within minutes. We provide instant confirmations and flexible options to ensure your travel plans are convenient and tailored to your needs. Our user-friendly interface makes it easy to find and book the perfect vehicle for your journey."
    },
    {
        "name": "Shirdi to Ajanta Ellora Taxi Fare",
        "description": "Plan your visit to the Ajanta and Ellora Caves with our competitive taxi fares for the trip from Shirdi. Experience the beauty of these UNESCO World Heritage Sites comfortably and at your own pace. Our knowledgeable drivers will enhance your trip with insights about the history and significance of these remarkable sites, ensuring a fulfilling and memorable experience."
    },
    {
        "name": "Shirdi to Aurangabad Taxi",
        "description": "For your journey from Shirdi to Aurangabad, our taxi service provides a comfortable and efficient option. Enjoy the scenic drive and reach your destination stress-free. Our commitment to quality service means you can expect a well-maintained vehicle and a friendly driver, making your journey both pleasant and enjoyable."
    },
    {
        "name": "Shirdi Cab Booking",
        "description": "Easily book your cab in Shirdi through our online platform. We offer a variety of vehicles to meet your travel needs, ensuring you have the perfect ride for your journey. Our booking process is quick and easy, allowing you to focus on your travel plans. With just a few clicks, you can secure your transportation and enjoy a hassle-free experience."
    },
    {
        "name": "Shirdi to Trimbakeshwar Cab Fare",
        "description": "Check our affordable fares for trips from Shirdi to Trimbakeshwar. Our transparent pricing policy ensures no hidden charges, providing peace of mind during your journey. We understand the importance of clear communication and reliable service, and we are dedicated to making your travel experience as seamless as possible."
    },
    {
        "name": "Shirdi to Aurangabad Cab",
        "description": "Travel from Shirdi to Aurangabad with ease using our reliable cab service. Our drivers are knowledgeable and ensure you have a pleasant experience on your journey. Enjoy the comfort of our well-equipped vehicles as we prioritize your comfort and safety throughout the trip."
    },
    {
        "name": "Shirdi to Trimbakeshwar Taxi",
        "description": "For trips to Trimbakeshwar from Shirdi, our taxi service offers comfort and convenience. Enjoy the beautiful scenery along the way with our experienced drivers, who are committed to making your journey enjoyable and stress-free. We ensure that all our vehicles are equipped with essential amenities to make your ride as comfortable as possible."
    },
    {
        "name": "SaitirthTours & Travels Shirdi",
        "description": "At SaitirthTours & Travels, we are dedicated to providing exceptional cab services in Shirdi. Our focus on customer satisfaction ensures a memorable travel experience, with our professional drivers and well-maintained vehicles ready to cater to your every need. We pride ourselves on offering a range of services, including sightseeing tours, airport transfers, and outstation trips, all tailored to meet the unique needs of our clients."
    },
    {
        "name": "Shirdi Cabs Service Contact Number",
        "description": "For bookings and inquiries, contact us at +91 9923879009 / +91 9922479009. Our team is available to assist you with your travel plans, ensuring that your journey with us is smooth and enjoyable. Whether you have questions about our services or need help with your booking, we are here to help you every step of the way."
    }
],
tableData: [
    ["- Shirdi to Mumbai Cab", "- Manmad to Shirdi Taxi"],
    ["- Shirdi Cab Service", "- Taxi Service in Shirdi"],
    ["- Shirdi to Trimbakeshwar Cab", "- Taxi in Shirdi"],
    ["- Shirdi to Shani Shingnapur Cab", "- Shirdi to Bhimashankar Taxi Fare"],
    ["- Best Taxi Service in Shirdi", "- Shirdi to Nasik Trimbakeshwar Taxi Fare"],
    ["- Shirdi to Aurangabad Taxi Fare", "- Shirdi to Manmad Taxi Fare"],
    ["- Nagarsol to Shirdi Cab Charges", "- Cab Booking in Shirdi"],
    ["- Shirdi to Ajanta Ellora Taxi Fare", "- Shirdi to Aurangabad Taxi"],
    ["- Shirdi Cab Booking", "- Cabs in Shirdi"],
    ["- Shirdi to Trimbakeshwar Cab Fare", "- Shirdi to Aurangabad Cab"],
    ["- Shirdi to Trimbakeshwar Taxi", "- SaitirthTours & Travels Shirdi"],
    ["", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Reliable Service",
      "WhyChoosedescription": "We take pride in offering dependable taxi services in Shirdi, ensuring that you always reach your destination safely and on time. Whether you're headed for religious sites like the Shirdi Sai Baba Temple or nearby cities, we ensure consistent and timely service. With Saitirth Travels, you can count on us to be there when you need us the most, without any delays."
    },
    {
      "WhyChooseheading": "Diverse Fleet",
      "WhyChoosedescription": "At Saitirth Travels, we understand that every trip is unique. That's why we offer a broad selection of vehicles, from compact cars for solo travelers to spacious SUVs and minivans for family and group trips. Whether you're looking for a luxury ride or a practical, budget-friendly vehicle, we have the perfect options to meet your requirements, ensuring comfort and convenience on every journey."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "We believe that quality service should be accessible. Our rates are designed to be competitive, offering great value for your money without compromising on service quality. Whether you're traveling for business, pleasure, or pilgrimage, our pricing is transparent, with no hidden fees or surprises. We make sure you get the best possible price while enjoying a premium travel experience."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our drivers are the backbone of our service. They are not only experienced and professional but also trained in customer service, ensuring that you have a pleasant and safe ride every time. Each of our drivers is well-versed in the best routes, traffic conditions, and shortcuts to ensure a smooth journey. Plus, our drivers are courteous and always ready to assist with any travel needs or questions you might have along the way."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking a cab with Saitirth Travels is quick and hassle-free. Our simple online booking system allows you to reserve a cab in just a few clicks, whether you're at home, at the office, or on the go. You can also reach out to our customer service team for any assistance, and we offer multiple payment options for your convenience. No matter how you prefer to book, the process is designed to be as smooth and straightforward as possible."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Travel doesn't stop, and neither do we. Our services are available 24/7, so you can count on us whether you're taking a morning trip, a late-night ride, or an early-morning airport transfer. We understand that your schedule can change, which is why we offer flexibility in booking and availability. Whether you need a ride at 6 AM or midnight, Saitirth Travels is here to accommodate your travel needs."
    },
    {
      "WhyChooseheading": "Real-Time Tracking",
      "WhyChoosedescription": "Stay informed with our real-time vehicle tracking system. When you book a taxi with us, you can track the location of your vehicle on your phone, ensuring that you know exactly when your ride will arrive. This feature gives you peace of mind, especially when traveling in a new place or during peak times, so you can relax and enjoy the journey without any worries."
    },
    {
      "WhyChooseheading": "Comfort and Cleanliness",
      "WhyChoosedescription": "We place a high value on your comfort and safety. Each vehicle in our fleet is regularly cleaned and sanitized, ensuring that you always travel in a pristine environment. We also maintain our vehicles to the highest standards, making sure they are mechanically sound for every journey. Our cars are equipped with air conditioning, comfortable seating, and ample legroom, providing you with a relaxing experience, no matter how long the ride."
    },
    {
      "WhyChooseheading": "Customer Support",
      "WhyChoosedescription": "At Saitirth Travels, we understand that customer satisfaction is key to our success. That's why our dedicated support team is always ready to help with any queries or concerns you may have. Whether you need assistance with booking, last-minute changes to your schedule, or have special requirements, we are just a phone call away. Your comfort and satisfaction are our top priority."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "We are proud of the positive feedback we have received from our customers. Our commitment to excellence has earned us numerous glowing reviews, with many customers returning to us for future travel needs. Our customers appreciate our punctuality, friendly service, clean vehicles, and overall reliability. Their testimonials speak volumes about our dedication to providing the best taxi services in Shirdi, and we aim to continue building trust and satisfaction with each ride."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "How can I book a cab in Shirdi?",
        answer: "You can easily book a cab through our website or by calling our customer service for assistance."
    },
    {
        question: "What types of vehicles do you offer?",
        answer: "We offer a range of vehicles including sedans, SUVs, and vans to accommodate different needs."
    },
    {
        question: "Are your rates competitive?",
        answer: "Yes, we provide affordable rates with transparent pricing and no hidden charges."
    },
    {
        question: "Can I book a cab for outstation trips?",
        answer: "Absolutely! We provide cab services for both local and outstation trips."
    },
    {
        question: "What payment options do you accept?",
        answer: "We accept cash, credit/debit cards, and mobile wallets for your convenience."
    },
    {
        question: "Is customer support available?",
        answer: "Yes, our customer support team is available to assist you with any questions or concerns."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, modifications and cancellations can be made based on availability. Please contact us as soon as possible."
    },
    {
        question: "Are your cabs available 24/7?",
        answer: "Yes, our services are available around the clock for your convenience."
    },
    {
        question: "Do you provide child seats in your cabs?",
        answer: "Yes, child seats can be provided upon request during the booking process."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at the following:"
    }
];

const testimonials = [
    {
        name: "Mr. Sameer Joshi",
        text: "My experience with Saitirth Travels for cabs in Shirdi was outstanding! The cab was clean and well-maintained, and the driver was friendly and knowledgeable about the area. The service was reliable, making my trip to Shirdi smooth and enjoyable. I highly recommend Saitirth Travels for anyone needing a cab in Shirdi!"
    },
    {
        name: "Ms. Neelam Sharma",
        text: "Saitirth Travels provided excellent cab services during my stay in Shirdi. The booking process was easy, and the cab was always on time. The driver was courteous and made sure I was comfortable throughout the journey. I will definitely use their services again for my future visits!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
   <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Cabs | Reliable Taxi Service for Shirdi and Surrounding Areas</title>
    <meta name="description" content="Book Shirdi Cabs with Saitirth Travels. Reliable and affordable taxi service for Shirdi to Mumbai, Manmad to Shirdi, Trimbakeshwar, Shirdi to Shani Shingnapur, Aurangabad, Bhimashankar, and more." />
    <meta name="keywords" content="Shirdi Cabs, Shirdi to Mumbai Cab, Manmad to Shirdi Taxi, Taxi Service in Shirdi, Shirdi to Trimbakeshwar Cab, Shirdi to Shani Shingnapur Cab, Shirdi to Bhimashankar Taxi Fare, Shirdi to Nasik Trimbakeshwar Taxi Fare, Shirdi to Aurangabad Taxi Fare, Shirdi Cab Booking, Shirdi to Ajanta Ellora Taxi Fare" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Cabs | Reliable Taxi Service for Shirdi and Surrounding Areas" />
    <meta property="og:description" content="Book Shirdi Cabs with Saitirth Travels. Reliable and affordable taxi service for Shirdi to Mumbai, Manmad to Shirdi, Trimbakeshwar, Shirdi to Shani Shingnapur, Aurangabad, Bhimashankar, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-cabs" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-cabs.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book Shirdi Cabs with Saitirth Travels. Reliable and affordable taxi service for Shirdi to Mumbai, Manmad to Shirdi, Trimbakeshwar, Shirdi to Shani Shingnapur, Aurangabad, Bhimashankar, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-cabs",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-cabs.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "300"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Cabs",
                "Shirdi to Mumbai Cab",
                "Manmad to Shirdi Taxi",
                "Taxi Service in Shirdi",
                "Shirdi to Trimbakeshwar Cab",
                "Shirdi to Shani Shingnapur Cab",
                "Shirdi to Bhimashankar Taxi Fare",
                "Shirdi to Nasik Trimbakeshwar Taxi Fare",
                "Shirdi to Aurangabad Taxi Fare",
                "Shirdi Cab Booking",
                "Shirdi to Ajanta Ellora Taxi Fare"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/91.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdicabs;