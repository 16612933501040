
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Punetoshirdicab() {



  const cardData =
  {
    keyword: 'Pune to Shirdi Cab',
    heading: 'Saitirth Travels: Pune to Shirdi Cab',
    headingDescription: 'Traveling from Pune to Shirdi is a popular choice for pilgrims and tourists looking to visit the sacred shrine of Sai Baba. At Saitirth Travels, we provide reliable and comfortable cab services that ensure a seamless journey. Our experienced drivers and well-maintained vehicles are dedicated to making your travel experience enjoyable and stress-free.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Samadhi Mandir",
            "description": "The main attraction in Shirdi, the Sai Baba Samadhi Mandir, is where the mortal remains of Sai Baba rest. Devotees flock to this temple to pay their respects and seek blessings. The serene atmosphere and beautiful architecture make it a spiritual haven."
        },
        {
            "title": "Dwarkamai Masjid",
            "description": "This historic mosque was once the dwelling place of Sai Baba. Visitors can see the sacred fire (Dhuni) that Sai Baba kept burning and experience the peaceful ambiance of this revered site."
        },
        {
            "title": "Chavadi",
            "description": "Chavadi is where Sai Baba used to sleep every alternate night. The site holds immense significance for devotees and features a beautiful idol of Sai Baba, along with intricate decorations that reflect the devotion of his followers."
        },
        {
            "title": "Shri Sai Baba Sansthan Trust",
            "description": "This trust manages the temple and its surrounding facilities. It plays a vital role in providing amenities to devotees, including accommodation, food, and medical services, ensuring a comfortable experience during their visit."
        },
        {
            "title": "Lendi Baug",
            "description": "A beautiful garden where Sai Baba used to spend time, Lendi Baug features a lovely grove of trees, a well, and a small shrine dedicated to Baba. The tranquil environment makes it a great place for reflection and peace."
        },
        {
            "title": "Samadhi Mandir Museum",
            "description": "This museum showcases various artifacts related to Sai Baba's life, including his personal belongings and photographs. It provides insight into his teachings and the profound impact he had on his followers."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is the spot where Sai Baba first appeared to his devotees. The site features a large neem tree and a small temple, making it a significant place for prayer and remembrance."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Located near the entrance of Shirdi, Khandoba Mandir is dedicated to Lord Khandoba. It is an important pilgrimage site and is often visited by devotees before heading to the main Sai Baba temple."
        },
        {
            "title": "Shirdi Village",
            "description": "Exploring the village of Shirdi provides a glimpse into the local culture and lifestyle. Visitors can interact with locals, try traditional foods, and experience the warmth of the community that reveres Sai Baba."
        },
        {
            "title": "Nearby Attractions",
            "description": "Shirdi is also close to other significant attractions such as the town of Nashik, known for its vineyards and temples, and the scenic beauty of the Western Ghats, perfect for nature enthusiasts."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Pune to Shirdi Cab Service",
        "description": "Experience a hassle-free journey with our reliable Pune to Shirdi cab service. We pride ourselves on providing comfortable and spacious transportation to one of the most revered pilgrimage sites in India. Our dedicated drivers are well-trained to ensure that your journey is not only smooth but also enjoyable, allowing you to relax and focus on your spiritual experience."
    },
    {
        "name": "Pune to Shirdi Taxi",
        "description": "Our taxis are available for direct travel from Pune to Shirdi. Enjoy the scenic drive as you make your way to the spiritual heart of Shirdi, taking in beautiful landscapes and local sights along the way. Our vehicles are regularly maintained to ensure safety and comfort during your ride."
    },
    {
        "name": "Pune to Shirdi Cab Charges",
        "description": "We provide transparent pricing with competitive cab charges. Our rates are designed to be budget-friendly while ensuring a high-quality travel experience. We believe in honesty and clarity, so you’ll know exactly what to expect without any hidden costs."
    },
    {
        "name": "Pune to Shirdi Cab Package",
        "description": "Opt for our special cab package for a comprehensive travel experience. The package includes round-trip transportation, ensuring you enjoy your visit without any hassle. Additionally, we offer customizable itineraries to suit your preferences, making it ideal for families and groups looking to explore the area."
    },
    {
        "name": "Pune to Shirdi Cab Fare",
        "description": "Our cab fare from Pune to Shirdi is affordable, with no hidden costs. We pride ourselves on offering value for your money, providing a detailed breakdown of fares for your peace of mind. Our pricing structure is straightforward, allowing you to plan your budget effectively."
    },
    {
        "name": "Pune to Shirdi Cab One Way",
        "description": "For those looking to travel one way, we offer flexible one-way cab services from Pune to Shirdi, tailored to suit your travel needs. This option allows you to plan your journey according to your schedule, making it convenient for pilgrims or tourists who wish to explore Shirdi at their own pace."
    },
    {
        "name": "Pune to Shirdi Taxi Fare",
        "description": "Get an instant quote for your taxi fare from Pune to Shirdi. We ensure our fares are competitive, providing you with a smooth travel experience while keeping your budget in check. Our user-friendly booking system allows you to easily check rates and make reservations online."
    },
    {
        "name": "Pune to Shirdi Cab Service",
        "description": "Book our Pune to Shirdi cab service for a comfortable and convenient ride. Our experienced drivers know the best routes to ensure a timely arrival, making your journey smooth and enjoyable. Whether you're traveling alone or with a group, our cabs are equipped to accommodate your needs."
    },
    {
        "name": "Shirdi to Pune Cab",
        "description": "We also offer cab services from Shirdi back to Pune. Enjoy your return journey with the same level of comfort and reliability, ensuring you have a pleasant end to your spiritual visit. Our drivers are familiar with the area and can provide insights about local attractions along the way."
    },
    {
        "name": "Pune to Shirdi Cab Booking Service",
        "description": "Our cab booking service is user-friendly, allowing you to book your ride online with ease. Simply provide your details, and we’ll take care of the rest, ensuring a hassle-free experience. Our customer support team is also available to assist you with any questions or changes to your booking."
    },
    {
        "name": "Pune to Shirdi Taxi Drop",
        "description": "Need a drop-off at Shirdi? Our taxi drop service ensures you reach your destination safely and on time, without any stress. We prioritize your comfort and safety throughout the journey, providing a reliable option for those visiting the holy site."
    },
    {
        "name": "Pune Airport to Shirdi Cab",
        "description": "Traveling from Pune Airport to Shirdi? We provide seamless airport transfer services, ensuring a smooth transition from your flight to your pilgrimage. Our drivers will be waiting for you upon arrival, ready to assist with your luggage and provide a comfortable ride."
    },
    {
        "name": "Pune to Shirdi Car Rental",
        "description": "For those wanting to explore at their own pace, our car rental service from Pune to Shirdi offers flexibility. Choose the vehicle that suits your needs, whether you need a compact car for solo travel or a larger vehicle for family trips, giving you the freedom to create your own itinerary."
    },
    {
        "name": "Pune to Shirdi Cab Booking Rates",
        "description": "Get the best rates for your cab booking from Pune to Shirdi. Our rates are clear, and we provide the best value for your journey, helping you make the most of your pilgrimage without breaking the bank. Regular promotions and discounts are also available for frequent travelers."
    },
    {
        "name": "Pune Airport to Shirdi Taxi",
        "description": "Take advantage of our airport taxi service for direct travel from Pune Airport to Shirdi. We guarantee a timely and comfortable ride, ensuring you can start your spiritual journey without delays. Our drivers are knowledgeable about the area and can offer tips for your visit."
    },
    {
        "name": "Pune to Shirdi Cab Service Rates",
        "description": "We provide competitive cab service rates from Pune to Shirdi, ensuring affordability without compromising on service quality. Our commitment to excellence means you can expect a comfortable and enjoyable ride every time."
    },
    {
        "name": "Pune to Shirdi Package by Car",
        "description": "Join our exclusive package for a car trip from Pune to Shirdi. This includes round-trip transportation and can be customized to meet your needs, making it perfect for both individual travelers and groups. We can arrange additional sightseeing options to enhance your travel experience."
    },
    {
        "name": "Pune Shirdi Taxi",
        "description": "Our Pune Shirdi taxi service is trusted for its reliability and comfort. We ensure that every journey is smooth and enjoyable, allowing you to focus on your spiritual experience while we take care of the travel logistics."
    },
    {
        "name": "Pune to Shirdi Cabs Round Trip",
        "description": "Enjoy our round-trip cab service from Pune to Shirdi, designed for your convenience. Book your round trip with us for the best experience, ensuring you travel comfortably to and from the pilgrimage site without any worries."
    },
    {
        "name": "Shirdi to Pune Airport Cab",
        "description": "We provide cab services from Shirdi to Pune Airport, ensuring timely transfers for your flight. Our drivers are punctual and professional, prioritizing your travel needs so you can arrive at the airport relaxed and on time."
    },
    {
        "name": "Cab for Shirdi from Pune",
        "description": "Looking for a cab to Shirdi from Pune? Our dedicated service offers you a reliable and comfortable travel experience, making your pilgrimage as enjoyable as possible. We focus on customer satisfaction and are committed to providing the best service."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for your Pune to Shirdi journey. Our commitment to customer satisfaction ensures you have a memorable trip, supported by our experienced drivers and well-maintained vehicles that meet all safety standards."
    },
    {
        "name": "Pune to Shirdi Cab Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Let us assist you in making your trip from Pune to Shirdi a memorable experience! Our friendly customer service team is here to help you every step of the way."
    }
],


tableData: [
    ["- Pune to Shirdi Cab", "- Pune to Shirdi Taxi"],
    ["- Pune to Shirdi Cab Charges", "- Pune to Shirdi Cab Package"],
    ["- Pune to Shirdi Cab Fare", "- Pune to Shirdi Cab One Way"],
    ["- Pune to Shirdi Taxi Fare", "- Pune to Shirdi Cab Service"],
    ["- Shirdi to Pune Cab", "- Pune to Shirdi Cab Booking Service"],
    ["- Pune to Shirdi Taxi Drop", "- Pune Airport to Shirdi Cab"],
    ["- Pune to Shirdi Car Rental", "- Pune to Shirdi Cab Booking"],
    ["- Pune to Shirdi Cab Booking Rates", "- Pune Airport to Shirdi Taxi"],
    ["- Pune to Shirdi Cab Service Rates", "- Pune to Shirdi Package by Car"],
    ["- Pune to Shirdi Cabs Round Trip", "- Shirdi to Pune Airport Cab"],
    ["- Cab for Shirdi from Pune", "- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Reliable Service",
      "WhyChoosedescription": "We are known for our reliability, ensuring that you reach Shirdi on time, every time. Our services are dependable, so you can travel with peace of mind knowing that your cab will be ready and waiting for you as scheduled."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Our fleet of well-maintained vehicles provides a comfortable journey. With modern amenities like air conditioning, spacious seating, and smooth rides, you can relax and enjoy your travel from Pune to Shirdi."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our drivers are professional, experienced, and fully trained. They know the best routes and ensure a safe and smooth ride for you, so you can enjoy your journey with confidence."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive pricing without compromising on service quality. Our transparent rates ensure you get the best value for your money, with no hidden charges or surprise costs."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Whether you need a ride early in the morning or late at night, our service is available 24/7. You can book a ride anytime, making it convenient for you to plan your trip as per your schedule."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking your Pune to Shirdi cab is simple and quick with our easy-to-use online platform. Alternatively, you can reach out to our customer service team for assistance. Booking your ride has never been more convenient."
    },
    {
      "WhyChooseheading": "Flexible Timings",
      "WhyChoosedescription": "We understand that travel needs can vary, which is why we offer flexible pick-up and drop-off timings. Whether you're looking for a morning or evening departure, we can accommodate your preferred travel schedule."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles are regularly serviced and sanitized to ensure that you have a safe and comfortable journey. We follow strict safety protocols to provide a secure environment for all passengers."
    },
    {
      "WhyChooseheading": "Customizable Packages",
      "WhyChoosedescription": "We offer customizable travel packages for one-way or round trips, tailored to your needs. Whether you need a quick trip or a longer stay, we can adjust our services to suit your travel requirements."
    },
    {
      "WhyChooseheading": "Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available to assist with any inquiries or concerns. Whether you need help with bookings or have questions during your trip, our team is here to ensure a smooth experience."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What is the distance from Pune to Shirdi?",
        answer: "The distance from Pune to Shirdi is approximately 240 kilometers, which takes around 5-6 hours by road."
    },
    {
        question: "How do I book a cab from Pune to Shirdi?",
        answer: "You can easily book a cab through our website or by contacting our customer service team for assistance."
    },
    {
        question: "What types of vehicles are available for the Pune to Shirdi journey?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and larger vans, to accommodate different group sizes."
    },
    {
        question: "Are there any additional charges for tolls and parking?",
        answer: "Yes, tolls and parking fees are additional and will be communicated during the booking process."
    },
    {
        question: "Can I request a return trip from Shirdi to Pune?",
        answer: "Yes, we offer round-trip services and can customize your itinerary as per your requirements."
    },
    {
        question: "What is the fare for the Pune to Shirdi cab service?",
        answer: "The fare depends on the vehicle type and duration of travel. Please contact us for a personalized quote."
    },
    {
        question: "Is it safe to travel with Saitirth Travels?",
        answer: "Yes, we prioritize safety with well-maintained vehicles and professional drivers to ensure a secure travel experience."
    },
    {
        question: "Can I change my booking after it is confirmed?",
        answer: "Yes, changes can be made, but please notify us as soon as possible to accommodate your request."
    },
    {
        question: "What are your cancellation policies?",
        answer: "We have a cancellation policy in place. Please contact us for more details."
    },
    {
        question: "How can I reach customer support for any assistance?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Vikram Joshi",
        text: "I had a wonderful experience using Saitirth Travels for my cab ride from Pune to Shirdi. The vehicle was comfortable, and the journey was smooth and enjoyable. The driver was punctual and very accommodating. I highly recommend their services for anyone traveling to Shirdi!"
    },
    {
        name: "Ms. Ananya Deshmukh",
        text: "Renting a cab from Pune to Shirdi with Saitirth Travels was a great choice! The fare was reasonable, and the service was excellent. Our driver was knowledgeable about the area, which made the trip even better. I will definitely use Saitirth Travels for my future trips!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Pune to Shirdi Cab | Pune to Shirdi Taxi | Affordable Taxi Service | Shirdi Tour Package</title>
    <meta name="description" content="Book Pune to Shirdi Cab with Saitirth Travels. We offer reliable and affordable taxi services for one-way, round-trip, and corporate bookings. Explore our Shirdi tour packages with Pune to Shirdi taxi services." />
    <meta name="keywords" content="Pune to Shirdi Cab, Pune to Shirdi Taxi, Pune to Shirdi Cab Charges, Pune to Shirdi Cab Package, Pune to Shirdi Taxi Fare, Pune to Shirdi Cab Service, Shirdi to Pune Cab, Pune to Shirdi Cab Booking, Pune to Shirdi Car Rental, Shirdi to Pune Airport Cab" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Pune to Shirdi Cab | Pune to Shirdi Taxi | Affordable Taxi Service | Shirdi Tour Package" />
    <meta property="og:description" content="Book Pune to Shirdi Cab with Saitirth Travels. Enjoy convenient and affordable taxi services for your one-way or round-trip journeys. We offer Shirdi tour packages for corporate, family, and individual travelers." />
    <meta property="og:url" content="https://www.saitirthcabs.com/pune-to-shirdi-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/pune-to-shirdi-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book Pune to Shirdi Cab with Saitirth Travels. Affordable and reliable taxi services for all types of journeys including one-way, round-trip, and Shirdi tour packages.",
            "url": "https://www.saitirthcabs.com/pune-to-shirdi-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/pune-to-shirdi-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Pune to Shirdi Cab",
                "Pune to Shirdi Taxi",
                "Pune to Shirdi Cab Charges",
                "Pune to Shirdi Cab Package",
                "Pune to Shirdi Taxi Fare",
                "Pune to Shirdi Cab Service",
                "Shirdi to Pune Cab",
                "Pune to Shirdi Cab Booking",
                "Pune to Shirdi Car Rental",
                "Shirdi to Pune Airport Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/81.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoshirdicab;