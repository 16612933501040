
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdiairportpickup() {



  const cardData =
  {
    keyword: ' Shirdi Airport Pick Up & Drop Service',
    heading: 'Saitirth Travels: Shirdi Airport Pick Up & Drop Service',
    headingDescription: 'Saitirth Travels offers reliable pick-up and drop services to and from Shirdi Airport. Our dedicated team ensures a smooth and hassle-free transfer experience, making your travel to and from Shirdi convenient and stress-free.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The heart of Shirdi, the Sai Baba Temple attracts millions of devotees seeking blessings from Sai Baba each year. This magnificent temple is renowned for its serene environment and stunning architecture, featuring intricate carvings and spacious prayer halls that create a perfect spiritual atmosphere. Visitors can engage in prayer, participate in daily rituals, and immerse themselves in the collective devotion that fills the air, making it a must-visit for anyone looking to connect with their spirituality."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Khandoba Mandir is a revered temple dedicated to Lord Khandoba, a significant deity in local culture. Nestled amidst picturesque surroundings, this site holds historical significance and offers breathtaking views of the nearby hills. The temple attracts pilgrims and tourists alike, who come to seek blessings and learn more about the region's spiritual heritage. It’s a must-visit for those interested in local culture and spirituality, especially during festivals when the temple comes alive with vibrant celebrations."
        },
        {
            "title": "Lendi Baug",
            "description": "Lendi Baug is a tranquil garden that Sai Baba frequently visited, making it a special place for devotees. The garden features a sacred well and beautiful trees, providing a serene environment perfect for meditation and relaxation. Visitors can take leisurely strolls among the lush greenery, enjoying the peaceful atmosphere that encourages introspection and connection with nature. This hidden gem is ideal for those seeking a moment of calm amidst the spiritual bustle of Shirdi."
        },
        {
            "title": "Dixit Wada Museum",
            "description": "Dixit Wada Museum showcases a collection of artifacts and photographs related to Sai Baba’s life, offering valuable insights into his teachings and the history of Shirdi. This museum is located in the Wada where Sai Baba lived, and exploring its exhibits allows visitors to gain a deeper understanding of his legacy and impact on countless devotees. The enriching experience at the museum not only honors Sai Baba’s memory but also educates visitors about the profound spiritual journey that many have embarked upon."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is a sacred site marking the place where Sai Baba is believed to have first appeared to his followers. This revered location features a small shrine and a holy tree, where devotees often gather to pray and seek blessings. The tranquility of Gurusthan, coupled with its spiritual significance, creates a profound environment for reflection and connection with Sai Baba’s teachings. It is an essential stop for any devotee visiting Shirdi."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust plays a crucial role in managing the Sai Baba Temple and overseeing various charitable activities aimed at supporting the local community. Visitors can learn about the trust’s numerous initiatives, including educational programs, healthcare services, and social welfare projects that embody Sai Baba’s teachings of compassion and service. This visit not only provides insight into the operational aspects of the temple but also highlights the enduring impact of Sai Baba’s legacy on the community."
        },
        {
            "title": "Manmad Fort",
            "description": "Located nearby, Manmad Fort offers a fascinating glimpse into history, with its remnants telling tales of the past. This fort provides panoramic views of the surrounding landscape, making it a fantastic spot for history lovers and adventure seekers alike. Visitors can explore the ruins, which serve as a reminder of the region's historical significance, while the natural beauty surrounding the fort adds to its charm. It’s an ideal destination for a day trip, combining exploration with stunning vistas."
        },
        {
            "title": "Nashik Caves",
            "description": "The Nashik Caves, known for their stunning rock-cut carvings, are located just a short drive from Shirdi. These ancient caves date back to the 1st century BCE and showcase exquisite artistry that provides a fascinating journey into the past. Exploring the caves allows visitors to appreciate the architectural ingenuity of ancient times and delve into the rich cultural heritage of the region. The cool interiors offer a refreshing escape, making it a captivating addition to any itinerary."
        },
        {
            "title": "Khandala Waterfall",
            "description": "Khandala Waterfall is a beautiful natural site that captivates nature lovers with its stunning cascade and serene surroundings. Ideal for picnics, especially during the monsoon season when the waterfall is in full flow, this picturesque location offers a tranquil escape from the bustling town of Shirdi. The lush greenery and scenic beauty make it an excellent spot for families and friends to gather, unwind, and enjoy the refreshing ambiance of nature."
        },
        {
            "title": "Shirdi Market",
            "description": "After your visit to the temple, the vibrant Shirdi Market offers an opportunity to explore the local culture and pick up souvenirs. This bustling market is filled with shops selling religious artifacts, devotional items, and traditional snacks that capture the essence of Shirdi. Visitors can immerse themselves in the lively atmosphere, interact with local vendors, and find unique mementos to remember their visit. The market experience adds a delightful end to your spiritual journey in Shirdi."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi Airport Pick up & Drop Service",
        "description": "Experience seamless travel with our Shirdi Airport pick up and drop service, designed to cater to all your transportation needs. At SaitirthTours & Travels, we provide reliable and efficient taxi services from Shirdi Airport to various destinations in the region, ensuring a comfortable and hassle-free journey. With our prompt pickups, professional drivers, and comfortable vehicles, we strive to make your airport travel experience as convenient as possible, allowing you to relax from the moment you land."
    },
    {
        "name": "Shirdi Airport Taxi Services",
        "description": "Our Shirdi Airport taxi services are among the best in the region, featuring prompt and professional drivers who are well-acquainted with the local area. Whether you're arriving or departing, our team is dedicated to making your travel experience as smooth and enjoyable as possible. We monitor flight timings to ensure timely pickups, and our range of vehicles caters to solo travelers, families, and business professionals alike. Let us take care of your transportation needs so you can focus on your trip."
    },
    {
        "name": "Best Taxi Service in Shirdi Airport",
        "description": "When you need the best taxi service at Shirdi Airport, look no further. We pride ourselves on providing high-quality transportation that caters to the diverse needs of all travelers. Our fleet includes a variety of vehicles, from compact sedans to larger SUVs, ensuring options that suit different group sizes and preferences. Our commitment to cleanliness, safety, and comfort makes us the preferred choice for travelers seeking reliable airport transportation."
    },
    {
        "name": "Shirdi Airport to Shani Shingnapur Cab",
        "description": "Traveling from Shirdi Airport to the popular pilgrimage destination of Shani Shingnapur? Our cab service ensures a safe and efficient journey, with drivers who are familiar with the quickest routes to avoid any unnecessary delays. Enjoy a comfortable ride and take in the scenery along the way as we transport you to Shani Shingnapur with ease and professionalism."
    },
    {
        "name": "Shirdi Airport to Ahmednagar Cab",
        "description": "For those heading to Ahmednagar from Shirdi Airport, our reliable cab service is at your service. We offer competitive rates and exceptional service, ensuring your travel is comfortable and enjoyable. Our experienced drivers take care of the navigation, allowing you to relax after your flight. With a range of vehicles to choose from, we offer a convenient and affordable travel solution."
    },
    {
        "name": "Shirdi Airport to Manmad Cab",
        "description": "Need a cab from Shirdi Airport to Manmad? Our well-maintained vehicles and experienced drivers ensure a smooth ride to your destination. We prioritize punctuality and comfort, making sure you have a pleasant journey without any hassle. Enjoy a stress-free travel experience with our professional cab service tailored to your needs."
    },
    {
        "name": "Shirdi Airport to Supa MIDC Cab",
        "description": "Our cab service from Shirdi Airport to Supa MIDC provides a stress-free transportation solution for business and personal travel. Let our drivers handle the roads while you prepare for your day or relax after your flight. With our reliable service, you can count on arriving at your destination on time and comfortably."
    },
    {
        "name": "Shirdi Airport to Parner MIDC Cab",
        "description": "Our reliable taxi service covers the route from Shirdi Airport to Parner MIDC, offering a comfortable journey with professional drivers who prioritize your safety. Whether you’re traveling for business or personal reasons, our well-maintained cabs and punctual service ensure that your transportation is smooth and efficient."
    },
    {
        "name": "Shirdi Airport Swift Dzire on Rent",
        "description": "For those who prefer the flexibility of driving themselves, we offer Swift Dzire cars on rent from Shirdi Airport. Enjoy the freedom to explore the region at your own pace with our reliable and affordable rental service. Our vehicles are maintained to high standards for your comfort and safety, and our rental process is simple, allowing you to hit the road in no time."
    },
    {
        "name": "Shirdi Car Package",
        "description": "Explore Shirdi and its surrounding areas with our comprehensive Shirdi car package. Tailored to meet the unique needs of travelers, this package includes a range of transportation solutions, from day trips to extended journeys. Whether you’re here for a pilgrimage, sightseeing, or a relaxing getaway, our package ensures you have a reliable vehicle and professional driver to make your travel experience enjoyable and memorable."
    },
    {
        "name": "Shirdi Car Rental",
        "description": "Our Shirdi car rental service offers a variety of vehicles for you to choose from, ranging from compact cars to spacious SUVs. With competitive pricing and excellent customer service, we are your go-to choice for car rentals in the area. Whether you need a car for a few hours or several days, we provide flexible options to suit your travel needs."
    },
    {
        "name": "SaitirthTours & Travels Shirdi",
        "description": "At SaitirthTours & Travels, we are committed to providing exceptional travel experiences with our comprehensive cab services in Shirdi. Our dedicated team is available to assist you with all your transportation needs, offering a range of services including airport pickups, sightseeing tours, and outstation trips. With a focus on customer satisfaction, we strive to make every journey enjoyable, safe, and convenient."
    },
    {
        "name": "Shirdi Airport Pick up & Drop Contact Number",
        "description": "For prompt and efficient Shirdi Airport pick up and drop service, contact Saitirth Travels at +91 9923879009 / +91 9922479009. Our team is available to help you with bookings and inquiries, ensuring a smooth and enjoyable ride for all our customers. With us, you can count on a seamless travel experience from start to finish."
    }
],
tableData: [
    ["- Shirdi Airport Taxi Services", "- Best Taxi Service in Shirdi Airport"],
    ["- Shirdi Airport to Shani Shingnapur Cab", "- Shirdi Airport to Ahmednagar Cab"],
    ["- Shirdi Airport to Manmad Cab", "- Shirdi Airport to Supa MIDC Cab"],
    ["- Shirdi Airport to Parner MIDC Cab", "- Shirdi Airport Swift Dzire on Rent"],
    ["- Shirdi Car Package", "- Shirdi Car Rental, Airport"],
    ["- SaitirthTours & Travels Shirdi", "- SaitirthTours & Travels Shirdi"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenient Airport Transfers",
      "WhyChoosedescription": "We specialize in providing hassle-free airport pick-up and drop services, ensuring a smooth start and end to your journey. Whether you're arriving in Shirdi or departing, we ensure timely and comfortable transfers, so you can focus on your travel plans and not on logistics."
    },
    {
      "WhyChooseheading": "Punctual Service",
      "WhyChoosedescription": "Our drivers are committed to being on time, so you never have to worry about missing your flight or waiting for a ride after landing. We take pride in our punctuality, ensuring that you're always picked up or dropped off right on schedule, no matter your flight times."
    },
    {
      "WhyChooseheading": "Variety of Vehicles",
      "WhyChoosedescription": "Choose from a range of vehicles to suit your travel needs, whether you're traveling alone or with a group. From comfortable sedans to spacious SUVs and minibuses, we have vehicles of different sizes and types to match your preferences and ensure you travel in comfort."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "Enjoy competitive pricing for airport transfers with no hidden fees, making our service cost-effective. We offer budget-friendly rates that ensure you receive high-quality service at an affordable price. Whether it's a short transfer or a long trip, our pricing is designed to give you the best value for your money."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our experienced drivers are knowledgeable about the local area and will assist you with your luggage for added convenience. They are professional, courteous, and dedicated to making your ride as smooth and comfortable as possible, ensuring your safety and satisfaction every step of the way."
    },
    {
      "WhyChooseheading": "Real-Time Flight Tracking",
      "WhyChoosedescription": "We monitor your flight status to adjust our pick-up schedule accordingly, ensuring timely service even with flight delays. Our real-time flight tracking system allows us to accommodate changes in flight schedules, ensuring that our drivers arrive when you need them, even in case of delays."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our airport pick-up and drop service is available around the clock, accommodating early morning or late-night flights. No matter what time your flight arrives or departs, we’re always here to provide reliable, on-time service to get you where you need to go."
    },
    {
      "WhyChooseheading": "Comfort and Cleanliness",
      "WhyChoosedescription": "Our vehicles are regularly cleaned and maintained, providing a comfortable and hygienic environment for your travels. We ensure that all our cars are spotless, sanitized, and well-maintained, offering you a clean, safe, and relaxing experience as you travel."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking your airport transfer is straightforward, either through our website or by contacting our customer service. We offer a seamless booking experience with user-friendly options that allow you to reserve your ride in just a few steps, making the process simple and hassle-free."
    },
    {
      "WhyChooseheading": "Customer Satisfaction",
      "WhyChoosedescription": "We prioritize customer satisfaction and have received positive feedback from our clients for our reliable service. Our commitment to meeting your needs and exceeding expectations has earned us loyal customers who trust us to provide excellent taxi services every time."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "How do I book an airport pick-up or drop service?",
        answer: "You can book our service online via our website or by calling our customer service for assistance."
    },
    {
        question: "What types of vehicles are available for airport transfers?",
        answer: "We offer a variety of vehicles, including sedans and larger vans, to cater to different passenger numbers and luggage needs."
    },
    {
        question: "Are your rates for airport transfers competitive?",
        answer: "Yes, we provide affordable rates with transparent pricing and no hidden costs for our airport pick-up and drop services."
    },
    {
        question: "What if my flight is delayed?",
        answer: "We monitor flight statuses and will adjust our pick-up time accordingly to ensure you’re picked up on time."
    },
    {
        question: "Do you provide child seats in your vehicles?",
        answer: "Yes, we can provide child seats upon request when you make your booking."
    },
    {
        question: "Is the service available 24/7?",
        answer: "Yes, our airport transfer service is available 24/7 for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment options including cash, credit/debit cards, and mobile wallets."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, modifications and cancellations can be made based on availability. Please contact us as soon as possible."
    },
    {
        question: "Will the driver assist with luggage?",
        answer: "Yes, our drivers are happy to assist you with your luggage during the pick-up and drop-off."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Ankit Patel",
        text: "I had a fantastic experience with Saitirth Travels for their airport pick-up and drop service. The driver was waiting for me at the airport upon my arrival, and the car was clean and comfortable. The entire process was smooth and stress-free. I highly recommend their services for anyone traveling to Shirdi!"
    },
    {
        name: "Ms. Rina Iyer",
        text: "Saitirth Travels provided excellent pick-up and drop service from Shirdi Airport. The booking was straightforward, and the driver was punctual and very courteous. The ride was comfortable, and I felt well taken care of. I will definitely choose Saitirth Travels for my future trips!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Airport Pick up & Drop Service | Reliable Taxi Service from Shirdi Airport</title>
    <meta name="description" content="Book Shirdi Airport Pick up & Drop Service with Saitirth Travels. Reliable and affordable taxi services from Shirdi Airport to Shani Shingnapur, Ahmednagar, Manmad, Supa MIDC, Parner MIDC, and more." />
    <meta name="keywords" content="Shirdi Airport Taxi Services, Shirdi Airport Pick up & Drop Service, Shirdi to Shani Shingnapur Cab, Shirdi Airport to Ahmednagar Cab, Shirdi Airport to Manmad Cab, Shirdi Airport to Supa MIDC Cab, Shirdi Airport to Parner MIDC Cab, Shirdi Airport Swift Dzire on Rent, Shirdi Car Rental, Shirdi Car Packages, SaitirthTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Airport Pick up & Drop Service | Reliable Taxi Service from Shirdi Airport" />
    <meta property="og:description" content="Book Shirdi Airport Pick up & Drop Service with Saitirth Travels. Reliable and affordable taxi services from Shirdi Airport to Shani Shingnapur, Ahmednagar, Manmad, Supa MIDC, Parner MIDC, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-airport-pickup-drop-service" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-airport-pickup.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book Shirdi Airport Pick up & Drop Service with Saitirth Travels. Reliable and affordable taxi services from Shirdi Airport to Shani Shingnapur, Ahmednagar, Manmad, Supa MIDC, Parner MIDC, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-airport-pickup-drop-service",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-airport-pickup.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "300"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Airport Taxi Services",
                "Shirdi Airport Pick up & Drop Service",
                "Shirdi to Shani Shingnapur Cab",
                "Shirdi Airport to Ahmednagar Cab",
                "Shirdi Airport to Manmad Cab",
                "Shirdi Airport to Supa MIDC Cab",
                "Shirdi Airport to Parner MIDC Cab",
                "Shirdi Airport Swift Dzire on Rent",
                "Shirdi Car Rental",
                "Shirdi Car Packages"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/92.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdiairportpickup;