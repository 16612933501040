
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Sainagarshirdi() {



  const cardData =
  {
    keyword: ' Sainagar Shirdi to Shirdi Cab Services',
    heading: 'Saitirth Travels: Sainagar Shirdi to Shirdi Cab Services',
    headingDescription: 'Saitirth Travels provides reliable and convenient cab services for travelers looking to explore the sacred town of Shirdi. Our Sainagar Shirdi to Shirdi cab service is ideal for pilgrims and tourists wanting to visit the famous Sai Baba Temple and other local attractions. Enjoy a comfortable ride as you embark on your spiritual journey.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The most revered site in Shirdi, the Sai Baba Temple attracts millions of devotees every year. The temple complex features a beautiful shrine dedicated to Sai Baba, known for its serene atmosphere and spiritual significance. Visitors can witness various rituals and ceremonies, including the famous 'Aarti' performed multiple times a day. The architecture of the temple is a blend of Hindu and Muslim styles, reflecting the universal appeal of Sai Baba's teachings of love and unity."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "This trust manages the Sai Baba Temple and is pivotal in maintaining the temple's operations and offering services to devotees. The trust provides various amenities, including accommodation, prasad distribution, and spiritual guidance, ensuring a comfortable experience for pilgrims. It also engages in charitable activities, making it an integral part of the pilgrimage experience and the community's welfare."
        },
        {
            "title": "Dwarkamai",
            "description": "Dwarkamai is a mosque where Sai Baba spent a significant part of his life and is considered a place of immense historical importance. Visitors can see the dhuni (sacred fire) that Baba lit and tended, which symbolizes purity and devotion. The mosque is adorned with various artifacts related to Sai Baba's life, and the atmosphere here is filled with a deep sense of peace and spirituality, making it a must-visit for any devotee."
        },
        {
            "title": "Chavadi",
            "description": "Chavadi is another significant place where Sai Baba spent every alternate night. This site is known for its beautiful architecture, which includes ornate wooden pillars and a charming ambiance. It holds a special place in the hearts of devotees as it was the location of Baba's various discourses and teachings. The Chavadi is decorated with flowers and lights during festival times, creating a vibrant and spiritual environment."
        },
        {
            "title": "Lendi Garden",
            "description": "Lendi Garden is a tranquil garden located near the temple that features lush greenery, beautiful fountains, and a serene atmosphere. This garden is a perfect spot for relaxation and reflection, where visitors can enjoy nature's beauty and tranquility. The garden is also home to various trees and plants that were reportedly planted by Sai Baba himself, adding to its spiritual significance."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "Situated on a hilltop, Shri Khandoba Mandir is an ancient temple dedicated to Lord Khandoba, a deity revered in Maharashtra. The temple offers stunning views of the surrounding landscape and is a significant religious site for devotees visiting Shirdi. The temple complex is vibrant and lively, especially during festivals, attracting many visitors seeking blessings and divine grace."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is the place where Sai Baba was first seen by his devotees, and a small shrine marks this sacred spot. The atmosphere here is imbued with spirituality and reverence, making it essential for visitors wanting to connect with Baba's legacy. The shrine contains a large stone, which devotees believe is where Baba meditated, and many come here to seek blessings and peace."
        },
        {
            "title": "Sainath Mahal",
            "description": "Sainath Mahal is a guesthouse managed by the Sai Baba Sansthan Trust, providing accommodation for devotees at affordable rates. It is conveniently located close to the main temple area, making it easy for visitors to attend the various rituals and ceremonies. The guesthouse is equipped with basic amenities, ensuring a comfortable stay for pilgrims and allowing them to immerse themselves in the spiritual environment of Shirdi."
        },
        {
            "title": "Khandoba Temple",
            "description": "Located near Shirdi, Khandoba Temple is dedicated to Khandoba, a popular deity in Maharashtra known for his warrior-like attributes. The temple is known for its vibrant atmosphere and intricate carvings, often bustling with devotees. It serves as a significant stop for those seeking blessings on their spiritual journey, especially for those attending the Sai Baba Temple."
        },
        {
            "title": "Maruti Mandir",
            "description": "Maruti Mandir is dedicated to Lord Hanuman and is known for its beautiful idol of the deity and peaceful ambiance. The temple attracts devotees from various regions who come to offer their prayers and seek divine blessings. The calm environment here provides a perfect setting for contemplation and devotion, making it a popular spot for visitors after a busy day at the main temple."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Sainagar Shirdi to Shirdi Cabs",
        "description": "For a seamless journey between Sainagar Shirdi Railway Station and Shirdi, SaitirthTours & Travels offers reliable cab services that prioritize comfort and convenience. Whether you're visiting the Sai Baba Temple or heading to Shirdi Airport, our cabs ensure a hassle-free travel experience. Enjoy a stress-free ride with professional drivers and well-maintained vehicles."
    },
    {
        "name": "Sainagar Shirdi Railway Station to Shirdi Taxi",
        "description": "Our taxi service from Sainagar Shirdi Railway Station to Shirdi provides prompt pickups and smooth rides to various locations in and around Shirdi. We ensure timely transfers, helping you start your journey with peace of mind. Whether you're heading directly to the temple or other attractions, our reliable service makes your arrival in Shirdi hassle-free."
    },
    {
        "name": "Shirdi to Sainagar Shirdi Railway Station Taxi Cabs Services",
        "description": "Choose our Shirdi to Sainagar Shirdi Railway Station taxi cabs for an efficient return journey. Our drivers are punctual and knowledgeable about the area, ensuring a comfortable ride and timely arrival at the railway station. We prioritize your convenience, whether you are traveling for business or leisure."
    },
    {
        "name": "Hire Cab from Sainagar to Shirdi",
        "description": "With our cab hire service from Sainagar to Shirdi, travelers can choose from a wide range of vehicles, including sedans, SUVs, and tempo travellers. Whether you're traveling solo or in a group, enjoy a customized experience tailored to your group size, budget, and travel preferences. We guarantee a safe, smooth, and enjoyable ride."
    },
    {
        "name": "Sainagar Railway Station to Shirdi Temple Taxi Fare",
        "description": "We offer competitive and transparent taxi fare rates from Sainagar Railway Station to Shirdi Temple. Our affordable fares come without hidden charges, ensuring you know exactly what to expect. Enjoy a stress-free travel experience without worrying about overcharging, while focusing on your pilgrimage."
    },
    {
        "name": "Sainagar Shirdi Railway Station to Sai Baba Temple Cabs",
        "description": "Our cabs from Sainagar Shirdi Railway Station to Sai Baba Temple provide a smooth and comfortable ride, ensuring you reach the temple with ease. Our drivers are experienced and familiar with the best routes, ensuring your journey is safe and relaxing. The comfort and convenience of our service make your spiritual experience even more fulfilling."
    },
    {
        "name": "Sainagar Shirdi Railway Station to Shirdi Airport Cabs",
        "description": "For travelers flying out, our cabs from Sainagar Shirdi Railway Station to Shirdi Airport offer timely transfers. Our professional drivers are familiar with the best routes to ensure that you never miss your flight. Enjoy a stress-free, punctual ride to the airport, with a comfortable vehicle suited to your needs."
    },
    {
        "name": "Sainagar to Shani Shingnapur Taxi Service",
        "description": "Visit the famous Shani Shingnapur temple with our reliable taxi service from Sainagar. We provide well-maintained vehicles and knowledgeable drivers, ensuring a safe and enjoyable journey to this iconic pilgrimage site. Let us take care of your travel needs so you can focus on your spiritual journey."
    },
    {
        "name": "Sainagar Shirdi to Shirdi MIDC Taxi Service",
        "description": "For travelers with business in the Shirdi MIDC area, our taxi service offers a convenient transportation option. We ensure efficient, punctual rides for corporate and business clients. Whether for meetings or corporate travel, our comfortable and reliable vehicles will meet your transportation needs."
    },
    {
        "name": "Sainagar Shirdi Innova Taxi Service",
        "description": "Our Innova taxi service from Sainagar Shirdi is perfect for families and groups. The spacious Innova ensures a comfortable journey, allowing you to relax and enjoy the scenic route to Shirdi. Whether you are visiting the temple or exploring nearby areas, this service provides ample space for your group and luggage."
    },
    {
        "name": "Sainagar to Shirdi Tempo Traveller on Rent",
        "description": "For larger groups, our tempo traveller rental service from Sainagar to Shirdi provides ample space and comfort. With seating for everyone, this is an ideal option for family trips, group tours, or religious pilgrimages. Enjoy the comfort of traveling together in one vehicle, ensuring convenience and ease throughout the journey."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for dependable transportation between Sainagar Shirdi Railway Station and Shirdi. Our services are designed to enhance your travel experience, providing quality, comfort, and reliability. Whether you need a taxi for a pilgrimage or business trip, we offer a range of vehicles suited to your requirements."
    },
    {
        "name": "SaitirthTours & Travels Contact Number",
        "description": "For prompt and efficient cab service from Sainagar Shirdi to Shirdi, contact Saitirth Travels at +91 9923879009 or +91 9922479009. Our team is here to ensure your journey is smooth, timely, and enjoyable. Call us to book your ride or inquire about our services."
    }
],

"tableData": [
    ["- Sainagar Shirdi to Shirdi Cabs", "- Sainagar Shirdi Railway Station to Shirdi Taxi"],
    ["- Shirdi to Sainagar Shirdi Railway Station Taxi cabs services", "- Hire Cab from Sainagar to Shirdi"],
    ["- Sainagar Railway Station to Shirdi Temple Taxi Fare", "- Sainagar Shirdi Railway Station to Sai Baba Temple Cabs"],
    ["- Sainagar Shirdi Railway Station to Shirdi Airport Cabs", "- Sainagar to Shani Shingnapur Taxi Service"],
    ["- Sainagar Shirdi to Shirdi MIDC Taxi Service", "- Sainagar Shirdi Innova Taxi Service"],
    ["- Sainagar to Shirdi Tempo Traveller on Rent", "- SaitirthTours & Travels Shirdi"],
    ["", ""]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Efficient Local Transfers",
      "WhyChoosedescription": "Our Sainagar Shirdi to Shirdi cab service ensures a quick and convenient transfer between the railway station and Shirdi. We understand the importance of timely transportation, especially for travelers arriving by train, so we streamline the entire process, making it simple and stress-free."
    },
    {
      "WhyChooseheading": "Experienced Local Drivers",
      "WhyChoosedescription": "Our drivers are knowledgeable about Shirdi and ensure the smoothest routes, saving you time and enhancing your visit. They are not only skilled in driving but are also trained to provide you with a friendly and informative experience, sharing interesting stories and recommendations about local attractions."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "Enjoy budget-friendly fares without compromising on quality or service standards. We believe that reliable transportation should be accessible to everyone, and we provide transparent pricing to help you plan your budget effectively. There are no hidden fees, ensuring that you can travel worry-free."
    },
    {
      "WhyChooseheading": "Easy Booking and Flexible Timings",
      "WhyChoosedescription": "With simple booking processes and flexible schedules, we make your travel planning easy and hassle-free. You can book your cab online or via phone, and we accommodate last-minute changes to your itinerary. Our goal is to provide a seamless experience, allowing you to focus on your journey."
    },
    {
      "WhyChooseheading": "Well-Maintained Fleet",
      "WhyChoosedescription": "Our vehicles are regularly maintained and sanitized, ensuring a comfortable and safe journey for every passenger. We prioritize your comfort and safety, providing clean and reliable vehicles for your travel. Our fleet includes a variety of options, from sedans to larger vehicles, accommodating all group sizes."
    },
    {
      "WhyChooseheading": "Prompt Pickup and Drop Services",
      "WhyChoosedescription": "Our reliable drivers are punctual and provide timely pickups and drop-offs, perfect for those on a tight schedule. We respect your time and ensure that you reach your destination promptly, giving you peace of mind during your travels. Our commitment to punctuality sets us apart."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our support team is available around the clock to assist with any travel queries or special requests. Whether you need assistance with bookings, have specific travel needs, or require emergency support during your journey, we're here to help at any time. Our dedicated staff is always just a call away."
    },
    {
      "WhyChooseheading": "Local Sightseeing Options",
      "WhyChoosedescription": "If you wish to explore Shirdi’s main attractions, we offer flexible options for local sightseeing. Our drivers can recommend popular spots, and you can customize your itinerary to make the most of your visit. Whether you want to visit the Sai Baba Temple, explore local markets, or enjoy other attractions, we can help you create a memorable experience."
    }
  ]




        
  }


  const faqData = [
    {
        question: "How long does it take to reach Shirdi from Sainagar Shirdi station?",
        answer: "The drive usually takes about 10-15 minutes, depending on traffic."
    },
    {
        question: "Can I book a return trip to Sainagar Shirdi station?",
        answer: "Yes, return trips can be easily arranged at your convenience."
    },
    {
        question: "What type of vehicles do you offer for this route?",
        answer: "We offer various vehicles, including hatchbacks, sedans, and SUVs, to meet different passenger needs."
    },
    {
        question: "Are there additional charges for luggage?",
        answer: "No, standard luggage is included, but please inform us if you have oversized items."
    },
    {
        question: "Can I book a cab on arrival at the station?",
        answer: "Yes, subject to availability, but we recommend booking in advance for guaranteed service."
    },
    {
        question: "Do you offer sightseeing services from Shirdi?",
        answer: "Yes, we provide customized sightseeing tours around Shirdi and nearby locations."
    },
    {
        question: "Is there a waiting charge if my train is late?",
        answer: "We provide a grace period, but extended waiting may incur a minimal fee."
    },
    {
        question: "Can I cancel my booking if my plans change?",
        answer: "Yes, we have a flexible cancellation policy. Contact us for details on any applicable fees."
    },
    {
        question: "How do I recognize my driver at the station?",
        answer: "Our drivers will be at the designated pickup point with a name placard for easy identification."
    },
    {
        question: "Do you offer early morning or late-night pickups?",
        answer: "Yes, we operate 24/7 and can arrange pickups at any time."
    }
];

const testimonials = [
    {
        name: "Mr. Ravi Sharma",
        text: "My trip to Shirdi was made so much easier and more comfortable thanks to Saitirth Travels. I used their Sainagar Shirdi to Shirdi Cabs service, and I couldn’t have asked for a better experience. The cab was clean, well-maintained, and the driver was not only professional but also knowledgeable about the area, offering some great tips for my visit. The entire journey was smooth and hassle-free, and I felt safe and taken care of throughout. Highly recommend Saitirth Travels for anyone visiting Shirdi!"
    },
    {
        name: "Miss. Priya Desai",
        text: "I’ve used several cab services for my visits to Shirdi, but Saitirth Travels' Sainagar Shirdi to Shirdi Cabs stands out for its excellent customer service. From the booking process to the actual ride, everything was seamless. The vehicle was punctual, the driver was courteous, and the ride was peaceful. It was a perfect way to get around Shirdi without any stress. I’ll definitely choose Saitirth Travels again for my future trips!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Sainagar Shirdi to Shirdi Cabs | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Book a convenient Sainagar Shirdi to Shirdi Cab with SaitirthTours & Travels. Offering taxi services from Sainagar Railway Station to Shirdi Temple, Shirdi Airport, and other local destinations." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Sainagar Shirdi to Shirdi Cabs | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a convenient Sainagar Shirdi to Shirdi Cab with SaitirthTours & Travels. Offering taxi services from Sainagar Railway Station to Shirdi Temple, Shirdi Airport, and other local destinations." />
    <meta property="og:url" content="https://www.saitirthcabs.com/sainagar-shirdi-to-shirdi-cabs" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/sainagar-shirdi-to-shirdi-cabs.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a convenient Sainagar Shirdi to Shirdi Cab with SaitirthTours & Travels. Offering taxi services from Sainagar Railway Station to Shirdi Temple, Shirdi Airport, and other local destinations.",
            "url": "https://www.saitirthcabs.com/sainagar-shirdi-to-shirdi-cabs",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/sainagar-shirdi-to-shirdi-cabs.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Sainagar Shirdi to Shirdi Cabs",
                "Sainagar Shirdi Railway Station to Shirdi Taxi",
                "Shirdi to Sainagar Shirdi Railway Station Taxi",
                "Hire Cab from Sainagar to Shirdi",
                "Sainagar Railway Station to Shirdi Temple Taxi Fare",
                "Sainagar Shirdi Railway Station to Sai Baba Temple Cabs",
                "Sainagar Shirdi Railway Station to Shirdi Airport Cabs",
                "Sainagar to Shani Shingnapur Taxi Service",
                "Sainagar Shirdi to Shirdi MIDC Taxi Service",
                "Sainagar Shirdi Innova Taxi Service",
                "Sainagar to Shirdi Tempo Traveller on Rent",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/72.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Sainagarshirdi;