
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Punetoairport() {



  const cardData =
  {
    keyword: ' Pune Airport to Shirdi Taxi',
    heading: 'Saitirth Travels: Pune Airport to Shirdi Taxi',
    headingDescription: 'When arriving at Pune Airport and planning a trip to Shirdi, Saitirth Travels offers convenient and comfortable taxi services tailored to your needs. Our well-maintained vehicles and professional drivers ensure a smooth journey, making your travel experience enjoyable from the moment you land.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Samadhi Mandir",
            "description": "The heart of Shirdi, the Sai Baba Samadhi Mandir, is where the remains of Sai Baba are interred. This sacred temple is a major pilgrimage site, drawing thousands of devotees seeking blessings and peace. The divine atmosphere, especially during Aarti (prayer ceremony), is a profound experience for all visitors."
        },
        {
            "title": "Dwarkamai Masjid",
            "description": "This historic mosque was the home of Sai Baba for many years. It houses the eternal flame (Dhuni) that Baba kept burning. Devotees can meditate and reflect in this serene space, surrounded by the rich history of Sai Baba's life and teachings."
        },
        {
            "title": "Chavadi",
            "description": "Chavadi is the place where Sai Baba used to rest every alternate night. The site is adorned with beautiful idols and decorations that reflect the love and devotion of his followers. Visiting Chavadi allows you to connect deeply with the legacy of Sai Baba."
        },
        {
            "title": "Shri Sai Baba Sansthan Trust",
            "description": "This trust manages the temple complex and offers various facilities for pilgrims, including accommodation and food services. It plays a vital role in ensuring that devotees have a comfortable experience during their visit to Shirdi."
        },
        {
            "title": "Lendi Baug",
            "description": "A tranquil garden where Sai Baba spent time, Lendi Baug features a peaceful grove, a well, and a shrine. The serene environment is perfect for relaxation and reflection, allowing visitors to feel closer to the spiritual essence of Sai Baba."
        },
        {
            "title": "Samadhi Mandir Museum",
            "description": "Located near the main temple, this museum displays artifacts and memorabilia related to Sai Baba's life. Visitors can learn more about his teachings and the impact he had on his followers through various exhibits."
        },
        {
            "title": "Gurusthan",
            "description": "This site marks the place where Sai Baba first revealed himself to the world. It features a neem tree and a small temple, serving as a significant spot for prayer and remembrance for devotees."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Before visiting the main Sai Baba temple, many devotees stop at Khandoba Mandir. This temple dedicated to Lord Khandoba is an important site and offers a glimpse into the rich spiritual culture of the region."
        },
        {
            "title": "Shirdi Village",
            "description": "A stroll through Shirdi Village allows visitors to experience the local lifestyle, culture, and cuisine. Interacting with locals and enjoying traditional dishes adds to the charm of your visit."
        },
        {
            "title": "Nearby Attractions",
            "description": "Shirdi is also close to Nashik, famous for its vineyards and temples, and the scenic Western Ghats, which are perfect for nature lovers and adventurers looking to explore beautiful landscapes."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Pune Airport to Shirdi Taxi Service",
        "description": "Travel comfortably from Pune Airport to Shirdi with our reliable taxi service. We ensure a smooth and timely journey to one of India's most revered pilgrimage sites, offering a seamless transition from your flight to your spiritual destination. Our professional drivers are dedicated to providing you with a stress-free travel experience."
    },
    {
        "name": "Shirdi to Pune Airport Cab",
        "description": "We also provide taxi services for your return journey from Shirdi to Pune Airport. Enjoy a stress-free ride with our experienced drivers, who know the best routes to ensure your timely arrival at the airport. Relax in our comfortable vehicles as we take care of all your transportation needs."
    },
    {
        "name": "Pune Airport to Shirdi Cab",
        "description": "Our dedicated cab service from Pune Airport to Shirdi offers convenience and comfort, allowing you to relax during your journey. With a fleet of well-maintained vehicles, we guarantee a pleasant ride as you embark on your pilgrimage to Shirdi."
    },
    {
        "name": "Pune Airport to Shirdi Distance Taxi Fare",
        "description": "The distance from Pune Airport to Shirdi is approximately 185 kilometers. Our transparent pricing ensures you get the best taxi fare for your journey, eliminating any surprises. We provide detailed fare estimates that reflect our commitment to honesty in our pricing."
    },
    {
        "name": "Pune Airport to Shirdi Cab Price",
        "description": "Our competitive pricing for the Pune Airport to Shirdi cab ensures affordability without compromising on quality service. We aim to provide the best value for your money, making your spiritual journey accessible and economical."
    },
    {
        "name": "Cab from Pune Airport to Shirdi",
        "description": "Book your cab from Pune Airport to Shirdi for a seamless travel experience. We have a fleet of well-maintained vehicles to choose from, ensuring a comfortable ride tailored to your specific needs. Our booking process is straightforward, making it easy to arrange your transportation."
    },
    {
        "name": "Car Rental from Pune Airport to Shirdi",
        "description": "For those who prefer to drive themselves, we offer car rental services from Pune Airport to Shirdi. Enjoy the flexibility of your own schedule and the freedom to explore the beautiful surroundings on your way to your destination."
    },
    {
        "name": "Pune Airport to Shirdi Airport Transfers",
        "description": "We provide transfers between Pune Airport and Shirdi. Whether you're arriving or departing, we’ve got you covered with our punctual and reliable service. Our drivers are knowledgeable about the area and can assist with any travel questions you may have."
    },
    {
        "name": "Pune Airport to Shirdi Cab Fare",
        "description": "Our cab fare from Pune Airport to Shirdi is affordable, with no hidden charges. We prioritize transparency in our pricing, ensuring you know exactly what you're paying for. This allows you to budget effectively for your trip without any financial surprises."
    },
    {
        "name": "Pune Airport to Shirdi Cab Service",
        "description": "Experience our dedicated cab service for a comfortable and reliable journey from Pune Airport to Shirdi. Our drivers are trained to provide excellent customer service, ensuring you have a pleasant experience from start to finish."
    },
    {
        "name": "Pune Airport to Shirdi Drop",
        "description": "Need a drop-off at Shirdi? Our taxi drop service ensures you reach your destination without any hassle. We focus on providing timely and efficient service, allowing you to focus on your spiritual journey."
    },
    {
        "name": "Pune Airport to Shirdi Shared Taxi",
        "description": "For budget-conscious travelers, we offer shared taxi options from Pune Airport to Shirdi, allowing you to share the ride and costs. This is an economical solution that also gives you the opportunity to meet fellow travelers."
    },
    {
        "name": "Pune Airport to Shirdi Taxi Drop",
        "description": "Our taxi drop service from Pune Airport to Shirdi guarantees a hassle-free travel experience. We ensure that your journey is comfortable and that you arrive at your destination relaxed and ready to enjoy your time in Shirdi."
    },
    {
        "name": "Pune Airport to Shirdi Taxi Fare",
        "description": "We provide competitive taxi fares for your journey from Pune Airport to Shirdi, ensuring value for your money. Our pricing is straightforward, and we offer various payment options for your convenience."
    },
    {
        "name": "Pune Airport to Shirdi Taxi Service",
        "description": "Our taxi service from Pune Airport to Shirdi is designed for comfort and reliability. Enjoy your trip with us, where customer satisfaction is our top priority. We are committed to providing safe and efficient transportation."
    },
    {
        "name": "Taxi from Pune Airport to Shirdi",
        "description": "Book a taxi from Pune Airport to Shirdi for a convenient travel option. We prioritize your safety and comfort on the road, ensuring that you arrive at your destination feeling refreshed and ready for your pilgrimage."
    },
    {
        "name": "Shirdi to Pune Airport Taxi",
        "description": "Our service extends to taxi rides from Shirdi to Pune Airport. Enjoy a comfortable and timely transfer back to the airport, with experienced drivers who are familiar with the best routes for efficient travel."
    },
    {
        "name": "Taxi to Shirdi from Pune",
        "description": "We offer a reliable taxi service for your journey from Pune to Shirdi, ensuring a pleasant travel experience. Our vehicles are equipped with modern amenities for your comfort, and our drivers are dedicated to providing a safe ride."
    },
    {
        "name": "Pune Airport to Shirdi Tempo Traveller",
        "description": "For larger groups, we provide tempo traveller services from Pune Airport to Shirdi. Travel together comfortably with ample space, making it the perfect choice for family trips or group pilgrimages."
    },
    {
        "name": "Pune Airport to Shirdi Innova Crysta",
        "description": "Travel in style with our Innova Crysta taxi service from Pune Airport to Shirdi. Experience luxury and comfort during your journey, with spacious seating and modern amenities to enhance your travel experience."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for all your transportation needs from Pune Airport to Shirdi. We are committed to providing excellent service and customer satisfaction, ensuring that your journey is as enjoyable as your destination."
    },
    {
        "name": "Pune Airport to Shirdi Taxi Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Let us assist you in making your trip from Pune Airport to Shirdi a memorable one! Our team is ready to help you with all your travel needs."
    }
],

tableData: [
    ["- Pune to Shirdi Cab", "- Pune to Shirdi Taxi"],
    ["- Pune to Shirdi Cab Charges", "- Pune to Shirdi Cab Package"],
    ["- Pune to Shirdi Cab Fare", "- Pune to Shirdi Cab One Way"],
    ["- Pune to Shirdi Taxi Fare", "- Pune to Shirdi Cab Service"],
    ["- Shirdi to Pune Cab", "- Pune to Shirdi Cab Booking Service"],
    ["- Pune to Shirdi Taxi Drop", "- Pune Airport to Shirdi Cab"],
    ["- Pune to Shirdi Car Rental", "- Pune to Shirdi Cab Booking"],
    ["- Pune to Shirdi Cab Booking Rates", "- Pune Airport to Shirdi Taxi"],
    ["- Pune to Shirdi Cab Service Rates", "- Pune to Shirdi Package by Car"],
    ["- Pune to Shirdi Cabs Round Trip", "- Shirdi to Pune Airport Cab"],
    ["- Cab for Shirdi from Pune", "- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Reliable Service",
      "WhyChoosedescription": "We are known for our reliability, ensuring that you reach Shirdi on time, every time. Our services are dependable, so you can travel with peace of mind knowing that your cab will be ready and waiting for you as scheduled."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Our fleet of well-maintained vehicles provides a comfortable journey. With modern amenities like air conditioning, spacious seating, and smooth rides, you can relax and enjoy your travel from Pune to Shirdi."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our drivers are professional, experienced, and fully trained. They know the best routes and ensure a safe and smooth ride for you, so you can enjoy your journey with confidence."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive pricing without compromising on service quality. Our transparent rates ensure you get the best value for your money, with no hidden charges or surprise costs."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Whether you need a ride early in the morning or late at night, our service is available 24/7. You can book a ride anytime, making it convenient for you to plan your trip as per your schedule."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking your Pune to Shirdi cab is simple and quick with our easy-to-use online platform. Alternatively, you can reach out to our customer service team for assistance. Booking your ride has never been more convenient."
    },
    {
      "WhyChooseheading": "Flexible Timings",
      "WhyChoosedescription": "We understand that travel needs can vary, which is why we offer flexible pick-up and drop-off timings. Whether you're looking for a morning or evening departure, we can accommodate your preferred travel schedule."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles are regularly serviced and sanitized to ensure that you have a safe and comfortable journey. We follow strict safety protocols to provide a secure environment for all passengers."
    },
    {
      "WhyChooseheading": "Customizable Packages",
      "WhyChoosedescription": "We offer customizable travel packages for one-way or round trips, tailored to your needs. Whether you need a quick trip or a longer stay, we can adjust our services to suit your travel requirements."
    },
    {
      "WhyChooseheading": "Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available to assist with any inquiries or concerns. Whether you need help with bookings or have questions during your trip, our team is here to ensure a smooth experience."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "How far is Shirdi from Pune Airport?",
        answer: "The distance from Pune Airport to Shirdi is approximately 230 kilometers, which typically takes around 5-6 hours by taxi."
    },
    {
        question: "How can I book a taxi from Pune Airport to Shirdi?",
        answer: "You can book your taxi easily through our website or by contacting our customer service team."
    },
    {
        question: "What type of vehicles do you offer for the airport transfer?",
        answer: "We provide a range of vehicles, including sedans, SUVs, and larger vans to accommodate different group sizes and preferences."
    },
    {
        question: "Are there any additional charges for tolls or parking?",
        answer: "Yes, tolls and parking fees are additional and will be communicated during the booking process."
    },
    {
        question: "Can I schedule a return taxi from Shirdi to Pune Airport?",
        answer: "Yes, we offer round-trip services and can customize your itinerary as needed."
    },
    {
        question: "What are the fare rates for the Pune Airport to Shirdi taxi service?",
        answer: "The fare depends on the vehicle type and duration of travel. Please contact us for a personalized quote."
    },
    {
        question: "Is traveling with Saitirth Travels safe?",
        answer: "Absolutely, we prioritize safety by maintaining our vehicles and employing professional drivers."
    },
    {
        question: "Can I modify my booking after it has been confirmed?",
        answer: "Yes, changes can be made, but please inform us as soon as possible to accommodate your request."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a cancellation policy in place. Please contact us for specific details."
    },
    {
        question: "How can I reach customer support for assistance?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Arvind Rao",
        text: "I had an excellent experience with Saitirth Travels for my taxi ride from Pune Airport to Shirdi. The booking process was seamless, and the driver was waiting for me at the airport. The car was clean and comfortable, making the journey enjoyable. I highly recommend Saitirth Travels for anyone needing reliable transport!"
    },
    {
        name: "Ms. Neha Kulkarni",
        text: "The taxi service from Pune Airport to Shirdi provided by Saitirth Travels was top-notch! The driver was friendly and knowledgeable, making the trip smooth and pleasant. The fare was reasonable, and the car was well-maintained. I will definitely choose Saitirth Travels for my future travels!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Pune Airport to Shirdi Taxi | Shirdi to Pune Airport Cab Service | Affordable Taxi & Car Rental</title>
    <meta name="description" content="Book Pune Airport to Shirdi Taxi with Saitirth Travels. We offer reliable and affordable taxi services, including car rentals, shared taxis, and tempo travellers from Pune Airport to Shirdi." />
    <meta name="keywords" content="Shirdi to Pune Airport Cab, Pune Airport to Shirdi Cab, Pune Airport to Shirdi Distance Taxi Fare, Pune Airport to Shirdi Cab Price, Cab from Pune Airport to Shirdi, Car Rental from Pune Airport to Shirdi, Pune Airport to Shirdi Taxi Service, Pune Airport to Shirdi Drop, Pune Airport to Shirdi Shared Taxi, Taxi from Pune Airport to Shirdi, Pune Airport to Shirdi Innova Crysta, Pune Airport to Shirdi Tempo Traveller" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Pune Airport to Shirdi Taxi | Shirdi to Pune Airport Cab Service | Affordable Taxi & Car Rental" />
    <meta property="og:description" content="Book a Pune Airport to Shirdi Taxi with Saitirth Travels. Enjoy convenient and affordable taxi services, car rentals, shared taxis, and tempo travellers for your journey from Pune Airport to Shirdi." />
    <meta property="og:url" content="https://www.saitirthcabs.com/pune-airport-to-shirdi-taxi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/pune-airport-to-shirdi-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book Pune Airport to Shirdi Taxi with Saitirth Travels. We provide affordable and reliable taxi services for your journey from Pune Airport to Shirdi, including shared taxis, car rentals, and tempo travellers.",
            "url": "https://www.saitirthcabs.com/pune-airport-to-shirdi-taxi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/pune-airport-to-shirdi-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Pune Airport Cab",
                "Pune Airport to Shirdi Cab",
                "Pune Airport to Shirdi Distance Taxi Fare",
                "Pune Airport to Shirdi Cab Price",
                "Cab from Pune Airport to Shirdi",
                "Car Rental from Pune Airport to Shirdi",
                "Pune Airport to Shirdi Taxi Service",
                "Pune Airport to Shirdi Drop",
                "Pune Airport to Shirdi Shared Taxi",
                "Taxi from Pune Airport to Shirdi",
                "Pune Airport to Shirdi Innova Crysta",
                "Pune Airport to Shirdi Tempo Traveller"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/82.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoairport;