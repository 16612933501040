
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Tempotravelleronrent() {



  const cardData =
  {
    keyword: ' Tempo Traveller 14 Seater Rent in Shirdi',
    heading: 'Saitirth Travels: Tempo Traveller 14 Seater Rent in Shirdi',
    headingDescription: 'Explore the spiritual heart of Shirdi with the convenience of a 14-seater Tempo Traveller. Perfect for large families, groups of friends, or corporate outings, this vehicle offers spacious seating, comfort, and ample luggage space, making it the ideal choice for your travels in and around Shirdi. Saitirth Travels provides reliable and well-maintained Tempo Travellers to ensure a smooth journey.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The main attraction of Shirdi, this temple is dedicated to Sai Baba, who is revered by millions. The serene atmosphere, especially during the daily aarti (worship ceremony), draws devotees from around the world."
        },
        {
            "title": "Dwarkamai",
            "description": "This mosque served as a home to Sai Baba and features the sacred fire (Dhuni) that he lit, which continues to burn. The site holds deep significance for devotees seeking spiritual connection."
        },
        {
            "title": "Chavadi",
            "description": "A small but significant place where Sai Baba would rest and meditate. Visitors can appreciate the simplicity and historical importance of this site."
        },
        {
            "title": "Lendi Garden",
            "description": "A tranquil garden with lush greenery, perfect for relaxation. Sai Baba is said to have tended to this garden, making it a peaceful retreat for visitors."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "A prominent temple dedicated to Lord Khandoba, located on a hilltop. It is famous for its vibrant celebrations during festivals, providing breathtaking views of the surrounding area."
        },
        {
            "title": "Gurusthan",
            "description": "The location where Sai Baba first gained recognition. The site features a small shrine, offering a reflective space for devotees."
        },
        {
            "title": "Sai Heritage Village",
            "description": "This cultural complex showcases the life and teachings of Sai Baba through various exhibits and presentations. It’s an educational stop for visitors of all ages."
        },
        {
            "title": "Maruti Mandir",
            "description": "A peaceful temple dedicated to Lord Hanuman, frequented by devotees who come to seek blessings and participate in prayers."
        },
        {
            "title": "Khandoba Temple",
            "description": "An ancient temple known for its vibrant festivals and beautiful architecture, attracting visitors for both spiritual and historical experiences."
        },
        {
            "title": "Sainath Mahal",
            "description": "A convenient accommodation option for travelers, located close to the main attractions in Shirdi, offering comfortable rooms and facilities."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Tempo Traveller 14 Seater Rent in Shirdi",
        "description": "Experience comfortable group travel with our 14-seater Tempo Traveller, ideal for family trips, corporate outings, and group tours. Our service is designed to cater to your travel needs while providing ample space and convenience."
    },
    {
        "name": "Tempo Traveller 14 Seater Rent Per Km in Shirdi",
        "description": "Our Tempo Traveller rental is offered at competitive rates per kilometer in Shirdi. Enjoy transparency in pricing with no hidden costs, making it easy to budget for your journey."
    },
    {
        "name": "Tempo Traveller on Rent Near Me",
        "description": "Looking for a Tempo Traveller rental nearby? We provide convenient options to hire a 14-seater vehicle in Shirdi, ensuring you have access to reliable transportation whenever you need it."
    },
    {
        "name": "Tempo Traveller on Rent in Shirdi",
        "description": "Our Tempo Traveller on rent in Shirdi is perfect for any occasion. Whether you need it for a local trip or an outstation journey, our well-maintained vehicles guarantee a comfortable ride."
    },
    {
        "name": "Tempo Traveller Hire in Shirdi",
        "description": "Hiring a Tempo Traveller in Shirdi is simple and hassle-free. Our experienced drivers ensure safe and smooth travel, allowing you to relax and enjoy your trip with family or friends."
    },
    {
        "name": "Shirdi to Shani Shingnapur 14 Seater Tempo Traveller",
        "description": "Planning a visit to Shani Shingnapur from Shirdi? Our 14-seater Tempo Traveller is the perfect choice for your pilgrimage, providing a spacious and comfortable ride for your group."
    },
    {
        "name": "Nagarsol to Shirdi Tempo Traveller",
        "description": "Traveling from Nagarsol to Shirdi? Our Tempo Traveller is available for this route, ensuring a comfortable journey for your family or friends."
    },
    {
        "name": "Shirdi to Bhimashankar 14 Seater Tempo Traveller",
        "description": "Explore the spiritual beauty of Bhimashankar with our 14-seater Tempo Traveller. Perfect for family trips, this vehicle provides ample space and comfort for your journey."
    },
    {
        "name": "Shirdi to Nashik 14 Seater Tempo Traveller",
        "description": "Make your trip to Nashik enjoyable with our 14-seater Tempo Traveller. Spacious interiors and comfortable seating make this the ideal choice for a group outing."
    },
    {
        "name": "Shirdi to Trimbakeshwar Tempo Traveller",
        "description": "Our Tempo Traveller service to Trimbakeshwar offers a comfortable ride for groups. Enjoy the scenic journey with friends or family in our well-equipped vehicle."
    },
    {
        "name": "Shirdi to Ajanta Ellora Temple Tempo Traveller",
        "description": "Visit the famous Ajanta and Ellora Caves with our 14-seater Tempo Traveller. Experience a comfortable ride while exploring these UNESCO World Heritage Sites."
    },
    {
        "name": "Shirdi to Aurangabad 14 Seater Tempo Traveller",
        "description": "Travel to Aurangabad in comfort with our Tempo Traveller. Perfect for family trips, we ensure a smooth journey with plenty of room for everyone."
    },
    {
        "name": "Shirdi Airport 14 Seater Tempo Traveller",
        "description": "Arriving at Shirdi Airport? Our 14-seater Tempo Traveller is available for airport transfers, ensuring you have a comfortable ride to your destination."
    },
    {
        "name": "Tempo Traveller 14 Seater AC Bus on Rent",
        "description": "Our 14-seater Tempo Traveller comes equipped with air conditioning, providing a pleasant travel experience, especially during warm weather."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for your 14-seater Tempo Traveller needs in Shirdi. We are dedicated to providing excellent service and well-maintained vehicles for all your travel requirements."
    },
    {
        "name": "Tempo Traveller 14 Seater Rent in Shirdi Contact Information",
        "description": "For bookings or inquiries, contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Let us help you create unforgettable travel experiences!"
    }
],

tableData: [
    ["- Tempo Traveller 14 Seater Rent Per KM in Shirdi", "- Tempo Traveller on Rent Near Me"],
    ["- Tempo Traveller on Rent in Shirdi", "- Tempo Traveller Hire in Shirdi"],
    ["- Shirdi to Shani Shingnapur 14 Seater Tempo Traveller", "- Nagarsol to Shirdi Tempo Traveller"],
    ["- Shirdi to Bhimashankar 14 Seater Tempo Traveller", "- Shirdi to Nashik 14 Seater Tempo Traveller"],
    ["- Shirdi to Trimbakeshwar Tempo Traveller", "- Shirdi to Ajanta Ellora Temple Tempo Traveller"],
    ["- Shirdi to Aurangabad 14 Seater Tempo Traveller", "- Shirdi Airport 14 Seater Tempo Traveller"],
    ["- Tempo Traveller 14 Seater AC Bus on Rent", "- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Ideal for Group Travel",
      "WhyChoosedescription": "Our 14-seater Tempo Traveller is the perfect choice for families, friends, or corporate groups traveling together. Whether you're going on a pilgrimage, a business trip, or a family vacation, this spacious vehicle ensures that everyone travels together in comfort. It offers the ideal solution for large groups seeking to travel together, saving you the hassle of coordinating multiple vehicles and ensuring you can all enjoy the journey as one."
    },
    {
      "WhyChooseheading": "Comfortable Seating",
      "WhyChoosedescription": "The Tempo Traveller is equipped with cushioned seats that provide excellent comfort, even on long trips. Each seat is designed to offer ample legroom, ensuring that everyone can travel comfortably without feeling cramped. Whether you're heading on a local sightseeing tour or a long-distance journey, the comfortable seating ensures you can relax and enjoy the ride."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We understand that group travel can be expensive, which is why we offer competitive pricing for our Tempo Traveller rentals. Our rental rates are designed to be affordable, allowing large groups to travel together at an economical cost. We offer transparent pricing with no hidden fees, ensuring that you get the best value for your money while enjoying a comfortable and safe ride."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are experienced, courteous, and knowledgeable about Shirdi and the surrounding areas. They are familiar with the best routes to all major tourist destinations and outstation locations, ensuring a smooth, safe, and timely journey. Our drivers are committed to providing you with the best possible travel experience, prioritizing your safety and comfort."
    },
    {
      "WhyChooseheading": "Flexible Rental Options",
      "WhyChoosedescription": "We offer flexible rental packages for our 14-seater Tempo Traveller, catering to a variety of travel needs. Whether you need the vehicle for a few hours, a full day of sightseeing, or an extended multi-day trip, we have the right plan for you. Our flexible options ensure that you only pay for the duration of your trip, making it convenient and cost-effective for all types of travel plans."
    },
    {
      "WhyChooseheading": "Well-Maintained Fleet",
      "WhyChoosedescription": "Our Tempo Traveller fleet is regularly maintained to ensure a safe, smooth, and comfortable journey for all passengers. Each vehicle undergoes routine checks, ensuring that it is in top condition before every trip. We take great pride in maintaining our vehicles, so you can have peace of mind knowing that you're traveling in a clean, reliable, and well-maintained vehicle."
    },
    {
      "WhyChooseheading": "Ample Luggage Space",
      "WhyChoosedescription": "One of the key features of our 14-seater Tempo Traveller is the large luggage compartment, which provides plenty of space for your bags, travel gear, or shopping. Whether you're heading for a religious trip, family vacation, or corporate tour, you'll have enough room to store all your belongings securely. This means that you don’t have to worry about luggage space during your trip."
    },
    {
      "WhyChooseheading": "On-Time Service",
      "WhyChoosedescription": "Punctuality is one of our core values. We understand that time is of the essence, especially when traveling for special occasions or important events. Our drivers are always on time for pick-ups and drop-offs, ensuring that you reach your destination as scheduled. We value your time and aim to provide timely service, so you can focus on enjoying your trip without worrying about delays."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our customer support team is available around the clock to assist with any queries, booking issues, or last-minute changes to your travel plans. Whether you need to adjust your booking, request additional services, or need assistance during your trip, our team is here to help. We provide responsive support to ensure a smooth and stress-free travel experience."
    },
    {
      "WhyChooseheading": "Fully Sanitized Vehicles",
      "WhyChoosedescription": "Your health and safety are our top priority. In response to health concerns, we ensure that all our Tempo Traveller vehicles are thoroughly cleaned and sanitized between each rental. From disinfecting the seats, handles, and other surfaces to ensuring fresh air circulation, we maintain strict hygiene standards to give you peace of mind while traveling."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "What is the rental cost for a 14-seater Tempo Traveller in Shirdi?",
        answer: "The rental cost depends on factors like trip duration and distance. Contact us for a detailed quote."
    },
    {
        question: "Can I book the Tempo Traveller for outstation trips?",
        answer: "Yes, our 14-seater Tempo Traveller is available for outstation travel to nearby cities and popular tourist destinations."
    },
    {
        question: "Is a driver provided with the rental?",
        answer: "Yes, a professional driver is included with every booking."
    },
    {
        question: "Are fuel charges included in the rental price?",
        answer: "Fuel charges are generally separate, but we can offer an all-inclusive package if preferred."
    },
    {
        question: "Can I extend the rental period during the trip?",
        answer: "Yes, extensions are possible, subject to vehicle availability."
    },
    {
        question: "How many passengers can the Tempo Traveller seat comfortably?",
        answer: "The vehicle comfortably seats up to 14 passengers with spacious seating."
    },
    {
        question: "Is there storage space for luggage?",
        answer: "Yes, the Tempo Traveller has ample luggage space for group travel needs."
    },
    {
        question: "What are the cancellation charges?",
        answer: "We offer flexible cancellation terms. Contact us for detailed information."
    },
    {
        question: "Are there discounts for multi-day rentals?",
        answer: "Yes, we offer discounts for extended rental periods. Please inquire for more details."
    },
    {
        question: "Do you provide pickup and drop services within Shirdi?",
        answer: "Yes, we offer convenient pickup and drop-off services within Shirdi and nearby areas."
    }
];

const testimonials = [
    {
        name: "Mr. Sanjay Kumar",
        text: "Renting the Tempo Traveller 14 seater from Saitirth Travels for our group trip to Shirdi was a great decision! The vehicle was spacious and comfortable, making our journey enjoyable. The staff was friendly and made the booking process seamless. I highly recommend Saitirth Travels for group travel in Shirdi!"
    },
    {
        name: "Ms. Rina Mehta",
        text: "Our experience with the Tempo Traveller 14 seater from Saitirth Travels was outstanding! The vehicle was well-maintained and perfect for our family outing. The service was prompt and professional, ensuring we had everything we needed for a fantastic trip. I will definitely choose Saitirth Travels again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Tempo Traveller 14 Seater Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Hire 14-seater Tempo Traveller in Shirdi for sightseeing, pilgrimage tours, and more. Available for trips to Shani Shingnapur, Bhimashankar, Nashik, Ajanta Ellora, and more with SaitirthTours & Travels." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Tempo Traveller 14 Seater Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Hire 14-seater Tempo Traveller in Shirdi for sightseeing, pilgrimage tours, and more. Available for trips to Shani Shingnapur, Bhimashankar, Nashik, Ajanta Ellora, and more with SaitirthTours & Travels." />
    <meta property="og:url" content="https://www.saitirthcabs.com/tempo-traveller-14-seater-rent-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/tempo-traveller-14-seater-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Hire 14-seater Tempo Traveller in Shirdi for sightseeing, pilgrimage tours, and more. Available for trips to Shani Shingnapur, Bhimashankar, Nashik, Ajanta Ellora, and more with SaitirthTours & Travels.",
            "url": "https://www.saitirthcabs.com/tempo-traveller-14-seater-rent-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/tempo-traveller-14-seater-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Tempo Traveller 14 Seater Rent Per Km in Shirdi",
                "Tempo Traveller on Rent Near Me",
                "Tempo Traveller on Rent in Shirdi",
                "Tempo Traveller Hire in Shirdi",
                "Shirdi to Shani Shingnapur 14 Seater Tempo Traveller",
                "Nagarsol to Shirdi Tempo Traveller",
                "Shirdi to Bhimashankar 14 Seater Tempo Traveller",
                "Shirdi to Nashik 14 Seater Tempo Traveller",
                "Shirdi to Trimbakeshwar Tempo Traveller",
                "Shirdi to Ajanta Ellora Temple Tempo Traveller",
                "Shirdi to Aurangabad 14 Seater Tempo Traveller",
                "Shirdi Airport 14 Seater Tempo Traveller",
                "Tempo Traveller 14 Seater AC Bus on Rent"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/77.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Tempotravelleronrent;