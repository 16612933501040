import React, { useState } from "react";
import '../css/Gallery.css'; // Import your CSS file
import { Helmet } from 'react-helmet';
import GoogleTag from "./GoogleTag";

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
    { src: "/images/gallery/Img for Gallery-600x400px-16.jpg", alt: "Image 16" },
    { src: "/images/gallery/Img for Gallery-600x400px-17.jpg", alt: "Image 17" },
    { src: "/images/gallery/Img for Gallery-600x400px-18.jpg", alt: "Image 18" },
    { src: "/images/gallery/Img for Gallery-600x400px-19.jpg", alt: "Image 19" },
    { src: "/images/gallery/Img for Gallery-600x400px-20.jpg", alt: "Image 20" },
    { src: "/images/gallery/Img for Gallery-600x400px-21.jpg", alt: "Image 21" },
    { src: "/images/gallery/3.jpeg", alt: "Image 22" },
    { src: "/images/gallery/4.jpeg", alt: "Image 23" },
    { src: "/images/gallery/6.jpeg", alt: "Image 25" },
    { src: "/images/gallery/2.jpeg", alt: "Image 25" },
    { src: "/images/gallery/1.jpeg", alt: "Image 25" },
   
    // Add more images as needed
  ];

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (

<div>
<GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

<section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title"> Gallery</h1>
            </div>
        </div>
    </section>




    <div className="gallery-container">
      <div className="gallery-row">
        {images.map((image, index) => (
          <div className="gallery-item col-md-4 borderrr" key={index}>
            <img
              src={image.src}
              alt={image.alt}
              className="gallery-image"
              onClick={() => openModal(index)}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={images[currentImageIndex].src} alt={images[currentImageIndex].alt} className="modal-image" />
            <button className="prev" onClick={goToPreviousImage}>&#10094;</button>
            <button className="next" onClick={goToNextImage}>&#10095;</button>
          </div>
        </div>
      )}
    </div>
</div>
  );
};

export default Gallery;
