

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa';

const Header = () => {
  const navbarCollapseRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState({ about: false, contact: false });

  const handleNavItemClick = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove('show');
    }
    setDropdownOpen({ about: false, contact: false }); // Close dropdowns
  };

  const toggleDropdown = (menu) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu]
    }));
  };

  useEffect(() => {
    const handleMobileMenuToggle = () => {
      if (navbarCollapseRef.current) {
        navbarCollapseRef.current.classList.toggle('show');
      }
    };

    const mobileMenuIcon = mobileMenuRef.current;
    mobileMenuIcon.addEventListener('click', handleMobileMenuToggle);

    return () => {
      mobileMenuIcon.removeEventListener('click', handleMobileMenuToggle);
    };
  }, []);

  return (
    <header className="main-header">
      <div className="header-top">
        <div className="auto-container">
          <div className="inner-container bg-redd text-white py-md-2 text-center">
            <div className="top-left">
              <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
                <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
                  <div>
                    <p className="mb-0">
                      <div className="phone-numbers d-md-flex">
                        <a href="tel:+919923879009" className="d-block fw-bold text-white fs-">
                          <i className="fas fa-phone text-dark colored me-2"></i>+91 9923879009
                        </a>
                        <a href="tel:+919922479009" className="d-none d-md-block fw-bold text-white fs">
                          &nbsp; +91 9922479009
                        </a>
                        <a href="tel:+919922479009" className="d-block d-md-none text-white fw-bold fs">
                          <i className="fas fa-phone-alt text-dark colored me-2"></i>+91 9922479009
                        </a>
                      </div>
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
                  <FaEnvelope className="text-dark colored me-2" />
                  <div>
                    <p className="mb-0">
                      <a href="mailto:booking@saitirthcabs.com" className="d-block fw-bold text-white">booking@saitirthcabs.com</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container ">
          <Link className="navbar-brand" to="/">
            <img src="/images/logo2.jpg" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler bordee"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={mobileMenuRef}
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className="collapse navbar-collapse" ref={navbarCollapseRef} id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/" onClick={handleNavItemClick}>Home</Link>
              </li>
              
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle active fw-bold"
                  onClick={() => toggleDropdown('about')}
                  style={{ cursor: 'pointer' }}
                >
                  About
                </span>
                <ul className={`dropdown-menu ${dropdownOpen.about ? 'show' : ''}`}>
                  <li><Link className="dropdown-item fw-bold" to="/about" onClick={handleNavItemClick}>About Us</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/term-condition" onClick={handleNavItemClick}>Term & Condition</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/privacy-policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
                </ul>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/services" onClick={handleNavItemClick}>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-fleets" onClick={handleNavItemClick}>Our Fleet</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/package" onClick={handleNavItemClick}>Packages</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-network" onClick={handleNavItemClick}>Our Network</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/booking" onClick={handleNavItemClick}>Booking</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
              </li>
              
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle active fw-bold"
                  onClick={() => toggleDropdown('contact')}
                  style={{ cursor: 'pointer' }}
                >
                  Contact
                </span>
                <ul className={`dropdown-menu ${dropdownOpen.contact ? 'show' : ''}`}>
                  <li><Link className="dropdown-item fw-bold" to="/contact" onClick={handleNavItemClick}>Contact</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/career" onClick={handleNavItemClick}>Career</Link></li>
                </ul>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/blog" onClick={handleNavItemClick}>Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;