import React from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const PricingSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const pricingData = [
        {
            name: '  Ertiga',
            city: 'Shirdi',
            image: '/images/gallery/Img for Gallery-600x400px-09.jpg',
            initialChange: '$2.50',
            perKM: '₹14',
            perTraffic: '$1.50',
            passengers: '6+1 ',
        },
        {
            name: 'Kia Carens',
            city: 'Shirdi',
            image: '/images/gallery/Img for Gallery-600x400px-10.jpg',
            initialChange: '$2.50',
            perKM: '₹15',
            perTraffic: '$1.50',
            passengers: '4+1 ',
        },
        {
            name: 'Hyundai Aura ',
            city: 'Shirdi',
            image: '/images/gallery/Img for Gallery-600x400px-04.jpg',
            initialChange: '$2.50',
            perKM: '₹13',
            perTraffic: '$1.50',
            passengers: '4+1 ',
        },
        // Add more data as needed...
    ];

    return (
        <section className="pricing-section-four py-3 p-0" style={{backgroundColor:'#C4CDD6'}}>
            <div className="container">
                <div className="sec-title text-center">
                    <span className="sub-title">Select a plan that shouts</span>
                    <h2 className="letters-slide-up text-split">Choose Your Taxi to <br />Ride!</h2>
                </div>
                <div className="outer-box">
                    <Slider {...settings}>
                        {pricingData.map((item, index) => (
                            <div className="pricing-block-four" key={index}>
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image"><img src={item.image} alt={item.name} /></figure>
                                    </div>
                                    <div className="content">
                                        <div className="car-detail">
                                            <h4 className="car-name">{item.name}</h4>
                                            <div className="city">{item.city}</div>
                                        </div>
                                        <ul className="feature-list">
                                            <li>Per Mile/KM: <span className="price">{item.perKM}</span></li>
                                            <li>Passengers: <span className="price">{item.passengers}</span></li>
                                        </ul>
                                        <div className="btn-box">
                                            <Link to="/booking" className="theme-btn btn-style-two hover-light">
                                                <span className="btn-title">Book a Taxi</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
