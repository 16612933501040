
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditohyderabad() {



  const cardData =
  {
    keyword: '  Shirdi to Hyderabad Cab ',
    heading: 'Saitirth Travels: Shirdi to Hyderabad Cab',
    headingDescription: 'Traveling from Shirdi to Hyderabad has never been easier with Saitirth Travels. Our cab service offers comfortable and reliable transportation, making it a popular choice for both pilgrims and tourists. Whether you’re visiting the historical sites of Hyderabad or heading for business, our cabs are well-equipped to meet your travel needs, ensuring a smooth journey.',

    top: 'Top Places to Visit in Hyderabad with Saitirth Travels',

    topPlaces: [
        {
            "title": "Charminar",
            "description": "Charminar, the iconic symbol of Hyderabad, is a beautiful 16th-century mosque known for its four towering arches and minarets. Built by Sultan Muhammad Quli Qutb Shah, it stands as a testament to Indo-Islamic architecture. Visitors can climb to the top to admire panoramic views of the surrounding Old City. Charminar is surrounded by vibrant bazaars, including the famous Laad Bazaar, where shoppers can find bangles, pearls, textiles, and street food. The lively atmosphere and historical allure make Charminar a must-visit landmark in Hyderabad."
        },
        {
            "title": "Golconda Fort",
            "description": "Golconda Fort, a grand fortress dating back to the 12th century, showcases remarkable architecture and engineering. Known for its acoustic effects and strategic design, it once served as the capital of the Qutb Shahi dynasty. Visitors can explore its impressive gates, royal palaces, mosques, and gardens. The climb to the top rewards with panoramic views of Hyderabad, especially beautiful at sunset. Guided tours provide insights into the fort’s rich history and tales of hidden treasure, making it a captivating destination for history enthusiasts."
        },
        {
            "title": "Hussain Sagar Lake",
            "description": "Hussain Sagar Lake, an artificial lake built by Ibrahim Quli Qutb Shah, is a popular recreational spot in Hyderabad. Its centerpiece is the massive Buddha statue standing in the middle, which can be reached by boat. The lake offers serene views, particularly striking at sunset. Around the lake, parks like Lumbini Park and NTR Gardens add to the attraction, providing spaces for picnics and relaxation. Hussain Sagar is also a hub for water sports, dining, and evening strolls along Necklace Road, a scenic boulevard that encircles the lake."
        },
        {
            "title": "Ramoji Film City",
            "description": "Ramoji Film City, one of the largest film cities in the world, is a major attraction for cinema lovers and families. Spanning over 2,000 acres, this film city offers guided tours through various film sets, themed gardens, and replica cities. Visitors can enjoy live shows, adventure activities, and themed rides, creating an interactive experience of the magic of filmmaking. With themed accommodations, restaurants, and a chance to explore behind-the-scenes, Ramoji Film City is an exciting destination that transports visitors into the world of cinema."
        },
        {
            "title": "Qutb Shahi Tombs",
            "description": "Nestled amidst beautiful gardens near Golconda Fort, the Qutb Shahi Tombs are the final resting places of the Qutb Shahi rulers. These tombs showcase intricate Persian, Pathan, and Hindu architectural elements, set in an expansive garden landscape. The domed structures and arches, adorned with tile work, are a tribute to Hyderabad’s royal history. Visitors often come here to admire the architectural beauty, enjoy peaceful walks through the gardens, and reflect on the city's royal heritage."
        },
        {
            "title": "Salar Jung Museum",
            "description": "Salar Jung Museum, one of the largest museums in India, houses a remarkable collection of art, artifacts, and antiques from around the world. Established by Nawab Mir Yousuf Ali Khan, the museum’s 38 galleries showcase sculptures, paintings, textiles, clocks, and rare manuscripts. Highlights include the Veiled Rebecca statue, a vast clock collection, and exquisite jade artifacts. It’s a treasure trove for art and history enthusiasts, providing a glimpse into diverse cultures and Hyderabad's storied past."
        },
        {
            "title": "Birla Mandir",
            "description": "Situated atop a hill, Birla Mandir is a stunning marble temple dedicated to Lord Venkateswara. Built entirely from white marble, the temple features intricate carvings and a peaceful atmosphere ideal for meditation and prayer. From the temple’s courtyard, visitors can enjoy panoramic views of the city, especially enchanting at sunrise and sunset. The serene surroundings and spiritual ambiance make Birla Mandir a favored spot for both locals and tourists seeking tranquility and divine blessings."
        },
        {
            "title": "Laad Bazaar",
            "description": "Laad Bazaar, located near Charminar, is a bustling market renowned for its bangles, especially those adorned with glittering lac and stones. The market offers a colorful array of traditional jewelry, textiles, perfumes, and handicrafts. With its narrow, lively lanes filled with vendors and shoppers, Laad Bazaar offers an immersive shopping experience and a true taste of Hyderabad's culture. It’s a must-visit for those looking for authentic souvenirs and a glimpse into local life."
        },
        {
            "title": "Necklace Road",
            "description": "Encircling Hussain Sagar Lake, Necklace Road is a scenic boulevard offering beautiful views and a range of recreational activities. It’s popular for evening walks, cycling, and picnics, with parks like Sanjeevaiah Park and Jalavihar Water Park along the way. The road is lined with restaurants and cafes offering lakeside dining, making it a perfect spot for a leisurely evening with family or friends. With its vibrant atmosphere, Necklace Road is a favorite for locals and tourists alike."
        },
        {
            "title": "Shilparamam",
            "description": "Shilparamam, an arts and crafts village in Hyderabad, is a celebration of India’s traditional arts and craftsmanship. Set amidst landscaped gardens, it offers stalls selling handicrafts, textiles, pottery, and jewelry from various regions of India. Visitors can watch artisans at work, participate in workshops, and enjoy cultural performances showcasing folk music and dance. Shilparamam also hosts seasonal fairs and festivals, providing a colorful, authentic experience of India’s rich cultural diversity."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi to Hyderabad Cab",
        "description": "Travel from Shirdi to Hyderabad with ease and comfort in our well-maintained cabs, ideal for individuals, families, and groups alike. Our service offers reliable, safe, and efficient transport with professional drivers who are knowledgeable of the best routes. Enjoy a smooth journey as you relax in a clean and comfortable vehicle, knowing that every detail has been handled to make your travel experience enjoyable. Whether you're headed to Hyderabad for business, leisure, or family visits, trust us to get you there comfortably and on time."
    },
    {
        "name": "Hyderabad to Shirdi Cab",
        "description": "Our Hyderabad to Shirdi cab service caters to travelers who want a seamless and hassle-free journey to the sacred city of Shirdi. Ideal for those visiting temples, engaging in spiritual journeys, or exploring local attractions, we offer a variety of spacious and comfortable vehicles to meet your preferences. Our experienced drivers ensure a safe journey, with punctual pickups and drop-offs, allowing you to focus on your experience rather than travel logistics. Perfect for pilgrims, tourists, or anyone heading to Shirdi, our service guarantees reliability and comfort."
    },
    {
        "name": "Hyderabad to Shirdi Cab Fare",
        "description": "Enjoy transparency and competitive pricing with our Hyderabad to Shirdi cab service. We provide upfront pricing with no hidden fees, so you know exactly what to expect. Our fare structure is designed to offer great value without sacrificing quality, and our rates are inclusive of comfortable, well-maintained vehicles and experienced drivers. For more personalized fare details, contact us for a tailored quote that meets your budget and travel requirements, ensuring a cost-effective yet high-quality journey."
    },
    {
        "name": "Hyderabad to Shirdi Distance by Cab",
        "description": "The journey from Hyderabad to Shirdi covers approximately 275 kilometers, which typically takes around 5-6 hours by road. Our experienced drivers are well-versed in the most efficient routes, ensuring you reach Shirdi as quickly and smoothly as possible. Enjoy the journey in our comfortable, air-conditioned vehicles equipped with amenities for your convenience. For travelers who want a scenic and enjoyable road trip, we provide options to stop at interesting spots along the way. This service is designed to be convenient and stress-free, with attentive drivers and a focus on passenger comfort."
    },
    {
        "name": "Shirdi to Hyderabad Cab",
        "description": "Our Shirdi to Hyderabad cab service provides comfortable, private transportation for travelers looking to reach Hyderabad from Shirdi. We maintain a fleet of spacious, clean, and well-equipped vehicles to suit your needs, whether you're traveling alone or with a group. Our friendly drivers are punctual and experienced, ensuring a pleasant journey on every trip. This service is ideal for individuals, families, and business travelers who want a reliable and direct route to Hyderabad without the hassle of public transport. Trust our team to make your journey from Shirdi smooth, safe, and enjoyable."
    },
    {
        "name": "Shirdi to Hyderabad Airport Cab Fare",
        "description": "If you need transportation from Shirdi to Hyderabad Airport, our service offers competitive and affordable cab fares. Enjoy a hassle-free transfer with experienced drivers who ensure timely arrival at the airport. Our transparent pricing means you know exactly what you’re paying for, with no surprises. Choose from a range of vehicles suited to your travel needs, whether you're carrying luggage, traveling with family, or require extra space for comfort. This airport transfer service prioritizes comfort, punctuality, and convenience, making it the perfect choice for a stress-free airport journey."
    },
    {
        "name": "Shirdi to Hyderabad One Way Taxi Fare",
        "description": "For those planning a one-way journey from Shirdi to Hyderabad, we offer affordable one-way taxi fares that allow you to enjoy a comfortable and reliable ride without the obligation of a return trip. This service is ideal for travelers with one-way travel plans, whether for personal, business, or leisure purposes. Our vehicles are meticulously maintained, providing a clean and comfortable environment, and our drivers are courteous and professional, ensuring a pleasant travel experience. Contact us for an upfront quote tailored to your itinerary, giving you peace of mind and flexibility for your travel."
    },
    {
        "name": "Shirdi to Mysore Taxi Fare",
        "description": "Thinking of visiting the historic city of Mysore? Our Shirdi to Mysore taxi service offers competitive fares and a comfortable journey with ample space for relaxation. Enjoy the scenic drive with our experienced drivers who ensure a smooth and safe ride. With our service, you can immerse yourself in the beauty of Mysore without worrying about the logistics of travel. We provide clear pricing, well-maintained vehicles, and knowledgeable drivers familiar with the best routes, making it easy for you to enjoy your journey from Shirdi to Mysore stress-free."
    },
    {
        "name": "Shirdi to Hyderabad Round Trip Taxi Fare",
        "description": "Our round-trip taxi service from Shirdi to Hyderabad is designed for travelers who need flexibility and convenience. This service allows you to travel to Hyderabad and return to Shirdi at your own schedule, making it perfect for day trips, business visits, or weekend getaways. Our competitive round-trip fares cover both legs of the journey, ensuring a cost-effective and seamless experience. Enjoy the convenience of our well-maintained vehicles and courteous drivers who prioritize your comfort and punctuality, giving you the freedom to plan your trip around your own needs."
    },
    {
        "name": "Shirdi to Mysore Ooty Taxi Fare",
        "description": "Our Shirdi to Mysore and Ooty taxi service combines scenic travel with competitive fares, ideal for nature lovers and explorers. This service covers both Mysore and Ooty, allowing you to enjoy the beautiful landscapes of these regions without the hassle of multiple bookings. Our drivers are well-informed about the best routes, providing a comfortable and convenient journey. With transparent pricing and no hidden costs, we offer a reliable option for travelers seeking a multi-destination road trip experience that’s both affordable and memorable."
    },
    {
        "name": "Shirdi to Telangana Taxi Services",
        "description": "Our extensive taxi services from Shirdi to various destinations in Telangana are ideal for any travel purpose, from business trips to family visits. We offer flexible booking options, competitive pricing, and a fleet of well-maintained vehicles to choose from, all driven by experienced drivers. Whether you’re headed to Hyderabad, Warangal, or any other city in Telangana, our service provides reliable transportation tailored to your schedule. Experience a safe, comfortable, and efficient journey with SaitirthTours & Travels, where customer satisfaction is our priority."
    },
    {
        "name": "Shirdi to Balaji Darshan Cab Booking",
        "description": "Embark on a spiritual journey from Shirdi to Balaji Darshan with our cab service, designed to provide a seamless experience for pilgrims. Our well-versed drivers ensure you reach this revered temple comfortably and on time, allowing you to focus on your spiritual visit. Our vehicles are kept in pristine condition, providing a peaceful environment for your pilgrimage. This service is ideal for individuals, families, and groups seeking a safe and comfortable means to connect with the divine. Book your cab today for a fulfilling and convenient journey to Balaji Darshan."
    },
    {
        "name": "Shirdi to Vijayawada Cab Booking",
        "description": "Plan your trip from Shirdi to Vijayawada with our dependable cab booking service. Whether you’re traveling for business or leisure, we offer comfortable and spacious vehicles that make the journey pleasant and stress-free. Our experienced drivers are familiar with the routes, ensuring a smooth ride from start to finish. We provide transparent and competitive pricing, so you can enjoy your trip to Vijayawada without any travel worries. With our reliable service, your journey becomes as enjoyable as your destination."
    },
    {
        "name": "Shirdi to Ramoji Film City Cab Booking",
        "description": "Make your journey to Ramoji Film City memorable with our reliable cab service from Shirdi. Ideal for families, tourists, and film enthusiasts, this service provides a comfortable and scenic ride to one of India's largest film cities. Our drivers are friendly and knowledgeable about the route, ensuring you have a pleasant travel experience. Our well-maintained cabs offer ample space and comfort, allowing you to focus on the excitement of visiting this iconic film destination while we handle the travel arrangements."
    },
    {
        "name": "SaitirthTours & Travels Shirdi",
        "description": "At SaitirthTours & Travels, we are dedicated to offering exceptional travel experiences tailored to meet each customer’s needs. Our services range from local sightseeing tours to long-distance intercity trips, all backed by our commitment to quality, safety, and satisfaction. Our fleet of modern vehicles is complemented by experienced drivers and a team that goes the extra mile to ensure your journey is memorable. Contact us for reliable, comfortable, and hassle-free travel solutions, making every trip with us a remarkable experience."
    },
    {
        "name": "Shirdi to Hyderabad Cab Contact Information",
        "description": "For bookings or inquiries regarding our cab services from Shirdi to Hyderabad, please contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Our team is here to assist you with any travel arrangements and ensure that your journey is smooth, comfortable, and memorable. Whether for a one-way trip, round trip, or a specialized package, we are committed to meeting your transportation needs with top-notch service."
    }
],

tableData: [
    ["- Hyderabad to Shirdi Cab", "- Hyderabad to Shirdi Cab Fare"],
    ["- Hyderabad to Shirdi Distance by Cab", "- Shirdi to Hyderabad Cab"],
    ["- Shirdi to Hyderabad Airport Cab Fare", "- Shirdi to Hyderabad One Way Taxi Fare"],
    ["- Shirdi to Mysore Taxi Fare", "- Shirdi to Hyderabad Round Trip Taxi Fare"],
    ["- Shirdi to Mysore Ooty Taxi Fare", "- Shirdi to Telangana Taxi Services"],
    ["- Shirdi to Balaji Darshan Cab Booking", "- Shirdi to Vijayawada Cab Booking"],
    ["- Shirdi to Ramoji Film City Cab Booking", "- SaitirthTours & Travels Shirdi"],
    ["", ""]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Comfortable and Reliable Transportation",
      "WhyChoosedescription": "Our fleet of cabs is carefully maintained to ensure comfort and reliability for every ride. Whether you’re traveling alone or with family, our vehicles are equipped with modern amenities such as air conditioning, plush seating, and ample legroom, ensuring a comfortable and relaxing journey from Shirdi to Hyderabad. Our vehicles are also fully insured, giving you added peace of mind throughout your trip."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are not only well-trained and experienced, but they also have in-depth knowledge of local routes, traffic patterns, and the most efficient paths to take. This expertise ensures a safe and smooth journey, avoiding delays and optimizing travel time. They are courteous, reliable, and committed to providing excellent customer service, making sure you have a pleasant experience throughout your trip."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We understand that every traveler has different needs, which is why we offer a variety of flexible booking options. Whether you prefer a one-way trip or a round-trip journey, our customizable packages allow you to tailor your travel plans according to your schedule and preferences. Additionally, you can easily make changes to your booking if required, making the whole process stress-free and adaptable to your plans."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "We believe in providing top-notch service at affordable prices. Our transparent pricing policy ensures that you never encounter hidden fees or surprise charges, giving you complete control over your budget. Whether you're traveling for business or leisure, we strive to offer you the best value for your money. Our competitive rates make us one of the most cost-effective travel options for a comfortable and reliable ride from Shirdi to Hyderabad."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our customer support team is available 24/7 to assist you with any queries, concerns, or last-minute changes to your booking. Whether you need to make a quick inquiry about your trip, ask for travel recommendations, or need to adjust your booking details, our team is always just a phone call or message away. We are dedicated to ensuring you have a smooth and seamless experience from booking to drop-off."
    },
    {
      "WhyChooseheading": "On-Time Service",
      "WhyChoosedescription": "We understand that timely service is crucial when traveling, especially for important appointments or flights. Our commitment to punctuality means you can rely on us to be at your designated pick-up location on time. Our drivers take every precaution to avoid delays, ensuring you have enough time to relax and enjoy your journey, without worrying about missing important appointments or flights."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our utmost priority. All our vehicles undergo regular maintenance and safety checks to ensure they are in optimal condition. We also ensure that our drivers follow all road safety rules and regulations, prioritizing your safety at all times. With comprehensive insurance coverage and strict adherence to safety protocols, you can trust us to provide a secure and worry-free travel experience."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers are local experts with years of experience on the road, so they can provide you with valuable tips and insights during your journey. Whether it's recommendations for local restaurants, places to visit, or must-see attractions along the route, you’ll gain a richer experience during your trip. Their knowledge enhances your journey, ensuring you make the most of your time while traveling from Shirdi to Hyderabad."
    },
    {
      "WhyChooseheading": "Easy Online Booking",
      "WhyChoosedescription": "Booking your cab from Shirdi to Hyderabad is incredibly simple and efficient through our user-friendly online platform. You can easily select your preferred pick-up time, vehicle type, and additional options like stops along the way or extra luggage. For added convenience, our customer service team is always available to assist with bookings, ensuring that your reservation is confirmed and hassle-free."
    },
    {
      "WhyChooseheading": "Customer Satisfaction Guarantee",
      "WhyChoosedescription": "We pride ourselves on our commitment to customer satisfaction. We believe that a satisfied customer is the best form of advertisement, which is why we go above and beyond to ensure that your expectations are met or exceeded. Our many repeat clients and positive reviews reflect our dedication to providing the highest standards of service. We continuously work to improve and refine our offerings to ensure you have the best possible experience on your journey."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "How do I book a cab from Shirdi to Hyderabad?",
        answer: "You can easily book a cab through our website or by calling our customer service team."
    },
    {
        question: "What type of vehicles do you offer for this route?",
        answer: "We offer a range of vehicles including sedans, SUVs, and vans to accommodate different group sizes."
    },
    {
        question: "What is the estimated travel time from Shirdi to Hyderabad?",
        answer: "The travel time is approximately 14 to 16 hours, depending on traffic and road conditions."
    },
    {
        question: "Are there any additional charges for long-distance travel?",
        answer: "Our pricing is transparent, and any additional charges will be communicated during the booking process."
    },
    {
        question: "Can I request a specific pickup time?",
        answer: "Yes, you can specify your desired pickup time when making your booking."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a flexible cancellation policy. Please refer to our terms for details or contact us."
    },
    {
        question: "Can I modify my booking after it has been made?",
        answer: "Yes, you can modify your booking. Please contact our customer service for assistance with changes."
    },
    {
        question: "Are the drivers trained in safety measures?",
        answer: "Yes, all our drivers are trained in safety protocols to ensure a secure travel experience."
    },
    {
        question: "Do you provide child seats if needed?",
        answer: "Yes, we can provide child seats upon request. Please mention it at the time of booking."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Suresh Reddy",
        text: "I had an excellent experience with Saitirth Travels for my cab ride from Shirdi to Hyderabad. The car was spacious and comfortable, making the long journey enjoyable. The driver was punctual and very professional, ensuring a smooth ride. I highly recommend Saitirth Travels for anyone needing reliable transport!"
    },
    {
        name: "Ms. Priya Nair",
        text: "Using Saitirth Travels for my cab from Shirdi to Hyderabad was a great choice! The booking process was easy, and the vehicle was in perfect condition. Our driver was friendly and knowledgeable about the route, which made the trip even better. I will definitely choose Saitirth Travels for my future travels!"
    }
];


  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Shirdi to Hyderabad Cab | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Hyderabad Cab with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Hyderabad in an Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Shirdi to Hyderabad Cab | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Hyderabad Cab with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Hyderabad in an Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-hyderabad-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-hyderabad-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book an affordable Shirdi to Hyderabad Cab with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Hyderabad in an Innova, Ertiga, or Tempo Traveller.",
            "url": "https://www.saitirthcabs.com/shirdi-to-hyderabad-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-hyderabad-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Hyderabad Cab",
                "Hyderabad to Shirdi Cab",
                "Hyderabad to Shirdi Cab Fare",
                "Hyderabad to Shirdi Distance by Cab",
                "Shirdi to Hyderabad Cab",
                "Shirdi to Hyderabad Airport Cab Fare",
                "Shirdi to Hyderabad One Way Taxi Fare",
                "Shirdi to Mysore Taxi Fare",
                "Shirdi to Hyderabad Round Trip Taxi Fare",
                "Shirdi to Mysore Ooty Taxi Fare",
                "Shirdi to Telangana Taxi Services",
                "Shirdi to Balaji Darshan Cab Booking",
                "Shirdi to Vijayawada Cab Booking",
                "Shirdi to Ramoji Film City Cab Booking",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/68.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditohyderabad;