
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditomanmad() {



  const cardData =
  {
    keyword: '  Shirdi to Manmad Cab ',
    heading: 'Saitirth Travels:  Shirdi to Manmad Cab ',
    headingDescription: 'When it comes to exploring the vibrant region of Maharashtra, the journey from Shirdi to Manmad is a popular choice for both pilgrims and tourists. Saitirth Travels offers reliable and comfortable cab services to ensure that your travel experience is smooth and enjoyable. Our Shirdi to Manmad cab service is designed to cater to your specific needs, providing you with a convenient way to discover the hidden gems of Manmad and its surroundings.',

    top: 'Top Places to Visit in Manmad with SaitirthTravers',

    topPlaces: [
        {
            "title": "1. Shri Khandoba Temple",
            "description": "This ancient temple dedicated to Lord Khandoba is a significant pilgrimage site for devotees. Perched on a picturesque hill, the temple provides breathtaking panoramic views of the surrounding countryside, enhancing the spiritual experience. The serene ambiance, coupled with its stunning architecture, makes it a must-visit for anyone traveling to Manmad. During festivals, the temple becomes a vibrant hub of local culture and devotion, drawing crowds from far and wide."
        },
        {
            "title": "2. Gorakhnath Temple",
            "description": "A revered site for spiritual seekers, Gorakhnath Temple is known for its tranquil surroundings and vibrant festivals. Dedicated to the sage Gorakhnath, this temple holds great cultural significance and is a place where visitors can witness various rituals that reflect the local traditions. The peaceful atmosphere makes it an excellent spot for reflection and meditation, allowing visitors to immerse themselves in the spiritual essence of the place."
        },
        {
            "title": "3. Brahma Kund",
            "description": "Brahma Kund is a historical water tank believed to possess healing properties, making it an important site for pilgrims. Visitors often come here to take a spiritual bath and offer prayers, seeking solace in its tranquil environment. Surrounded by lush greenery, this serene retreat provides a perfect escape for those looking to connect with nature and spirituality."
        },
        {
            "title": "4. Mhaiskar Sweets",
            "description": "Renowned for its delicious traditional sweets and snacks, Mhaiskar Sweets is a local favorite that every visitor should explore. This delightful spot offers a chance to indulge in local culinary delights, especially the famous pedas and barfis made with local ingredients and traditional recipes. A visit here is not just a treat for your taste buds but also an opportunity to experience the rich flavors of the region."
        },
        {
            "title": "5. Ankala Devi Temple",
            "description": "Situated in a picturesque location, Ankala Devi Temple is dedicated to Goddess Ankala and is popular among devotees for its architectural beauty and scenic backdrop. This temple is an ideal spot for photography enthusiasts, and the annual festivals attract many visitors, creating a lively atmosphere filled with devotion and celebration. The serene environment invites visitors to pause and appreciate the spiritual significance of the site."
        },
        {
            "title": "6. Manmad Fort",
            "description": "Manmad Fort is a historical site that offers breathtaking panoramic views of the surrounding area, making it an excellent destination for history enthusiasts and trekkers alike. The ruins of the fort tell tales of the region's rich past, showcasing the architectural styles of bygone eras. The trek to the fort is rewarding, providing stunning views and a sense of adventure as you explore this important historical landmark."
        },
        {
            "title": "7. Dattatray Mandir",
            "description": "Dedicated to Lord Dattatreya, Dattatray Mandir is a spiritual site frequented by devotees seeking blessings. The temple's peaceful environment is perfect for meditation, allowing visitors to immerse themselves in a tranquil ambiance. Engaging in various rituals here helps deepen the spiritual experience, making it a noteworthy stop for those on a pilgrimage."
        },
        {
            "title": "8. Nashik Caves",
            "description": "Located a short distance from Manmad, the Nashik Caves are rock-cut caves that date back to ancient times, showcasing exquisite carvings that provide a fascinating glimpse into the region's history. Exploring these caves is a captivating experience, and guided tours can enhance your understanding of their historical significance and the artistry involved in their creation."
        },
        {
            "title": "9. Pandav Leni Caves",
            "description": "Just a few kilometers from Manmad, the Pandav Leni Caves are a group of ancient Buddhist rock-cut caves renowned for their stunning sculptures and intricate carvings. These caves are significant historical sites that offer insight into ancient Buddhist practices. The serene surroundings add to their mystique, making them an ideal location for exploration and contemplation."
        },
        {
            "title": "10. Vani Temple",
            "description": "Nearby Vani Temple, dedicated to Goddess Saraswati, attracts many visitors due to its spiritual significance. The temple's tranquil surroundings provide a perfect escape for those seeking relaxation and wisdom. Many devotees visit to engage in prayer and meditation, seeking knowledge and enlightenment in this serene environment, which is enriched by the peaceful aura surrounding the temple."
        }
    ],
        
    
    "services": [
        {
            "name": "Shirdi to Manmad Cab Service",
            "description": "When planning your journey from Shirdi to Manmad, having a reliable cab service is essential. At SaitirthTours & Travels, we offer the best Shirdi to Manmad cab service that guarantees comfort, convenience, and affordability for all travelers. Our goal is to ensure that every trip is enjoyable and hassle-free, making your travel experience memorable."
        },
        {
            "name": "Cab From Shirdi to Manmad",
            "description": "Our cab from Shirdi to Manmad provides a seamless travel experience, ensuring you arrive at your destination without any hassle. We understand that time is valuable, so our drivers are trained to take the best routes while adhering to all traffic regulations. With professional drivers and a fleet of well-maintained vehicles, we ensure a smooth ride, whether you’re traveling for business or leisure."
        },
        {
            "name": "Shirdi Manmad Taxi Service",
            "description": "Choose our Shirdi Manmad taxi service for an enjoyable and stress-free journey. Our experienced drivers are not only skilled in navigating the roads but also possess extensive knowledge about the local attractions, ensuring you don't miss out on any important sites. Whether you need a quick ride or a full-day rental, we cater to all your transportation needs with punctuality and reliability."
        },
        {
            "name": "Taxi from Shirdi to Manmad",
            "description": "Booking a taxi from Shirdi to Manmad is easy with us. Our user-friendly online booking system allows you to reserve your cab in advance, ensuring that you have a vehicle ready when you need it. We also offer 24/7 customer support to assist you with any inquiries or changes to your booking."
        },
        {
            "name": "Shirdi to Manmad Car Rental",
            "description": "Our Shirdi to Manmad car rental options provide flexibility for your travel plans. Whether you prefer a sedan for a romantic getaway, an Innova Crysta for family trips, or a tempo traveller for larger groups, we have a range of vehicles to suit your requirements. All our cars are equipped with modern amenities, ensuring a comfortable and enjoyable journey."
        },
        {
            "name": "Shirdi to Manmad Taxi Fare",
            "description": "We offer competitive Shirdi to Manmad taxi fare rates to ensure affordability. Our transparent pricing means you’ll know the cost upfront, without any hidden charges. Our fares include all taxes and fees, providing you with peace of mind and allowing you to budget for your trip effectively."
        },
        {
            "name": "Shirdi to Manmad Cab Booking",
            "description": "Booking your Shirdi to Manmad cab is quick and straightforward. Visit our website to easily complete your cab booking in just a few clicks. You can choose your preferred vehicle, select the pickup and drop-off points, and specify any additional services you may require."
        },
        {
            "name": "Affordable Cab Shirdi to Manmad",
            "description": "Looking for an affordable cab from Shirdi to Manmad? Our service is designed to fit your budget while maintaining high-quality standards. We offer various packages and discounts for regular customers, making it easier for you to enjoy the best transportation service without straining your finances."
        },
        {
            "name": "Best Cab for Shirdi to Manmad",
            "description": "As the best cab for Shirdi to Manmad, we pride ourselves on providing excellent customer service. Our team is dedicated to ensuring your comfort and satisfaction throughout the journey. Each vehicle in our fleet undergoes regular maintenance checks to guarantee safety and reliability."
        },
        {
            "name": "Shirdi to Manmad Railway Station Cab",
            "description": "We also provide cab services from Shirdi to Manmad Railway Station, ensuring you have reliable transport when you arrive. Our drivers will be waiting for you at the station, ready to assist with your luggage and ensure a smooth transition to your next destination."
        },
        {
            "name": "Innova Crysta for Shirdi to Manmad",
            "description": "For a spacious and comfortable ride, consider our Shirdi to Manmad Innova Crysta option. This vehicle is ideal for families or groups traveling together, providing ample space for passengers and luggage alike."
        },
        {
            "name": "Tempo Traveller on Rent for Shirdi to Manmad",
            "description": "For group travels, our Shirdi to Manmad tempo traveller on rent is the best choice. Enjoy ample space and comfort on your journey, with the capacity to accommodate larger groups. Our tempo traveller is equipped with comfortable seating and modern amenities."
        },
        {
            "name": "Shirdi to Manmad 7 Seater Cab",
            "description": "Our Shirdi to Manmad 7 seater cab on rent is perfect for families or small groups, providing a comfortable and convenient way to travel. With sufficient legroom and luggage space, your journey will be both relaxing and enjoyable."
        },
        {
            "name": "Contact Information for Shirdi to Manmad Cab",
            "description": "Contact SaitirthTours & Travels at +91 9923879009 or +91 9922479009 for prompt and efficient Shirdi to Manmad cab service. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],

    tableData: [
        ["- Shirdi Car Rental", "- Rent a car in Shirdi with Driver"],
        ["- SaitirthTours & Travels Shirdi", "- Shirdi Taxi Rental for Local Sightseeing"],
        ["- Car Hire Shirdi", "- Affordable Car Rental Shirdi"],
        ["- Rental Car Shirdi Sai Baba Temple", "- Best Car Rental Shirdi"],
        ["- Shirdi Airport Car Rental", "- Shirdi Car Rental"],
        ["- Shirdi Cab Service", "- Cab Booking in Shirdi"],
        ["- Car Hire in Shirdi", "- Rent a car for Shirdi"],
        ["- Shirdi Taxi Cab Service", "- Travel Agents in Shirdi"],
        ["- Tours and Travels in Shirdi", "- Best Travel Agency in Shirdi"],
        ["- Shirdi Cab Booking Service", "- Shirdi Saibaba Travels"]
    ],
    whychoose: [
        {
            "WhyChooseheading": "Why Choose Saitirth Travels Shirdi to Manmad Cab?",
            "WhyChoosedescription": "At Saitirth Travels, we strive to provide an exceptional car rental experience for your journey from Shirdi to Manmad, ensuring that your travel is as memorable as your destination. Our commitment to customer satisfaction and quality service makes us a trusted choice for travelers. Here’s why you should choose us:"
        },
        {
            "WhyChooseheading": "Reliable and On-Time Service:",
            "WhyChoosedescription": "Punctuality is crucial in travel, and we understand that. Our drivers are committed to arriving on time, every time. With Saitirth Travels, you can rely on us for timely pickups and drop-offs, ensuring that your schedule is never disrupted. We keep track of traffic conditions and make necessary adjustments to keep you on track, so you can relax and enjoy your journey."
        },
        {
            "WhyChooseheading": "Comfort and Convenience:",
            "WhyChoosedescription": "Our fleet consists of well-maintained vehicles equipped with modern amenities to guarantee a comfortable ride. Each vehicle is designed for spacious seating and climate control, allowing you to relax and enjoy the scenery along the way. Additionally, we provide complimentary water bottles and Wi-Fi on request, ensuring you have everything you need for a pleasant journey."
        },
        {
            "WhyChooseheading": "Experienced and Knowledgeable Drivers:",
            "WhyChoosedescription": "Our drivers are not just skilled professionals; they are local experts familiar with the best routes and places to visit. They can provide you with insightful information about the history and significance of various landmarks along the way, enriching your travel experience. Whether you need recommendations for food or sightseeing, our drivers are ready to assist you."
        },
        {
            "WhyChooseheading": "Affordable and Transparent Pricing:",
            "WhyChoosedescription": "We pride ourselves on offering competitive rates without sacrificing quality. Our pricing is transparent with no hidden fees, so you can plan your budget confidently. We also offer various packages tailored to different needs, ensuring you get the best value for your money. Special discounts are available for group bookings and frequent travelers."
        },
        {
            "WhyChooseheading": "Flexible Booking Options:",
            "WhyChoosedescription": "Whether you need a ride on short notice or prefer to plan ahead, we provide flexible booking options to suit your needs. Our user-friendly online booking system allows you to reserve your cab in advance or on the same day. For added convenience, you can also modify or cancel your bookings easily, giving you the freedom to adapt to your travel plans."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is always available to assist you with any inquiries or issues you may encounter. Whether you have questions before booking, need assistance during your journey, or want to provide feedback after your ride, we are here to listen and ensure your satisfaction. You can reach us via phone, email, or chat, and we promise prompt and friendly assistance."
        },
        {
            "WhyChooseheading": "Safety First:",
            "WhyChoosedescription": "Your safety is our highest priority. We strictly adhere to hygiene and safety protocols, including regular sanitization of our vehicles and the use of PPE by our drivers. Each vehicle undergoes thorough maintenance checks to ensure it meets safety standards, giving you peace of mind as you travel. We also provide seat belts for all passengers and conduct driver background checks for added security."
        },
        {
            "WhyChooseheading": "Customized Travel Packages:",
            "WhyChoosedescription": "We understand that every traveler has unique preferences and requirements. Our team can create customized travel packages tailored to your specific needs. Whether you're interested in a direct route from Shirdi to Manmad or wish to include stops at local attractions, we can accommodate your requests. Let us help you design an itinerary that enhances your experience."
        },
        {
            "WhyChooseheading": "Comfortable Vehicles for All Group Sizes:",
            "WhyChoosedescription": "No matter the size of your group, we have a variety of vehicles to accommodate you. From sedans for solo travelers to spacious vans and SUVs for families or larger groups, we ensure everyone enjoys a comfortable ride. Each vehicle is equipped with essential amenities to enhance your travel experience, allowing you to relax and enjoy the journey together."
        },
        {
            "WhyChooseheading": "Commitment to Customer Satisfaction:",
            "WhyChoosedescription": "At Saitirth Travels, your satisfaction is our ultimate goal. We continually seek feedback to improve our services and exceed your expectations. Our dedicated team works hard to ensure that every aspect of your journey is smooth and enjoyable, from booking to your final drop-off. We aim to build long-lasting relationships with our customers based on trust and satisfaction."
        },
        {
            "WhyChooseheading": "Transparent Policies:",
            "WhyChoosedescription": "We believe in maintaining transparency in our operations. All our policies regarding bookings, cancellations, and fees are clearly communicated upfront. This approach eliminates any surprises and fosters trust between us and our customers. You can expect straightforward agreements that detail all aspects of your rental, allowing you to focus on enjoying your trip."
        }
    ]
        
  }


  const faqData = [
    {
      question: "How do I book a cab from Shirdi to Manmad?",
      answer: "You can easily book a cab by visiting our website or calling our customer support. We offer online booking options for your convenience."
    },
    {
      question: "What is the distance between Shirdi and Manmad?",
      answer: "The distance between Shirdi and Manmad is approximately 60 kilometers, and the travel time is around 1.5 to 2 hours, depending on traffic conditions."
    },
    {
      question: "Are your drivers experienced?",
      answer: "Yes, all our drivers are experienced professionals familiar with the routes and local attractions. They ensure a safe and pleasant journey."
    },
    {
      question: "What types of vehicles do you offer?",
      answer: "We offer a range of vehicles to suit your needs, including sedans, SUVs, and larger vehicles for groups. You can choose the vehicle type that best fits your travel requirements."
    },
    {
      question: "Is the pricing for the cab service fixed?",
      answer: "Our pricing is competitive and transparent. We provide a clear quote before you book, ensuring there are no hidden fees. You will be informed of any additional charges if applicable."
    },
    {
      question: "Can I modify my booking?",
      answer: "Yes, you can modify your booking, including the date, time, or vehicle type. Please contact our customer support team, and they will assist you with the changes."
    },
    {
      question: "Do you offer round-trip services?",
      answer: "Yes, we offer both one-way and round-trip services from Shirdi to Manmad. You can select the option that best fits your travel plans."
    },
    {
      question: "What safety measures do you have in place?",
      answer: "We prioritize safety by ensuring our vehicles are regularly serviced and sanitized. Our drivers adhere to all safety protocols, and we encourage passengers to follow hygiene practices during their journey."
    },
    {
      question: "Can I request additional stops during the trip?",
      answer: "Yes, you can request additional stops along the way when booking your cab. Please inform our customer support team about your specific requirements."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment methods, including cash, credit/debit cards, and online payment options. You can choose the payment method that is most convenient for you."
    }
  ];
  
  const testimonials = [
    {
      name: "Mrs. Priya Desai",
      text: "I recently booked a cab from Shirdi to Manmad with Saitirth Travels, and I couldn't be happier with the service. The booking process was seamless, and our driver arrived right on time. He was very courteous and knew the best routes to take, which made our journey smooth and enjoyable. The cab was clean and comfortable, perfect for our group. I highly recommend Saitirth Travels for anyone needing reliable transportation from Shirdi to Manmad!"
    },
    {
      name: "Mr. Amit Verma",
      text: "Saitirth Travels provided an excellent cab service for our trip from Shirdi to Manmad. The entire experience was hassle-free, from booking to drop-off. Our driver was friendly and shared interesting facts about the places we passed along the way. The car was spacious and well-maintained, making the long drive very pleasant. I will definitely choose Saitirth Travels again for future trips!"
    }
  ];
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Manmad Cab | SaitirthTours & Travels</title>
    <meta name="description" content="Affordable and reliable taxi service from Shirdi to Manmad. Book a cab for a smooth journey with SaitirthTours & Travels. Get the best rates on Shirdi to Manmad cab rentals, including sedans, SUVs, and more." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Manmad Cab | SaitirthTours & Travels" />
    <meta property="og:description" content="Affordable and reliable taxi service from Shirdi to Manmad. Book a cab for a smooth journey with SaitirthTours & Travels. Get the best rates on Shirdi to Manmad cab rentals, including sedans, SUVs, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-manmad-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-manmad-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Affordable and reliable taxi service from Shirdi to Manmad. Book a cab for a smooth journey with SaitirthTours & Travels. Get the best rates on Shirdi to Manmad cab rentals, including sedans, SUVs, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-to-manmad-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-manmad-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Manmad Cab", 
                "Cab From Shirdi to Manmad", 
                "Shirdi Manmad Taxi Service", 
                "Taxi from Shirdi to Manmad", 
                "Shirdi to Manmad Car Rental", 
                "Shirdi to Manmad Taxi Fare", 
                "Shirdi to Manmad Cab Booking", 
                "Affordable Cab Shirdi to Manmad", 
                "Best Cab for Shirdi to Manmad", 
                "Shirdi to Manmad Cab Rate", 
                "Shirdi to Manmad Taxi Fare", 
                "Shirdi to Manmad Cab Fare", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Temple to Manmad Taxi Fare", 
                "Shirdi to Manmad Cab", 
                "Shirdi to Manmad Online Cab Booking", 
                "Shirdi to Manmad Railway Station Cab", 
                "Shirdi to Manmad Innova Crysta", 
                "Shirdi to Sedan Cab Service", 
                "Shirdi to Manmad Ertiga Taxi Fare", 
                "Shirdi to Manmad Tempo Traveller on Rent", 
                "Shirdi to Manmad 7 Seater Cab On Rent", 
                "Shirdi Saibaba Travels"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/19.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomanmad;