import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import GoogleTag from './GoogleTag';

const Enquiry = () => {
  const [formStatus, setFormStatus] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "fe7f2472-10f4-4f4d-8568-b3cd991dba60");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setFormStatus("success");
      event.target.reset();
    } else {
      setFormStatus("error");
      console.log("Error", data);
    }
  };

  return (
    <div>
     <GoogleTag/>
      <Helmet>
        <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title>
        <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
        <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
        <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
        <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
        <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
        <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "250",
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91-9923879009",
              "contactType": "Customer Service",
            },
            "keywords": [
              "Force Urbania on Rent in Shirdi",
              "Urbania on Rent in Manmad",
              "Force Urbania on Rent in Shani Shingnapur",
              "Shirdi to Bhimashankar Urbania on Rent",
              "Shirdi to Nashik Force Urbania on Rent",
              "Mini Bus on Rent in Shirdi",
              "Luxury Force Urbania on Rent in Shirdi",
              "13 Seater Urbania on Rent",
              "17 Seater Urbania on Rent",
              "Shirdi Airport Urbania on Rent",
              "Sai Tirth Cabs Shirdi",
              "Aura on Rent in Shirdi",
              "Ertiga on Rent in Shirdi",
              "Scorpio on Rent in Shirdi",
              "Kia Carens on Rent in Shirdi",
            ],
          })}
        </script>
      </Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h1 className="title">Enquiry</h1>
          </div>
        </div>
      </section>

      <section className="news-details-section fix section-padding">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="de-item-list mb30">
                  <h3 className='text-white'>Do you have any enquiry?</h3>
                  <form 
                                onSubmit={onSubmit}
                                    name="contactForm" 
                                    id="contact_form" 
                                    className="form-border" 
                                    method="post" 
                                >
                                    <input type="hidden" />
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="name" className=" small">Your Full Name <i className="fa fa-user darkcolor"></i></label>
                                                <input 
                                                    name="name" 
                                                    id="name" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Full Name" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Name must contain only letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="email" className=" small">Your Email <i className="fa fa-envelope darkcolor"></i></label>
                                                <input 
                                                    type="email" 
                                                    name="email" 
                                                    id="email" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Email" 
                                                    required 
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                                    title="Please enter a valid email address" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="phone" className=" small">Your Phone <i className="fa fa-phone darkcolor"></i></label>
                                                <input 
                                                    type="tel" 
                                                    name="phone" 
                                                    id="phone" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Phone" 
                                                    required 
                                                    pattern="[0-9]{10}" 
                                                    title="Phone number must be of 10 digits" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="cab_location" className=" small">Cabs <i className="fa fa-map-marker darkcolor"></i></label>
                                                <input 
                                                    name="cab_location" 
                                                    id="cab_location" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Cabs" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Cabs can only have letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="days" className=" small">Number of Days <i className="fa fa-calendar-check darkcolor"></i></label>
                                                <input 
                                                    name="days" 
                                                    id="days" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Number of Days" 
                                                    required 
                                                    min="1" 
                                                    max="100" 
                                                    step="1" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="pick_up_point" className=" small">Pick Up Point <i className="fa fa-map-marker-alt darkcolor"></i></label>
                                                <input 
                                                    name="pick_up_point" 
                                                    id="pick_up_point" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Pick Up Point" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Pick Up Point must contain only letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="cabs" className=" small">Select Cabs <i className="fa fa-taxi darkcolor"></i></label>
                                                <select 
                                                    name="cabs" 
                                                    id="cabs" 
                                                    className="border py-2 form-control form-control-lg rounded-0" 
                                                    required
                                                >
                                                    <option value="" selected disabled>Select Cabs</option>
                                                    <option value="Swift Dzire (4 + 1 Seater)">Swift Dzire (4 + 1 Seater)</option>
                                                    <option value="Kia Carens(6 + 1 Seater)">Kia Carens(6 + 1 Seater)</option>
                                                    <option value="Ertiga (6 + 1 Seater)">Ertiga (6 + 1 Seater)</option>
                                                    <option value="Tavera (6 + 1 Seater)">Tavera (6 + 1 Seater)</option>
                                                    <option value="Innova (6 + 1 Seater)">Innova (6 + 1 Seater)</option>
                                                    <option value="13 Seater(13 Seater)">13 Seater(13 Seater)</option>
                                                    <option value="17 Seater(17 Seater)">17 Seater(17 Seater)</option>
                                                    <option value="Innova Crysta(6 + 1 Seater)">Innova Crysta(6 + 1 Seater)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="date" className=" small">Date of Journey <i className="fa fa-calendar-alt ml-2 darkcolor"></i></label>
                                                <input 
                                                    type="date" 
                                                    name="date" 
                                                    id="date" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Date of Journey" 
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group position-relative col-12">
                                            <textarea 
                                                name="message" 
                                                id="message" 
                                                className="form-control form-control-lg rounded-0" 
                                                placeholder="Your Message" 
                                                required 
                                            />
                                        </div>
                                        <div className="g-recaptcha" data-sitekey="copy-your-site-key-here"></div>
                                        <div id='submit' className="mt20 text-center py-3 px-5">
                                            <input 
                                                type='submit' 
                                                id='send_message' 
                                                value='Submit Now' 
                                                className="btn-main  mt-2 py-2 px-3 border-0 text-balck" 
                                            />
                                        </div>
                                        <div id="success_message" className='success'>
                                            Your message has been sent successfully. Refresh this page if you want to send more messages.
                                        </div>
                                        <div id="error_message" className='error'>
                                            Sorry, something went wrong. Try again later.
                                        </div>
                                    </div>
                                </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Enquiry;
