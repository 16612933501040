
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoooty() {



  const cardData =
  {
    keyword: ' Shirdi to Ooty & Mysore Cab Package',
    heading: 'Saitirth Travels: Shirdi to Ooty & Mysore Cab Package',
    headingDescription: 'Experience the serene beauty of South India with Saitirth Travels’ Shirdi to Ooty and Mysore Cab Package. This package offers a comfortable and scenic journey through the lush Nilgiri hills of Ooty and the historic charm of Mysore. Enjoy nature, adventure, and rich cultural experiences as you explore these two iconic destinations.',

    top: 'Top Places to Visit in Ooty & Mysore with Saitirth Travels',

    topPlaces: [
        {
            "title": "Ooty Botanical Gardens",
            "description": "A beautifully landscaped garden featuring rare trees, colorful flower beds, and lush lawns, the Ooty Botanical Gardens are ideal for nature lovers and photography enthusiasts. The gardens showcase a wide variety of exotic plants and offer a tranquil setting for leisurely strolls."
        },
        {
            "title": "Ooty Lake",
            "description": "Ooty Lake is a serene body of water surrounded by eucalyptus trees, providing a perfect spot for paddle boating. It’s an ideal location for a relaxing day with family and friends, where visitors can enjoy the scenic beauty and calm ambiance."
        },
        {
            "title": "Doddabetta Peak",
            "description": "As the highest peak in the Nilgiris, Doddabetta Peak offers panoramic views of the surrounding hills and valleys. It is a popular trekking destination and a fantastic viewpoint for photography, providing stunning vistas that are a photographer's dream."
        },
        {
            "title": "Tea Estates",
            "description": "Ooty is renowned for its sprawling tea gardens, and visitors can take guided tours of the tea estates to learn about the tea-making process. Tasting freshly brewed Ooty tea is a highlight of these tours, offering a unique insight into the region's rich tea culture."
        },
        {
            "title": "Pykara Falls",
            "description": "Pykara Falls is a beautiful waterfall set amidst picturesque views, making it an ideal location for nature walks and picnics. The nearby Pykara Lake also offers boating options, allowing visitors to fully immerse themselves in the natural beauty of the area."
        },
        {
            "title": "Rose Garden",
            "description": "Home to one of the largest collections of roses in India, the Rose Garden in Ooty is a stunning garden that attracts flower lovers from all over. The vibrant blooms and well-maintained paths make it a perfect spot for peaceful walks and relaxation."
        },
        {
            "title": "Mysore Palace",
            "description": "Mysore Palace is one of India's most famous palaces, celebrated for its grandeur and architectural beauty. Visitors can explore its ornate halls, admire impressive artworks, and learn about the rich history that surrounds this magnificent structure."
        },
        {
            "title": "Chamundi Hill and Chamundeshwari Temple",
            "description": "This revered hilltop temple, dedicated to Goddess Chamundeshwari, offers stunning views of Mysore city and its surrounding landscapes. The temple's rich history and architectural significance attract many devotees and visitors alike."
        },
        {
            "title": "Brindavan Gardens",
            "description": "Famous for its musical fountain show, Brindavan Gardens is a well-maintained garden located near the Krishnarajasagar Dam. Visitors can enjoy a beautiful evening amidst illuminated fountains, lush greenery, and vibrant flower beds."
        },
        {
            "title": "St. Philomena’s Church",
            "description": "Known for its Neo-Gothic architecture, St. Philomena’s Church is one of the largest churches in India. Its grandeur and tranquility create a peaceful environment, making it a great place for visitors seeking a moment of reflection."
        },
        {
            "title": "Mysore Zoo",
            "description": "One of the oldest and best-maintained zoos in India, Mysore Zoo houses a wide range of animals, including rare and exotic species. It provides a fun and educational experience for families, with opportunities for children to learn about wildlife."
        },
        {
            "title": "Karanji Lake",
            "description": "Karanji Lake is a scenic spot offering boating, a butterfly park, and excellent birdwatching opportunities. This peaceful location is perfect for relaxing and enjoying Mysore's natural beauty, making it a favorite among locals and tourists."
        }
    ],      
    
            
        
"services": [
    {
        "name": "Shirdi to Ooty Mysore Cab",
        "description": "Our Shirdi to Ooty Mysore Cab service offers a comfortable and hassle-free journey to two of South India's most scenic destinations. Whether you're traveling alone or with a group, our fleet of well-maintained vehicles ensures a smooth and enjoyable ride. With experienced and professional drivers who know the best routes, you can relax and enjoy the stunning landscapes along the way, including the Nilgiris and Mysore's rich cultural heritage."
    },
    {
        "name": "Mysore to Ooty Cab Fare",
        "description": "We offer competitive Mysore to Ooty cab fares, ensuring that your journey through the lush Nilgiri hills is both affordable and comfortable. Our transparent pricing ensures no hidden costs, and we provide value for your money with well-maintained vehicles and professional drivers. Whether you're traveling solo, with family, or in a group, our affordable fares make your trip a cost-effective and enjoyable experience."
    },
    {
        "name": "Shirdi to Ooty Tour Package",
        "description": "Join our Shirdi to Ooty Tour Package for a comprehensive travel experience that includes sightseeing, local attractions, and accommodations. Explore the breathtaking hill stations, including Ooty's scenic lakes, botanical gardens, and the famous Nilgiri Mountain Railway. With expert guides and well-planned itineraries, this package ensures you make the most of your trip to Ooty and its surrounding areas."
    },
    {
        "name": "Tirupati Tour Package from Shirdi",
        "description": "Combine spirituality with scenic beauty with our Tirupati Tour Package from Shirdi. Visit the world-renowned Tirupati Temple and experience its divine charm. This package includes comfortable transportation, temple visits, and sightseeing, allowing you to enjoy the landscapes of Andhra Pradesh and Telangana along the way. Our experienced drivers ensure a smooth and hassle-free journey to this spiritual destination."
    },
    {
        "name": "Shirdi to Bangalore Cab Booking",
        "description": "Extend your spiritual journey with our Shirdi to Bangalore cab booking service. After visiting Ooty and Mysore, explore the vibrant city life of Bangalore, known for its rich culture, tech hubs, shopping, and dining. Our cab service offers a seamless journey with flexible schedules, ensuring your travels are convenient and comfortable."
    },
    {
        "name": "Shirdi to Outstation Cab Package",
        "description": "Looking to visit more destinations beyond Ooty and Mysore? Our Shirdi to Outstation Cab Package offers the flexibility to explore other parts of India. Whether you're looking for a short trip or a multi-destination vacation, we provide customized solutions to meet your travel needs. Choose your destinations and enjoy a hassle-free, comfortable journey with SaitirthTours & Travels."
    },
    {
        "name": "Best Taxi Service in Shirdi",
        "description": "SaitirthTours & Travels is recognized as the best taxi service in Shirdi, offering a range of vehicles and personalized services to make your trip enjoyable and stress-free. Whether you're traveling to Shirdi for spiritual purposes or exploring the surrounding areas, our commitment to customer satisfaction, safety, and comfort ensures you have a pleasant travel experience."
    },
    {
        "name": "Ooty Tour Package with Sai Sairaj Travels",
        "description": "We partner with Sai Sairaj Travels to offer exclusive Ooty tour packages. Discover the best of Ooty's attractions, including the Ooty Lake, Botanical Gardens, and the Nilgiri Mountain Railway. Our guided tours provide insightful commentary on the local culture, history, and natural beauty, ensuring a memorable trip for all visitors."
    },
    {
        "name": "Places to Visit in Ooty with Family",
        "description": "Our package highlights the top places to visit in Ooty with family, making it an ideal getaway for all ages. Explore Ooty Lake, enjoy a ride on the famous Nilgiri Mountain Railway, visit the lush Botanical Gardens, and discover the scenic views from viewpoints like Doddabetta Peak. This family-friendly itinerary ensures fun, relaxation, and bonding time in the heart of the Nilgiris."
    },
    {
        "name": "Shirdi to Mudumalai Tiger Reserve",
        "description": "Embark on an exciting wildlife adventure with our Shirdi to Mudumalai Tiger Reserve excursion. Located at the foothills of the Nilgiris, the reserve is home to a diverse range of flora and fauna, including tigers, elephants, and leopards. Enjoy a thrilling safari experience and explore the rich wildlife sanctuary, making it a perfect addition to your trip from Shirdi."
    },
    {
        "name": "Shirdi to Ramoji Film City Cab Booking",
        "description": "For movie enthusiasts, we offer Shirdi to Ramoji Film City cab booking. Located in Hyderabad, Ramoji Film City is one of the largest film studios in the world. Our taxi service ensures a comfortable and stress-free journey, giving you the chance to explore the magical world of cinema with guided tours and interactive experiences."
    },
    {
        "name": "Shirdi to South India Tour Packages",
        "description": "Our Shirdi to South India Tour Packages are designed for those who want to experience the rich culture, traditions, and natural beauty of Southern India. From the hill stations of Ooty and Munnar to the beaches of Goa and the temples of Tamil Nadu, our packages offer a diverse range of experiences. Whether you're seeking spirituality, adventure, or relaxation, this package has something for everyone."
    },
    {
        "name": "Shirdi Tour Packages from Hyderabad",
        "description": "For travelers from Hyderabad, our Shirdi Tour Packages from Hyderabad provide an easy and convenient way to experience the spiritual essence of Shirdi. We offer well-planned itineraries with comfortable transport, including train and flight options, to ensure that your pilgrimage is as smooth and fulfilling as possible."
    },
    {
        "name": "Shirdi to Ooty Mysore Cab Package Contact Information",
        "description": "To book your Shirdi to Ooty Mysore Cab Package or for any inquiries, please reach out to SaitirthTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to assist with your travel needs, ensuring a seamless and enjoyable journey. Whether you're booking a one-way trip, round trip, or customized tour, we are committed to making your travel experience comfortable and memorable."
    }
],

"tableData": [
    ["- Shirdi to Ooty Mysore Cab", "- Mysore to Ooty Cab Fare"],
    ["- Shirdi to Ooty Tour Package", "- Tirupati Tour Package from Shirdi"],
    ["- Shirdi to Bangalore Cab Booking", "- Shirdi to Outstation Cab Package"],
    ["- Best Taxi Service in Shirdi", "- Ooty Tour Package with Sai Sairaj Travels"],
    ["- Places to Visit in Ooty with Family", "- Shirdi to Mudumalai Tiger"],
    ["- Shirdi to Ramoji Film City Cab Booking", "- Shirdi to South India Tour Packages"],
    ["- Shirdi Tour Packages from Hyderabad", "- SaitirthTours & Travels Shirdi"],
    ["", ""]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Scenic Journey",
      "WhyChoosedescription": "Experience the breathtaking landscapes of South India as you travel from Shirdi to Ooty and Mysore, renowned for their lush greenery, hills, and serene lakes. The journey will take you through picturesque hills, tea gardens, and charming towns, allowing you to soak in the natural beauty and vibrant culture of the region. With each turn of the road, you'll be greeted by stunning vistas that make for perfect photo opportunities and memorable experiences."
    },
    {
      "WhyChooseheading": "Tailored Itinerary",
      "WhyChoosedescription": "Our Ooty Mysore Cab Package is designed to cover all the must-see attractions, including Ooty Lake, Botanical Gardens, Mysore Palace, and more, ensuring you have an unforgettable experience. We work closely with you to create a personalized itinerary that fits your interests and schedule. Whether you want to spend more time at a particular site or include additional attractions, we ensure every moment of your trip is filled with exploration and enjoyment."
    },
    {
      "WhyChooseheading": "Comfortable Transportation",
      "WhyChoosedescription": "Travel in style with our well-maintained, air-conditioned vehicles, ensuring a comfortable journey throughout your trip. Our vehicles are equipped with modern amenities designed to enhance your travel experience. We prioritize your comfort by providing spacious seating and a smooth ride, allowing you to relax and enjoy the scenic views as you make your way to your destinations."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are familiar with the routes and local attractions, providing a smooth and safe travel experience while sharing insights about the regions you visit. With their extensive knowledge of the area, they can recommend hidden gems and local favorites that are not on the typical tourist path. Their friendly demeanor and professionalism make your journey not just safe, but also enjoyable."
    },
    {
      "WhyChooseheading": "Flexible Packages",
      "WhyChoosedescription": "Whether you're traveling solo, with family, or in a group, we offer flexible packages that can be customized according to your travel preferences and duration. We understand that each traveler has unique needs, and our team is dedicated to creating a package that fits perfectly with your itinerary. From luxury vehicles to budget-friendly options, we accommodate various travel styles to ensure everyone has a memorable journey."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "We provide competitive pricing with transparent cost structures, so you know exactly what you’re paying for without any hidden charges. Our aim is to offer great value while maintaining high standards of service. We believe that quality travel experiences should be accessible to everyone, and our pricing reflects our commitment to affordability without compromising on comfort or safety."
    },
    {
      "WhyChooseheading": "Customer-Centric Approach",
      "WhyChoosedescription": "Your satisfaction is our priority. We focus on delivering exceptional service, ensuring a pleasant and hassle-free experience from start to finish. Our team is dedicated to understanding your needs and preferences, and we go the extra mile to ensure that your trip meets your expectations. We value your feedback and continuously strive to improve our services based on your insights."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated team is available around the clock to assist you with any inquiries, adjustments, or support you may need during your trip. Whether you have questions before booking or need assistance while traveling, our customer support is just a call away. We pride ourselves on our responsiveness and commitment to resolving any issues that may arise, ensuring you have peace of mind throughout your journey."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "Our satisfied customers rave about our reliable service and unforgettable travel experiences, reflecting our commitment to quality. We are proud of the numerous positive reviews we have received, which motivate us to maintain high standards of service. The testimonials from our previous travelers speak volumes about our dedication to providing exceptional travel experiences."
    },
    {
      "WhyChooseheading": "Cultural Insights",
      "WhyChoosedescription": "Immerse yourself in the local culture with our guides who will share interesting facts and stories about the places you visit, enriching your travel experience. We believe that travel is not just about seeing new places but also about understanding and appreciating the local culture. Our guides are passionate about their heritage and are eager to share their knowledge, providing you with a deeper connection to the regions you explore."
    }
  ]





        
  }


  const faqData = [
    {
        question: "What attractions are included in the Ooty Mysore Cab Package?",
        answer: "The package typically includes attractions like Ooty Lake, Botanical Gardens, Doddabetta Peak, Mysore Palace, and Chamundi Hill."
    },
    {
        question: "How long is the duration of the trip?",
        answer: "The trip can range from 5 to 7 days, depending on your selected itinerary and sightseeing preferences."
    },
    {
        question: "What type of vehicle will be used for the trip?",
        answer: "We provide a variety of vehicles, including sedans and SUVs, based on the size of your group and your specific requirements."
    },
    {
        question: "Are meals included in the package?",
        answer: "Meal arrangements can be made depending on your package choice. Please consult our customer service for specific details."
    },
    {
        question: "Can the itinerary be customized?",
        answer: "Yes, we offer customization options to fit your interests and preferences. Just let us know what you would like to include."
    },
    {
        question: "What is the cancellation policy?",
        answer: "Our cancellation policy is customer-friendly. Please contact our customer service for specific details regarding cancellations."
    },
    {
        question: "Is travel insurance part of the package?",
        answer: "Travel insurance is not included but can be arranged at your request for added security during your trip."
    },
    {
        question: "How do I make a booking?",
        answer: "Bookings can be made easily through our website or by contacting our customer service for assistance."
    },
    {
        question: "Are there any additional costs?",
        answer: "We maintain transparency in our pricing. All costs will be clearly communicated during the booking process with no hidden fees."
    },
    {
        question: "What if I have a complaint during the trip?",
        answer: "Please contact our customer service immediately, and we will address your concerns promptly for a resolution."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Rao",
        text: "I recently booked the Shirdi to Ooty Mysore Cab Package with Saitirth Travels, and it was an incredible experience! The views were stunning, and our driver was both professional and friendly. He made the journey enjoyable by sharing stories about the places we visited. Highly recommend!"
    },
    {
        name: "Mrs. Neeta Singh",
        text: "Saitirth Travels provided an exceptional service for our Ooty and Mysore trip. Everything was well-organized, and the car was comfortable. We loved exploring the beautiful landscapes and the historical sites. A big thank you to the team for making our trip memorable!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Shirdi to Ooty Mysore Cab Package | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Ooty Mysore Cab Package with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ooty, Mysore, and other South India destinations." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Shirdi to Ooty Mysore Cab Package | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Ooty Mysore Cab Package with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ooty, Mysore, and other South India destinations." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-ooty-mysore-cab-package" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-ooty-mysore-cab-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book an affordable Shirdi to Ooty Mysore Cab Package with SaitirthTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ooty, Mysore, and other South India destinations.",
            "url": "https://www.saitirthcabs.com/shirdi-to-ooty-mysore-cab-package",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-ooty-mysore-cab-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ooty Mysore Cab Package",
                "Mysore to Ooty Cab Fare",
                "Shirdi to Ooty Tour Package",
                "Tirupati Tour Package from Shirdi",
                "Shirdi to Bangalore Cab Booking",
                "Shirdi to Outstation Cab Package",
                "Best Taxi Service in Shirdi",
                "Ooty Tour Package with Sai Sairaj Travels",
                "Places to Visit in Ooty with Family",
                "Shirdi to Mudumalai Tiger",
                "Shirdi to Ramoji Film City Cab Booking",
                "Shirdi to South India Tour Packages",
                "Shirdi Tour Packages from Hyderabad",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/69.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoooty;