
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoajantaellora() {



  const cardData =
  {
    keyword: 'Shirdi to Ajanta Ellora Caves Package ',
    heading: 'Saitirth Travels:Shirdi to Ajanta Ellora Caves Package ',
    headingDescription: 'Embark on a memorable journey from Shirdi to the historical wonders of Ajanta and Ellora Caves with Saitirth Travels. This package offers an immersive experience of these UNESCO World Heritage Sites, renowned for their magnificent rock-cut architecture, intricate carvings, and cultural significance. Our package includes comfortable cab service with skilled drivers, ensuring a hassle-free and enjoyable journey.',

    top: 'Top places Ajanta Ellora Caves Package by Saitirth Travels',

    topPlaces: [
        {
            "title": "Ajanta Caves",
            "description": "These rock-cut caves date back to the 2nd century BCE and feature intricate murals and sculptures inspired by Buddhist traditions. The frescoes and paintings depict stories from the life of Buddha and various Jataka tales, making Ajanta Caves a UNESCO World Heritage Site and a significant historical treasure."
        },
        {
            "title": "Ellora Caves",
            "description": "Renowned for its diverse cultural representation, Ellora showcases Hindu, Buddhist, and Jain rock-cut temples. The Kailasa Temple (Cave 16), dedicated to Lord Shiva, is particularly impressive as the largest monolithic rock excavation in the world. Ellora's architectural and artistic brilliance makes it a UNESCO World Heritage Site."
        },
        {
            "title": "Grishneshwar Temple",
            "description": "Situated near the Ellora Caves, Grishneshwar Temple is one of the 12 Jyotirlingas dedicated to Lord Shiva. With intricate carvings and a serene ambiance, the temple holds great significance for devotees and is a peaceful place to offer prayers."
        },
        {
            "title": "Daulatabad Fort",
            "description": "A short drive from Ellora, Daulatabad Fort is a historic fort offering panoramic views and an insight into medieval architectural and defensive structures. This hilltop fort was once known as the impregnable fortress due to its strategic location and clever design."
        },
        {
            "title": "Bibi Ka Maqbara",
            "description": "Often called the 'Mini Taj,' Bibi Ka Maqbara in Aurangabad resembles the Taj Mahal and was built in memory of Emperor Aurangzeb's wife. Its Mughal architecture, beautiful gardens, and marble structure make it a notable site for visitors."
        },
        {
            "title": "Panchakki",
            "description": "This unique water mill, also located in Aurangabad, showcases ancient engineering techniques. Panchakki, or the 'Water Mill,' harnesses water power to grind grain and offers a tranquil garden, making it an intriguing and peaceful spot."
        },
        {
            "title": "Khuldabad",
            "description": "Known as the 'Valley of Saints,' Khuldabad is home to the tomb of Emperor Aurangzeb and many Sufi saints. The site is a serene place for reflection and is deeply rooted in India's spiritual heritage."
        },
        {
            "title": "Jain Caves at Ellora",
            "description": "These caves, located at the northern end of Ellora, offer exquisite rock-cut carvings dedicated to Jain Tirthankaras. The simplicity of the architecture combined with detailed sculptures reflects the spiritual essence of Jainism."
        },
        {
            "title": "Aurangabad Caves",
            "description": "Carved out of the soft basalt rock in the 6th and 7th centuries, the Aurangabad Caves are located near Bibi Ka Maqbara. These caves exhibit an interesting mix of Buddhist iconography and intricate designs, showcasing the artistic diversity of the period."
        },
        {
            "title": "Siddharth Garden and Zoo",
            "description": "Situated in Aurangabad, this lush garden is ideal for a leisurely visit. It features well-maintained lawns, fountains, and a small zoo, making it a good spot for families and those looking to relax."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi to Ajanta Ellora Package",
        "description": "Our Shirdi to Ajanta Ellora Package offers a seamless and enriching travel experience, combining history, culture, and breathtaking natural beauty. This package includes comfortable transportation, expert guides, and carefully selected itineraries, ensuring you get the most out of your visit to these UNESCO World Heritage sites. Explore the awe-inspiring Ajanta and Ellora caves, known for their ancient Buddhist, Hindu, and Jain temples, intricate carvings, and stunning frescoes that tell the story of India's rich cultural and religious history."
    },
    {
        "name": "Shirdi to Ajanta Ellora Tour Package",
        "description": "The Shirdi to Ajanta Ellora Tour Package is a comprehensive all-in-one tour that covers round-trip transportation from Shirdi, entrance fees to both Ajanta and Ellora caves, and guided tours to help you uncover the deep history behind these monuments. With the expertise of our experienced guides, you'll explore the architectural marvels of the caves, such as the elaborate rock-cut temples, exquisite sculptures, and colorful murals that date back thousands of years. This package is ideal for history lovers, spiritual seekers, and anyone wishing to connect with India's ancient heritage."
    },
    {
        "name": "Shirdi to Ajanta Ellora Caves Package",
        "description": "Our Shirdi to Ajanta Ellora Caves Package provides a customizable itinerary, allowing you to choose between a one-day trip or a multi-day exploration. This package includes comfortable transportation, entrance tickets, and options for pre-arranged accommodation, making it easy for you to focus on enjoying the stunning beauty of the caves. Whether you prefer a quick visit or a deeper exploration, our package accommodates your schedule, providing flexibility and convenience. You'll be accompanied by expert guides who will share fascinating insights into the history, art, and significance of the caves."
    },
    {
        "name": "Shirdi to Ajanta Ellora Darshan Package",
        "description": "The Shirdi to Ajanta Ellora Darshan Package is designed for those seeking a blend of spirituality and cultural discovery. This journey will take you through the serene landscapes surrounding the caves while offering a deep dive into the spiritual significance of Ajanta and Ellora. The caves are home to ancient religious artworks and Buddhist stupas, while the tranquil environment provides an ideal setting for meditation and reflection. Our package includes a guided tour of both sites, allowing you to experience the spiritual atmosphere and gain a better understanding of the role these caves played in India's ancient history."
    },
    {
        "name": "Best Shirdi to Ajanta Ellora Tour",
        "description": "When it comes to the best Shirdi to Ajanta Ellora tour, we ensure that every detail of your journey is taken care of for maximum comfort and satisfaction. Our team of experienced drivers and knowledgeable tour guides ensures a smooth and educational travel experience. You’ll have the opportunity to visit the most famous caves at Ajanta and Ellora, including the magnificent Kailasa temple, and explore lesser-known gems, with ample time for sightseeing, photography, and appreciating the rich heritage. This tour is designed for those looking for a perfect balance of comfort, learning, and adventure."
    },
    {
        "name": "Shirdi to Ajanta Ellora Taxi Package",
        "description": "Our Shirdi to Ajanta Ellora Taxi Package offers a personalized travel experience with the convenience of a dedicated cab service. Enjoy the freedom of traveling at your own pace, with door-to-door pick-up and drop-off service. The package includes comfortable and well-maintained vehicles with professional, experienced drivers who are familiar with the best routes and can offer helpful suggestions along the way. Whether you’re traveling solo, as a couple, or with a group, our taxi package ensures a stress-free journey, allowing you to fully immerse yourself in the awe-inspiring beauty of Ajanta and Ellora."
    },
    {
        "name": "Shirdi to Ajanta Ellora Car Rental",
        "description": "For those who prefer flexibility and independence, our Shirdi to Ajanta Ellora car rental service provides a wide range of vehicles to suit different needs. From compact cars for solo travelers to spacious SUVs for families or small groups, our fleet offers comfort, reliability, and convenience. Our well-maintained cars come with all the necessary amenities, ensuring a pleasant journey. You can choose to drive yourself or opt for a chauffeur-driven experience. This service is ideal for travelers who wish to explore Ajanta and Ellora at their own pace, without the constraints of a fixed itinerary."
    },
    {
        "name": "Ajanta Ellora Tour from Shirdi",
        "description": "Embark on an unforgettable Ajanta Ellora tour from Shirdi, where history comes alive through spectacular rock-cut temples, vivid frescoes, and awe-inspiring sculptures. Our tour offers a complete cultural immersion, with a guided exploration of the Ajanta and Ellora caves, each renowned for their exceptional artistry and historical importance. Ajanta, with its ancient Buddhist monasteries and intricate wall paintings, and Ellora, known for its colossal temples and breathtaking architecture, are two of the finest examples of ancient Indian art and architecture. This tour is perfect for history buffs, art lovers, and spiritual seekers alike."
    },
    {
        "name": "Shirdi to Ajanta Ellora Cab",
        "description": "Travel in comfort with our Shirdi to Ajanta Ellora cab service. Whether you're visiting for a day trip or a longer stay, our cab service ensures prompt and reliable transportation to and from the Ajanta and Ellora caves. Our professional drivers are well-versed in the best routes, ensuring a smooth and efficient journey. With our door-to-door service, you don’t have to worry about anything other than enjoying your visit. Our clean, air-conditioned cabs are equipped for comfort and convenience, providing the ideal mode of travel for individuals, couples, or families."
    },
    {
        "name": "Shirdi to Ajanta Ellora Sightseeing Package",
        "description": "Our Shirdi to Ajanta Ellora sightseeing package offers a curated itinerary that includes guided tours of both the Ajanta and Ellora caves, ensuring you gain an in-depth understanding of their historical, cultural, and artistic significance. The package covers all the major attractions, including the Buddhist caves of Ajanta and the remarkable Kailasa temple at Ellora, allowing you to fully appreciate the craftsmanship and architectural brilliance of these ancient sites. Our knowledgeable guides will provide fascinating insights into the stories and symbolism behind the cave art and architecture, enriching your visit."
    },
    {
        "name": "Shirdi to Ajanta Ellora Caves Package Contact Information",
        "description": "For more details or to book your Shirdi to Ajanta Ellora Caves Package, please contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Our team is ready to assist you in planning a memorable journey to these iconic heritage sites. Whether you have questions about our packages, need help with itinerary planning, or want to customize your travel experience, we are here to provide you with all the information you need. Your comfort, safety, and satisfaction are our top priorities, and we look forward to helping you explore the beauty and history of Ajanta and Ellora."
    }
],
"tableData": [
    ["- Shirdi to Ajanta Ellora Package", "- Shirdi to Ajanta Ellora Tour Package"],
    ["- Shirdi to Ajanta Ellora Caves Package", "- Shirdi to Ajanta Ellora Sarshan Package"],
    ["- Best Shirdi to Ajanta Ellora Tour", "- Shirdi to Ajanta Ellora Taxi Package"],
    ["- Shirdi to Ajanta Ellora Car Rental", "- Ajanta Ellora Tour from Shirdi"],
    ["- Shirdi to Ajanta Ellora Cab", "- Shirdi to Ajanta Ellora Sightseeing Package"],
    ["- SaitirthTours & Travels Shirdi"]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Comprehensive Packages",
      "WhyChoosedescription": "Our Shirdi to Ajanta Ellora Caves package offers an all-inclusive experience, covering transportation, guide services, and entry fees to make your trip hassle-free. We take care of every detail so you can focus on enjoying the breathtaking Ajanta and Ellora caves, world-renowned for their architectural beauty and historical significance. From start to finish, our team ensures you have a seamless experience with no hidden charges or unexpected expenses. By choosing our comprehensive packages, you can fully immerse yourself in this cultural journey, knowing everything is covered for you."
    },
    {
      "WhyChooseheading": "Expert Guidance",
      "WhyChoosedescription": "Our knowledgeable guides bring the stories of Ajanta and Ellora to life with insights into their rich history, intricate architecture, and hidden meanings behind the art. They share fascinating tales about the origins of the caves, the artistry of ancient craftsmen, and the spiritual significance that has drawn visitors for centuries. With their expertise, you gain a deep understanding of these UNESCO World Heritage sites. Our guides enrich your journey, answering your questions and highlighting unique details, ensuring your visit is both educational and memorable."
    },
    {
      "WhyChooseheading": "Comfortable Transportation",
      "WhyChoosedescription": "We prioritize your comfort with a fleet of well-maintained vehicles suitable for short and long-distance travel alike. Our vehicles are equipped with plush seating, climate control, and ample space, allowing you to relax as you journey between Shirdi and the caves. We understand the importance of arriving refreshed, especially on longer journeys, so our vehicles are designed to maximize comfort. With our clean, comfortable, and reliable transport, you can enjoy every moment of the scenic drive as part of your adventure."
    },
    {
      "WhyChooseheading": "Flexible Itineraries",
      "WhyChoosedescription": "Understanding that every traveler is unique, we offer flexible itineraries that can be tailored to fit your schedule, interests, and travel style. Whether you want a quick tour or a more immersive experience with extra stops, we work to customize the itinerary for your ideal day. You can add additional sightseeing spots, adjust departure times, or even opt for a multi-day journey. Our flexibility allows you to experience Ajanta and Ellora your way, on a timeline that suits you."
    },
    {
      "WhyChooseheading": "Punctuality Guaranteed",
      "WhyChoosedescription": "We recognize that time is precious, especially on a journey as enriching as this. That’s why punctuality is a cornerstone of our service. Our drivers are trained to be on time, ensuring that you can maximize your time at Ajanta and Ellora without worrying about delays. With real-time traffic monitoring, we adjust routes if needed to stay on schedule. Our commitment to punctuality allows you to enjoy a stress-free experience, knowing you’ll have plenty of time to explore each destination."
    },
    {
      "WhyChooseheading": "Safety Measures",
      "WhyChoosedescription": "Your safety is our top priority. Our vehicles are routinely inspected and maintained to the highest standards, ensuring they are safe and reliable for every trip. Our drivers undergo extensive safety training and follow all traffic rules to provide a secure travel environment. We also adhere to strict hygiene protocols, ensuring a clean and sanitized vehicle for each journey. Our safety-first approach lets you focus on enjoying the journey without worry, knowing you’re in good hands."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "Our packages are designed to offer excellent value, combining quality service with affordable pricing. We believe that an enriching travel experience should be accessible to everyone, so our pricing is transparent and competitive. With no hidden costs, you can plan your trip confidently and enjoy the best of Ajanta and Ellora without stretching your budget. Our goal is to provide a premium travel experience that meets your expectations while respecting your budget."
    },
    {
      "WhyChooseheading": "Local Expertise",
      "WhyChoosedescription": "Our drivers possess in-depth local knowledge and are happy to share insights about the best places to visit along your route. Whether it’s a scenic overlook, a local eatery, or an additional cultural site, they can make recommendations to enhance your journey. Beyond simply driving, they serve as informal guides, offering suggestions tailored to your interests. By choosing us, you gain access to local expertise that adds a layer of richness to your travel experience."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "We understand that questions and needs can arise at any time, so our customer support team is available 24/7. Whether you need to make last-minute changes to your itinerary, ask about local attractions, or get assistance during your trip, our responsive support team is ready to help. Our commitment to customer service means that help is always just a call or message away, ensuring peace of mind throughout your journey."
    },
    {
      "WhyChooseheading": "Positive Reviews",
      "WhyChoosedescription": "We are proud of our excellent customer feedback and positive reviews, which reflect our commitment to quality and customer satisfaction. Many travelers have praised our reliable service, professional guides, and well-maintained vehicles. These testimonials affirm our dedication to making each trip a memorable experience. When you choose our services, you join a community of satisfied travelers who trust us to deliver exceptional value and unforgettable journeys."
    }
  ]





        
  }


  const faqData = [
    {
        question: "What does the package include?",
        answer: "Our package typically includes transportation, guide services, entry fees to the caves, and complimentary refreshments during the journey."
    },
    {
        question: "How long is the trip from Shirdi to Ajanta Ellora Caves?",
        answer: "The journey takes approximately 4 to 5 hours, depending on traffic conditions, allowing you to enjoy the scenic beauty along the route."
    },
    {
        question: "Are meals included in the package?",
        answer: "Meals are not typically included, but we can suggest recommended restaurants along the way for your convenience."
    },
    {
        question: "Can I customize my itinerary?",
        answer: "Yes, we offer customizable itineraries based on your preferences, including additional stops or extended time at specific sites."
    },
    {
        question: "What type of vehicles do you use for transportation?",
        answer: "We use a variety of vehicles, including sedans, SUVs, and vans, to accommodate different group sizes comfortably."
    },
    {
        question: "How can I book the package?",
        answer: "You can book through our website or contact our customer service team directly for assistance with your reservation."
    },
    {
        question: "Is it possible to book the package for a large group?",
        answer: "Yes, we can accommodate large groups and offer special arrangements for corporate or family outings."
    },
    {
        question: "What if I need to cancel or reschedule my booking?",
        answer: "Cancellations and rescheduling can be done with prior notice. Please contact our customer service for assistance with any changes."
    },
    {
        question: "Are there any age restrictions for visiting the caves?",
        answer: "There are no specific age restrictions, but please note that some areas may require moderate walking or climbing."
    },
    {
        question: "How can I contact you for more information?",
        answer: "For further inquiries or to get more details about our packages, you can reach us via phone or email."
    }
];

const testimonials = [
    {
        name: "Mr. Sanjay Rao",
        text: "Our trip to Ajanta and Ellora Caves with Saitirth Travels was nothing short of amazing! The driver was very friendly, and the guide provided us with valuable insights that made the experience unforgettable. I highly recommend this package for anyone visiting Shirdi!"
    },
    {
        name: "Mrs. Anjali Kapoor",
        text: "We had a fantastic time on the Ajanta Ellora tour! The vehicle was comfortable, and the itinerary was perfectly organized. Our guide was knowledgeable and made the history of the caves come alive. Thank you, Saitirth Travels!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Ajanta Ellora Caves Package | Best Tour Package | SaitirthTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Ajanta Ellora Caves Package with SaitirthTours & Travels. Enjoy a memorable tour to Ajanta Ellora Caves, including taxi service, sightseeing, and car rental options." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Ajanta Ellora Caves Package | Best Tour Package | SaitirthTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Ajanta Ellora Caves Package with SaitirthTours & Travels. Enjoy a memorable tour to Ajanta Ellora Caves, including taxi service, sightseeing, and car rental options." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-ajanta-ellora-caves-package" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/ajanta-ellora-caves-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book an affordable Shirdi to Ajanta Ellora Caves Package with SaitirthTours & Travels. Enjoy a memorable tour to Ajanta Ellora Caves, including taxi service, sightseeing, and car rental options.",
            "url": "https://www.saitirthcabs.com/shirdi-to-ajanta-ellora-caves-package",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/ajanta-ellora-caves-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ajanta Ellora Package",
                "Shirdi to Ajanta Ellora Tour Package",
                "Shirdi to Ajanta Ellora Caves Package",
                "Shirdi to Ajanta Ellora Darshan Package",
                "Best Shirdi to Ajanta Ellora Tour",
                "Shirdi to Ajanta Ellora Taxi Package",
                "Shirdi to Ajanta Ellora Car Rental",
                "Ajanta Ellora Tour from Shirdi",
                "Shirdi to Ajanta Ellora Cab",
                "Shirdi to Ajanta Ellora Sightseeing Package",
                "Shirdi to Ajanta Ellora Package with Taxi",
                "Ajanta Ellora Travel from Shirdi",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/65.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoajantaellora;