
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoindore() {



  const cardData =
  {
    keyword: ' Shirdi to Indore Darshan Cab',
    heading: 'Saitirth Travels: Shirdi to Indore Darshan Cab',
    headingDescription: 'Traveling from Shirdi to Indore for a spiritual journey is made easy with Saitirth Travels. We provide comfortable and reliable cab services that cater to your needs, ensuring a pleasant travel experience. Our professional drivers are familiar with the route, making your journey smooth and hassle-free.',

    top: 'Top Places to Visit in Indore with Saitirth Travels',

    topPlaces: [
        {
            "title": "Rajwada Palace",
            "description": "Rajwada Palace is a historic landmark that beautifully encapsulates the grandeur of Indore’s royal past. This stunning palace features intricate wooden carvings, spacious courtyards, and impressive fountains that reflect the architectural brilliance of the era. As visitors explore its opulent halls and galleries, they can learn about the rich heritage of the Holkar dynasty, the royal family that ruled the region. The palace's well-maintained gardens add to its charm, making it a popular spot for both history enthusiasts and architecture lovers."
        },
        {
            "title": "Lal Baag Palace",
            "description": "Lal Baag Palace stands as a majestic symbol of Indore’s royal heritage. The palace complex is surrounded by beautifully manicured gardens and showcases impressive architecture that reflects the lifestyle of the Holkar rulers. Visitors can wander through the expansive grounds and admire the intricate designs and luxurious interiors that offer a glimpse into the opulence of royal life. This historical site is perfect for those interested in history and architecture, providing a serene environment to explore and reflect on Indore’s royal past."
        },
        {
            "title": "Indore Museum",
            "description": "The Indore Museum is a treasure trove of cultural heritage, housing a fascinating collection of artifacts, including ancient sculptures, exquisite paintings, and traditional textiles. This museum provides an insightful overview of the region's rich history and artistic legacy. Visitors can delve into various exhibits that showcase the evolution of Indore and the surrounding area, making it an excellent destination for history buffs and those interested in local culture. The museum's engaging displays offer a deeper understanding of the artistic expressions of different eras."
        },
        {
            "title": "Sarad Sagar Lake",
            "description": "Sarad Sagar Lake is a picturesque spot that offers stunning views and various recreational activities. The serene environment surrounding the lake is perfect for relaxation and unwinding away from the hustle and bustle of city life. Visitors can enjoy boating on the calm waters or simply take a leisurely stroll along the banks while soaking in the natural beauty. The lake is a popular destination for families and nature lovers, providing a peaceful retreat that enhances the overall experience of visiting Indore."
        },
        {
            "title": "Khajrana Ganesh Temple",
            "description": "Dedicated to Lord Ganesh, the Khajrana Ganesh Temple is a revered spiritual site that attracts countless devotees seeking blessings and guidance. The temple's serene atmosphere, coupled with its beautifully crafted idols of Ganesh, creates an inviting space for prayer and reflection. Visitors can witness the daily rituals and offerings, adding to the spiritual experience. The temple is not only significant for its religious importance but also for its artistic architecture, making it a must-visit for spiritual seekers and architecture enthusiasts alike."
        },
        {
            "title": "Annapurna Temple",
            "description": "Annapurna Temple, dedicated to Goddess Annapurna, is a significant religious site in Indore. This temple features stunning architecture with intricate carvings and a vibrant interior that reflects the devotion of its followers. The peaceful surroundings provide a perfect setting for prayer and meditation, allowing visitors to connect with the divine. The temple attracts many devotees, especially during festivals, creating a lively atmosphere filled with spirituality and devotion. Its architectural beauty and serene environment make it an essential stop for those exploring Indore's spiritual landscape."
        },
        {
            "title": "Patalpani Waterfall",
            "description": "Located just outside Indore, Patalpani Waterfall is a breathtaking natural attraction that captivates visitors with its stunning cascade and lush greenery. The waterfall is a popular picnic spot, offering a refreshing escape into nature. With its scenic surroundings, it is an ideal location for nature photography, as well as for those looking to unwind in a serene environment. The sound of the cascading water and the beauty of the surrounding landscape create a perfect backdrop for a day of relaxation and exploration."
        },
        {
            "title": "Nehru Park",
            "description": "Nehru Park is a popular recreational area that features beautifully landscaped gardens, winding walking paths, and well-maintained playgrounds. It serves as a great place for families and friends to gather and enjoy leisure time together. The park is equipped with facilities for various outdoor activities, making it a favorite spot for picnics and community events. With its vibrant atmosphere and abundant greenery, Nehru Park offers a refreshing escape from the urban landscape, encouraging visitors to relax and engage in recreational activities."
        },
        {
            "title": "Ralamandal Wildlife Sanctuary",
            "description": "Ralamandal Wildlife Sanctuary is a haven for nature lovers, boasting diverse flora and fauna set within picturesque landscapes. Visitors can enjoy trekking through the sanctuary's trails, bird watching, and exploring the rich biodiversity that thrives in the area. The sanctuary offers a unique opportunity to connect with nature and observe wildlife in their natural habitat. Its scenic beauty and tranquil environment make it an excellent destination for eco-tourism, attracting those who seek adventure and a deeper appreciation for nature."
        },
        {
            "title": "Indore's Street Food",
            "description": "While not a specific location, Indore is famous for its vibrant street food scene that offers a delightful array of culinary experiences. Food enthusiasts can indulge in local specialties such as poha (flattened rice), jalebi (sweet deep-fried pastry), and various types of chaat (snacks). Exploring the bustling street food markets provides an authentic taste of Indore’s culture and flavors. The lively atmosphere and the aroma of delicious dishes make street food exploration a memorable experience, showcasing the city’s rich culinary heritage."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi to Indore Darshan Cab Service",
        "description": "Experience a spiritual journey with our Shirdi to Indore Darshan cab service. We provide comfortable and reliable transportation for your pilgrimage, ensuring that you can focus on your spiritual experience without worrying about travel logistics. Our well-maintained vehicles and professional drivers are dedicated to making your journey pleasant and safe, allowing you to relax as you travel to your next destination."
    },
    {
        "name": "Shirdi to Indore Cab Booking",
        "description": "Book your cab from Shirdi to Indore easily and conveniently through our user-friendly online platform. With just a few clicks, you can secure your ride and rest assured that your transportation needs are taken care of. Our booking system allows you to select your preferred vehicle type and travel dates, streamlining your trip planning process."
    },
    {
        "name": "Shirdi to Indore Cab Booking Online",
        "description": "Take advantage of our online cab booking service for seamless planning. Select your travel dates, choose your vehicle, and confirm your booking in just a few clicks. We ensure a straightforward booking experience with instant confirmation, allowing you to focus on preparing for your pilgrimage instead of worrying about transportation."
    },
    {
        "name": "Indore to Shirdi Taxi Fare",
        "description": "We offer competitive taxi fares for travel from Indore to Shirdi. Our pricing is transparent, with no hidden charges, ensuring that you receive the best value for your journey. Contact us for detailed pricing information tailored to your specific travel plans, and enjoy peace of mind knowing you’ve chosen an economical option for your pilgrimage."
    },
    {
        "name": "Shirdi to Bhopal Cab Service",
        "description": "In addition to Indore, we also provide taxi services from Shirdi to Bhopal. Plan your trip with us for a comfortable ride that ensures you arrive relaxed and ready to explore. Our drivers are experienced in navigating the best routes, so you can enjoy the scenic beauty of Madhya Pradesh during your journey."
    },
    {
        "name": "Shirdi to Bhopal Tempo Traveller Booking",
        "description": "For larger groups, we offer tempo traveller services from Shirdi to Bhopal. Enjoy a spacious ride with your family and friends, ensuring everyone travels comfortably together. Our tempo travellers are equipped with modern amenities, making it easy to enjoy the journey as you connect with your loved ones on the way to Bhopal."
    },
    {
        "name": "Shirdi to Ujjain Cab Service",
        "description": "Our cab service extends to Ujjain, allowing you to explore this sacred city known for the Mahakaleshwar Temple. With our reliable transportation, you can immerse yourself in the spiritual atmosphere of Ujjain without any travel hassles. Our drivers will ensure a smooth journey, helping you make the most of your visit."
    },
    {
        "name": "Shirdi to Mahakaleshwar Ujjain Cab Service",
        "description": "Experience the divine ambiance of Ujjain with our dedicated cab service from Shirdi to Mahakaleshwar. We cater to your needs by providing timely pickups and drop-offs, allowing you to focus on your spiritual journey and temple visits. Our knowledgeable drivers can also offer insights into local traditions and attractions to enhance your visit."
    },
    {
        "name": "Shirdi to Indore Packages",
        "description": "Choose from various packages for your journey from Shirdi to Indore, tailored to suit your needs and preferences. We offer customizable itineraries that can include sightseeing, local attractions, and additional stops, ensuring your travel experience is enriching and fulfilling."
    },
    {
        "name": "Shirdi to Madhya Pradesh Tour Packages",
        "description": "Explore the beauty of Madhya Pradesh with our customized tour packages, including visits to major attractions. Our itineraries are designed to provide a comprehensive experience of the region's cultural and historical significance, making your journey both enjoyable and enlightening."
    },
    {
        "name": "Shirdi to Madhya Pradesh Package Innova Crysta",
        "description": "Travel in style with our Innova Crysta packages for the Shirdi to Madhya Pradesh journey. Offering both comfort and luxury, our premium vehicles are perfect for families or groups seeking a high-quality travel experience. Enjoy spacious interiors and modern amenities as you embark on your pilgrimage."
    },
    {
        "name": "Shirdi to Indore Swift Dzire Tour",
        "description": "Opt for our Swift Dzire for your Shirdi to Indore tour for a compact and efficient travel experience. This vehicle is perfect for smaller groups or solo travelers looking for a budget-friendly yet comfortable option. Enjoy a smooth ride as you travel between these two spiritual destinations."
    },
    {
        "name": "Shirdi Outstation Cab Booking",
        "description": "Book outstation cabs from Shirdi for your travel needs, with a range of vehicle options to choose from. Our service covers various destinations, making it easy for you to plan your pilgrimage and other travels throughout the region. Enjoy competitive rates and excellent customer support throughout your journey."
    },
    {
        "name": "Shirdi to Khandwa Cab Booking",
        "description": "We also offer cab booking services for travel from Shirdi to Khandwa. Enjoy a smooth ride to your destination with our experienced drivers who prioritize your comfort and safety. With our reliable service, you can focus on your spiritual journey and leave the travel arrangements to us."
    },
    {
        "name": "Shirdi to Ujjain Cab Package",
        "description": "Explore Ujjain with our specially curated cab packages from Shirdi, ensuring a memorable visit to this sacred city. Our packages can be customized to include temple visits, local sightseeing, and cultural experiences, providing a holistic travel experience tailored to your preferences."
    },
    {
        "name": "Shirdi to Madhya Pradesh Tour Packages",
        "description": "We offer comprehensive Madhya Pradesh tour packages that include Shirdi as a starting point. These packages are designed to showcase the best of Madhya Pradesh’s spiritual and cultural heritage, giving you a rich travel experience filled with memorable moments."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for your travel needs from Shirdi to Indore and beyond. We are dedicated to providing excellent service and customer satisfaction, ensuring your journey is smooth and enjoyable. Our team is committed to meeting your travel preferences and exceeding your expectations with every ride."
    },
    {
        "name": "Shirdi to Indore Darshan Cab Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Let us make your journey from Shirdi to Indore a memorable experience! Our team is ready to assist you with any questions you may have, ensuring a smooth and enjoyable pilgrimage."
    }
],

tableData: [
    ["- Shirdi to Indore Cab Booking", "- Shirdi to Indore Cab Booking Online"],
    ["- Indore to Shirdi Taxi Fare", "- Shirdi to Bhopal Cab"],
    ["- Shirdi to Bhopal Tempo Traveller Booking", "- Shirdi to Ujjain Cab Service"],
    ["- Shirdi to Mahakaleshwar Ujjain Cab Service", "- Shirdi to Indore Packages Shirdi"],
    ["- Shirdi to Madhya Pradesh Packages", "- Shirdi to Madhya Pradesh Package Innova Crysta"],
    ["- Shirdi to Indore Swift Dzire Tour", "- Shirdi Outstation Cab Booking"],
    ["- Shirdi to Khandwa Cab Booking", "- Shirdi to Ujjain Cab Package"],
    ["- Shirdi to Madhya Pradesh Tour Packages", "- SaitirthTours & Travels Shirdi"]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Comfortable Journey",
      "WhyChoosedescription": "We ensure that your journey from Shirdi to Indore is as comfortable as possible. Our fleet of well-maintained vehicles, ranging from sedans to SUVs, is designed to provide you with a smooth and enjoyable ride. Whether you’re traveling alone or in a group, you’ll find the perfect vehicle for your needs."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our drivers are highly skilled and well-versed in navigating routes between Shirdi and Indore. Not only are they experienced behind the wheel, but they are also committed to delivering excellent customer service. They prioritize your safety and comfort throughout the journey, making sure you have a pleasant trip from start to finish."
    },
    {
      "WhyChooseheading": "Flexible Scheduling",
      "WhyChoosedescription": "We understand that travel plans can change. That's why we offer flexible scheduling options for our Shirdi to Indore service. Whether you need to adjust your departure time or require last-minute changes, we are happy to accommodate your needs, ensuring you have a hassle-free travel experience."
    },
    {
      "WhyChooseheading": "Transparent Pricing",
      "WhyChoosedescription": "Our pricing is competitive and transparent. When you book a taxi with us, you’ll receive an upfront quote with no hidden charges. We believe in providing excellent value for your money, with clear and honest pricing that gives you peace of mind about the cost of your journey."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our Shirdi to Indore cab service is available round the clock. Whether you need an early morning pick-up or a late-night ride, we are ready to serve you at your convenience. Our 24/7 availability ensures you can book your taxi whenever it fits your schedule."
    },
    {
      "WhyChooseheading": "Seamless Booking Experience",
      "WhyChoosedescription": "Booking your ride with us is quick and easy. Our online booking platform is designed for convenience, allowing you to reserve your Shirdi to Indore taxi with just a few clicks. Alternatively, our customer service team is always available to assist you with any inquiries or changes to your booking."
    },
    {
      "WhyChooseheading": "Safe and Secure Travel",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles are regularly maintained and sanitized to ensure a safe and secure travel experience. Our drivers follow all traffic laws and safety protocols, so you can relax and enjoy the ride with peace of mind."
    },
    {
      "WhyChooseheading": "Local Expertise",
      "WhyChoosedescription": "Our drivers are not only experienced professionals, but they also have valuable local knowledge. They can offer insights into the attractions and culture of Indore, helping you make the most of your visit. If you're interested, they can suggest the best places to visit, restaurants to try, or local landmarks to explore."
    },
    {
      "WhyChooseheading": "Hassle-Free Travel",
      "WhyChoosedescription": "We take care of all the details, so you don’t have to. From handling the logistics to providing a smooth and uninterrupted journey, we ensure that you can sit back, relax, and enjoy your travel. Whether you're heading to Indore for leisure or business, we’ve got you covered."
    },
    {
      "WhyChooseheading": "Satisfaction Guaranteed",
      "WhyChoosedescription": "At Saitirth Travels, your satisfaction is our priority. We strive to go above and beyond to meet your travel needs. If you're not completely satisfied with our service, we are committed to resolving any issues promptly. Our goal is to ensure a positive, comfortable, and memorable journey."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What is the distance from Shirdi to Indore?",
        answer: "The distance is approximately 550 kilometers, and the journey typically takes around 10-12 hours by cab."
    },
    {
        question: "How do I book a cab for Shirdi to Indore?",
        answer: "You can easily book a cab through our website or by contacting our customer service team for assistance."
    },
    {
        question: "What types of vehicles are available for the journey?",
        answer: "We offer a range of vehicles including sedans, SUVs, and luxury options to cater to different group sizes and preferences."
    },
    {
        question: "Are toll and parking fees included in the fare?",
        answer: "Any applicable tolls and parking fees will be added to the total fare and discussed at the time of booking."
    },
    {
        question: "Can I plan a return trip from Indore to Shirdi?",
        answer: "Yes, we provide round-trip services and can customize your itinerary to suit your travel needs."
    },
    {
        question: "What are the fare rates for Shirdi to Indore?",
        answer: "Fares vary based on the type of vehicle and specific travel requirements. Please contact us for a detailed quote."
    },
    {
        question: "Is your service safe?",
        answer: "Yes, we prioritize passenger safety through well-maintained vehicles and trained drivers."
    },
    {
        question: "Can I change my booking after it's confirmed?",
        answer: "Yes, modifications to your booking are allowed; just let us know as soon as possible."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a clear cancellation policy in place; please reach out for further details."
    },
    {
        question: "How can I contact customer support?",
        answer: "You can contact our customer support team via phone or email for any questions or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Desai",
        text: "My experience with Saitirth Travels for the Shirdi to Indore Darshan cab was exceptional! The vehicle was comfortable and spacious, making our journey enjoyable. The driver was knowledgeable about the route and very accommodating. I highly recommend Saitirth Travels for anyone planning a pilgrimage trip!"
    },
    {
        name: "Ms. Sneha Agarwal",
        text: "I had a wonderful trip from Shirdi to Indore with Saitirth Travels. The cab was in excellent condition, and the service was top-notch. Our driver was friendly and made sure we had everything we needed for a smooth journey. I will definitely choose Saitirth Travels for my future travels!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Indore Darshan Cab | Madhya Pradesh Tour Packages</title>
    <meta name="description" content="Book Shirdi to Indore Darshan Cab with Saitirth Travels. We offer affordable and reliable taxi services for Shirdi to Indore, including packages for Indore, Ujjain, Bhopal, and Mahakaleshwar Darshan. Book your Madhya Pradesh tour today!" />
    <meta name="keywords" content="Shirdi to Indore Darshan Cab, Shirdi to Indore cab booking, Indore to Shirdi taxi fare, Shirdi to Bhopal cab, Shirdi to Bhopal tempo traveller booking, Shirdi to Ujjain cab service, Shirdi to Mahakaleshwar Ujjain cab service, Shirdi to Madhya Pradesh packages, Shirdi to Indore packages, Shirdi to Indore tour, Shirdi to Khandwa cab booking, Shirdi to Madhya Pradesh tour packages, SaitirthTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Indore Darshan Cab | Madhya Pradesh Tour Packages" />
    <meta property="og:description" content="Book Shirdi to Indore Darshan Cab with Saitirth Travels. We provide affordable and convenient taxi services for your pilgrimage tours to Indore, Ujjain, Bhopal, and other important Madhya Pradesh destinations." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-indore-darshan-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-indore-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book Shirdi to Indore Darshan Cab with Saitirth Travels. Reliable and affordable taxi services for Shirdi to Indore, Ujjain, Bhopal, and other pilgrimage locations in Madhya Pradesh.",
            "url": "https://www.saitirthcabs.com/shirdi-to-indore-darshan-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-indore-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Indore Darshan Cab",
                "Shirdi to Indore cab booking",
                "Indore to Shirdi taxi fare",
                "Shirdi to Bhopal cab",
                "Shirdi to Bhopal tempo traveller booking",
                "Shirdi to Ujjain cab service",
                "Shirdi to Mahakaleshwar Ujjain cab service",
                "Shirdi to Madhya Pradesh packages",
                "Shirdi to Indore packages",
                "Shirdi to Indore tour",
                "Shirdi to Khandwa cab booking",
                "Shirdi to Madhya Pradesh tour packages"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/86.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoindore;