
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Cabsforeventinshirdi() {



  const cardData =
  {
    keyword: ' Cabs for Events in Shirdi',
    heading: 'Saitirth Travels: Cabs for Events in Shirdi',
    headingDescription: 'When it comes to organizing events in Shirdi, Saitirth Travels is your go-to solution for reliable and comfortable transportation. Whether you are planning a wedding, corporate event, family function, or a spiritual journey, we offer top-notch cab services tailored to meet your specific needs. Our fleet of well-maintained vehicles ensures a seamless travel experience, allowing you to focus on making your event memorable.',

    top: 'Top Places for Events in Shirdi with Saitirth Travels',

  "topPlaces": [
    {
        "title": "1. Shirdi Sai Baba Temple",
        "description": "The iconic Shirdi Sai Baba Temple is a spiritual haven that attracts millions of devotees every year. The temple's serene atmosphere is ideal for organizing spiritual gatherings, meditation sessions, and religious events. Its stunning architecture and the presence of Sai Baba's samadhi create a deeply sacred experience, making it a perfect place for devotees to seek blessings and connect with their spirituality."
    },
    {
        "title": "2. Saibaba Sansthan Trust",
        "description": "The Saibaba Sansthan Trust, responsible for the management of the Sai Baba Temple, provides a range of facilities for various events. From large-scale religious ceremonies to intimate spiritual gatherings, the trust ensures that all events are conducted with utmost reverence. This venue is known for its organizational excellence, helping to create memorable experiences steeped in spirituality."
    },
    {
        "title": "3. Shirdi Village",
        "description": "The picturesque Shirdi Village surrounds the Sai Baba Temple and is perfect for cultural events, fairs, and festivals. Its vibrant atmosphere, filled with local traditions and hospitality, allows visitors to experience the rich cultural heritage of Maharashtra. The village is known for its welcoming community and diverse activities, making it an ideal location for events that celebrate local culture."
    },
    {
        "title": "4. Khandoba Mandir",
        "description": "Khandoba Mandir is an ancient temple dedicated to Lord Khandoba and is a revered site for weddings and religious ceremonies. The tranquil environment of the temple offers a peaceful setting for spiritual celebrations, surrounded by lush greenery and the sounds of nature. Its historical significance and beautiful architecture make it a popular choice for couples and families seeking a sacred place for important life events."
    },
    {
        "title": "5. Samadhi Mandir",
        "description": "Samadhi Mandir, the resting place of Sai Baba, serves as a focal point for devotees seeking peace and reflection. This sacred site is ideal for private spiritual gatherings, memorial services, and contemplative retreats. The calm ambiance and spiritual energy of the mandir provide a perfect backdrop for those wishing to honor their loved ones or engage in deep meditation and prayer."
    },
    {
        "title": "6. Shirdi Water Park",
        "description": "Shirdi Water Park is an exciting destination perfect for family gatherings and fun events. With a variety of water rides, wave pools, and play areas for children, it offers a unique recreational experience for all ages. The park is designed for enjoyment and relaxation, making it an excellent choice for birthday parties, family reunions, and group outings, where families can create lasting memories together."
    },
    {
        "title": "7. Local Banquet Halls",
        "description": "Shirdi features several well-equipped banquet halls that cater to weddings, parties, and corporate events. These venues offer customizable packages, including catering and decoration services, ensuring that every detail aligns with your vision. With spacious interiors and professional staff, they provide a perfect setting for memorable celebrations, whether intimate or grand in scale."
    },
    {
        "title": "8. Shirdi Ashrams",
        "description": "The various ashrams in Shirdi offer tranquil environments conducive to retreats, workshops, and spiritual events. These serene spaces are perfect for groups looking to deepen their spiritual practice or for individuals seeking a peaceful getaway. The ashrams often provide guided meditation, yoga sessions, and spiritual teachings, fostering personal growth and community connection."
    },
    {
        "title": "9. Community Centers",
        "description": "Local community centers in Shirdi are vibrant hubs for cultural events, educational workshops, and family functions. These centers provide a welcoming space for gatherings of all sizes, encouraging community spirit and engagement. They often host local festivals and celebrations, making them a focal point for social interaction and cultural exchange in the region."
    },
    {
        "title": "10. Nearby Resorts",
        "description": "Several resorts in and around Shirdi are designed to cater to events, offering luxurious amenities for weddings, conferences, and retreats. Surrounded by beautiful landscapes, these resorts provide both comfort and elegance, enhancing the overall event experience. With professional event planning services, they ensure that every occasion is executed flawlessly, making them an attractive choice for both leisure and business travelers."
    }
],

    
            
        
"services": [
    {
        "name": "Cabs for Events in Shirdi",
        "description": "SaitirthTours & Travels offers dedicated cab services for all types of events in Shirdi. Whether you’re planning a wedding, festival, or corporate gathering, we provide reliable transportation solutions tailored to your needs. Our experienced drivers are well-versed in local routes and traffic patterns, ensuring timely arrivals and departures. With a focus on comfort and safety, our fleet includes a variety of vehicles that cater to any event size, allowing you to enjoy your special occasion without transportation worries."
    },
    {
        "name": "Shirdi Event Cab Service",
        "description": "Our event cab service ensures you and your guests arrive at your destination safely and on time. With a fleet of well-maintained vehicles, including sedans, SUVs, and luxury options, we cater to events of all sizes. Each vehicle is equipped with comfortable seating and air conditioning, creating an enjoyable travel experience. Our drivers are punctual and professional, helping to create a smooth transition from one location to another, so you can focus on your event's details."
    },
    {
        "name": "Event Transportation in Shirdi",
        "description": "For seamless event transportation in Shirdi, choose our professional services. We specialize in managing logistics for various occasions, ensuring that every guest enjoys a comfortable ride. Our team can assist with route planning, scheduling, and coordination, making sure that all aspects of transportation are handled efficiently. With our focus on customer service, we strive to accommodate any last-minute changes or requests, ensuring a hassle-free experience."
    },
    {
        "name": "Shirdi Event Shuttle Service",
        "description": "Our shuttle service is perfect for events with multiple guests. We provide scheduled pickups and drop-offs, making it easy for everyone to travel together and enjoy the festivities. Our shuttles are spacious and can accommodate large groups, ensuring that no one is left behind. We manage all logistics, including timing and routing, so you can focus on enjoying your event while we take care of the transportation."
    },
    {
        "name": "Shirdi Event Car Rental",
        "description": "Rent a car for your event in Shirdi with ease. Our rental options include various vehicles, from compact cars to spacious vans, ensuring you have the right transport for your specific needs. We offer flexible rental agreements and competitive pricing, allowing you to choose the duration and type of vehicle that best suits your event. Our user-friendly booking process makes it simple to secure your rental and plan your travel arrangements."
    },
    {
        "name": "Cab Service for Weddings in Shirdi",
        "description": "Make your special day stress-free with our wedding cab service in Shirdi. We offer transportation for the bridal party, guests, and vendors, ensuring everyone arrives punctually. Our fleet includes luxury vehicles for the bridal party and comfortable options for guests, catering to all needs. With attention to detail and a commitment to professionalism, we ensure that every transportation aspect of your wedding is handled with care, allowing you to enjoy your celebration."
    },
    {
        "name": "Shirdi Festival Taxi Service",
        "description": "Celebrate festivals in Shirdi without worrying about transportation. Our festival taxi service ensures you can focus on enjoying the festivities while we handle your travel arrangements. We understand the significance of festivals and are committed to providing timely and comfortable transportation to various events and venues, allowing you to fully immerse yourself in the celebrations without logistical stress."
    },
    {
        "name": "Corporate Event Cabs in Shirdi",
        "description": "For corporate events, our cab services provide reliable transportation for employees and clients. We offer flexible options to suit your schedule and preferences, whether it's for team-building exercises, meetings, or client visits. Our corporate transportation services include customized itineraries and professional drivers who understand the importance of punctuality and professionalism, ensuring a smooth experience for all attendees."
    },
    {
        "name": "Affordable Event Transportation Shirdi",
        "description": "Enjoy budget-friendly event transportation in Shirdi without compromising quality. We offer competitive rates, ensuring you get the best value for your transportation needs. Our transparent pricing policy means there are no hidden charges, allowing you to plan your budget effectively. With our focus on customer satisfaction, we provide high-quality service at prices that won't break the bank."
    },
    {
        "name": "Most Trusted Cab Services in Shirdi",
        "description": "With a reputation for reliability and professionalism, SaitirthTours & Travels is among the most trusted cab services in Shirdi. We prioritize customer satisfaction and strive to exceed your expectations with every ride. Our experienced team is dedicated to providing you with safe, comfortable, and timely transportation. We continuously seek feedback to improve our services and maintain the trust of our valued clients."
    },
    {
        "name": "Shirdi Car Rental Services for Events",
        "description": "Our car rental services are designed to accommodate any event in Shirdi. Choose from a variety of vehicles to ensure your travel experience is comfortable and convenient. Whether you need a compact car for a small gathering or a larger vehicle for transporting multiple guests, we have the right options available. Our flexible rental terms allow you to select the duration and type of vehicle that meets your event requirements."
    },
    {
        "name": "Bus for Events in Shirdi",
        "description": "For larger gatherings, we offer bus services that can accommodate many passengers. Ideal for weddings or corporate events, our buses provide ample space and comfort. We ensure that every guest travels together, enhancing the experience and fostering camaraderie among attendees. Our experienced drivers manage all aspects of the journey, including scheduling and route planning, so you can focus on your event."
    },
    {
        "name": "Innova Crysta on Rent in Shirdi for Corporate Events",
        "description": "Hire an Innova Crysta for your corporate event in Shirdi. This spacious and luxurious vehicle is perfect for transporting clients and executives. Equipped with modern amenities and comfortable seating, it provides an upscale travel experience that makes a great impression. Our attentive drivers ensure that your corporate guests receive a premium level of service throughout their journey."
    },
    {
        "name": "Ertiga Cab in Shirdi for Corporate Events",
        "description": "The Ertiga cab is an excellent choice for corporate events in Shirdi, offering comfort and style for small groups traveling together. With its versatile seating arrangements, it is perfect for team outings or client meetings, allowing everyone to travel in comfort. We ensure timely pickups and drop-offs, accommodating your corporate schedule while providing a pleasant ride."
    },
    {
        "name": "Bus Hire on Rent for Corporate Events in Shirdi",
        "description": "When you need to transport a larger group, our bus hire service is available for corporate events in Shirdi. We provide comfortable and spacious options for your convenience, allowing for effective team travel or client gatherings. Our buses are equipped with modern facilities to ensure a pleasant journey, and our drivers are experienced in handling corporate logistics, guaranteeing professionalism throughout the event."
    },
    {
        "name": "Tempo Traveller on Rent in Shirdi for Corporate Events",
        "description": "For group travel, consider renting a Tempo Traveller for your corporate event in Shirdi. This vehicle offers ample space for passengers and luggage, making it ideal for team outings or workshops. Our Tempo Travellers are designed for comfort, ensuring your group can travel together without compromising on space or convenience. We prioritize a smooth journey, so your team can relax and focus on the event ahead."
    },
    {
        "name": "Urbania Bus Hire in Shirdi for Corporate Events",
        "description": "Our Urbania bus hire service is perfect for corporate events, offering modern amenities and comfort for all passengers. With spacious seating and the ability to accommodate larger groups, this option is ideal for conferences or off-site meetings. Our team handles all logistics, ensuring timely arrivals and departures so you can concentrate on your business engagements."
    },
    {
        "name": "Event Bus in Shirdi for Weddings",
        "description": "For weddings, we provide dedicated event buses that transport guests from various locations to the wedding venue, ensuring a smooth experience for everyone. Our buses are equipped to handle the demands of wedding logistics, with room for both guests and their belongings. We coordinate pickups and drop-offs based on your schedule, allowing your guests to enjoy the celebration without the hassle of parking or navigating traffic."
    },
    {
        "name": "Cabs for Events in Shirdi Contact Number",
        "description": "For all your event transportation needs in Shirdi, reach out to SaitirthTours & Travels. Contact us at +91 9923879009 / 9922479009 for inquiries and bookings. Our dedicated team is ready to assist you with all aspects of your transportation, from planning to execution, ensuring that your event is not only successful but also memorable. We are committed to providing reliable and efficient solutions that enhance your overall experience."
    }
],
"tableData": [
        ["- Shirdi Event Cab Service", "- Cabs for Events in Shirdi"],
        ["- Event Transportation in Shirdi", "- Shirdi Event Taxi Service"],
        ["- Shirdi Event Shuttle Service", "- Shirdi Event Car Rental"],
        ["- Cab Service for Weddings in Shirdi", "- Shirdi Festival Taxi Service"],
        ["- Corporate Event Cabs in Shirdi", "- Affordable Event Transportation Shirdi"],
        ["- Most Trusted Cab Services in Shirdi", "- Shirdi Car Rental Services for Events"],
        ["- Bus for Events in Shirdi", "- Innova Crysta on Rent in Shirdi for Corporate Events"],
        ["- Ertiga Cab in Shirdi for Corporate Events", "- Bus Hire on Rent for Corporate Events in Shirdi"],
        ["- Tempo Traveller on Rent in Shirdi for Corporate Events", "- Urbania Bus Hire in Shirdi for Corporate Events"],
        ["- Event Bus in Shirdi for Weddings", "- SaitirthTours & Travels Shirdi"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Tailored Services for Events",
            "WhyChoosedescription": "At Saitirth Travels, we recognize that each event is unique, which is why we offer tailored transportation solutions designed to meet your specific requirements. From weddings to corporate events and religious ceremonies, our team works closely with you to understand your needs, ensuring that all logistical aspects are managed smoothly, and you can focus on enjoying your event."
        },
        {
            "WhyChooseheading": "Punctuality and Reliability",
            "WhyChoosedescription": "Punctuality is crucial when it comes to event transportation. Our drivers are trained to prioritize time management, ensuring that you and your guests arrive at your destination on schedule. We understand that delays can disrupt your event, which is why our reliable service is designed to eliminate any transportation-related stress."
        },
        {
            "WhyChooseheading": "Diverse Fleet of Vehicles",
            "WhyChoosedescription": "Our fleet of vehicles is extensive and diverse, catering to various group sizes and preferences. Whether you need a comfortable sedan for an intimate gathering or a spacious bus for larger groups, we have the right vehicle for you. Each vehicle is maintained to the highest standards to provide comfort and safety throughout your journey."
        },
        {
            "WhyChooseheading": "Professional Drivers",
            "WhyChoosedescription": "Our drivers are not just skilled behind the wheel; they are also trained in customer service, ensuring a friendly and professional experience for all passengers. With extensive knowledge of local routes and attractions, they can provide insights along the way, enhancing your overall travel experience."
        },
        {
            "WhyChooseheading": "Seamless Booking Process",
            "WhyChoosedescription": "We believe that booking your event transportation should be straightforward and hassle-free. Our intuitive online booking platform allows you to secure your vehicles quickly, while our customer support team is available to assist you with any questions or special requests, making the process as smooth as possible."
        },
        {
            "WhyChooseheading": "Flexible Scheduling",
            "WhyChoosedescription": "We understand that events often have specific timing requirements. That’s why we offer flexible scheduling options available 24/7, allowing you to choose departure times that suit your event’s agenda. Whether you need transportation at the crack of dawn or late at night, Saitirth Travels is here to accommodate your needs."
        },
        {
            "WhyChooseheading": "Safety First",
            "WhyChoosedescription": "The safety of our passengers is paramount. All our vehicles undergo rigorous inspections and regular maintenance, and we adhere to strict sanitization protocols to ensure a clean and safe environment. Our commitment to safety means you can travel with peace of mind, knowing that you are in good hands."
        },
        {
            "WhyChooseheading": "Affordable Solutions",
            "WhyChoosedescription": "We believe that quality event transportation should be accessible to everyone. Our pricing structure is designed to be competitive and transparent, with no hidden fees or surprise costs. We provide excellent value for your money, ensuring that you receive top-tier service without breaking the bank."
        }
    ]


        
  }


  const faqData = [
    {
        question: "What types of events do you provide cab services for?",
        answer: "We cater to a wide range of events, including weddings, corporate gatherings, religious ceremonies, and more."
    },
    {
        question: "How do I book a cab for an event?",
        answer: "You can book your cab easily through our website or by contacting our customer support team, who will assist you with your booking."
    },
    {
        question: "Can I reserve multiple vehicles for a large event?",
        answer: "Yes, we can arrange multiple vehicles to accommodate large groups or events."
    },
    {
        question: "What is the cancellation policy?",
        answer: "Our cancellation policy allows for changes and cancellations up to 24 hours before your scheduled pick-up. Please check our website for detailed information."
    },
    {
        question: "Are there any additional charges for event bookings?",
        answer: "Additional charges may apply depending on the distance traveled and the duration of the booking. For specific pricing details, please contact us."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept various payment options, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Are the vehicles cleaned before each event?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before each event to ensure passenger safety."
    },
    {
        question: "Can I request a specific type of vehicle for my event?",
        answer: "Absolutely! You can request a specific vehicle type when making your reservation, and we will do our best to accommodate your preferences."
    }
];

const testimonials = [
    {
        name: "Mrs. Kavita Sharma",
        text: "We hired Saitirth Travels for our wedding transportation, and they exceeded our expectations! The drivers were punctual, friendly, and the cars were in excellent condition. Highly recommend their services!"
    },
    {
        name: "Mr. Rajesh Naik",
        text: "Our corporate event transportation was handled flawlessly by Saitirth Travels. The drivers were professional, and the booking process was simple and efficient. Thank you for a great experience!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Cabs for Events in Shirdi | SaitirthTours & Travels</title>
    <meta name="description" content="Book reliable and affordable cabs for events in Shirdi. We offer event transportation, wedding taxis, corporate event cabs, and more. Rent cars, buses, or tempo travellers for any event in Shirdi." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Cabs for Events in Shirdi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book reliable and affordable cabs for events in Shirdi. We offer event transportation, wedding taxis, corporate event cabs, and more. Rent cars, buses, or tempo travellers for any event in Shirdi." />
    <meta property="og:url" content="https://www.saitirthcabs.com/cabs-for-events-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/cabs-for-events-in-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book reliable and affordable cabs for events in Shirdi. We offer event transportation, wedding taxis, corporate event cabs, and more. Rent cars, buses, or tempo travellers for any event in Shirdi.",
            "url": "https://www.saitirthcabs.com/cabs-for-events-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/cabs-for-events-in-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi event cab service",
                "Cabs for events in Shirdi",
                "Event transportation in Shirdi",
                "Shirdi event taxi service",
                "Shirdi event shuttle service",
                "Shirdi event car rental",
                "Cab service for weddings in Shirdi",
                "Shirdi festival taxi service",
                "Corporate event cabs Shirdi",
                "Affordable event transportation Shirdi",
                "Most trusted cab services in Shirdi",
                "Shirdi car rental services for events",
                "Bus for events in Shirdi",
                "Corporate event cabs in Shirdi",
                "Innova Crysta on rent in Shirdi for corporate event",
                "Ertiga cab in Shirdi for corporate event",
                "Bus hire on rent in corporate event in Shirdi",
                "Tempo traveller on rent in Shirdi for corporate event",
                "Urbania bus hire in Shirdi for corporate event",
                "Event bus in Shirdi for wedding",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/40.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Cabsforeventinshirdi;