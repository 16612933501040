
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdisightseeingcab() {



  const cardData =
  {
    keyword: ' Shirdi Sightseeing Cab',
    heading: 'Saitirth Travels: Shirdi Sightseeing Cab',
    headingDescription: 'When it comes to exploring the holy town of Shirdi, Saitirth Travels provides exceptional sightseeing cab services designed to make your journey enjoyable and hassle-free. Our dedicated drivers are knowledgeable about the local attractions and can help you discover the rich spiritual heritage and cultural charm of Shirdi and its surroundings.',
    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "1. Shirdi Sai Baba Temple",
            "description": "The centerpiece of Shirdi, this revered temple attracts millions of devotees each year. Built in honor of Sai Baba, the temple complex features a stunning idol of Baba that draws visitors into its divine aura. The daily rituals, especially the aarti (prayer ceremony), create an electrifying atmosphere filled with devotion. Don’t miss the opportunity to offer your prayers and feel the spiritual energy that permeates the temple."
        },
        {
            "title": "2. Samadhi Mandir",
            "description": "This sacred site is where Sai Baba was laid to rest, and it serves as a focal point for pilgrims. The Samadhi is adorned with flowers and lights, creating a serene and spiritual environment. Visitors often find solace in this peaceful ambiance, reflecting on Sai Baba's teachings of love, faith, and compassion. The serene gardens surrounding the mandir are perfect for quiet contemplation."
        },
        {
            "title": "3. Dwarkamai",
            "description": "Once the residence of Sai Baba, this mosque is deeply significant to his life and teachings. It features the sacred fire that Baba tended, known as the 'Nanda Deep,' which continues to burn even today. Visitors can admire the unique architecture of the mosque and absorb the rich history and spirituality that surround this beloved site."
        },
        {
            "title": "4. Chavadi",
            "description": "A short distance from the Samadhi Mandir, Chavadi served as Sai Baba's resting place on alternate nights. This simple yet significant site is where devotees gather to express their reverence. The serene environment encourages meditation and prayer, allowing visitors to connect with the spiritual essence of Sai Baba."
        },
        {
            "title": "5. Khandoba Mandir",
            "description": "Dedicated to Lord Khandoba, this ancient temple features stunning architecture with intricate carvings and vibrant murals. It is believed that Sai Baba himself visited this temple regularly. The temple's tranquil surroundings and historical significance make it a must-visit for those seeking a deeper understanding of the local culture and spirituality."
        },
        {
            "title": "6. Nanda Deep",
            "description": "Located near the Samadhi Mandir, this eternal flame symbolizes the presence of Sai Baba and serves as a reminder of his teachings. Devotees often light candles and offer prayers at this sacred site, making it a poignant spot for reflection and meditation."
        },
        {
            "title": "7. Shri Saibaba Sansthan Trust",
            "description": "This organization manages the temple and provides various facilities for devotees, including accommodations, food services, and medical assistance. It plays a vital role in ensuring the smooth functioning of the temple activities. Visitors can explore the trust’s initiatives that support the local community and promote the teachings of Sai Baba."
        },
        {
            "title": "8. Hanuman Mandir",
            "description": "A small yet significant temple dedicated to Lord Hanuman, this site offers a peaceful environment for prayer and reflection. The temple's simple architecture and serene ambiance provide a lovely retreat from the bustling town. Many devotees come here to seek blessings for strength and protection."
        },
        {
            "title": "9. Shirdi Water Park",
            "description": "For those traveling with family, this water park is a great place to unwind and enjoy some fun after visiting the religious sites. With various water slides, pools, and play areas, it offers a refreshing break. The park also features food stalls where you can indulge in local snacks, making it a perfect spot for relaxation and recreation."
        },
        {
            "title": "10. Local Markets",
            "description": "Stroll through the vibrant local markets where you can purchase religious souvenirs, handicrafts, and unique items that reflect the cultural heritage of Shirdi. Engaging with local artisans adds a personal touch to your visit, and you can find everything from Sai Baba idols to traditional clothing. Don’t forget to try the local street food, which is a delightful way to experience the local flavors."
        }
    ],    
    
    services: [
        {
            "name": "Best Cab Service in Shirdi",
            "description": "When it comes to exploring the spiritual haven of Shirdi, SaitirthTours & Travels offers the best cab service tailored to your travel needs. Our cabs are comfortable and reliable, ensuring a smooth journey whether you're visiting the iconic Shirdi Sai Baba Temple or exploring local attractions. With a dedicated team committed to customer satisfaction, we provide not just transportation but a holistic travel experience that highlights the beauty and spirituality of Shirdi."
        },
        {
            "name": "Reliable Taxi Service in Shirdi",
            "description": "Our reliable taxi service in Shirdi guarantees punctuality and safety, making us the preferred choice for both pilgrims and tourists. With experienced drivers who know the local routes inside out, you can rest assured that you will reach your destination on time and enjoy a stress-free journey. Whether you're in need of a quick ride to the temple or a full-day exploration of the area, our reliable service ensures you never miss a moment of your spiritual journey."
        },
        {
            "name": "Affordable Cab Service in Shirdi",
            "description": "Traveling on a budget? Our affordable cab service in Shirdi is designed to provide quality transportation without breaking the bank. We offer a variety of packages that cater to diverse needs, allowing you to experience the best of Shirdi without worrying about high transportation costs. Our transparent pricing ensures that you get great value for your money, making it easier to enjoy your pilgrimage or sightseeing experience without financial stress."
        },
        {
            "name": "Shirdi Car Rental Options",
            "description": "For those who prefer flexibility, our Shirdi car rental options allow you to hire a vehicle for a day or longer. Explore Shirdi at your own pace with our well-maintained cars that ensure a comfortable and safe driving experience. Choose from a range of vehicles to suit your travel preferences, and enjoy the freedom to customize your itinerary while we handle the logistics."
        },
        {
            "name": "Top Cab Company in Shirdi",
            "description": "As the top cab company in Shirdi, we prioritize customer satisfaction above all else. Our fleet includes various vehicles suitable for solo travelers, families, and groups, all equipped with modern amenities to enhance your journey. We pride ourselves on our professional drivers and our commitment to providing a seamless travel experience, ensuring that every trip is memorable."
        },
        {
            "name": "Taxi Booking in Shirdi",
            "description": "Booking a taxi in Shirdi has never been easier! Our online taxi booking service allows you to reserve your ride with just a few clicks, ensuring a seamless travel experience from start to finish. With real-time availability and instant confirmation, you can plan your trip with confidence, knowing that your transportation needs are taken care of."
        },
        {
            "name": "Shirdi Cab Transportation Service",
            "description": "Our Shirdi cab transportation service covers all essential routes and attractions, from bustling local markets to serene temples. We ensure you visit all the highlights of Shirdi with ease and comfort. Our drivers are not only skilled navigators but also provide local insights, making your journey informative and engaging."
        },
        {
            "name": "Comfortable Cabs in Shirdi",
            "description": "Comfort is our priority. Our comfortable cabs in Shirdi are designed for a relaxing journey for every passenger. Whether you’re traveling alone or with companions, expect a smooth ride and friendly service. Our vehicles are regularly maintained and cleaned, ensuring a pleasant atmosphere for your travels around this holy city."
        },
        {
            "name": "Shirdi Taxi Hire",
            "description": "Looking for a Shirdi taxi hire service? We offer flexible hiring options to accommodate your travel itinerary, ensuring our dedicated team is ready to assist with all your transportation needs. Whether it's a quick trip or an extended journey, our taxi hire service is designed to provide convenience and reliability throughout your stay in Shirdi."
        },
        {
            "name": "Best Taxi Service in Shirdi",
            "description": "At SaitirthTours & Travels, we take pride in being the best taxi service in Shirdi. Our knowledgeable drivers are familiar with local attractions, ensuring you get the most out of your visit while enjoying reliability and convenience. We focus on delivering an exceptional customer experience, which includes helpful travel tips and guidance to enhance your journey."
        },
        {
            "name": "Innova Crysta on Rent in Shirdi",
            "description": "Traveling with a group? Our Innova Crysta on rent in Shirdi is an excellent choice for larger parties. This spacious vehicle allows families to travel together comfortably, enjoying ample legroom and modern amenities. Perfect for longer trips or family outings, the Innova Crysta is designed for comfort and convenience, making your travels as enjoyable as possible."
        },
        {
            "name": "Tempo Traveller on Rent in Shirdi",
            "description": "For larger groups, we offer the best tempo traveller on rent in Shirdi. Ideal for family outings or group pilgrimages, our tempo travellers are well-equipped to ensure a comfortable journey for everyone. With ample space for luggage and a seating arrangement designed for relaxation, you can share your spiritual experience with friends and family without any hassles."
        },
        {
            "name": "Online Cab Booking in Shirdi",
            "description": "Experience the convenience of our online cab service in Shirdi. With just a few clicks, you can book a cab that suits your travel needs, allowing you to focus on enjoying your spiritual journey. Our user-friendly platform offers real-time availability, making it easy to plan your travel schedule while ensuring a hassle-free experience from the moment you arrive."
        },
        {
            "name": "Outstation Taxi in Shirdi",
            "description": "Planning to explore nearby destinations? Our outstation taxi in Shirdi service is perfect for day trips or extended excursions. Whether you want to visit Nasik, Aurangabad, or any other nearby location, we provide comfortable and reliable transportation. Our drivers are well-versed in the routes, ensuring you get to your destination quickly and safely while enjoying the scenic views along the way."
        },
        {
            "name": "Contact Information for Best Cab Service in Shirdi",
            "description": "Contact SaitirthTours & Travels at +91 9923879009 or +91 9922479009 for prompt and efficient cab service in Shirdi. Our friendly team is available to assist you with inquiries, bookings, and any special requests, ensuring a smooth and enjoyable ride for all our customers, making your journey memorable. We look forward to being part of your Shirdi experience!"
        }
    ],

    "tableData": [
        ["- Shirdi Car Rental", "- Rent a car in Shirdi with Driver"],
        ["- SaitirthTours & Travels Shirdi", "- Shirdi Taxi Rental for Local Sightseeing"],
        ["- Car Hire Shirdi", "- Affordable Car Rental Shirdi"],
        ["- Rental Car Shirdi Sai Baba Temple", "- Best Car Rental Shirdi"],
        ["- Shirdi Airport Car Rental", "- Shirdi Car Rental"],
        ["- Shirdi Cab Service", "- Cab Booking in Shirdi"],
        ["- Car Hire in Shirdi Rent a car for Shirdi", "- Shirdi Car Hire for Outstation Cabs"],
        ["- Shirdi Taxi Cab Service", "- Travel Agents in Shirdi"],
        ["- Tours and Travels in Shirdi", "- Best Travel Agency in Shirdi"],
        ["- Shirdi Cab Booking Service", "- Shirdi Saibaba Travels"]
      ],
      "whychoose": [
        {
          "WhyChooseheading": "Why Choose Saitirth Travels Shirdi Car Rental?",
          "WhyChoosedescription": "At Saitirth Travels, we strive to provide an exceptional car rental experience in Shirdi, ensuring that your journey is as memorable as your destination. Our commitment to customer satisfaction and quality service makes us a trusted choice for travelers. Here’s why you should choose us:"
        },
        {
          "WhyChooseheading": "Wide Range of Vehicles:",
          "WhyChoosedescription": "We offer a diverse fleet of well-maintained cars, ranging from economical sedans to luxurious SUVs, allowing you to select the perfect vehicle tailored to your specific needs and budget. Whether you're traveling alone or with a group, we have options that provide comfort, style, and reliability for every type of journey, including city tours, long-distance travel, or special occasions."
        },
        {
          "WhyChooseheading": "Affordable Rates:",
          "WhyChoosedescription": "Our competitive pricing structure ensures you receive the best value for your money without compromising on the quality of service. We believe that excellent transportation should be accessible to everyone, which is why we offer transparent pricing with no hidden fees. Our pricing options are flexible, accommodating different budgets while ensuring a high level of service that enhances your travel experience."
        },
        {
          "WhyChooseheading": "Flexible Rental Periods:",
          "WhyChoosedescription": "Whether you need a car for a few hours, a day, or an extended rental period, we provide flexible options designed to accommodate your travel plans. You can customize your rental duration based on your itinerary, allowing you the freedom to explore Shirdi and surrounding areas at your own pace without any constraints."
        },
        {
          "WhyChooseheading": "Easy Booking Process:",
          "WhyChoosedescription": "Our user-friendly online booking system makes reserving your car straightforward and hassle-free. You can complete your reservation in just a few clicks, with options to choose your vehicle, rental duration, and additional services. For added convenience, our customer support team is available via phone or chat to assist you with any questions or modifications you may need during the booking process."
        },
        {
          "WhyChooseheading": "Transparent Policies:",
          "WhyChoosedescription": "We pride ourselves on our clear and transparent rental policies, ensuring you know exactly what to expect without any surprises. Our agreements are straightforward, with all fees clearly outlined upfront, so you can plan your budget without worries. This commitment to transparency helps us build trust with our customers and ensures a smooth rental experience from start to finish."
        },
        {
          "WhyChooseheading": "24/7 Customer Support:",
          "WhyChoosedescription": "Our dedicated support team is available around the clock to assist you with any questions or concerns you might have before, during, and after your rental. Whether you need help with your booking, have inquiries about our services, or require assistance during your rental, our friendly and knowledgeable staff are just a call away, ensuring you receive prompt and efficient support."
        },
        {
          "WhyChooseheading": "Safety and Maintenance:",
          "WhyChoosedescription": "The safety of our customers is our top priority. All our vehicles undergo regular maintenance checks and thorough cleaning to provide you with a safe and comfortable ride throughout your journey. We conduct comprehensive inspections of our fleet to ensure each car meets our high standards for safety and reliability, allowing you to travel with peace of mind."
        },
        {
          "WhyChooseheading": "Customized Sightseeing Tours:",
          "WhyChoosedescription": "We understand that every traveler has unique interests. That's why we provide customized sightseeing packages that cover all major attractions in and around Shirdi, such as the Sai Baba Temple, Dwarkamai, and other significant temples and landmarks. Whether you want a comprehensive tour or just a few highlights, we ensure you don’t miss any essential experiences during your visit."
        },
        {
          "WhyChooseheading": "Experienced Drivers:",
          "WhyChoosedescription": "Our drivers are not just skilled behind the wheel; they are knowledgeable locals who can provide valuable insights and interesting stories about the places you visit. Their familiarity with the area allows them to recommend hidden gems and local dining spots, enriching your sightseeing experience and helping you make the most of your time in Shirdi."
        },
        {
          "WhyChooseheading": "Comfortable Vehicles:",
          "WhyChoosedescription": "Travel in style and comfort with our fleet of well-maintained, air-conditioned vehicles. We cater to all group sizes, from solo travelers to large families, ensuring that everyone has a comfortable ride. Our cars are equipped with modern amenities to make your journey enjoyable and stress-free, allowing you to relax and focus on the beautiful sights around you."
        },
        {
          "WhyChooseheading": "Flexible Itineraries:",
          "WhyChoosedescription": "We believe that flexibility is key to a satisfying travel experience. With Saitirth Travels, you have the freedom to create your own itinerary or choose from our suggested tours, allowing you to explore at your own pace. Whether you want to spend more time at the temple, enjoy local cuisine, or visit nearby attractions, we accommodate your preferences for a personalized experience."
        },
        {
          "WhyChooseheading": "Affordable Pricing:",
          "WhyChoosedescription": "We are committed to providing exceptional value without compromising on quality. Our competitive pricing structure ensures you receive the best rates for our sightseeing cab services. We believe in transparency, so there are no hidden fees—what you see is what you pay. This approach makes excellent transportation accessible to everyone, enhancing your travel experience while respecting your budget."
        },
        {
          "WhyChooseheading": "Safety First:",
          "WhyChoosedescription": "Your safety is our utmost priority. All our vehicles undergo regular maintenance checks and thorough cleaning protocols to ensure a safe and comfortable ride. We comply with all safety regulations and guidelines, giving you peace of mind during your travels. Our drivers are trained to handle all situations with professionalism, ensuring a secure environment throughout your journey."
        },
        {
          "WhyChooseheading": "24/7 Availability:",
          "WhyChoosedescription": "Travel plans can be unpredictable, which is why we offer 24/7 availability for your sightseeing needs. Whether it’s an early morning start to catch the sunrise at the temple or a late evening tour, we are here to accommodate your schedule. Our team is always ready to assist you, ensuring that you have access to transportation whenever you need it."
        }
      ]
  }


  const faqData = [
    {
      question: "What attractions are included in your sightseeing tours?",
      answer: "Our tours typically include key attractions like the Sai Baba Temple, Shirdi Museum, Dwarkamai, and other local points of interest. Customized tours can also be arranged."
    },
    {
      question: "How do I book a sightseeing cab?",
      answer: "You can easily book a sightseeing cab through our website or by contacting our customer support team, who will assist you with your itinerary."
    },
    {
      question: "Is it possible to hire a cab for a full day?",
      answer: "Yes, we offer full-day cab rentals for sightseeing, allowing you to explore multiple locations at your own pace."
    },
    {
      question: "Can I change my itinerary after booking?",
      answer: "Yes, we can accommodate changes to your itinerary, provided you inform us in advance."
    },
    {
      question: "What are the payment options available?",
      answer: "We accept various payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
      question: "Are there any additional charges for waiting time?",
      answer: "Yes, waiting time beyond the agreed duration may incur additional charges. Please check our terms for specifics."
    },
    {
      question: "Is the driver knowledgeable about the local attractions?",
      answer: "Absolutely! Our drivers are familiar with the local area and can provide you with valuable information about the sights."
    },
    {
      question: "What happens if I need to cancel my booking?",
      answer: "Our cancellation policy allows for changes and cancellations up to 24 hours before your scheduled booking. Please check our website for details."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Rakesh Jain",
      text: "I had an amazing experience with Saitirth Travels for my sightseeing tour in Shirdi. The driver was friendly and knowledgeable, and I got to see all the important sites comfortably!"
    },
    {
      name: "Mrs. Neha Patel",
      text: "Saitirth Travels provided excellent service for our sightseeing cab. The vehicle was clean, and our driver was very accommodating. Highly recommend!"
    }
  ];
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Sightseeing Cab | SaitirthTours & Travels</title>
    <meta name="description" content="Explore Shirdi with reliable and affordable sightseeing cab services. Book a local taxi for Shirdi Darshan, temple tours, and nearby attractions with SaitirthTours & Travels." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Sightseeing Cab | SaitirthTours & Travels" />
    <meta property="og:description" content="Explore Shirdi with reliable and affordable sightseeing cab services. Book a local taxi for Shirdi Darshan, temple tours, and nearby attractions with SaitirthTours & Travels." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-sightseeing-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-sightseeing-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Explore Shirdi with reliable and affordable sightseeing cab services. Book a local taxi for Shirdi Darshan, temple tours, and nearby attractions with SaitirthTours & Travels.",
            "url": "https://www.saitirthcabs.com/shirdi-sightseeing-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-sightseeing-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Sightseeing Cabs", 
                "Cab for Shirdi Tour", 
                "Shirdi Local Cab Service", 
                "Shirdi Sightseeing Taxi", 
                "Taxi for Shirdi Darshan", 
                "Shirdi Temple Cab Service", 
                "Cab Rental Shirdi Sightseeing", 
                "Best Cab for Shirdi Visit", 
                "Shirdi Tourist Cab", 
                "Shirdi Local Cabs", 
                "Local Taxi Service in Shirdi", 
                "Shirdi Local Sightseeing Packages", 
                "Shirdi One Day Tour Package", 
                "Shirdi Local Tour Packages", 
                "Shirdi Local Tour Operators", 
                "Best Travel Agency in Shirdi", 
                "Shirdi Travels Contact Number", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Domestic Airport Taxi Service", 
                "Shirdi Nearby Places to Visit with Shirdi Saibaba Travels", 
                "Tourist Places Near Shirdi Within 50 km", 
                "Shirdi Local Sightseeing Taxi Booking Online", 
                "Shirdi Local Travels Contact Number"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/18.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdisightseeingcab;