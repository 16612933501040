import React from 'react';
import '../css/Blog.css'; 
import { Helmet } from 'react-helmet';
import GoogleTag from './GoogleTag';

const BlogPost = ({ title, date, excerpt, link, image }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className="blog-post p-4 shadow-lg rounded">
                <img src={image} alt={title} className="img-fluid rounded mb-3" />
                <h5 className="blog-title">
                    <a href={link} className="text-white">{title}</a>
                </h5>
                <p className="text-muted">{date}</p>
                <p>{excerpt}</p>
            </div>
        </div>
    );
};

const BlogSection = () => {
    const posts = [
        {
            title: "Exploring the Scenic Routes of India",
            excerpt: "Discover the most beautiful routes for your next road trip in India. From mountains to beaches, we've got you covered.",
            image: "/images/blog-1.webp"
        },
        {
            title: "Top Travel Destinations to Explore in Maharashtra",
            excerpt: "Highlight popular tourist spots in Maharashtra like Lonavala, Mahabaleshwar, Shirdi, and Pune.",
            image: "/images/blog-2.webp" 
        },
        {
            title: "Shirdi Darshan Taxi",
            excerpt: "Book a Shirdi Darshan Taxi for a convenient and comfortable tour of Shirdi's most sacred sites, including the Sai Baba Samadhi Mandir and other key attractions!",
            image: "/images/blog-6.webp" 
        },
        {
            title: "Shirdi Sightseeing Cab",
            excerpt: "Explore Shirdi Sightseeing Cab and visit top spots like Sai Baba Samadhi Mandir, Dwarkamai Mosque, and Chavadi for a peaceful and memorable experience!",
            image: "/images/keyword-img/18.jpg"
        },
        {
            title: "Shirdi to Shani Shingnapur Cab",
            excerpt: "Book a Shirdi to Shani Shingnapur Cab and visit the famous Shani Shingnapur Temple for a smooth and memorable trip!",
            image: "/images/keyword-img/32.jpg" 
        },
        {
            title: "Shirdi Airport Cabs",
            excerpt: "Book Shirdi Airport Cabs for a hassle-free and comfortable ride to and from Shirdi Airport. Enjoy a smooth journey with reliable and convenient cab services!",
            image: "/images/keyword-img/87.jpg" 
        },
        
        {
            title: "Shirdi Airport to Shirdi Temple Taxi Fare",
            excerpt: "Check the Shirdi Airport to Shirdi Temple Taxi Fare for an affordable and convenient ride from Shirdi Airport to the Sai Baba Samadhi Mandir. Enjoy a comfortable and hassle-free journey!",
            image: "/images/keyword-img/26.jpg" 
        },
        {
            title: "Cabs for Events in Shirdi",
            excerpt: "Book Cabs for Events in Shirdi and enjoy a comfortable, reliable, and convenient ride to your special occasion. Whether it's a wedding, conference, or family gathering, we ensure you travel in style and comfort.",
            image: "/images/keyword-img/40.jpg" 
        },
        {
            title: "Shirdi to Mumbai Cabs",
            excerpt: "Book Shirdi to Mumbai Cabs for a smooth and comfortable journey between the two cities. Enjoy a hassle-free ride with reliable and professional service!",
            image: "/images/keyword-img/20.jpg" 
        },
        
    ];

    return (
        <div>
<GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

<section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title">Blog</h1>
              
            </div>
        </div>
    </section>

            <section className="blog-section py-5">
                <div className="container">
                    {/* <h2 className="text-center mb-4">Saitirth Travels Blog</h2> */}
                    <div className="row text-white">
                        {posts.map((post, index) => (
                            <BlogPost 
                                key={index} 
                                title={post.title} 
                                date={post.date} 
                                excerpt={post.excerpt} 
                                image={post.image} 
                            />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BlogSection;
