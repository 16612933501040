import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/TermsConditions.css'; // Ensure this CSS file includes the new styles
import GoogleTag from './GoogleTag';

const TermsConditions = () => {
  return (
    <div>
     <GoogleTag/>
      
      <Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h1 className="title">Terms & Conditions</h1>
          </div>
        </div>
      </section>

      <section className="terms-section">
        <div className="container">
          <div className="row g-0">
            {/* Text Content Column */}
            <div className="col-md-7">
              <div className="terms-content">
                <p><b className="highlight">1. Booking Confirmation:</b> All bookings with Saitirth Tour & Travels(Saitirth Cabs) are confirmed upon successful payment and issuance of a booking reference number.</p>

                <p><b className="highlight">2. Payment:</b> Full payment is required at the time of booking. Payments can be made through our website or at our authorized offices.</p>

                <p><b className="highlight">3. Cancellation and Rescheduling:</b> Cancellations and rescheduling requests are subject to our cancellation policy. Fees may apply based on the timing of the request.</p>

                <p><b className="highlight">4. Passenger Responsibility:</b> Passengers must carry valid identification and booking confirmation. We reserve the right to refuse service without proper documentation.</p>

                <p><b className="highlight">5. Baggage Policy:</b> Each ticket allows a limited amount of baggage. Additional baggage may incur extra charges.</p>

                <p><b className="highlight">6. Travel Insurance:</b> We recommend purchasing travel insurance to cover unforeseen circumstances during your trip.</p>

                <p><b className="highlight">7. Safety Regulations:</b> Passengers must adhere to all safety regulations and follow instructions from our staff at all times.</p>

                <p><b className="highlight">8. Changes to Itinerary:</b> Saitirth Tour & Travels(Saitirth Cabs) reserves the right to alter itineraries due to unforeseen circumstances beyond our control.</p>

                <p><b className="highlight">9. Refusal of Service:</b> We may refuse service to passengers exhibiting disruptive behavior or violating our terms of service.</p>

                <p><b className="highlight">10. Jurisdiction:</b> These terms are governed by the laws of India. Any disputes will be resolved in the jurisdiction of Mumbai.</p>

                <h3>General Policy</h3>
                <p><b className="highlight">Booking Procedure:</b> Customers can book services through our website or by contacting our customer service.</p>
                <p><b className="highlight">Customer Support:</b> Our support team is available to assist with any inquiries and requests regarding bookings.</p>

                <h3>Refund Policy</h3>
                <p><b className="highlight"></b> • If you cancel the trip, the cancellation charges will be applicable as per the above mentioned cancellation and rfuend policy of Saitirth Cabs                </p>
                <p><b className="highlight"></b>• If eligible for refunds based on the “Cancellation & Refund Policy” as mentioned above, then the refund will be returned within 5-7 working days.</p>
              </div>
            </div>

            {/* Image Column */}
            <div className="col-md-5">
              <div className="image-container">
                <img src="/images/term.webp" alt="Terms and Conditions" className="terms-image" />
              </div>
            </div>
          </div>

          <div className="contact-info">
            <h2>Contact Us</h2>
            <p>If you have any questions regarding our terms, please reach out:</p>
            <ul>
              <li><b>Phone:</b> <a href="tel:+919923879009">+91 9923879009</a></li>
              <li><b>Phone:</b> <a href="tel:+919922479009">+91 9922479009</a></li>
              <li><b>Email:</b> <a href="mailto:booking@saitirthcabs.com">booking@saitirthcabs.com</a></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsConditions;
