
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Swiftdezire() {



  const cardData =
  {
    keyword: ' Swift Dzire on Rent in Shirdi',
    heading: 'Saitirth Travels: Swift Dzire on Rent in Shirdi',
    headingDescription: 'Saitirth Travels offers affordable and comfortable Swift Dzire car rental services in Shirdi, perfect for pilgrims and tourists looking to explore the spiritual and scenic beauty of the region. Whether you are traveling solo or with family, our Swift Dzire provides a smooth ride and ample space for luggage.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The spiritual heart of Shirdi, this temple is dedicated to Sai Baba and attracts thousands of devotees daily. The temple complex is designed in a beautiful architectural style, blending elements of Hindu and Islamic designs, symbolizing Baba's teachings of unity and love. Visitors can participate in various rituals and ceremonies, including the famous 'Aarti' and 'Naivedya' offerings, creating a profound spiritual experience."
        },
        {
            "title": "Dwarkamai",
            "description": "Dwarkamai is a mosque where Sai Baba spent much of his life, and it holds deep significance for his followers. The mosque is adorned with historical artifacts and features the sacred dhuni (eternal flame) that Sai Baba lit and tended. Pilgrims come to pay their respects, and the atmosphere is filled with devotion, making it an essential part of the Shirdi pilgrimage."
        },
        {
            "title": "Chavadi",
            "description": "Chavadi is the site where Sai Baba used to stay every alternate night. This place is renowned for its beautiful architecture, characterized by intricate wooden carvings and a charming ambiance. The Chavadi holds a special significance as it was where Baba would share his wisdom and teachings with devotees, and it is often adorned with flowers and lights during festivals, creating a festive spirit."
        },
        {
            "title": "Lendi Garden",
            "description": "Lendi Garden is a serene garden with lush greenery, perfect for relaxation and contemplation after visiting the temple. This tranquil space features pathways lined with trees, fountains, and peaceful seating areas where visitors can meditate and reflect on their spiritual journey. The garden is often visited by devotees seeking a moment of peace amidst the bustling atmosphere of the pilgrimage."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "Located nearby, Shri Khandoba Mandir is a significant temple dedicated to Lord Khandoba, a revered deity in Maharashtra. The temple offers stunning views of the surrounding landscape and is a popular pilgrimage site for those seeking blessings and guidance. Its vibrant atmosphere during festivals attracts many devotees, enhancing the spiritual experience of the area."
        },
        {
            "title": "Maruti Mandir",
            "description": "Maruti Mandir is dedicated to Lord Hanuman, known for its peaceful environment and beautiful idol. This temple serves as a spiritual haven for visitors, where they can offer prayers and seek blessings for strength and courage. The calm ambiance encourages contemplation and devotion, making it a favored stop for many pilgrims after their visit to the main Sai Baba Temple."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is the site where Sai Baba was first seen by devotees, featuring a small shrine that attracts many visitors. This sacred place is marked by a large stone, believed to be where Baba meditated. Devotees come here to connect with Baba's legacy, seeking blessings and a deeper understanding of his teachings, making it a vital part of the Shirdi pilgrimage."
        },
        {
            "title": "Sainath Mahal",
            "description": "Sainath Mahal is a guesthouse managed by the Sai Baba Sansthan Trust, offering convenient accommodation for devotees. Located within walking distance from the main temple, it provides basic amenities and a comfortable stay, allowing pilgrims to immerse themselves fully in their spiritual experience. The guesthouse often has a warm and welcoming atmosphere, fostering a sense of community among visitors."
        },
        {
            "title": "Khandoba Temple",
            "description": "Khandoba Temple, another significant temple dedicated to Khandoba, offers a vibrant atmosphere filled with devotion. This temple is frequented by devotees seeking blessings for their spiritual journeys. Its lively ambiance, especially during festivals, draws many visitors, enhancing the cultural and religious experience of the area."
        },
        {
            "title": "Sai Heritage Village",
            "description": "Sai Heritage Village is a cultural center showcasing the life and teachings of Sai Baba. This center provides visitors with insight into Baba's spiritual legacy through exhibitions, artifacts, and storytelling. The environment is designed to foster understanding and appreciation of Sai Baba's teachings, making it a meaningful stop for both devotees and those interested in learning more about his impact."
        }
    ],  
    
            
        
    services: [
        {
            "name": "Sainagar Shirdi to Shirdi Cabs",
            "description": "Our Sainagar Shirdi to Shirdi cab service provides quick and efficient transfers, ensuring you get to your destination promptly and comfortably."
        },
        {
            "name": "Sainagar Shirdi Railway Station to Shirdi Taxi",
            "description": "We offer reliable taxi services from Sainagar Shirdi Railway Station to Shirdi, making your travel easy after a long journey."
        },
        {
            "name": "Shirdi to Sainagar Shirdi Railway Station Taxi Services",
            "description": "Our taxi services from Shirdi to Sainagar Shirdi Railway Station guarantee timely pickups and drop-offs, allowing you to relax and focus on your trip."
        },
        {
            "name": "Hire Cab from Sainagar to Shirdi",
            "description": "Easily hire a cab from Sainagar to Shirdi with us, enjoying a smooth ride in well-maintained vehicles."
        },
        {
            "name": "Sainagar Railway Station to Shirdi Temple Taxi Fare",
            "description": "We offer competitive and transparent pricing for taxi rides from Sainagar Railway Station to the Shirdi Temple, ensuring affordability."
        },
        {
            "name": "Sainagar Shirdi Railway Station to Sai Baba Temple Cabs",
            "description": "Our cab service provides direct transport from Sainagar Railway Station to Sai Baba Temple, making your spiritual journey hassle-free."
        },
        {
            "name": "Sainagar Shirdi Railway Station to Shirdi Airport Cabs",
            "description": "Travel conveniently from Sainagar Shirdi Railway Station to Shirdi Airport with our reliable cab services designed for your comfort."
        },
        {
            "name": "Sainagar to Shani Shingnapur Taxi Service",
            "description": "Our taxi service takes you from Sainagar to Shani Shingnapur, ensuring you enjoy a safe and comfortable journey to this popular pilgrimage site."
        },
        {
            "name": "Sainagar Shirdi to Shirdi MIDC Taxi Service",
            "description": "We provide dedicated taxi services from Sainagar Shirdi to Shirdi MIDC, catering to business travelers with reliable and efficient transport."
        },
        {
            "name": "Sainagar Shirdi Innova Taxi Service",
            "description": "For larger groups, our Innova taxi service from Sainagar to Shirdi offers spacious and comfortable rides, perfect for family or group travels."
        },
        {
            "name": "Sainagar to Shirdi Tempo Traveller on Rent",
            "description": "Rent a tempo traveler for your journey from Sainagar to Shirdi, accommodating larger groups with ease and comfort."
        },
        {
            "name": "SaitirthTours & Travels Shirdi",
            "description": "Choose SaitirthTours & Travels for all your travel needs, offering reliable and quality services for your journeys from Shirdi."
        }
    ],        
    
    tableData: [
        ["- Swift Dzire On Rent in Shirdi", "- Hire Swift Dzire in Shirdi"],
        ["- Sedan Car on Rent in Shirdi", "- Maruti Suzuki Swift Dzire-Car Rental in Shirdi"],
        ["- Swift Dzire On Rent in Shirdi - Book Now", "- Shirdi to Bhimashankar Swift Dzire on Rent"],
        ["- Shirdi to Trimbakeshwar Swift Dzire on Rent", "- Shirdi to Nashik Trimbakeshwar Taxi Services"],
        ["- Shirdi to Trimbakeshwar Tour Package", "- Shirdi Outstation Taxi Dzire Cab Service"],
        ["- 5 Seater Car on Rent in Shirdi", "- Shirdi to Nashik Swift Dzire on Rent"],
        ["- Shirdi to Aurangabad Swift Dzire Hire", "- Shirdi Airport Swift Dzire on Rent"],
        ["- SaitirthTours & Travels Shirdi", ""]
    ],
    whychoose: [
        {
          "WhyChooseheading": "Comfortable Ride",
          "WhyChoosedescription": "The Swift Dzire offers a spacious interior and comfortable seating, making it an ideal choice for both short trips and long journeys around Shirdi."
        },
        {
          "WhyChooseheading": "Affordable Rates",
          "WhyChoosedescription": "We provide competitive rental rates for the Swift Dzire without compromising on quality, ensuring you get value for your money."
        },
        {
          "WhyChooseheading": "Well-Maintained Vehicles",
          "WhyChoosedescription": "Our fleet is regularly serviced and well-maintained, ensuring reliability and safety during your travels."
        },
        {
          "WhyChooseheading": "Easy Booking Process",
          "WhyChoosedescription": "Booking a Swift Dzire is quick and hassle-free through our user-friendly website or by contacting our customer service."
        },
        {
          "WhyChooseheading": "Experienced Drivers",
          "WhyChoosedescription": "Our drivers are experienced and knowledgeable about the local area, providing you with a safe and enjoyable ride."
        },
        {
          "WhyChooseheading": "24/7 Customer Support",
          "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any inquiries or booking modifications."
        },
        {
          "WhyChooseheading": "Flexible Rental Options",
          "WhyChoosedescription": "We offer flexible rental options, including hourly, daily, and long-term rentals, to suit your travel needs."
        },
        {
          "WhyChooseheading": "Local Insights",
          "WhyChoosedescription": "Our drivers can offer local insights and recommendations for places to visit in and around Shirdi, enhancing your travel experience."
        },
        {
          "WhyChooseheading": "Punctuality Guaranteed",
          "WhyChoosedescription": "We value your time and guarantee on-time pickups and drop-offs."
        },
        {
          "WhyChooseheading": "Customer Satisfaction",
          "WhyChoosedescription": "We strive for excellence in service, and our positive customer feedback reflects our commitment to quality."
        }
    ]
    




        
  }


  const faqData = [
    {
        question: "How can I rent a Swift Dzire in Shirdi?",
        answer: "You can easily rent a Swift Dzire by booking through our website or contacting our customer service team."
    },
    {
        question: "What is included in the rental price?",
        answer: "Our rental price typically includes vehicle maintenance and insurance. Additional charges may apply for fuel or extra services."
    },
    {
        question: "Do I need a valid driving license to rent the vehicle?",
        answer: "Yes, a valid driving license is required to rent a Swift Dzire. International licenses are also accepted."
    },
    {
        question: "Is there a mileage limit on rentals?",
        answer: "Yes, we have a mileage policy. Please check our terms for specific mileage limits based on your rental duration."
    },
    {
        question: "Can I extend my rental period?",
        answer: "Yes, you can extend your rental period based on vehicle availability. Please contact us as soon as possible to make arrangements."
    },
    {
        question: "What should I do if I have a breakdown?",
        answer: "In case of a breakdown, please contact our customer support team immediately for assistance."
    },
    {
        question: "Are there any additional fees for extra drivers?",
        answer: "Yes, there may be additional fees for adding extra drivers. Please inquire when making your booking."
    },
    {
        question: "Can I return the vehicle at a different location?",
        answer: "Our standard policy is to return the vehicle to the original pickup location, but please contact us to discuss any special requests."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We offer a flexible cancellation policy. Please refer to our terms for details or contact our customer service for assistance."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at: \nPhone: +91 9923879009 / +91 9922479009 \nEmail: info@www.saitirthcabs.com"
    }
];

const testimonials = [
    {
        name: "Mr. Amit Sharma",
        text: "My experience renting a Swift Dzire from Saitirth Travels in Shirdi was fantastic! The car was in impeccable condition and provided a smooth ride throughout my trip. The staff was incredibly helpful and made the whole rental process seamless. I highly recommend Saitirth Travels for anyone needing a reliable vehicle in Shirdi!"
    },
    {
        name: "Ms. Priya Desai",
        text: "I had a wonderful time using Saitirth Travels for my Swift Dzire rental during my visit to Shirdi. The vehicle was comfortable and perfect for my family. The team was professional and attentive, ensuring I had everything I needed for a great trip. I’ll definitely be using their services again in the future!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Swift Dzire On Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Rent a Swift Dzire in Shirdi with SaitirthTours & Travels. Offering Swift Dzire rental services for local and outstation trips, including trips to Bhimashankar, Trimbakeshwar, and Nasik." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Swift Dzire On Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Rent a Swift Dzire in Shirdi with SaitirthTours & Travels. Offering Swift Dzire rental services for local and outstation trips, including trips to Bhimashankar, Trimbakeshwar, and Nasik." />
    <meta property="og:url" content="https://www.saitirthcabs.com/swift-dzire-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/swift-dzire-on-rent-in-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Rent a Swift Dzire in Shirdi with SaitirthTours & Travels. Offering Swift Dzire rental services for local and outstation trips, including trips to Bhimashankar, Trimbakeshwar, and Nasik.",
            "url": "https://www.saitirthcabs.com/swift-dzire-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/swift-dzire-on-rent-in-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Swift Dzire On Rent in Shirdi",
                "Hire Swift Dzire in Shirdi",
                "Sedan Car on Rent in Shirdi",
                "Maruti Suzuki Swift Dzire-Car Rental in Shirdi",
                "Swift Dzire on Rent in Shirdi Book Now",
                "Shirdi to Bhimashankar Swift Dzire on Rent",
                "Shirdi to Trimbakeshwar Swift Dzire on Rent",
                "Shirdi to Nasik Trimbakeshwar Taxi Services",
                "Shirdi to Trimbakeshwar Tour Package",
                "Shirdi Outstation Taxi Dzire Cab Service",
                "5 Seater Car on Rent in Shirdi",
                "Shirdi to Nashik Swift Dzire on Rent",
                "Shirdi to Aurangabad Swift Dzire Hire",
                "Shirdi Airport Swift Dzire on Rent",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/73.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Swiftdezire;