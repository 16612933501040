
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Seaterbusss() {



  const cardData =
  {
    keyword: ' 7 Seater Car on Rent in Shirdi',
    heading: 'Saitirth Travels: 7 Seater Car on Rent in Shirdi',
    headingDescription: 'Saitirth Travels offers a reliable 7-seater car rental service in Shirdi, perfect for families, groups, or pilgrims looking to travel comfortably together. Our 7-seater vehicles are spacious and equipped with modern amenities, ensuring a pleasant journey as you explore the sacred town and its surrounding attractions. Whether you are visiting Shirdi for spiritual reasons or for leisure, our rental service provides the flexibility and convenience to meet your travel needs.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels ',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The Sai Baba Temple is Shirdi’s foremost spiritual site, drawing millions of devotees worldwide. It houses a magnificent marble statue of Sai Baba, and visitors can engage in daily rituals and aarti ceremonies, immersed in the temple's serene and divine atmosphere. The complex provides facilities like accommodation and dining options, making it a holistic destination for spiritual seekers."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Khandoba Mandir, perched on a scenic hilltop, is dedicated to Lord Khandoba and is one of the oldest temples in the area. Known for its vibrant festivals and panoramic countryside views, it’s an ideal place to experience local spirituality and culture. The serene surroundings also offer a peaceful retreat for reflection and prayer."
        },
        {
            "title": "Lendi Baug",
            "description": "Lendi Baug, a peaceful garden frequented by Sai Baba, features a sacred well and lush greenery. This tranquil spot invites visitors to stroll, relax, and meditate, offering a serene escape from the town's hustle and bustle. It’s a perfect place to reflect on one’s spiritual journey amidst natural beauty."
        },
        {
            "title": "Dixit Wada Museum",
            "description": "Located within the Sai Baba Temple complex, the Dixit Wada Museum exhibits an array of artifacts related to Sai Baba’s life, including his personal belongings, photographs, and writings. This museum deepens visitors’ understanding of Sai Baba's teachings and legacy, enriching their experience of Shirdi."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is a significant site where Sai Baba first appeared to the public, making it a revered destination for devotees. The shrine, accompanied by a peaceful, tree-lined area, provides a contemplative space for prayer and reflection. Many visitors come here to pay respects and seek blessings on their pilgrimage."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust manages the Sai Baba Temple and supports numerous charitable initiatives inspired by Sai Baba’s teachings of compassion and service. Visitors can learn about these projects, which encompass healthcare, education, and social welfare, embodying Sai Baba’s principles of humanity and love."
        },
        {
            "title": "Manmad Fort",
            "description": "Located a short distance from Shirdi, Manmad Fort is a historic site offering panoramic views of the landscape. The fort’s ruins echo the region’s storied past and provide an adventurous trek for history enthusiasts and nature lovers, making it an intriguing addition to any Shirdi itinerary."
        },
        {
            "title": "Nashik Caves",
            "description": "The Nashik Caves, located nearby, are ancient rock-cut caves renowned for their intricate carvings and sculptures. Exploring these caves offers a unique perspective on the region's artistic heritage, with guided tours that provide fascinating historical insights and context."
        },
        {
            "title": "Khandala Waterfall",
            "description": "Khandala Waterfall, situated near Shirdi, is a breathtaking natural attraction, especially during the monsoon season. Surrounded by verdant greenery, the waterfall’s scenic setting is perfect for relaxation, photography, and picnicking, making it a favorite spot for families and nature enthusiasts alike."
        },
        {
            "title": "Shirdi Market",
            "description": "For a taste of local culture, visit the bustling Shirdi Market. Here, visitors can shop for souvenirs, religious items, and local delicacies, enjoying a lively atmosphere that showcases the spirit of Shirdi. Engaging with local vendors offers a genuine glimpse into the community’s daily life and traditions."
        }
    ],  
    
            
        
"services": [
    {
        "name": "7 Seater Car On Rent in Shirdi",
        "description": "Travel comfortably with our 7-seater car rental services in Shirdi, perfect for families and small groups. These vehicles provide spacious interiors and ample legroom, making them ideal for a relaxed and enjoyable journey. Whether you're heading to temples, sightseeing, or simply exploring the area, our 7-seater rentals offer convenience and comfort at every mile. Our fleet is well-maintained, ensuring a smooth ride for everyone."
    },
    {
        "name": "7 Seater Car Hire In Shirdi Airport",
        "description": "Arriving at Shirdi Airport? Begin your journey without hassle with our 7-seater cars available for hire directly from the airport. Our service ensures a seamless start to your trip, offering convenient pick-up and drop-off options. Perfect for group travel, these vehicles provide plenty of space and comfort, so you and your family or friends can start your journey in Shirdi stress-free."
    },
    {
        "name": "7 Seater Ertiga on Rent in Shirdi Airport",
        "description": "For travelers arriving at Shirdi Airport, our 7-seater Ertiga rental service is a great option, offering the ideal balance of space and comfort. This vehicle is designed to accommodate families and small groups, with spacious seating and ample luggage space, making it perfect for long drives or short excursions alike. Start your journey in Shirdi with the Ertiga and enjoy the convenience and ease of comfortable group travel."
    },
    {
        "name": "7 Seater Innova on Rent in Shirdi",
        "description": "Experience luxury and comfort with our 7-seater Innova rentals in Shirdi. Known for its spacious interiors and smooth ride, the Innova is perfect for longer journeys or family trips. Enjoy ample legroom, plush seating, and a premium experience, allowing you to travel in style and comfort. Our well-maintained vehicles and professional drivers ensure a first-class travel experience for you and your group."
    },
    {
        "name": "7 Seater Innova on Rent in Shirdi Airport",
        "description": "Arriving at Shirdi Airport and need a premium ride? Our 7-seater Innova rental service offers a luxurious travel experience, providing the comfort and space you need to relax on your way to Shirdi or other destinations. With well-maintained vehicles, comfortable seating, and professional drivers, this service guarantees a smooth, hassle-free transfer right from the airport."
    },
    {
        "name": "Book Innova Cabs in Shirdi",
        "description": "Booking an Innova cab in Shirdi has never been easier with our efficient and reliable service. We ensure you reach your destination comfortably and on time, with experienced drivers and well-maintained vehicles. Whether you're heading to a temple, hotel, or exploring Shirdi’s sights, our Innova cabs are designed to provide a smooth, stress-free travel experience."
    },
    {
        "name": "Book Innova Cabs in Shirdi from Pune",
        "description": "Traveling from Pune to Shirdi? Book our Innova cabs for a seamless journey, featuring spacious seating and comfort for the entire group. Our experienced drivers are well-acquainted with the best routes, ensuring you have a smooth and relaxing ride to Shirdi. Enjoy the ease and convenience of our Innova cab service for long-distance travel."
    },
    {
        "name": "Pune To Shirdi Innova Cab Booking",
        "description": "For a comfortable and enjoyable travel experience from Pune to Shirdi, our Innova cab booking service is the perfect choice. We provide competitive rates, well-maintained vehicles, and experienced drivers who prioritize your comfort and safety. Enjoy a hassle-free ride to Shirdi with our reliable cab service, designed to meet your needs."
    },
    {
        "name": "7 Seater Kia Carens on Rent in Shirdi",
        "description": "For those seeking a modern and spacious travel option, our 7-seater Kia Carens rental service in Shirdi is an excellent choice. Perfect for family trips or group outings, the Kia Carens offers a comfortable interior with stylish amenities. Experience the convenience and ease of travel in this versatile vehicle, ideal for exploring Shirdi and nearby areas."
    },
    {
        "name": "Kia Carens Car Rental in Shirdi",
        "description": "Our Kia Carens car rental service in Shirdi offers flexibility and comfort, making it ideal for family trips or outings with friends. With spacious seating, modern features, and excellent performance, the Kia Carens ensures a comfortable and enjoyable journey. Book with us and experience top-notch car rental service tailored to your needs."
    },
    {
        "name": "7 Seater Taxi in Shirdi",
        "description": "Need a 7-seater taxi in Shirdi? We offer a variety of well-maintained vehicles to suit your group travel needs. Whether you're touring temples, attending an event, or exploring the region, our 7-seater taxis provide ample space and comfort for everyone on board, ensuring a pleasant travel experience from start to finish."
    },
    {
        "name": "Best 7 Seater Taxi in Shirdi",
        "description": "When it comes to finding the best 7-seater taxi service in Shirdi, look no further. Our fleet prioritizes both comfort and convenience, with well-maintained vehicles and friendly, experienced drivers. Enjoy a smooth and enjoyable journey as we handle all your transportation needs in Shirdi, offering reliable and quality service every time."
    },
    {
        "name": "Shirdi Taxi Booking Online",
        "description": "Booking a taxi in Shirdi is just a few clicks away with our online booking system. Easily reserve a 7-seater vehicle for your travel needs, allowing you to arrange reliable transportation ahead of time. Our service is designed to make your booking experience convenient and hassle-free, ensuring you have a reliable ride ready when you need it."
    },
    {
        "name": "7 Seater Car on Rent in Shirdi Contact Information",
        "description": "For 7-seater car bookings and inquiries, contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. Our team is here to assist with all your travel arrangements, ensuring a comfortable and enjoyable experience from Shirdi to your destination. Let us take care of your transportation needs and make your journey a memorable one!"
    }
],

tableData: [
    ["- 7 Seater Car Hire in Shirdi Airport", "- 7 Seater Ertiga on Rent in Shirdi Airport"],
    ["- 7 Seater Innova on Rent in Shirdi", "- 7 Seater Innova on Rent in Shirdi Airport"],
    ["- Book Innova Cabs in Shirdi", "- Book Innova Cabs in Shirdi from Pune"],
    ["- Pune to Shirdi Innova Cab Booking", "- Book Innova Cabs in Shirdi"],
    ["- 7 Seater Kia Carens on Rent in Shirdi", "- Kia Carens Car Rental in Shirdi"],
    ["- 7 Seater Taxi in Shirdi", "- Best 7 Seater Taxi in Shirdi"],
    ["- Shirdi Taxi Booking Online", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Ideal for Families",
      "WhyChoosedescription": "Our 7-seater cars are designed with families in mind. Offering plenty of space for both passengers and luggage, these vehicles ensure that your family can travel in comfort, whether you're visiting religious sites or going on a holiday. With enough space for everyone, long trips become more enjoyable, and you don't need to worry about fitting your belongings."
    },
    {
      "WhyChooseheading": "Comfortable and Spacious",
      "WhyChoosedescription": "With ample legroom, comfortable seats, and modern amenities, our 7-seater vehicles make every journey a pleasure. Whether it's a short trip or a long drive, everyone will enjoy the spacious interior. Comfort is a priority, and we ensure that each vehicle is equipped to provide an enjoyable travel experience for all passengers."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our drivers are more than just chauffeurs—they are experienced professionals who are well-versed in the local roads and routes around Shirdi and nearby areas. With a focus on safety, punctuality, and customer satisfaction, you can relax knowing you're in good hands. Our drivers will ensure your trip is smooth and worry-free."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We believe in offering great value for your money. Our rental rates are competitive and affordable, with transparent pricing that ensures you won’t encounter any hidden fees. Whether you're booking a ride for a few hours or an extended trip, our pricing structure is designed to give you the best deal without compromising on service."
    },
    {
      "WhyChooseheading": "Well-Maintained Vehicles",
      "WhyChoosedescription": "All our 7-seater vehicles are meticulously maintained to the highest standards. We regularly service our fleet to ensure optimal performance, reliability, and comfort. Every car undergoes routine checks, including mechanical inspections, cleanliness, and hygiene protocols, so you can be confident that your ride will be both safe and pleasant."
    },
    {
      "WhyChooseheading": "Flexible Rental Options",
      "WhyChoosedescription": "We understand that different travelers have different needs, which is why we offer flexible rental packages for our 7-seater cars. Whether you need the car for a few hours, a full day, or multiple days, we can tailor the rental to suit your schedule and preferences. Customizable options make it easy for you to plan your trip exactly the way you want."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking a 7-seater car with us is quick and easy. You can book online through our user-friendly platform, or you can contact our customer service team for personalized assistance. Whether you need a last-minute booking or planning ahead, our booking process is designed to be as hassle-free as possible, ensuring you can secure your vehicle when you need it."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "We offer 24/7 availability for our 7-seater car rentals, meaning you can book a vehicle at any time that suits your travel plans. Whether you have an early morning flight or need a late-night pickup, we’re always ready to serve you. Our around-the-clock service ensures that no matter when you need transportation, we’re here to meet your needs."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers are not only experts in driving but also well-versed in the local culture, attractions, and best routes around Shirdi. They can provide recommendations on must-see places, dining spots, and off-the-beaten-path destinations, enhancing your overall travel experience. You can rely on our local knowledge to make your trip more enjoyable and informative."
    },
    {
      "WhyChooseheading": "Customer Satisfaction Guarantee",
      "WhyChoosedescription": "Customer satisfaction is our top priority. We strive to provide exceptional service that leaves a lasting impression. Our clients continue to return to us for their travel needs, and we take pride in receiving positive reviews and testimonials. With our commitment to excellence, we guarantee that your experience with us will be smooth, pleasant, and hassle-free."
    }
  ]
  



        
  }

  const faqData = [
    {
        question: "How can I book a 7-seater car for rent?",
        answer: "You can book your car easily through our website or by calling our customer service for assistance."
    },
    {
        question: "What types of 7-seater cars do you offer?",
        answer: "We have a variety of 7-seater vehicles available, including SUVs and MPVs, to meet different preferences and needs."
    },
    {
        question: "Are the rental rates inclusive of fuel?",
        answer: "Fuel charges may vary depending on the rental agreement. We provide detailed pricing information during booking."
    },
    {
        question: "Can I book a 7-seater car for a day trip?",
        answer: "Yes, we offer flexible rental options that allow you to rent a vehicle for a few hours or a full day."
    },
    {
        question: "Do you provide drivers with the rental car?",
        answer: "Yes, all our rentals come with experienced drivers who ensure a safe and comfortable journey."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a flexible cancellation policy. Please contact us for specific details regarding your booking."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods including cash, credit/debit cards, and mobile wallets for your convenience."
    },
    {
        question: "Are the vehicles insured?",
        answer: "Yes, all our vehicles come with insurance coverage for your safety while traveling."
    },
    {
        question: "Can I request a child seat for the rental?",
        answer: "Yes, child seats can be arranged upon request at the time of booking."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Arvind Sharma",
        text: "Renting a 7 seater car from Saitirth Travels in Shirdi was a fantastic experience! The vehicle was spacious and comfortable for my family, making our trip enjoyable. The booking process was straightforward, and the staff was very helpful throughout. I highly recommend Saitirth Travels for anyone needing a reliable car rental in Shirdi!"
    },
    {
        name: "Ms. Kavita Rao",
        text: "I had a wonderful time using Saitirth Travels for a 7 seater car rental during my visit to Shirdi. The car was well-maintained and perfect for our group. The driver was friendly and knowledgeable, which made our journey even better. I will definitely use Saitirth Travels for my future travel needs!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | 7 Seater Car On Rent in Shirdi | Rent Innova, Ertiga & Kia Carens | Affordable Taxi Services in Shirdi</title>
    <meta name="description" content="Book 7 Seater Cars on Rent in Shirdi. Choose from Innova, Ertiga, and Kia Carens for your Shirdi trip. Comfortable, affordable, and reliable car rental services. Book online now!" />
    <meta name="keywords" content="7 Seater Car Hire in Shirdi Airport, 7 Seater Ertiga on Rent in Shirdi, 7 Seater Innova on Rent in Shirdi, 7 Seater Innova on Rent in Shirdi Airport, Book Innova Cabs in Shirdi, Book Innova Cabs from Pune, Pune to Shirdi Innova Cab Booking, 7 Seater Kia Carens on Rent in Shirdi, Kia Carens Car Rental in Shirdi, 7 Seater Taxi in Shirdi, Best 7 Seater Taxi in Shirdi, Shirdi Taxi Booking Online" />
    <meta property="og:title" content="Contact: +91 9923879009 | 7 Seater Car On Rent in Shirdi | Rent Innova, Ertiga & Kia Carens | Affordable Taxi Services in Shirdi" />
    <meta property="og:description" content="Book 7 Seater Cars on Rent in Shirdi. Choose from Innova, Ertiga, and Kia Carens for your Shirdi trip. Comfortable, affordable, and reliable car rental services. Book online now!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/7-seater-car-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/7-seater-car-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book 7 Seater Cars on Rent in Shirdi. Choose from Innova, Ertiga, and Kia Carens for your Shirdi trip. Comfortable, affordable, and reliable car rental services. Book online now!",
            "url": "https://www.saitirthcabs.com/7-seater-car-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/7-seater-car-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "7 Seater Car Hire in Shirdi Airport",
                "7 Seater Ertiga on Rent in Shirdi",
                "7 Seater Innova on Rent in Shirdi",
                "7 Seater Innova on Rent in Shirdi Airport",
                "Book Innova Cabs in Shirdi",
                "Book Innova Cabs from Pune",
                "Pune to Shirdi Innova Cab Booking",
                "7 Seater Kia Carens on Rent in Shirdi",
                "Kia Carens Car Rental in Shirdi",
                "7 Seater Taxi in Shirdi",
                "Best 7 Seater Taxi in Shirdi",
                "Shirdi Taxi Booking Online"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/94.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seaterbusss;