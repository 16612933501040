
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditopuneairport() {



  const cardData =
  {
    keyword: '  Shirdi to Pune Airport Cab',
    heading: 'Saitirth Travels: Shirdi to Pune Airport Cab',
    headingDescription: 'When it comes to traveling from Shirdi to Pune Airport, Saitirth Travels provides an exceptional cab service tailored to meet your travel needs. Our Shirdi to Pune Airport cab service ensures a comfortable and timely journey, allowing you to relax and focus on your travel plans. With our reliable vehicles and professional drivers, you can expect a hassle-free ride to one of India’s busiest airports.',

    top: 'Top Places to Visit in Pune with Saitirth Travels',
"topPlaces": [
    {
        "title": "Shaniwar Wada",
        "description": "Shaniwar Wada is a historic fortification that once served as the seat of the Peshwas of the Maratha Empire. Built in the 18th century, this grand structure is known for its impressive architecture, featuring intricately carved wooden pillars, grand gates, and beautiful gardens that reflect the royal heritage of the time. Visitors can explore the remaining structures and learn about the fort's rich history, including the tales of valor and intrigue that surrounded it. The fort also hosts a sound and light show that brings its history to life, making it a must-visit for history enthusiasts and anyone interested in the vibrant past of Maharashtra."
    },
    {
        "title": "Aga Khan Palace",
        "description": "A significant landmark in Pune, the Aga Khan Palace is renowned for its beautiful architecture and historical importance. Constructed in 1892, this magnificent palace is surrounded by lush gardens and elegant fountains. It served as a prison for Mahatma Gandhi and his associates during the Indian freedom struggle, making it an essential site for understanding India’s fight for independence. The palace houses a museum dedicated to Gandhi, showcasing personal artifacts, photographs, and documents that narrate his life and philosophies. Visitors are drawn not only to its historical significance but also to its tranquil environment, which offers a peaceful retreat from the bustling city."
    },
    {
        "title": "Sinhagad Fort",
        "description": "Nestled atop a hill, Sinhagad Fort is a historical fortress that offers breathtaking panoramic views of the surrounding Sahyadri mountain range. Known for its strategic importance in various battles, particularly the Battle of Sinhagad in 1670, the fort is steeped in history and legend. The trek to the fort is popular among adventure enthusiasts, and the route is adorned with lush greenery and vibrant wildflowers. At the top, visitors can explore ancient ruins, temples, and the remnants of fortifications while enjoying stunning views of the landscape. It’s also a favored spot for experiencing magnificent sunrises, making it a perfect destination for nature lovers and photography enthusiasts."
    },
    {
        "title": "Osho Ashram",
        "description": "The Osho Ashram is a tranquil retreat dedicated to meditation and spiritual practices, attracting individuals seeking peace and self-discovery. Founded by the renowned spiritual leader Osho, this serene environment features lush gardens, meditation halls, and beautiful art installations. Visitors can participate in various meditation programs, workshops, and therapies aimed at enhancing personal growth and well-being. The ashram offers a unique opportunity for individuals to disconnect from the chaos of daily life and immerse themselves in self-reflection. With its peaceful ambiance and holistic approach to wellness, the Osho Ashram is an ideal destination for those looking to explore spirituality and mindfulness."
    },
    {
        "title": "Pune Okayama Friendship Garden",
        "description": "The Pune Okayama Friendship Garden, inspired by Japanese garden designs, is a beautifully landscaped oasis perfect for leisurely walks and picnics. This serene garden features intricate pathways, vibrant flower beds, and tranquil water bodies that reflect the harmony of nature. Visitors can enjoy the calming ambiance while surrounded by meticulously maintained greenery and artistic sculptures. The garden is designed to promote peace and relaxation, making it a popular spot for locals and tourists alike. It also hosts various cultural events and workshops, further enriching the community's connection to nature and art."
    },
    {
        "title": "Raja Dinkar Kelkar Museum",
        "description": "The Raja Dinkar Kelkar Museum is a treasure trove of Indian culture and heritage, showcasing an extensive collection of artifacts and exhibits. Established by Dr. Dinkar Kelkar, this museum features over 20,000 artifacts, including traditional Indian crafts, textiles, and musical instruments. The intricately designed exhibits provide a deep insight into the artistic skills and traditions of various Indian communities. Each gallery tells a story, reflecting the diversity and richness of Indian art and culture. The museum is not only an educational experience but also a visual feast, making it a fascinating destination for culture enthusiasts and history buffs who wish to delve into India's rich artistic legacy."
    },
    {
        "title": "Parvati Hill",
        "description": "Parvati Hill is a prominent landmark in Pune, offering a stunning panoramic view of the city. The hill is home to several temples, including the famous Parvati Temple dedicated to Lord Shiva, as well as a historic palace that adds to its charm. The climb to the top is invigorating, with a series of well-maintained steps leading visitors through lush greenery and scenic landscapes. Upon reaching the summit, visitors are rewarded with breathtaking vistas and a peaceful environment that invites relaxation and reflection. The spiritual significance of the temples, coupled with the natural beauty, makes Parvati Hill a popular destination for both locals and visitors looking to enjoy nature and spirituality."
    },
    {
        "title": "National War Museum",
        "description": "The National War Museum in Pune serves as a solemn tribute to the valor and sacrifices of the Indian Armed Forces. This museum displays an impressive collection of military memorabilia, including aircraft, weapons, uniforms, and various artifacts that showcase India's military history. Each exhibit tells a story of bravery and heroism, offering visitors a chance to learn about significant events in India’s defense history. The museum also features dioramas and interactive displays, making it an engaging experience for history buffs and families alike. It is an important educational resource that honors the legacy of soldiers and fosters appreciation for their contributions to the nation."
    },
    {
        "title": "Fergusson College",
        "description": "Fergusson College is one of Pune's oldest and most prestigious educational institutions, known for its beautiful colonial architecture and vibrant campus life. Established in 1885, the college has a rich academic heritage and has produced many notable alumni. The surrounding area is bustling with cafes, shops, and street food stalls, creating a lively atmosphere that attracts students and visitors. The college grounds are often filled with cultural events, exhibitions, and student activities, making it a hub of intellectual and artistic engagement. Visitors can stroll through the picturesque campus, soak in the vibrant student life, and enjoy the charm of this historic institution."
    },
    {
        "title": "Mulshi Dam",
        "description": "Located a short drive from Pune, Mulshi Dam is a scenic getaway that captivates nature lovers and photography enthusiasts. The dam, set against the backdrop of the Sahyadri hills, offers breathtaking views and a tranquil environment. It is an ideal spot for picnicking, birdwatching, and enjoying outdoor activities like trekking and boating. The area around the dam is rich in biodiversity, making it a great place for nature walks and exploring the local flora and fauna. Visitors can indulge in photography, enjoy the stunning sunsets, or simply relax by the water, creating lasting memories in this picturesque landscape."
    }
],
    
            
        
"services": [
    {
      "name": "Shirdi to Pune Airport Cab",
      "description": "Traveling from Shirdi to Pune Airport is made easy and convenient with our dedicated cab services. Whether you're heading for a flight or arriving in Pune, our reliable transportation options ensure a smooth journey. We understand the importance of getting to the airport on time, which is why our drivers are punctual and familiar with the best routes to avoid delays. Enjoy a comfortable ride equipped with modern amenities, allowing you to relax or prepare for your journey."
    },
    {
      "name": "Shirdi to Pune Airport Taxi Service",
      "description": "Experience comfortable and hassle-free travel with our Shirdi to Pune Airport taxi service. Our drivers are trained to provide a safe and pleasant ride, ensuring you arrive on time for your flight. We prioritize your safety and comfort, offering a clean and well-maintained fleet of vehicles, from sedans to spacious SUVs, catering to individual travelers and groups alike."
    },
    {
      "name": "Shirdi to Pune Airport Cab Fare",
      "description": "Our transparent pricing ensures you know the Shirdi to Pune Airport cab fare upfront. With no hidden costs, you can budget your travel expenses effectively. We provide a detailed breakdown of all charges, allowing you to understand the total fare clearly. This commitment to transparency helps you make informed decisions without any surprises at the end of your trip."
    },
    {
      "name": "Taxi Fare from Shirdi to Pune Airport",
      "description": "We offer competitive taxi fare from Shirdi to Pune Airport, making your journey affordable. Our pricing is designed to cater to different budgets without compromising service quality. You can trust that our rates are among the best in the region, allowing you to travel comfortably while keeping your expenses in check."
    },
    {
      "name": "Shirdi Pune Airport Car Rental",
      "description": "For those who prefer flexibility, our Shirdi Pune Airport car rental service is the perfect option. Rent a vehicle that suits your needs and travel at your own pace. Whether you want to explore Pune, visit local attractions, or require transportation for a special event, we have a range of well-maintained vehicles available, ensuring you have the freedom to create your own itinerary."
    },
    {
      "name": "Shirdi to Pune Airport One Way Taxi",
      "description": "Our Shirdi to Pune Airport one way taxi service is ideal for travelers who need a direct ride without a return. Enjoy a straightforward booking process and reliable transportation, perfect for those who are flying out and don’t require a return trip. Our one-way service eliminates the hassle of planning a round trip and gives you peace of mind."
    },
    {
      "name": "Shirdi to Pune Airport Cab Booking",
      "description": "Booking your cab from Shirdi to Pune Airport is simple with our user-friendly online platform. Reserve your ride in advance to secure your preferred vehicle and ensure timely travel. Our platform also allows you to modify your booking easily, accommodating any last-minute changes in your plans, ensuring flexibility in your travel arrangements."
    },
    {
      "name": "Shirdi to Pune Airport Travel by Taxi",
      "description": "Choose our Shirdi to Pune Airport travel by taxi service for a convenient journey. Our experienced drivers know the best routes, ensuring a smooth ride and timely arrival at the airport. We monitor traffic conditions to provide you with the fastest routes, allowing you to arrive relaxed and on schedule, ready for your flight."
    },
    {
      "name": "SaitirthTours & Travels Shirdi",
      "description": "We are proud to be associated with SaitirthTours & Travels Shirdi, known for exceptional customer service and reliable transportation solutions. Count on us for your travel needs, as we are dedicated to making your journey as comfortable and enjoyable as possible. Our team is always ready to assist you, providing a personalized experience to meet your specific requirements."
    },
    {
      "name": "Shirdi Pune Airport Cab Rates",
      "description": "Our Shirdi Pune Airport cab rates are designed to be competitive and transparent. We strive to provide excellent service at reasonable prices for all our customers. Our pricing reflects our commitment to quality, ensuring that you receive outstanding value for your money while enjoying a stress-free journey."
    },
    {
      "name": "Shirdi to Pune Airport Taxi Fare Per Km",
      "description": "Our taxi fare from Shirdi to Pune Airport is calculated per kilometer, allowing you to only pay for the distance traveled. This pricing model offers transparency and helps manage travel expenses, making it easier for you to plan your trip without worrying about fluctuating rates."
    },
    {
      "name": "Affordable Shirdi Pune Airport Cab",
      "description": "Finding an affordable Shirdi Pune Airport cab service is easy with us. We focus on providing high-quality service at budget-friendly rates, ensuring your journey is both enjoyable and economical. Our commitment to affordability doesn't compromise the quality of your experience; we believe that everyone should have access to reliable transportation."
    },
    {
      "name": "Pune to Shirdi Cab One Way",
      "description": "If you're looking to travel from Pune to Shirdi, our Pune to Shirdi cab one way service is also available. Enjoy the convenience of direct transportation without the need for a return trip. Ideal for those visiting the sacred site or attending events in Shirdi, our service ensures you arrive comfortably and on time."
    },
    {
      "name": "Pune to Shirdi Taxi Drop",
      "description": "Our Pune to Shirdi taxi drop service offers reliable and timely transportation for passengers traveling between these two locations. Sit back and relax while we handle the journey for you, ensuring a stress-free experience. Our drivers are knowledgeable about the area, enhancing your travel experience with local insights."
    },
    {
      "name": "Pune Airport to Shirdi Cab",
      "description": "If you are arriving at Pune Airport and need transportation to Shirdi, our Pune Airport to Shirdi cab service is ready to assist. Experience a smooth transition from the airport to your destination with our friendly and professional drivers, who ensure that you feel welcome and comfortable from the moment you step out of the airport."
    },
    {
      "name": "Pune Airport to Shirdi Taxi Fare",
      "description": "We offer clear and competitive Pune Airport to Shirdi taxi fare. Our pricing ensures you are well-informed about the costs associated with your trip, enabling you to travel without any financial surprises. Our commitment to clarity in pricing helps you plan your journey effectively."
    },
    {
      "name": "Cab from Pune Airport to Shirdi",
      "description": "Easily book a cab from Pune Airport to Shirdi with us. Our dedicated services guarantee a comfortable and efficient ride to your destination. We ensure our vehicles are equipped with the latest amenities for a pleasant travel experience, giving you the comfort you deserve."
    },
    {
      "name": "Pune Airport to Shirdi Taxi Service",
      "description": "Our Pune Airport to Shirdi taxi service ensures you have a reliable transportation option upon arrival at the airport. Count on us for timely pickups and drop-offs, allowing you to start your journey smoothly and without hassle. Our team is committed to providing excellent service every step of the way."
    },
    {
      "name": "Taxi to Shirdi from Pune",
      "description": "We provide a convenient taxi to Shirdi from Pune service, allowing you to travel seamlessly between these two popular locations. Our drivers are experienced and knowledgeable about the best routes, making your trip efficient and enjoyable. Let us take care of your transportation needs while you focus on enjoying your journey."
    },
    {
      "name": "Shirdi to Pune Airport Innova Cab Service",
      "description": "For a more spacious and comfortable travel option, consider our Shirdi to Pune Airport Innova cab service. Perfect for families or larger groups, it offers ample room for everyone. Enjoy modern amenities that enhance your travel experience, making your journey pleasant and relaxed, with enough space for luggage and personal belongings."
    },
    {
      "name": "Contact Number for Shirdi to Pune Airport Cab",
      "description": "Contact Saitirth Travels at +91 9923879009 / 9922479009 for prompt and efficient Shirdi to Pune Airport cab services. We ensure a smooth and enjoyable ride for all our customers, with our team ready to assist you at any time to enhance your travel experience. Your satisfaction is our top priority, and we are here to support you throughout your journey."
    }
  ],
  "tableData": [
    ["- Shirdi to Pune Airport Taxi Service", "- Shirdi to Pune Airport Cab Fare"],
    ["- Taxi Fare from Shirdi to Pune Airport", "- Shirdi Pune Airport Car Rental"],
    ["- Shirdi to Pune Airport One Way Taxi", "- Shirdi to Pune Airport Cab Booking"],
    ["- Shirdi to Pune Airport Travel by Taxi", "- SaitirthTours & Travels Shirdi"],
    ["- Shirdi Pune Airport Cab Rates", "- Shirdi to Pune Airport Taxi Fare per Km"],
    ["- Affordable Shirdi Pune Airport Cab", "- Shirdi to Pune Airport Cab"],
    ["- Pune to Shirdi Cab One Way", "- Pune to Shirdi Taxi Drop"],
    ["- Pune Airport to Shirdi Cab", "- Pune Airport to Shirdi Taxi Fare"],
    ["- Cab from Pune Airport to Shirdi", "- Pune Airport to Shirdi Taxi Service"],
    ["- Taxi to Shirdi from Pune", "- Shirdi to Pune Airport Innova Cab Service"]
],
"whychoose": [
    {
        "WhyChooseheading": "Effortless Booking Process",
        "WhyChoosedescription": "Our intuitive online booking system allows you to reserve your cab from Shirdi to Pune Airport quickly and easily. With just a few clicks, you can choose your vehicle, set your pickup time, and secure your reservation. You’ll receive instant confirmation via email or SMS, providing peace of mind that your travel plans are in place. This hassle-free approach saves you time and effort, allowing you to focus on preparing for your trip."
    },
    {
        "WhyChooseheading": "Diverse Fleet of Vehicles",
        "WhyChoosedescription": "We pride ourselves on offering a comprehensive range of vehicles to suit your individual travel preferences and group sizes. Whether you need a compact sedan for a solo journey, a spacious SUV for family trips, or a luxurious car for corporate travel, we have the right vehicle to meet your needs. Each vehicle is meticulously maintained and equipped with modern amenities to ensure a comfortable and enjoyable ride, making your journey to Pune Airport a pleasant experience."
    },
    {
        "WhyChooseheading": "Professional and Reliable Drivers",
        "WhyChoosedescription": "Our team of professional drivers is carefully selected and trained to prioritize your safety and comfort. They are knowledgeable about the best routes to Pune Airport, which helps in avoiding traffic and ensuring timely arrivals. Our drivers also provide a friendly and courteous service, making your journey more enjoyable. Their commitment to reliability means you can trust us to get you to your destination safely and on time."
    },
    {
        "WhyChooseheading": "Transparent Pricing",
        "WhyChoosedescription": "At Saitirth Travels, we believe in complete transparency with our pricing. You can expect clear and upfront quotes with no hidden charges, so you know exactly what you’re paying for. Our competitive rates are designed to fit a variety of budgets, and we offer special packages for regular travelers, ensuring that you receive the best value for your money. This transparency allows you to plan your travel expenses confidently."
    },
    {
        "WhyChooseheading": "24/7 Customer Support",
        "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any inquiries, changes to your booking, or additional requests. Whether you have questions about your ride, need to adjust your pickup time, or require assistance during your journey, our responsive team is just a call away. We prioritize your satisfaction and are committed to ensuring that your travel experience is smooth and enjoyable from start to finish."
    },
    {
        "WhyChooseheading": "Punctuality Guaranteed",
        "WhyChoosedescription": "We understand the critical importance of time when traveling to catch a flight. Our drivers are trained to prioritize punctuality, ensuring you reach Pune Airport with ample time to spare before your departure. We monitor traffic conditions and adjust routes as necessary to avoid delays, giving you peace of mind that you won’t miss your flight. You can rely on us to be there when you need us, ready to get you to your destination on time."
    },
    {
        "WhyChooseheading": "Safety and Hygiene Measures",
        "WhyChoosedescription": "Your health and safety are our highest priorities. We implement rigorous hygiene protocols, including thorough sanitization of our vehicles before and after each trip. Our drivers adhere to safety guidelines and health measures to provide a clean travel environment. Additionally, we provide hand sanitizers in our vehicles for your convenience. This commitment to safety ensures that you can travel with confidence, knowing we take every precaution to protect your well-being."
    },
    {
        "WhyChooseheading": "Flexible Travel Options",
        "WhyChoosedescription": "We recognize that travel needs can vary, which is why we offer flexible options tailored to your itinerary. Whether you require a one-way trip to Pune Airport or a round trip with stops along the way, we can accommodate your preferences. Our booking system allows you to customize your journey based on your schedule, and our team is always willing to help with any special requests you may have. This flexibility ensures your travel arrangements align perfectly with your plans."
    }
]


        
  }


  const faqData = [
    {
        question: "How can I book a cab from Shirdi to Pune Airport?",
        answer: "You can book your cab easily through our website or by contacting our customer service team for assistance."
    },
    {
        question: "What types of vehicles are available for this route?",
        answer: "We provide a range of vehicles including compact cars, SUVs, and luxury sedans to accommodate different group sizes and preferences."
    },
    {
        question: "Are the fare rates inclusive of fuel and tolls?",
        answer: "Typically, fuel and toll charges are calculated separately based on the journey, ensuring transparency in pricing."
    },
    {
        question: "Can I book a one-way trip?",
        answer: "Yes, we offer both one-way and round-trip bookings to fit your travel itinerary."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Are your drivers experienced?",
        answer: "Yes, all our drivers are experienced, licensed, and knowledgeable about the routes to ensure a safe and pleasant journey."
    },
    {
        question: "What if I need to change or cancel my booking?",
        answer: "You can easily modify or cancel your booking by contacting our customer support team."
    },
    {
        question: "What safety measures are in place during the ride?",
        answer: "We ensure our vehicles are regularly sanitized and our drivers adhere to health and safety protocols to keep you safe."
    },
    {
        question: "Is there a customer support number I can call?",
        answer: "Yes, our customer support team is available 24/7 for any questions or concerns you may have."
    },
    {
        question: "Can I request a specific vehicle for my trip?",
        answer: "Yes, you can specify your preferred vehicle type when making your booking, and we will do our best to accommodate your request."
    }
];

const testimonials = [
    {
        name: "Mrs. Kavita Sharma",
        text: "I had a great experience with Saitirth Travels for my cab ride from Shirdi to Pune Airport. The booking was straightforward, and our driver was incredibly punctual and friendly. He helped with our luggage and made sure we had a smooth ride. The car was clean and comfortable, making our trip stress-free. I will definitely use their services again!"
    },
    {
        name: "Mr. Sanjay Kulkarni",
        text: "Traveling to Pune Airport from Shirdi with Saitirth Travels was a breeze. Our driver was on time and drove safely. I appreciated his knowledge of the best routes, which helped us avoid traffic. The car was spacious and well-maintained, which made the journey pleasant. I highly recommend Saitirth Travels for anyone needing a reliable cab service!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Pune Airport Cab | SaitirthTours & Travels | Affordable Taxi Services</title>
    <meta name="description" content="Book affordable and reliable Shirdi to Pune Airport cab services. Offering one-way taxi, car rentals, and more. Fast, safe, and convenient travel. Book now with Saitirth Travels!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Pune Airport Cab | SaitirthTours & Travels | Affordable Taxi Services" />
    <meta property="og:description" content="Book affordable and reliable Shirdi to Pune Airport cab services. Offering one-way taxi, car rentals, and more. Fast, safe, and convenient travel. Book now with Saitirth Travels!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-pune-airport-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-pune-airport-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book affordable and reliable Shirdi to Pune Airport cab services. Offering one-way taxi, car rentals, and more. Fast, safe, and convenient travel. Book now with Saitirth Travels!",
            "url": "https://www.saitirthcabs.com/shirdi-to-pune-airport-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-pune-airport-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Pune Airport taxi service",
                "Shirdi to Pune Airport cab fare",
                "Taxi fare from Shirdi to Pune Airport",
                "Shirdi Pune Airport car rental",
                "Shirdi to Pune Airport one way taxi",
                "Shirdi to Pune Airport cab booking",
                "Shirdi to Pune Airport travel by taxi",
                "SaitirthTours & Travels Shirdi",
                "Shirdi Pune Airport cab rates",
                "Shirdi to Pune Airport taxi fare per km",
                "Affordable Shirdi Pune Airport cab",
                "Pune to Shirdi cab one way",
                "Pune to Shirdi taxi drop",
                "Pune airport to Shirdi cab",
                "Pune airport to Shirdi taxi service",
                "Shirdi to Pune Airport Innova cab service",
                "Pune to Shirdi taxi drop",
                "Pune airport to Shirdi cab fare"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/46.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditopuneairport;