import React from "react";
import '../css/Booking.css'; // Import the CSS for styling
import { Helmet } from 'react-helmet';
import GoogleTag from "./GoogleTag";

const Booking = () => {
    const cabs = [
    {
      id: 1,
      name: "Maruti Suzuki Swift Dzire",
      seats: "4+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-01.jpg" 
    },
    {
      id: 1,
      name: "Toyota Etios",
      seats: "4+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-02.jpg"
    },
    {
      id: 1,
      name: "Maruti Suzuki Ciaz",
      seats: "4+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-03.jpg" 
    },
    {
      id: 1,
      name: "Hyundai Aura",
      seats: "4+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-04.jpg" 
    },
    {
      id: 1,
      name: "Hyundai Verna",
      seats: "4+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-05.jpg" 
    },
    {
      id: 1,
      name: "Toyota Innova Crysta",
      seats: "6+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-06.jpg" 
    },
    {
      id: 1,
      name: "Innova Cab",
      seats: "6+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-07.jpg" 
    },

     {
      id: 1,
      name: "Mahindra Scorpio",
      seats: "6+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-08.jpg" 
    },

    {
      id: 1,
      name: "Maruti Suzuki Ertiga",
      seats: "6+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-09.jpg" 
    },
    {
      id: 1,
      name: "Kia Carens",
      seats: "6+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-10.jpg" 
    },
    {
      id: 1,
      name: "Tavera",
      seats: "7+1",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-11.jpg" 
    },

    {
      id: 1,
      name: "13 Seater Tempo Traveller",
      seats: "13",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-12.jpg" 
    },
    {
      id: 2,
      name: "17 Seater Tempo Traveller",
      seats: "17",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-14.jpg" 
    },
    {
      id: 3,
      name: "13 Seater Urbania Bus",
      seats: "13",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-15.jpg" 
    },
    {
      id: 4,
      name: "17 Seater Urbania Bus",
      seats: "17",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-16.jpg" 
    },
    {
      id: 5,
      name: "20 Seater Tempo Traveller",
      seats: "20",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-17.jpg" 
    },
    {
      id: 6,
      name: "26 Seater Tempo Traveller",
      seats: "26",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-18.jpg" 
    },
    {
      id: 7,
      name: "32 Seater Tempo Traveller",
      seats: "32",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-19.jpg" 
    },
    {
      id: 8,
      name: "40 Seater Ac / Non AC bus",
      seats: "40",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-19.jpg" 
    },
    {
      id: 9,
      name: "45 Seater Ac / Non AC bus",
      seats: "45",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-20.jpg" 
    },
    {
      id: 10,
      name: "50 Seater Ac / Non AC bus",
      seats: "50",
      local: "₹2500",
      perKm: "₹13",
      extraHr: "₹150",
      driverAllowance: "₹400",
      nightHalt: "₹250",
      pickupDrop: "₹3000",
      outstation: "₹3900",
      distance: "300km",
      image: "/images/gallery/Img for Gallery-600x400px-21.jpg" 
    }
  ];

  // Function to handle the booking click with a predefined message format
  const handleBookingClick = (cabName) => {
    const message = `I want to book ${cabName}`;
    const whatsappUrl = `https://wa.me/919923879009?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="pb-5">
      <GoogleTag/>
      <Helmet>
        <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
        <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
        <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
        <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
        <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
        <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
        <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "250"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91-9923879009",
              "contactType": "Customer Service"
            },
            "keywords": [
              "Force Urbania on Rent in Shirdi",
              "Urbania on Rent in Manmad",
              "Force Urbania on Rent in Shani Shingnapur",
              "Shirdi to Bhimashankar Urbania on Rent",
              "Shirdi to Nashik Force Urbania on Rent",
              "Mini Bus on Rent in Shirdi",
              "Luxury Force Urbania on Rent in Shirdi",
              "13 Seater Urbania on Rent",
              "17 Seater Urbania on Rent",
              "Shirdi Airport Urbania on Rent",
              "Sai Tirth Cabs Shirdi",
              "Aura on Rent in Shirdi",
              "Ertiga on Rent in Shirdi",
              "Scorpio on Rent in Shirdi",
              "Kia Carens on Rent in Shirdi"
            ]
          })}
        </script>
      </Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h1 className="title">Booking</h1>
          </div>
        </div>
      </section>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>Booking</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      <div className="container d-flex justify-content-center align-items-center py-5 bg-dark ">
        <div className="row w-100 ">
          {cabs.map((cab) => (
            <div key={cab.id} className="col-12 mb-4 d-flex justify-content-center ">
              <div className="row booking-card">
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <img src={cab.image} alt={cab.name} className="booking-image img-fluid" />
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center ">
                  <h3 className="darkcolor">{cab.name}</h3>
                  <p>Seater: {cab.seats}</p>
                  <p>Local: {cab.local}</p>
                  <p>Mumbai Pickup & Drop: {cab.pickupDrop}</p>
                  <p>Outstation ({cab.distance}): {cab.outstation}</p>
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center pricing-details">
                  <p>Extra Hr: {cab.extraHr}</p>
                  <p>Driver Allowance: {cab.driverAllowance}</p>
                  <p>Night Halt: {cab.nightHalt}</p>
                  <button onClick={() => handleBookingClick(cab.name)} className="book-now-btn mt-3">
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Booking;
