import { Link } from 'react-router-dom';
function Foot(){
  return(
      <div>

<div className="container-fluid  bg-dark">
      <section className="service">
      <div className="row container-fluid he text-white justify-content-center g-0 p-0">







      <div className="col-12 col-md-3">
      <h4 className="py-3 case">we offer services</h4>
      <Link className="mx-md-2" to="/Best-cab-service-in-Shirdi"><i className="fas fa-car darkcolor"></i>  Best cab service in Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-Car-Rental"><i className="fas fa-car darkcolor"></i> Shirdi Car Rental</Link>
      <Link className="mx-md-2" to="/Shirdi-Sightseeing-Cab"><i className="fas fa-car darkcolor"></i> Shirdi Sightseeing Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Manmad-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Manmad Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Mumbai-Cabs"><i className="fas fa-car darkcolor"></i> Shirdi to Mumbai Cabs</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Aurangabad-Taxi-Fare"><i className="fas fa-car darkcolor"></i> Shirdi to Aurangabad (Sambhaji Nagar) Taxi Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Bhimashankar-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Bhimashankar Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-Darshan-Taxi"><i className="fas fa-car darkcolor"></i> Shirdi Darshan Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Pune-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Pune Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Ahmednagar-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Ahmednagar Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-Airport-to-Shirdi-Temple-Taxi-Fare"><i className="fas fa-car darkcolor"></i> Shirdi Airport to Shirdi Temple Taxi Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Nasik-Taxi-Fare"><i className="fas fa-car darkcolor"></i> Shirdi to Nasik Taxi Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Trimbakeshwar-Cab-Fare"><i className="fas fa-car darkcolor"></i> Shirdi to Trimbakeshwar Cab Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-Saibaba-Taxi-Booking-Online"><i className="fas fa-car darkcolor"></i> Shirdi Saibaba Taxi Booking Online</Link>
      <Link className="mx-md-2" to="/Airport-taxi-Shirdi"><i className="fas fa-car darkcolor"></i> Airport taxi Shirdi</Link>
      <Link className="mx-md-2" to="/Kopargaon-to-Shirdi-Taxi"><i className="fas fa-car darkcolor"></i> Kopargaon to Shirdi Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Shani-Shingnapur-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Shani Shingnapur Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Ajanta-Ellora-Taxi-Fare"><i className="fas fa-car darkcolor"></i> Shirdi to Ajanta Ellora Taxi Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Grishneshwar-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Grishneshwar Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Lonavala-Taxi"><i className="fas fa-car darkcolor"></i> Shirdi to Lonavala Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Mahabaleshwar-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Mahabaleshwar Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Matheran-Taxi"><i className="fas fa-car darkcolor"></i>  Shirdi to Matheran Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Kolhapur-Taxi"><i className="fas fa-car darkcolor"></i>  Shirdi to Kolhapur Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Konkan-Darshan-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Konkan Darshan Cab</Link>
      <Link className="mx-md-2" to="/Cabs-for-Events-in-Shirdi"><i className="fas fa-car darkcolor"></i> Cabs for Events in Shirdi</Link>
      <Link className="mx-md-2" to="/Monthly-Cab-Service-Shirdi"><i className="fas fa-car darkcolor"></i> Monthly Cab Service Shirdi</Link>
      <Link className="mx-md-2" to="/Corporate-Cab-Services-in-Shirdi"><i className="fas fa-car darkcolor"></i> Corporate Cab Services in Shirdi</Link>
      
      
     
    </div>


    <div className="col-12 col-md-3">
      <h4 className="py-3 case">we offer services</h4>
      <Link className="mx-md-2" to="/Kalyan-to-Shirdi-Car-Rental"><i className="fas fa-car darkcolor"></i> Kalyan to Shirdi Car Rental</Link>

      <Link className="mx-md-2" to="/Shirdi-to-Pandharpur-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Pandharpur Cab</Link>
      
      <Link className="mx-md-2" to="/Shirdi-to-Ashtavinayak-Darshan-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Ashtavinayak Darshan Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Pune-Airport-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Pune Airport Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Verul-Car-Rental"><i className="fas fa-car darkcolor"></i> Shirdi to Verul Car Rental</Link>
      <Link className="mx-md-2" to="/Tempo-Traveller-17-seater-on-rent-Shirdi"><i className="fas fa-car darkcolor"></i> Tempo Traveller 17 seater on rent Shirdi</Link>
      <Link className="mx-md-2" to="/Toyota-Etios-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Toyota Etios On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Innova-on-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i>  Innova on Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Mini-bus-on-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Mini bus on Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Surat-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Surat Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Shegaon-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Shegaon Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Bhandardara-Taxi"><i className="fas fa-car darkcolor"></i> Shirdi to Bhandardara Taxi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Mumbai-Airport-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Mumbai Airport Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Ujjain-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Ujjain Cab</Link>
      <Link className="mx-md-2" to="/One-Way-Taxi-Shirdi"><i className="fas fa-car darkcolor"></i> One Way Taxi Shirdi</Link>
      <Link className="mx-md-2" to="/Aurangabad-Airport-to-Shirdi-Cab"><i className="fas fa-car darkcolor"></i> Aurangabad Airport to Shirdi Cab</Link>
      <Link className="mx-md-2" to="/Nasik-Airport-to-Shirdi-Taxi"><i className="fas fa-car darkcolor"></i> Nasik Airport to Shirdi Taxi</Link>
      <Link className="mx-md-2" to="/14-17-25-32-Seater-Bus-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> 14 17 25 32 Seater Bus On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-5-Jyotirlinga-Package"><i className="fas fa-car darkcolor"></i> Shirdi to 5 Jyotirlinga Package</Link>
      <Link className="mx-md-2" to="/Shirdi-Tour-Package"><i className="fas fa-car darkcolor"></i> Shirdi Tour Package</Link>
      <Link className="mx-md-2" to="/Rental-Car-for-Wedding-Shirdi"><i className="fas fa-car darkcolor"></i> Rental Car for Wedding  Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Outstation-Cabs"><i className="fas fa-car darkcolor"></i> Shirdi to Outstation Cabs</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Ajanta-Ellora-Caves-Package"><i className="fas fa-car darkcolor"></i> Shirdi to Ajanta Ellora Caves Package</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Mumbai-Darshan-Package"><i className="fas fa-car darkcolor"></i> Shirdi to Mumbai Darshan Package</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Nagpur-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Nagpur Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Maharashtra-Darshan-Package"><i className="fas fa-car darkcolor"></i> Shirdi to Maharashtra Darshan Package</Link>
     
      <Link className="mx-md-2" to="/Shirdi-to-Ooty-Mysore-Cab-Package"><i className="fas fa-car darkcolor"></i> Shirdi to Ooty Mysore Cab Package </Link>
    
      
      
    </div>



    <div className="col-12 col-md-3 text-white">
      <h4 className="py-3 case">we offer services</h4>
      <Link className="mx-md-2" to="/Shirdi-to-Ujjain-Cab-Booking"><i className="fas fa-car darkcolor"></i> Shirdi to Ujjain Cab Booking</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Gujarat-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Gujarat Cab</Link>
      <Link className="mx-md-2" to="/Sainagar-Shirdi-to-Shirdi-Cabs"><i className="fas fa-car darkcolor"></i> Sainagar Shirdi to Shirdi Cabs</Link>
      <Link className="mx-md-2" to="/Swift-Dzire-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Swift Dzire On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Innova-Crysta-Cab-on-Rent-Shirdi"><i className="fas fa-car darkcolor"></i> Innova Crysta Cab on Rent Shirdi</Link>
      <Link className="mx-md-2" to="/Ertiga-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Ertiga On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Kia-Carens-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Kia Carens On Rent in Shirdi</Link>
      
      <Link className="mx-md-2" to="/Tempo-Traveller-14-seater-rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> Tempo Traveller 14 seater rent in Shirdi</Link>
      <Link className="mx-md-2" to="/50-Seater-bus-on-rent-in-Shirdi"><i className="fas fa-car darkcolor"></i> 50 Seater bus on rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Saptashrungi-Taxi-Fare"><i className="fas fa-car darkcolor"></i> Shirdi to Saptashrungi Taxi Fare</Link>
      <Link className="mx-md-2" to="/Shirdi-to-All-India-Explorer-Cab-Service"><i className="fas fa-car darkcolor"></i> Shirdi to All India Explorer Cab Service</Link>
      <Link className="mx-md-2" to="/Pune-to-Shirdi-Cab"><i className="fas fa-car darkcolor"></i>  Pune to Shirdi Cab</Link>
      <Link className="mx-md-2" to="/Pune-Airport-to-shirdi-Taxi"><i className="fas fa-car darkcolor"></i> Pune Airport to shirdi Taxi</Link>
      <Link className="mx-md-2" to="/Pune-to-Shani-Shingnapur-Cab"><i className="fas fa-car darkcolor"></i> Pune to Shani Shingnapur Cab</Link>
      <Link className="mx-md-2" to="/Mumbai-to-Shirdi-Cab"><i className="fas fa-car darkcolor"></i>  Mumbai to Shirdi Cab </Link>
      <Link className="mx-md-2" to="/Mumbai-Airport-to-Shirdi-Cab"><i className="fas fa-car darkcolor"></i> Mumbai Airport to Shirdi Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-to-Indore-Darshan-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Indore Darshan Cab</Link>
      <Link className="mx-md-2" to="/Shirdi-Airport-Cabs"><i className="fas fa-car darkcolor"></i> Shirdi Airport Cabs</Link>
      <Link className="mx-md-2" to="/Shirdi-Outstation-Cabs"><i className="fas fa-car darkcolor"></i> Shirdi Outstation Cabs</Link>

      <Link className="mx-md-2" to="/Shirdi-One-Way-Cab-Service"><i className="fas fa-car darkcolor"></i> Shirdi One Way Cab Service</Link>
      <Link className="mx-md-2" to="/Shirdi-Taxi-Booking-Online"><i className="fas fa-car darkcolor"></i> Shirdi Taxi Booking Online</Link>
      <Link className="mx-md-2" to="/Shirdi-Cabs"><i className="fas fa-car darkcolor"></i> Shirdi Cabs</Link>
      <Link className="mx-md-2" to="/Shirdi-Airport-Pick-up-&-Drop-Service"><i className="fas fa-car darkcolor"></i> Shirdi Airport Pick up & Drop Service</Link>

      <Link className="mx-md-2" to="/Force-Urbania-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i>  Force Urbania On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/7-Seater-Car-On-Rent-in-Shirdi"><i className="fas fa-car darkcolor"></i>  7 Seater Car On Rent in Shirdi</Link>
      <Link className="mx-md-2" to="/Shirdi-to-all-Maharashtra-Cab-Service"><i className="fas fa-car darkcolor"></i>  Shirdi to all Maharashtra Cab Service</Link>
      <Link className="mx-md-2" to="/Shirdi-Round-Trip-Cab-Booking"><i className="fas fa-car darkcolor"></i> Shirdi Round Trip Cab Booking </Link>
      <Link className="mx-md-2" to="/Shirdi-to-Hyderabad-Cab"><i className="fas fa-car darkcolor"></i> Shirdi to Hyderabad Cab </Link>
    </div>


    <div className="col-12 col-md-3">
      <h4 className="py-3 case"></h4>
      <h4 className="case darkcolor">Saitirth Travels  </h4>
      <p><strong className='darkcolor fw-bold'>Phone:</strong><br />
      <i className="fas fa-phone-alt text-white"></i> <a href="tel:+919923879009" className='d-inline'>+91 9923879009</a><br />
        <i className="fas fa-phone-alt text-white"></i> <a href="tel:+919922479009" className='d-inline'>+91 9922479009</a>
      
      </p>
      <p className='row w-100'><strong className='col-12 darkcolor fw-bold'>Email:</strong><br />
        <a href="mailto:booking@saitirthcabs.com" className='col-12 overflow-hidden'>booking@saitirthcabs.com</a>
      </p>
      <p className='text-white'><strong className='fw-bold darkcolor'>Address:</strong><br />
        Saitirth Travels :<br />
        Hotel sai jashan Sana and   <br/>
        son Road Shirdi,  Taluka Rahata <br/>
        District Ahmednagar, Maharashtra - 423109
      </p>
      <div className="mt-4">
        <h5 className="case darkcolor">Our Location:</h5>








<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3133.739794562332!2d74.478748!3d19.771244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc5b9650d6a807%3A0x31d2acca36481c15!2sHotel%20Sai%20Jashan%20-%20Top%20Rank%20%231%20Award%20Winning%20Best%20Hotel%20in%20Shirdi!5e1!3m2!1sen!2sin!4v1733112161906!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


      </div>
    </div>

    <hr />
   
    <p className="text-white text-center">
  All Rights Reserved @ <span className="darkcolor">Shraddha Travels</span> 2024 Developed By
  <a href="https://www.dexterintelligence.com/" className="d-inline" target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Dexter Intelligence</span>
  </a> | Hosted By
  <a href="https://www.urvahosting.com/" className="d-inline" target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Urva Hosting</span>
  </a>
</p>
      </div>
        </section>
      </div>
    </div>
  );
}

export default Foot;


