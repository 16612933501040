
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditomumbaiairport() {



  const cardData =
  {
    keyword: '  Shirdi to Mumbai Airport Cab',
    heading: 'Saitirth Travels: Shirdi to Mumbai Airport Cab',
    headingDescription: 'When traveling from Shirdi to Mumbai Airport, Saitirth Travels is your reliable partner for a comfortable and efficient ride. We understand the importance of reaching your destination on time, especially for flights. Our professional drivers and well-maintained vehicles ensure a smooth journey, allowing you to travel with ease.',

    top: 'Top Places to Visit in Mumbai with Saitirth Travels',

"topPlaces": [
    {
        "title": "Gateway of India",
        "description": "The Gateway of India, an iconic archway built in 1924, stands as a prominent historical landmark overlooking the Arabian Sea. It marks the entrance to Mumbai, serving as a symbolic welcome for visitors arriving by sea. The grand structure features Indo-Saracenic architecture, adorned with intricate details and carvings. This bustling site is a popular gathering point for both locals and tourists, who come to enjoy the refreshing sea breeze and take stunning photographs. Nearby, the Taj Mahal Palace Hotel, an emblem of luxury and hospitality, adds to the area’s charm, offering a glimpse into Mumbai’s rich history. Visitors can explore the vibrant surroundings, enjoy boat rides, and indulge in the local street food at nearby stalls, making it a must-visit destination in the city."
    },
    {
        "title": "Marine Drive",
        "description": "Known as the 'Queen’s Necklace' due to its stunning coastline and lit-up curve resembling a string of pearls at night, Marine Drive is a picturesque boulevard stretching along the Arabian Sea. It serves as a favorite promenade for couples, families, and friends, particularly during the evening when the area comes alive with energy. The gentle sound of waves, the cool sea breeze, and the beautiful sunset create a romantic atmosphere. Nearby, Chowpatty Beach is famous for its delectable street food, including the iconic pav bhaji and bhel puri, inviting visitors to experience the local culinary delights. Whether for a leisurely stroll, a casual chat, or simply to soak in the views, Marine Drive is an essential part of Mumbai’s coastal charm."
    },
    {
        "title": "Chhatrapati Shivaji Maharaj Terminus (CST)",
        "description": "A UNESCO World Heritage Site, Chhatrapati Shivaji Maharaj Terminus (CST) is a bustling railway station that showcases the grandeur of Victorian Gothic architecture. Completed in 1887, this architectural marvel features intricate detailing and magnificent spires, making it a symbol of Mumbai’s rich history and cultural heritage. Serving as a hub for thousands of commuters daily, CST is not only functional but also a stunning work of art. Visitors can marvel at its impressive façade and explore the interior, where vibrant scenes of daily life unfold against the backdrop of historical significance. CST is a testament to Mumbai's evolution, blending the old with the new in a vibrant urban setting."
    },
    {
        "title": "Elephanta Caves",
        "description": "A short ferry ride from the Gateway of India leads you to the mystical Elephanta Island, home to the ancient rock-cut temples dedicated to Lord Shiva. The Elephanta Caves, dating back to the 5th to 8th centuries, are renowned for their stunning sculptures, including the awe-inspiring 20-foot Trimurti sculpture depicting a three-headed Shiva. The caves are surrounded by lush greenery, offering a serene setting perfect for history enthusiasts and nature lovers alike. As visitors explore the caves, they can appreciate the intricate carvings and artistic expressions that reflect the spiritual significance of the site. The tranquil atmosphere invites contemplation, making it a unique blend of history, art, and natural beauty."
    },
    {
        "title": "Haji Ali Dargah",
        "description": "Located on a small islet off Worli, Haji Ali Dargah is a beautiful mosque and a significant pilgrimage site accessible only during low tide. Built in 1431, this stunning structure is a masterpiece of Indo-Islamic architecture, characterized by its white marble façade and intricate minarets. Visitors can experience its spiritual ambiance, wander through the lush gardens, and enjoy breathtaking views of the Arabian Sea. The dargah attracts people from all walks of life, fostering a sense of unity and peace. The vibrant atmosphere is heightened during religious festivals, when thousands come to pay their respects, making Haji Ali a cultural and spiritual landmark in Mumbai."
    },
    {
        "title": "Juhu Beach",
        "description": "One of Mumbai's most famous beaches, Juhu Beach is a lively spot known for relaxation and enjoyment. The sandy shore, lined with swaying palm trees, is bustling with energy as families and friends gather to unwind. Food stalls offering local delicacies fill the area, inviting visitors to savor treats like vada pav and ice-cold coconut water. Beach activities abound, from camel rides to volleyball games, ensuring there’s something for everyone. As the sun sets, the beach transforms into a picturesque scene, with vibrant colors painting the sky. Juhu Beach is not just a destination; it's a vibrant community hub where memories are made and moments are cherished."
    },
    {
        "title": "Chhatrapati Shivaji Maharaj Vastu Sangrahalaya (CSMVS)",
        "description": "Formerly known as the Prince of Wales Museum, the Chhatrapati Shivaji Maharaj Vastu Sangrahalaya (CSMVS) is a cultural treasure trove that houses an extensive collection of artifacts spanning various cultures and epochs. The museum's striking Indo-Saracenic architecture complements its rich exhibits, which include sculptures, decorative art, and textiles from India and beyond. CSMVS is a must-visit for art and history enthusiasts, offering insight into the rich heritage of India through its carefully curated displays. Visitors can engage with interactive exhibits, attend educational workshops, and participate in cultural events, making it a dynamic center for learning and exploration."
    },
    {
        "title": "Bandra-Worli Sea Link",
        "description": "The Bandra-Worli Sea Link is an engineering marvel that seamlessly connects the suburbs of Bandra and Worli, offering stunning views of the Mumbai skyline and the Arabian Sea. This architectural feat features sleek, modern design and is especially captivating at sunset when the sky is painted with vibrant hues. Traveling on the sea link provides a breathtaking experience, as the bridge’s height allows for panoramic views of the city’s landmarks and the bustling harbor. The sea link is not just a vital transportation route; it symbolizes the modern face of Mumbai, reflecting the city’s rapid development and ambition. Stopping at viewpoints along the way allows visitors to capture unforgettable photographs and enjoy the refreshing sea breeze."
    },
    {
        "title": "Dharavi",
        "description": "Known as one of Asia's largest slums, Dharavi is a vibrant hub of entrepreneurship and creativity. Guided tours provide unique insights into the lives of residents, showcasing their resilience, innovation, and the vibrant community spirit that defines this area. Visitors can explore local industries, such as pottery and textiles, witnessing firsthand the entrepreneurial drive that thrives in this bustling environment. The tours often include interactions with residents, allowing visitors to learn about their daily lives and the challenges they face. Dharavi challenges stereotypes, highlighting the strength and determination of its community. This visit offers a deeper understanding of Mumbai's diverse fabric, fostering appreciation for the city's complexities."
    },
    {
        "title": "Babulnath Temple",
        "description": "Located near Marine Lines, Babulnath Temple is an ancient Hindu temple dedicated to Lord Shiva. Known for its peaceful ambiance and beautiful architecture, the temple attracts devotees and tourists alike. The intricate carvings and serene atmosphere provide a tranquil environment for worship and reflection. Visitors often participate in rituals, offering prayers and observing the spiritual practices of the devotees. The temple's location, nestled among the bustling city streets, makes it a serene oasis where individuals can escape the urban rush and connect with their spirituality. Babulnath Temple is not only a place of worship but also a cultural landmark that reflects the rich spiritual heritage of Mumbai."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to Mumbai Airport Cab",
        "description": "Traveling from Shirdi to Mumbai Airport is made effortless with our dedicated taxi services. We focus on providing reliable transportation that ensures you arrive at your destination on time and in comfort. Our vehicles are regularly serviced and equipped with modern amenities for a pleasant journey."
    },
    {
        "name": "Shirdi to Mumbai Airport Cab Service",
        "description": "Our Shirdi to Mumbai Airport cab service caters to all types of travelers, whether you are departing for a flight or returning home after a trip. We offer a diverse fleet of vehicles, including sedans, SUVs, and luxury cars, ensuring you can select the perfect ride to meet your needs and preferences."
    },
    {
        "name": "Shirdi Mumbai Airport Taxi Fare",
        "description": "Our taxi fare from Shirdi to Mumbai Airport is both competitive and transparent. We believe in providing clear pricing with no hidden fees, so you can accurately budget your travel expenses. Our pricing structure is straightforward, allowing you to understand exactly what you are paying for."
    },
    {
        "name": "Best Cab from Shirdi to Mumbai Airport",
        "description": "We take pride in being the best cab service from Shirdi to Mumbai Airport. Our commitment to punctuality, cleanliness, and well-maintained vehicles ensures that your travel experience is smooth and enjoyable. Our drivers are trained professionals who prioritize your safety and comfort throughout the journey."
    },
    {
        "name": "Cheap Taxi Shirdi to Mumbai Airport",
        "description": "If you're looking for affordable transport options, our cheap taxi services from Shirdi to Mumbai Airport offer excellent value. We provide competitive rates without compromising on quality, allowing you to travel comfortably while staying within your budget."
    },
    {
        "name": "Book Cab Shirdi to Mumbai Airport",
        "description": "Booking your cab from Shirdi to Mumbai Airport is straightforward and hassle-free. Use our user-friendly online booking system or call us directly to secure your ride in advance. We encourage early bookings to ensure availability and to help you avoid last-minute rushes."
    },
    {
        "name": "One-Way Taxi Shirdi to Mumbai Airport",
        "description": "Need a one-way taxi from Shirdi to Mumbai Airport? We offer flexible options to meet your travel requirements, with our drivers ensuring a direct and efficient route. This service is ideal for travelers who want a quick and easy transfer without the need for a round trip."
    },
    {
        "name": "Round Trip Cab Shirdi to Mumbai Airport",
        "description": "For those planning to return to Shirdi after their trip, our round trip cab service offers seamless transportation. Enjoy the convenience of pre-arranged pickups, so you don't have to worry about transportation logistics upon your return."
    },
    {
        "name": "Car Rental Shirdi to Mumbai Airport",
        "description": "In addition to taxi services, we provide flexible car rental options from Shirdi to Mumbai Airport. Choose from a variety of vehicles tailored to your needs, whether for personal use or corporate travel. Our rentals come with full insurance and 24/7 roadside assistance."
    },
    {
        "name": "Reliable Cab Shirdi to Mumbai Airport",
        "description": "When you choose our services, you can trust that you're getting a reliable cab from Shirdi to Mumbai Airport. Our experienced drivers are well-versed in the best routes and traffic patterns, ensuring punctual arrivals and a stress-free travel experience."
    },
    {
        "name": "Distance Shirdi to Mumbai Airport Taxi",
        "description": "The distance from Shirdi to Mumbai Airport is approximately 250 kilometers, making it a manageable journey. Our drivers are familiar with the best routes to ensure you arrive on time, taking into account traffic conditions and potential delays."
    },
    {
        "name": "Direct Cab Shirdi to Mumbai Airport",
        "description": "Our service provides a direct cab from Shirdi to Mumbai Airport, eliminating unnecessary stops along the way. Enjoy uninterrupted travel as our drivers navigate the quickest and most efficient routes to get you to your flight on time."
    },
    {
        "name": "Shirdi to Mumbai Taxi One Way",
        "description": "Our one-way taxi service from Shirdi to Mumbai is designed for travelers needing quick and efficient transport to the airport or city center. Enjoy a straightforward and reliable ride that gets you to your destination without delays."
    },
    {
        "name": "Shirdi to Mumbai Innova Crysta",
        "description": "For larger groups or families, we offer the Innova Crysta for rent from Shirdi to Mumbai Airport. This spacious vehicle provides ample legroom and luggage space, ensuring a comfortable travel experience for everyone."
    },
    {
        "name": "Shirdi to Mumbai Airport Ertiga Cab",
        "description": "If you prefer a slightly smaller vehicle, our Ertiga cabs are available for the trip to Mumbai Airport. These vehicles combine comfort and style, making them perfect for small groups or families traveling together."
    },
    {
        "name": "Shirdi to Mumbai Airport Mini Bus on Rent",
        "description": "For group travel, consider our mini bus rental for transport from Shirdi to Mumbai Airport. Ideal for family trips, corporate events, or group outings, our mini buses provide plenty of seating and luggage space."
    },
    {
        "name": "Shirdi to Mumbai Airport Sedan Car on Rent",
        "description": "Our sedan cars for rent are an excellent choice for solo travelers or couples seeking a comfortable and stylish ride to the airport. Enjoy a smooth journey with all the necessary amenities."
    },
    {
        "name": "Daily Shirdi to Mumbai Cab Service on Rent",
        "description": "We offer a daily cab service from Shirdi to Mumbai, perfect for business travelers or frequent flyers needing consistent transportation to the airport at any time of the day or night."
    },
    {
        "name": "Contact Information",
        "description": "For more information or to book your Shirdi to Mumbai Airport cab, please contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Experience hassle-free travel with our reliable and professional taxi services, and let us take care of your journey."
    }
],
"tableData": [
    ["- Shirdi to Mumbai Airport Cab Service", "- Shirdi Mumbai Airport Taxi Fare"],
    ["- Best Cab from Shirdi to Mumbai Airport", "- Cheap Taxi Shirdi Mumbai Airport"],
    ["- Book Cab Shirdi to Mumbai Airport", "- One-way Taxi Shirdi Mumbai Airport"],
    ["- Round Trip Cab Shirdi Mumbai Airport", "- Car Rental Shirdi to Mumbai Airport"],
    ["- Reliable Cab Shirdi Mumbai Airport", "- Distance Shirdi Mumbai Airport Taxi"],
    ["- Direct Cab Shirdi to Mumbai Airport", "- Shirdi to Mumbai Taxi One Way"],
    ["- Shirdi to Mumbai Innova Crysta", "- Shirdi to Mumbai Airport Ertiga Cab"],
    ["- Shirdi to Mumbai Airport Mini Bus on Rent", "- Shirdi to Mumbai Airport Sedan Car on Rent"],
    ["- Daily Shirdi to Mumbai Cab Service on Rent", "- SaitirthTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Seamless Booking Process:",
        "WhyChoosedescription": "Booking your cab is an effortless experience thanks to our user-friendly online platform. With just a few clicks, you can secure your ride from Shirdi to Mumbai Airport. Our system is designed to minimize the hassle typically associated with travel arrangements. Additionally, our dedicated customer service representatives are available to assist you at any time, ready to answer questions or provide support throughout your booking journey, ensuring you feel confident and well-informed."
    },
    {
        "WhyChooseheading": "Comfortable Ride:",
        "WhyChoosedescription": "At Saitirth Travels, we prioritize your comfort. Our diverse fleet consists of various vehicles, including sedans, SUVs, and luxury models, all maintained to the highest standards. Each vehicle is equipped with plush seating, climate control, and ample space for luggage, ensuring that your journey to Mumbai Airport is not only pleasant but also relaxing. We believe that the ride itself should be part of the overall travel experience, allowing you to arrive at your destination refreshed and ready."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are highly trained professionals who not only excel at driving but also possess extensive knowledge of local routes and traffic patterns. They are committed to ensuring your safety and comfort throughout your journey. With their expertise, you can trust that you’ll be navigating the most efficient routes to arrive on time at Mumbai Airport. Furthermore, our drivers are friendly and approachable, ready to assist with any special requests or needs you may have along the way."
    },
    {
        "WhyChooseheading": "Affordable Pricing:",
        "WhyChoosedescription": "Saitirth Travels offers transparent and competitive pricing for all our cab services. We strive to provide high-quality travel experiences at rates that fit a variety of budgets, with no hidden fees or surprise charges. Our commitment to affordability ensures that you can enjoy premium transportation services without financial strain. Additionally, we frequently update our pricing options to remain competitive, allowing you to benefit from cost-effective travel solutions while maintaining exceptional service quality."
    },
    {
        "WhyChooseheading": "Flexible Travel Options:",
        "WhyChoosedescription": "Understanding that travel plans can vary widely, we offer flexible travel options tailored to your specific needs. Whether you require a one-way trip to the airport, a round trip, or a customized itinerary with multiple stops, we can accommodate your preferences. This flexibility allows you to design your travel experience according to your schedule and requirements, ensuring a convenient and personalized journey that meets all your expectations."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our commitment to customer satisfaction is unwavering, which is why our dedicated support team is available around the clock. Whether you have questions before booking, need to make modifications to your itinerary, or require assistance during your journey, we are just a call away. Our representatives are well-trained to address any concerns promptly and effectively, ensuring that your travel experience is seamless and enjoyable from start to finish."
    },
    {
        "WhyChooseheading": "Safety and Hygiene Standards:",
        "WhyChoosedescription": "In light of current health concerns, we have implemented strict hygiene and safety protocols to protect our passengers. Each vehicle is meticulously cleaned and sanitized before and after every trip, and we provide hand sanitizers for passengers upon request. Our drivers adhere to health guidelines to ensure a safe environment throughout your journey. By prioritizing your health and safety, we create a worry-free travel experience that allows you to focus on enjoying your trip."
    },
    {
        "WhyChooseheading": "Punctuality Guaranteed:",
        "WhyChoosedescription": "We understand the importance of time when it comes to air travel. Our punctuality is a hallmark of our service; we ensure our drivers arrive at your designated pick-up location on time, every time. Our efficient scheduling practices and route planning are designed to account for potential delays, so you can rest assured that you will arrive at Mumbai Airport promptly. This reliability helps alleviate the stress often associated with travel, allowing you to focus on your journey."
    },
    {
        "WhyChooseheading": "Local Knowledge:",
        "WhyChoosedescription": "Our drivers are not just skilled navigators; they possess valuable local knowledge that can enhance your travel experience. They can share insights about the places you pass, recommend local dining spots, or provide tips about the best things to see and do in the area. This not only makes your journey more enjoyable but also enriches your understanding of the local culture and attractions, turning a simple ride into a memorable experience."
    },
    {
        "WhyChooseheading": "Positive Customer Reviews:",
        "WhyChoosedescription": "Our reputation is built on the satisfaction of our customers, and the numerous positive reviews we receive reflect our commitment to quality service. We take pride in delivering reliable, safe, and enjoyable travel experiences, and our customers frequently share their testimonials about the professionalism of our drivers, the comfort of our vehicles, and the overall ease of the booking process. This feedback reinforces our dedication to maintaining high standards of service, making us a trusted choice for travelers."
    }
]




        
  }


  const faqData = [
    {
        question: "How can I book a cab from Shirdi to Mumbai Airport?",
        answer: "You can book a cab easily through our website or by calling our customer service for assistance."
    },
    {
        question: "What is the distance from Shirdi to Mumbai Airport?",
        answer: "The distance is approximately 250 kilometers, with an estimated travel time of 5 to 6 hours."
    },
    {
        question: "Are all tolls and taxes included in the fare?",
        answer: "Yes, our fares include all tolls and taxes, ensuring transparency in pricing."
    },
    {
        question: "Can I change my booking after confirmation?",
        answer: "Yes, you can modify your booking by contacting our support team, subject to availability."
    },
    {
        question: "What types of vehicles are available for this route?",
        answer: "We offer a variety of vehicles including sedans, SUVs, and luxury cars to suit your preferences."
    },
    {
        question: "Are your drivers licensed and experienced?",
        answer: "Yes, all our drivers are fully licensed professionals with extensive experience in the field."
    },
    {
        question: "What payment options do you offer?",
        answer: "We accept cash, credit/debit cards, and online payments for your convenience."
    },
    {
        question: "Is customer support available 24/7?",
        answer: "Yes, our customer support team is available around the clock to assist you."
    },
    {
        question: "Can I arrange for a round trip to Mumbai Airport?",
        answer: "Absolutely! We provide flexible options for round trips tailored to your travel schedule."
    },
    {
        question: "What should I do if my flight is delayed?",
        answer: "Our drivers will track your flight and adjust the pick-up time accordingly to ensure timely service."
    }
];

const testimonials = [
    {
        name: "Mr. Rajesh Iyer",
        text: "I recently used Saitirth Travels for my journey from Shirdi to Mumbai Airport, and it was a fantastic experience. The booking was easy, and our driver was incredibly professional. The ride was smooth and stress-free. I highly recommend their services for airport transfers!"
    },
    {
        name: "Mrs. Neeta Kapoor",
        text: "Traveling from Shirdi to Mumbai Airport with Saitirth Travels was a breeze. Our driver arrived right on time and helped with our luggage. The car was clean and comfortable, making the long drive enjoyable. I will definitely choose them for my future travels!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mumbai Airport Cab | SaitirthTours & Travels | Affordable Airport Transportation</title>
    <meta name="description" content="Get affordable and reliable taxi services from Shirdi to Mumbai Airport. Book your one-way or round trip cab service now! We offer Innova, Ertiga, and mini bus options." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mumbai Airport Cab | SaitirthTours & Travels | Affordable Airport Transportation" />
    <meta property="og:description" content="Get affordable and reliable taxi services from Shirdi to Mumbai Airport. Book your one-way or round trip cab service now! We offer Innova, Ertiga, and mini bus options." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-mumbai-airport-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-mumbai-airport-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
  
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Get affordable and reliable taxi services from Shirdi to Mumbai Airport. We offer a variety of vehicles including Innova, Ertiga, and mini buses. One-way, round trip options available.",
            "url": "https://www.saitirthcabs.com/shirdi-to-mumbai-airport-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-mumbai-airport-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "150"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Mumbai Airport cab service",
                "Shirdi Mumbai Airport taxi fare",
                "Shirdi to Mumbai Airport one way taxi",
                "Shirdi to Mumbai Airport round trip",
                "Shirdi to Mumbai Airport car rental",
                "Shirdi to Mumbai Airport innova crysta",
                "Shirdi to Mumbai Airport mini bus on rent",
                "Shirdi to Mumbai taxi service",
                "Reliable cab service from Shirdi to Mumbai Airport",
                "Affordable Mumbai Airport taxi from Shirdi",
                "SaitirthTours & Travels"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/55.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomumbaiairport;