
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Airporttaxishirdi() {



  const cardData =
  {
    keyword: '  Airport Taxi Shirdi',
    heading: 'Saitirth Travels: Airport Taxi Shirdi',
    headingDescription: 'When you arrive at the airport and need a reliable way to reach Shirdi, Saitirth Travels offers exceptional airport taxi services tailored to your needs. Our commitment is to provide you with a comfortable, safe, and convenient journey, ensuring that your transition from the airport to Shirdi is smooth and stress-free. With our easy online booking system, you can reserve your taxi ahead of time, allowing you to focus on your travel experience without worrying about transportation.',

    top: 'Top Places to Visit in Shirdi Saitirth Travels',

    "topPlaces": [
    {
        "title": "1. Shirdi Saibaba Temple",
        "description": "The Shirdi Saibaba Temple is the heart of Shirdi and one of the most visited pilgrimage sites in India. Dedicated to the revered saint Sai Baba, the temple complex is home to the iconic Samadhi Mandir, where Saibaba’s mortal remains are interred. The stunning architecture is complemented by intricate carvings and vibrant floral decorations, enhancing the spiritual ambiance. Visitors can participate in daily rituals and experience the serene atmosphere that attracts millions of devotees each year."
    },
    {
        "title": "2. Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a significant site closely associated with Sai Baba, where he spent much of his life providing solace to devotees. The masjid features the sacred Dhuni, a continuous fire that symbolizes selfless service and love. Visitors can reflect on Saibaba’s teachings while admiring the simple yet profound atmosphere. The presence of a beautiful stone statue of Saibaba and various artifacts from his life enrich the spiritual experience, making it a key stop for those seeking deeper connection."
    },
    {
        "title": "3. Chavadi",
        "description": "Chavadi is a historical site where Sai Baba rested every alternate night. This tranquil space features an idol of Saibaba dressed in traditional attire and showcases artifacts from his daily life, offering visitors a glimpse into his routines. The peaceful ambiance invites contemplation and reflection, making it an essential part of the pilgrimage for those wishing to feel the essence of Saibaba’s presence."
    },
    {
        "title": "4. Shri Sai Baba Sansthan Trust",
        "description": "The Shri Sai Baba Sansthan Trust manages the temple and engages in various charitable activities inspired by Saibaba’s teachings. Visitors can learn about the trust’s initiatives focusing on community welfare, healthcare, and education. Engaging with these activities offers a unique opportunity to witness how Saibaba’s teachings positively impact lives, encouraging visitors to contribute to meaningful causes."
    },
    {
        "title": "5. Lendi Baug",
        "description": "Lendi Baug is a beautifully landscaped garden created by Saibaba, providing a peaceful retreat for visitors. The garden features a well, a banyan tree, and a small temple, making it an excellent spot for relaxation and introspection. The lush greenery and vibrant flowers create an inviting atmosphere, ideal for meditation amidst nature, allowing visitors to connect with their inner peace."
    },
    {
        "title": "6. Samadhi Mandir",
        "description": "The Samadhi Mandir, where Saibaba's mortal remains rest, is an architectural marvel adorned with exquisite marble work and intricate designs. It serves as a serene environment for worship and reflection. Visitors can pay their respects at the Samadhi, experiencing a profound sense of devotion. The daily aarti held here is a spiritual highlight that uplifts the hearts of all who attend, creating a divine experience."
    },
    {
        "title": "7. Sai Heritage Village",
        "description": "Sai Heritage Village offers a captivating glimpse into the life and teachings of Saibaba through various exhibitions and displays. Visitors can explore replicas of significant locations from Saibaba's life, enhancing their understanding of his teachings. This engaging experience is particularly valuable for families, providing an insightful journey into the principles of love, faith, and service that Saibaba espoused."
    },
    {
        "title": "8. Khandoba Mandir",
        "description": "Khandoba Mandir, dedicated to Lord Khandoba, is an ancient temple located near Shirdi, rich in historical significance as a site where Saibaba was once a devotee. The temple's peaceful ambiance and beautiful architecture attract visitors seeking blessings, particularly lively during festivals, adding to its charm and spiritual significance."
    },
    {
        "title": "9. Gurusthan",
        "description": "Gurusthan is a sacred site believed to be where Saibaba first appeared in Shirdi. Visitors can find a stone idol of Saibaba beneath a tree, offering a serene environment for meditation and prayer. This site is often frequented by devotees seeking blessings, making it an essential part of the Shirdi pilgrimage, filled with spiritual energy and connection."
    },
    {
        "title": "10. Shri Saibaba Museum",
        "description": "The Shri Saibaba Museum showcases a collection of artifacts and personal belongings associated with Saibaba. Visitors can explore photographs, manuscripts, and items that provide insights into Saibaba's life and teachings. The museum serves as a valuable educational resource, highlighting the profound impact Saibaba had on his devotees and the rich history surrounding the Shirdi pilgrimage."
    }
],    
    
            
        
    
   "services": [
    {
        "name": "Shirdi to Trimbakeshwar Taxi Fare",
        "description": "Understanding the Shirdi to Trimbakeshwar cab fare is essential for your travel budget. Our fares are not only competitive but also designed to ensure transparency, allowing you to plan your pilgrimage or sightseeing without financial worries. We provide detailed fare breakdowns, including base fare, per kilometer charges, and any applicable tolls, ensuring you choose the right fit for your journey while keeping your expenses in check."
    },
    {
        "name": "Shirdi to Trimbakeshwar Taxi Service",
        "description": "Our Shirdi to Trimbakeshwar taxi service offers reliable transportation tailored to your schedule. With a focus on customer satisfaction, our skilled drivers are trained to provide a safe and pleasant journey. Whether you’re making a one-way trip or planning a round trip, our service includes door-to-door pickup and drop-off, allowing you to relax and enjoy the scenic drive through beautiful landscapes. We also provide flexible scheduling to accommodate your plans, ensuring a seamless travel experience."
    },
    {
        "name": "SaitirthTours & Travels Shirdi",
        "description": "At SaitirthTours & Travels, we pride ourselves on our customer service and commitment to quality. Our fleet is well-maintained, ensuring a comfortable ride from Shirdi to Trimbakeshwar. Our knowledgeable team is always available to assist you with your travel plans, whether it’s recommending local attractions or adjusting your itinerary. We strive to make your journey memorable, focusing on your comfort and convenience every step of the way."
    },
    {
        "name": "Shirdi Trimbakeshwar Car Rental",
        "description": "If you prefer more flexibility, consider our Shirdi Trimbakeshwar car rental options. This allows you to explore at your own pace while enjoying breathtaking landscapes along the way. Choose from a variety of well-maintained vehicles, from compact cars to spacious SUVs, perfect for any group size and travel style. Our rental services come with comprehensive insurance coverage, ensuring peace of mind during your travels."
    },
    {
        "name": "Shirdi to Trimbakeshwar One Way Taxi",
        "description": "Our Shirdi to Trimbakeshwar one-way taxi service is perfect for those looking for a straightforward journey without the hassle of returning to Shirdi immediately. With no need to plan for a round trip, you can enjoy the ride and focus on your destination, making the most of your time at the temple. Our drivers are familiar with the best routes, ensuring a smooth ride while you relax and reflect on your spiritual journey."
    },
    {
        "name": "Shirdi to Trimbakeshwar Cab Booking",
        "description": "Booking your Shirdi to Trimbakeshwar cab is easy with our user-friendly online platform. Reserve your vehicle in advance or contact our customer service for personalized assistance. Our booking system allows you to select your preferred vehicle, view real-time availability, and make secure payments, ensuring a seamless booking experience. We also offer last-minute bookings to cater to your spontaneous travel plans."
    },
    {
        "name": "Shirdi Trimbakeshwar Cab Rates",
        "description": "We offer competitive Shirdi Trimbakeshwar cab rates to suit different budgets. Our pricing structure is transparent, ensuring you know what to expect before you travel. Additionally, we provide special discounts for group bookings and frequent travelers, making it easier for everyone to embark on their spiritual journeys. You can also inquire about our promotional offers, which are updated regularly to help you save more."
    },
    {
        "name": "Shirdi to Trimbakeshwar Taxi Fare per KM",
        "description": "Our Shirdi to Trimbakeshwar taxi fare per km is clearly laid out, allowing you to budget your travel expenses efficiently. You can rest assured that our pricing is among the most competitive in the region, providing great value without compromising on service quality. We also offer detailed explanations of any additional fees, ensuring complete transparency in our pricing model."
    },
    {
        "name": "Affordable Shirdi Trimbakeshwar Cab",
        "description": "Looking for an affordable Shirdi Trimbakeshwar cab? We strive to provide the best service at rates that won't break the bank, making your spiritual journey accessible and enjoyable. We believe everyone should have the opportunity to experience the tranquility of Trimbakeshwar, which is why our pricing is designed to accommodate various financial plans. Our commitment to quality means you won’t have to sacrifice comfort for affordability."
    },
    {
        "name": "Shirdi to Nasik Trimbakeshwar Taxi Fare",
        "description": "If your travels include a stop in Nasik, our Shirdi to Nasik Trimbakeshwar taxi fare is designed to give you flexibility while maintaining affordability for your travel plans. We provide detailed information on routes, travel time, and fare estimates, allowing you to plan accordingly and enjoy your pilgrimage without worries. Our drivers are knowledgeable about the area, ensuring you take the best routes to reach your destinations."
    },
    {
        "name": "Trimbakeshwar to Shirdi Taxi Fare",
        "description": "For return trips, our Trimbakeshwar to Shirdi taxi fare options are equally competitive. We ensure that you have a pleasant return journey filled with reflections of your spiritual experience. Our drivers are familiar with the best routes, providing a timely and comfortable ride back to Shirdi. We also monitor traffic conditions to ensure minimal delays on your journey home."
    },
    {
        "name": "From Shirdi to Trimbakeshwar by Taxi",
        "description": "Traveling from Shirdi to Trimbakeshwar by taxi provides a direct and personalized experience. Our drivers are well-acquainted with the best routes, ensuring you enjoy a smooth ride. This direct service eliminates the stress of navigating public transportation, allowing you to sit back, relax, and focus on your spiritual journey. We prioritize your safety and comfort throughout your trip, ensuring a memorable experience."
    },
    {
        "name": "Taxi Fare from Shirdi to Trimbakeshwar",
        "description": "Our taxi fare from Shirdi to Trimbakeshwar is structured to provide great value, ensuring you can focus on your spiritual pursuits without worrying about transportation costs. We offer clear fare policies and upfront pricing, so you can travel with confidence knowing there are no hidden fees. Our commitment to transparency means you’ll always know what you’re paying for."
    },
    {
        "name": "Shirdi to Trimbakeshwar Innova Cab",
        "description": "For families or larger groups, our Shirdi to Trimbakeshwar Innova cab service is perfect. Spacious and comfortable, the Innova ensures everyone travels together comfortably. Our drivers are experienced in handling group dynamics, making your journey enjoyable and hassle-free. We also provide child safety seats upon request, ensuring the safety of younger passengers."
    },
    {
        "name": "Shirdi to Trimbakeshwar Tempo Traveller on Rent",
        "description": "If you’re planning to travel with a bigger group, our Shirdi to Trimbakeshwar tempo traveller on rent option is ideal. It provides ample space for everyone to enjoy the journey together while accommodating luggage and personal belongings comfortably. Our tempo travellers are equipped with modern amenities, ensuring a comfortable ride for all passengers during the trip."
    },
    {
        "name": "Shirdi to Trimbakeshwar Contact Number",
        "description": "Contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009 for prompt and efficient Shirdi to Trimbakeshwar services. Our customer service team is ready to assist you with inquiries, bookings, and any other travel needs. Whether you have questions about routes, vehicle options, or special requests, we are committed to ensuring a smooth and enjoyable ride for all our customers."
    }
],
    "tableData": [
        ["- Shirdi Airport Taxi Service", "- Shirdi Airport Cab Service"],
        ["- Airport Taxi to Shirdi", "- Taxi from Shirdi Airport"],
        ["- Shirdi Airport Pickup Taxi", "- Shirdi Airport Drop Taxi"],
        ["- Shirdi Airport Transfer Taxi", "- Shirdi Airport Taxi Booking"],
        ["- Shirdi Airport to City Taxi", "- Affordable Airport Taxi Shirdi"],
        ["- Airport Taxi Shirdi", "- Shirdi Airport to Shirdi Temple Taxi Fare"],
        ["- Cabs From Shirdi Airport to Shirdi Temple", "- Shirdi Airport to Shirdi Cab"],
        ["- Shirdi Airport to Shirdi Taxi", "- SaitirthTours & Travels Shirdi"],
        ["- Shirdi Airport to Shirdi Temple Cabs", "- Shirdi Airport to Shirdi Temple Taxi"],
        ["- Shirdi to Airport Taxi", "- Innova Cab Shirdi Airport"],
        ["- Tempo Traveller on Rent Shirdi Airport", "- Ertiga on Rent in Shirdi Airport"],
        ["- Sedan Cab on Rent Shirdi Airport", ""]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Convenient Airport Transfers:",
            "WhyChoosedescription": "We provide reliable airport taxi services for seamless transfers to and from Shirdi Airport, ensuring you start and end your journey comfortably. Our booking process is straightforward, allowing you to arrange your taxi ahead of time, reducing last-minute hassles and ensuring a smooth travel experience."
        },
        {
            "WhyChooseheading": "Timely Service:",
            "WhyChoosedescription": "Our professional drivers are committed to punctuality, ensuring you arrive at the airport on time for your flights. We monitor flight schedules and traffic conditions, allowing us to adjust pickup times as needed to guarantee that you never miss a flight, giving you peace of mind during your travels."
        },
        {
            "WhyChooseheading": "Well-Maintained Vehicles:",
            "WhyChoosedescription": "Travel in comfort with our fleet of clean, well-maintained vehicles equipped to meet your travel needs. Each vehicle undergoes regular inspections and cleaning, ensuring optimal performance and comfort for every passenger. We prioritize hygiene and provide amenities like air conditioning and comfortable seating to enhance your travel experience."
        },
        {
            "WhyChooseheading": "Affordable Rates:",
            "WhyChoosedescription": "We offer competitive pricing for our airport taxi services, with no hidden fees. Our transparent pricing model allows you to travel within your budget without compromising on quality or service. We also provide special discounts and packages for frequent travelers, making our services even more affordable."
        },
        {
            "WhyChooseheading": "Easy Booking Options:",
            "WhyChoosedescription": "Booking your airport taxi is simple through our website or by calling our customer service team for assistance. We provide a user-friendly interface that allows you to choose your vehicle, schedule your ride, and manage your booking effortlessly. You can also modify or cancel your reservation with ease, ensuring flexibility."
        },
        {
            "WhyChooseheading": "24/7 Availability:",
            "WhyChoosedescription": "Our services are available round the clock, accommodating your travel schedule no matter what time your flight is. Whether it's an early morning or late-night arrival, you can count on us to be there when you need us. Our commitment to 24/7 service ensures that you're never left stranded."
        },
        {
            "WhyChooseheading": "Customer-Centric Approach:",
            "WhyChoosedescription": "We pride ourselves on a customer-centric approach, where your satisfaction is our top priority. Our team is dedicated to providing personalized service to cater to your specific travel requirements, and we actively seek feedback to improve our offerings continuously."
        },
        {
            "WhyChooseheading": "Local Expertise:",
            "WhyChoosedescription": "Our drivers are not just transporters; they are knowledgeable locals who can offer insights about Shirdi and recommend places to visit or eat during your stay. They can share interesting stories and tips about the area, enhancing your overall travel experience and helping you discover hidden gems."
        },
        {
            "WhyChooseheading": "Safety Measures:",
            "WhyChoosedescription": "Your safety is paramount to us. We follow strict safety protocols and guidelines to ensure a secure journey, including vehicle inspections and driver training. We also provide contactless options for payment and interactions, ensuring a safe environment for all our passengers."
        },
        {
            "WhyChooseheading": "Flexible Travel Options:",
            "WhyChoosedescription": "We provide a variety of vehicle options to meet your travel needs, whether you require a compact car for solo travel or a larger vehicle for a group. This flexibility allows you to choose the best fit for your journey, ensuring comfort for all passengers, regardless of their number or preferences."
        },
        {
            "WhyChooseheading": "Loyalty Benefits:",
            "WhyChoosedescription": "Join our loyalty program to earn points for every airport taxi service you use. Redeem these points for discounts on future rides, rewarding you for choosing Saitirth Travels again and again. This program is designed to thank our repeat customers, making your travel even more economical."
        }
    ]

        
  }


  const faqData = [
    {
        question: "How do I book an airport taxi from Shirdi?",
        answer: "You can easily book your airport taxi through our website or by contacting our customer support team."
    },
    {
        question: "What is the fare for an airport taxi from Shirdi Airport?",
        answer: "The fare generally ranges from INR 500 to INR 1,200, depending on the destination within Shirdi and the type of vehicle. Please check our website for the latest pricing."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for late-night pickups or if you request extra stops during your journey."
    },
    {
        question: "How can I ensure a timely pickup from the airport?",
        answer: "Please provide your flight details during booking so we can track your flight and ensure timely pickup."
    },
    {
        question: "What payment methods are accepted for airport taxi bookings?",
        answer: "We accept multiple payment methods including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I cancel or modify my airport taxi booking?",
        answer: "Yes, you can cancel or modify your booking through our website or by contacting customer support, according to our cancellation policy."
    },
    {
        question: "Is the taxi sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every trip to ensure your safety."
    },
    {
        question: "Can I request a specific vehicle for my airport transfer?",
        answer: "Yes, you can request a specific vehicle type during the booking process, and we will do our best to accommodate your preference."
    }
];

const testimonials = [
    {
        name: "Mr. Sanjay Mehta",
        text: "I booked an airport taxi with Saitirth Travels, and it was an excellent experience. The driver was waiting for me at the airport and helped with my luggage. The ride was smooth and comfortable!"
    },
    {
        name: "Ms. Kavita Rao",
        text: "Saitirth Travels provided me with a hassle-free airport transfer. The booking process was easy, and the driver was friendly and punctual. Highly recommend their service for anyone traveling to Shirdi!"
    }
];


  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Airport Taxi Shirdi | SaitirthTours & Travels</title>
    <meta name="description" content="Book an affordable airport taxi from Shirdi Airport to your destination. SaitirthTours & Travels offers reliable Shirdi airport taxi services, including airport pickup, drop, and temple transfers." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Airport Taxi Shirdi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book an affordable airport taxi from Shirdi Airport to your destination. SaitirthTours & Travels offers reliable Shirdi airport taxi services, including airport pickup, drop, and temple transfers." />
    <meta property="og:url" content="https://www.saitirthcabs.com/airport-taxi-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-airport-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book an affordable airport taxi from Shirdi Airport to your destination. SaitirthTours & Travels offers reliable Shirdi airport taxi services, including airport pickup, drop, and temple transfers.",
            "url": "https://www.saitirthcabs.com/airport-taxi-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-airport-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Airport Taxi Service", 
                "Shirdi Airport Cab Service", 
                "Airport Taxi to Shirdi", 
                "Taxi from Shirdi Airport", 
                "Shirdi Airport Pickup Taxi", 
                "Shirdi Airport Drop Taxi", 
                "Shirdi Airport Transfer Taxi", 
                "Shirdi Airport Taxi Booking", 
                "Shirdi Airport to City Taxi", 
                "Affordable Airport Taxi Shirdi", 
                "Shirdi Airport to Shirdi Temple Taxi Fare", 
                "Cabs From Shirdi Airport to Shirdi Temple", 
                "Shirdi Airport to Shirdi Cab", 
                "Shirdi Airport to Shirdi Taxi", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Airport to Shirdi Temple Cabs", 
                "Shirdi Airport to Shirdi Temple Taxi", 
                "Shirdi to Airport Taxi", 
                "Innova Cab Shirdi Airport", 
                "Tempo Traveller on Rent Shirdi Airport", 
                "Ertiga on Rent in Shirdi Airport", 
                "Sedan Cab on Rent Shirdi Airport"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/30.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Airporttaxishirdi;