
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoajanta() {



  const cardData =
  {
    keyword: ' Shirdi to Ajanta Ellora Taxi Fare',
    heading: 'Saitirth Travels: Shirdi to Ajanta Ellora Taxi Fare',
    headingDescription: 'When it comes to traveling from Shirdi to Ajanta Ellora, Saitirth Travels offers reliable and comfortable taxi services that make your journey enjoyable and hassle-free. Our professional drivers ensure that you arrive at your destination safely and on time, allowing you to fully appreciate the beauty of the Ajanta and Ellora caves.',

    top: 'Top Places to Visit in Ajanta Ellora with Saitirth Travels',

    "topPlaces": [
    {
        "title": "1. Ajanta Caves",
        "description": "The Ajanta Caves are a UNESCO World Heritage Site famous for their rock-cut Buddhist cave monuments, which date back to the 2nd century BCE. The intricate murals and sculptures depict the life of Buddha and ancient Indian culture, making it a must-visit for history and art enthusiasts."
    },
    {
        "title": "2. Ellora Caves",
        "description": "Another UNESCO World Heritage Site, the Ellora Caves are renowned for their impressive rock-cut temples, featuring Hindu, Buddhist, and Jain architecture. The Kailasa Temple, dedicated to Lord Shiva, is the most remarkable of the caves, showcasing remarkable engineering and artistry."
    },
    {
        "title": "3. Buddhist Caves",
        "description": "The Buddhist caves within Ajanta are known for their stunning frescoes and statues that illustrate the life and teachings of Buddha. Visitors can admire the artistic achievements of ancient craftsmen as they explore these sacred sites."
    },
    {
        "title": "4. Kailasa Temple",
        "description": "Located in the Ellora complex, the Kailasa Temple is an architectural marvel carved from a single rock. Its grand design and intricate carvings attract visitors from around the world, providing a glimpse into the ingenuity of ancient Indian builders."
    },
    {
        "title": "5. Grishneshwar Temple",
        "description": "Close to the Ellora Caves, the Grishneshwar Temple is one of the twelve Jyotirlingas dedicated to Lord Shiva. The temple is revered for its spiritual significance and beautiful architecture, drawing numerous devotees."
    },
    {
        "title": "6. Daulatabad Fort",
        "description": "Situated near Aurangabad, Daulatabad Fort offers breathtaking views and rich history. The fort's impressive structure and strategic location make it a fascinating stop for visitors exploring the region."
    },
    {
        "title": "7. Ajanta Viewpoint",
        "description": "For stunning panoramic views of the Ajanta Caves, the Ajanta Viewpoint is a must-visit. The scenic beauty surrounding the caves adds to the experience, providing excellent photo opportunities."
    },
    {
        "title": "8. Local Markets",
        "description": "The local markets around Ajanta and Ellora offer traditional handicrafts, souvenirs, and local delicacies. Visitors can immerse themselves in the culture by exploring these vibrant markets."
    }
],  
    
            
        
    
"services": [
    {
        "name": "Shirdi to Ajanta Ellora Taxi Service",
        "description": "SaitirthTours & Travels offers a reliable taxi service from Shirdi to the iconic Ajanta and Ellora Caves, allowing you to explore these UNESCO World Heritage Sites with ease. Our professional drivers prioritize your comfort and safety, making your journey smooth and hassle-free. Enjoy clean, comfortable vehicles equipped for long-distance travel, with drivers knowledgeable about local attractions and history. This service is ideal for both tourists and pilgrims, providing a seamless travel experience from Shirdi to these historic sites renowned for their breathtaking rock-cut caves and intricate carvings."
    },
    {
        "name": "Shirdi to Ajanta Ellora Cab Fare",
        "description": "Our cab fare from Shirdi to Ajanta and Ellora is designed to offer maximum value with transparent and competitive pricing. We believe in providing clear and upfront billing so you can budget for your trip without surprises. Our fare structure includes details like base fare, per-kilometer charges, and any additional fees if applicable, ensuring you receive the best value for an enriching journey to these culturally significant locations. Ideal for solo travelers, families, or groups, we guarantee fair pricing aligned with the quality of service you deserve."
    },
    {
        "name": "Taxi Fare from Shirdi to Ajanta Ellora",
        "description": "SaitirthTours & Travels provides an accurate, transparent, and affordable taxi fare from Shirdi to Ajanta Ellora. Our fares are calculated based on the distance, vehicle type, and travel preferences to ensure that every journey is budget-friendly. Whether you choose a sedan, SUV, or larger vehicle, we make sure our pricing is straightforward and free of hidden costs, allowing you to focus on exploring these remarkable sites rather than worrying about expenses. Our pricing model is designed to give you peace of mind and value for your travel."
    },
    {
        "name": "Shirdi Ajanta Ellora Car Rental",
        "description": "Our car rental services for the Shirdi to Ajanta Ellora route offer flexibility and convenience, allowing you to explore at your own pace. With a variety of vehicle options to suit different group sizes and preferences, you can select the ideal car for a comfortable journey. Each vehicle is well-maintained and equipped with modern amenities, providing a pleasant travel experience. Additionally, our rental packages include insurance coverage for extra peace of mind, and we offer round-the-clock customer support to address any queries you might have during your journey."
    },
    {
        "name": "Shirdi to Ajanta Ellora One-Way Taxi",
        "description": "For those seeking a convenient, economical travel option, our Shirdi to Ajanta Ellora one-way taxi service allows you to pay for a single journey without the need to return to Shirdi. This is perfect for travelers who wish to explore further or continue their journey beyond Ajanta Ellora. With no obligations for a round trip, you can enjoy a cost-effective travel solution tailored to your itinerary. Our drivers are well-versed in the best routes, providing a smooth journey while you enjoy the scenery and plan your day at Ajanta Ellora."
    },
    {
        "name": "Shirdi to Ajanta Ellora Cab Booking",
        "description": "Booking your cab from Shirdi to Ajanta Ellora has never been easier. SaitirthTours & Travels offers a streamlined booking process, allowing you to reserve your cab online or through our dedicated customer support. Select your preferred vehicle, check real-time availability, and make secure payments within minutes. Our booking system is designed to provide a hassle-free experience, ensuring that you can start your journey promptly and without stress. We also offer last-minute bookings and flexible scheduling to accommodate any spontaneous travel plans."
    },
    {
        "name": "Shirdi to Ajanta Ellora Travel by Taxi",
        "description": "Travel comfortably from Shirdi to Ajanta Ellora with our specialized taxi service, designed for a safe and enjoyable journey. Our drivers are experienced, courteous, and familiar with the area, ensuring that you reach your destination without hassle. This travel option is perfect for visitors who want a private and relaxed way to reach these historical sites, bypassing the need for crowded public transportation. Our taxis are equipped with amenities to make your trip pleasant, including comfortable seating, air conditioning, and luggage space for all your essentials."
    },
    {
        "name": "Shirdi Ajanta Ellora Cab Rates",
        "description": "We offer competitive cab rates from Shirdi to Ajanta Ellora, providing clear and fair pricing that caters to a variety of budgets. Our transparent fare system includes details of base fare, per-kilometer charges, and any additional fees, so you know exactly what you are paying for. With a range of vehicles available at affordable rates, you can select the option that best suits your travel needs. Additionally, we offer periodic discounts for early bookings or group travel, making our service a budget-friendly choice for families and solo travelers alike."
    },
    {
        "name": "Shirdi to Ajanta Ellora Taxi Fare per KM",
        "description": "Our taxi fare from Shirdi to Ajanta Ellora is calculated on a per-kilometer basis, allowing you to understand and plan your travel costs accurately. This transparent pricing model means you pay only for the distance covered, making it an affordable and flexible choice for travelers. Whether you’re taking a short day trip or planning an extended stay, our per-kilometer rate offers great value and clarity, helping you stay within your budget while enjoying a reliable and comfortable ride to the Ajanta Ellora Caves."
    },
    {
        "name": "Affordable Shirdi Ajanta Ellora Cab",
        "description": "We understand the importance of affordability, which is why our Shirdi Ajanta Ellora cab service is designed with budget-conscious travelers in mind. Without compromising on comfort or quality, we offer economical travel options that make exploring the historic Ajanta and Ellora Caves accessible to everyone. Enjoy a clean, well-maintained vehicle and friendly driver service, all at a price that won’t strain your budget. SaitirthTours & Travels aims to make cultural exploration affordable for all, allowing you to make the most of your journey."
    },
    {
        "name": "Shirdi to Ellora Caves Taxi",
        "description": "Our taxi service from Shirdi to the Ellora Caves offers a direct and comfortable route to this ancient site, known for its stunning rock-cut temples and intricate carvings. With experienced drivers who are knowledgeable about the area, you can enjoy a safe and enjoyable ride as you learn more about the history of this UNESCO World Heritage Site. Our taxis are equipped with modern amenities for a smooth journey, and we offer various vehicle options to suit different group sizes, ensuring you have a memorable experience at Ellora."
    },
    {
        "name": "Shirdi to Ellora Taxi",
        "description": "Explore the Ellora Caves with ease using our Shirdi to Ellora taxi service. This service provides a comfortable and efficient way to reach one of India’s most famous archaeological sites. Our drivers are trained for long-distance travel, ensuring safety and punctuality on every trip. Whether you’re traveling solo or with family, our taxis offer the convenience and reliability you need for a fulfilling visit to Ellora. Book with us for a worry-free journey that prioritizes comfort and enjoyment."
    },
    {
        "name": "Taxi Fare from Shirdi to Ajanta Ellora",
        "description": "We provide an affordable and transparent taxi fare from Shirdi to Ajanta Ellora, giving you confidence in our pricing and service quality. Our fare includes a detailed breakdown of charges so you understand the total cost before your journey begins. SaitirthTours & Travels believes in fair pricing, offering excellent value for a comfortable and reliable service. Plan your budget easily with our upfront taxi fares, tailored to make your cultural exploration an enjoyable and stress-free experience."
    },
    {
        "name": "Shirdi to Ajanta Taxi Fare",
        "description": "Our Shirdi to Ajanta taxi fare is designed to offer value and transparency for travelers eager to explore the Ajanta Caves. Known for their ancient Buddhist murals and stunning architecture, Ajanta is a must-visit destination. With our clear fare structure, you can plan your trip knowing exactly what you’ll pay, free from hidden charges. Our service combines affordability with quality, ensuring you receive excellent value as you discover these remarkable caves in comfort."
    },
    {
        "name": "Shirdi to Ajanta Taxi Fare Contact Information",
        "description": "For more details or to book your trip, contact SaitirthTours & Travels at +91 9923879009 or +91 9922479009. Our friendly and knowledgeable customer service team is ready to assist with any inquiries, helping you plan the perfect journey to Ajanta and Ellora. We are committed to making your travel experience seamless and enjoyable, from booking to reaching your destination. With SaitirthTours & Travels, your journey to explore India's cultural heritage is just a call away."
    }
],
"tableData": [
    ["- Shirdi to Ajanta Ellora Taxi Service", "- Shirdi to Ajanta Ellora Cab Fare"],
    ["- Taxi Fare from Shirdi to Ajanta Ellora", "- Shirdi Ajanta Ellora Car Rental"],
    ["- Shirdi to Ajanta Ellora One Way Taxi", "- Shirdi to Ajanta Ellora Cab Booking"],
    ["- Shirdi to Ajanta Ellora Travel by Taxi", "- Shirdi Ajanta Ellora Cab Rates"],
    ["- Shirdi to Ajanta Ellora Taxi Fare per KM", "- Affordable Shirdi Ajanta Ellora Cab"],
    ["- Shirdi to Ajanta Ellora Taxi", "- SaitirthTours & Travels Shirdi"],
    ["- Shirdi to Ajanta Ellora Taxi Fare", "- Shirdi to Ajanta Taxi Fare"],
    ["- Shirdi to Ellora Caves Taxi", "- Shirdi to Ellora Taxi"],
    ["- Taxi Fare from Shirdi to Ajanta Ellora", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Competitive Pricing:",
        "WhyChoosedescription": "We provide affordable and competitive taxi fares from Shirdi to Ajanta Ellora, ensuring clear and upfront pricing without any hidden charges. Our cost-effective rates allow you to explore the historic sites of Ajanta and Ellora Caves within your budget. Whether you're planning a single trip or a round journey, our pricing is structured to give you value while maintaining the quality of service. With Saitirth Travels, you can plan your travels confidently, knowing that our fares are both reasonable and transparent."
    },
    {
        "WhyChooseheading": "Comfortable Rides:",
        "WhyChoosedescription": "Travel in comfort and style with our fleet of spacious, well-maintained vehicles. Each vehicle is equipped with comfortable seating, air conditioning, and ample space for both passengers and luggage, making it perfect for both short trips and longer journeys to Ajanta Ellora. Our vehicles are regularly serviced to ensure a smooth, safe, and reliable ride, providing you with a relaxing experience as you journey through the scenic landscapes of Maharashtra. We focus on delivering comfort in every mile, allowing you to sit back and enjoy the trip."
    },
    {
        "WhyChooseheading": "Knowledgeable Drivers:",
        "WhyChoosedescription": "Our drivers are highly experienced and knowledgeable about the Shirdi to Ajanta Ellora route, ensuring you reach your destination smoothly and efficiently. In addition to driving expertise, they are also well-versed with local history and cultural highlights, which they happily share, enhancing your journey with fascinating insights about the region. Whether it’s identifying key sights along the way or offering travel tips, our drivers strive to make your journey as informative and enjoyable as possible, providing a unique, guided travel experience."
    },
    {
        "WhyChooseheading": "Convenient Booking Options:",
        "WhyChoosedescription": "Booking a taxi with us is hassle-free, whether you prefer to do so online or via a quick call to our customer support team. Our easy-to-navigate website and responsive support make it simple to reserve your ride in just a few clicks. With instant booking confirmations and flexible payment options, you can organize your trip quickly and focus on enjoying your journey. We value your time, so we streamline the booking process to ensure every reservation is completed smoothly and efficiently."
    },
    {
        "WhyChooseheading": "Flexible Scheduling:",
        "WhyChoosedescription": "We operate 24/7 to cater to your scheduling needs, allowing you to plan your trip at any time that best suits you. Whether you're planning an early morning departure or a late-night return, our cabs are available to provide dependable transport whenever you need it. This flexibility makes us an ideal choice for travelers with specific schedules or those needing last-minute bookings. With our round-the-clock service, you can enjoy the convenience of planning your journey on your terms."
    },
    {
        "WhyChooseheading": "Safety and Hygiene:",
        "WhyChoosedescription": "Your safety is our top priority. All of our vehicles undergo rigorous maintenance checks to ensure they meet the highest safety standards, allowing you to travel worry-free. Additionally, we have implemented comprehensive cleaning and sanitization protocols, with vehicles sanitized thoroughly between each trip, ensuring a clean, hygienic environment for all passengers. Our commitment to safety extends to every aspect of your journey, giving you peace of mind as you explore the historic Ajanta and Ellora Caves."
    }
]

        
  }


  const faqData = [
    {
        question: "What is the fare for a taxi from Shirdi to Ajanta Ellora?",
        answer: "The fare generally ranges from INR 2,500 to INR 3,500, depending on the type of vehicle you choose and current demand. Please check our website for the latest rates."
    },
    {
        question: "How long does the journey take?",
        answer: "The drive from Shirdi to Ajanta Ellora typically takes around 3.5 to 4 hours, depending on traffic conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for extra stops or if you request a larger vehicle for your group."
    },
    {
        question: "How can I book a taxi for this route?",
        answer: "You can book your taxi easily through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept multiple payment options, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, modifications or cancellations can be made through our website or by contacting customer support, following our cancellation policy."
    },
    {
        question: "Is the taxi sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request extra stops during the trip?",
        answer: "Yes, you can request extra stops for refreshments or sightseeing along the way, as long as it fits within your travel schedule."
    }
];

const testimonials = [
    {
        name: "Mrs. Kavita Sharma",
        text: "Saitirth Travels provided an excellent taxi service for our trip from Shirdi to Ajanta Ellora. The driver was punctual and very friendly. The ride was comfortable, and we enjoyed every moment!"
    },
    {
        name: "Mr. Vikram Desai",
        text: "I highly recommend Saitirth Travels for anyone traveling from Shirdi to Ajanta Ellora. The booking process was easy, and the vehicle was spacious and well-maintained. A great experience overall!"
    }
];

  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Ajanta Ellora Taxi | SaitirthTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable taxi from Shirdi to Ajanta Ellora with SaitirthTours & Travels. We offer one-way and round-trip services for a comfortable ride." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Ajanta Ellora Taxi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable taxi from Shirdi to Ajanta Ellora with SaitirthTours & Travels. We offer one-way and round-trip services for a comfortable ride." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-ajanta-ellora-taxi-fare" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-ajanta-ellora-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a reliable and affordable taxi from Shirdi to Ajanta Ellora with SaitirthTours & Travels. We offer one-way and round-trip services for a comfortable ride.",
            "url": "https://www.saitirthcabs.com/shirdi-to-ajanta-ellora-taxi-fare",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-ajanta-ellora-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ajanta Ellora Taxi Service", 
                "Shirdi to Ajanta Ellora Cab Fare", 
                "Taxi Fare from Shirdi to Ajanta Ellora", 
                "Shirdi Ajanta Ellora Car Rental", 
                "Shirdi to Ajanta Ellora One Way Taxi", 
                "Shirdi to Ajanta Ellora Cab Booking", 
                "Shirdi to Ajanta Ellora Travel by Taxi", 
                "Shirdi Ajanta Ellora Cab Rates", 
                "Shirdi to Ajanta Ellora Taxi Fare per km", 
                "Affordable Shirdi Ajanta Ellora Cab", 
                "Shirdi to Ajanta Ellora Taxi", 
                "Shirdi to Ajanta Ellora Taxi Fare", 
                "Shirdi to Ajanta Taxi Fare", 
                "Shirdi to Ellora Caves Taxi", 
                "Shirdi to Ellora Taxi",
                "Taxi Fare from Shirdi to Ajanta Ellora"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/33.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoajanta;