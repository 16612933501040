import React from 'react';
import { Helmet } from 'react-helmet';
import GoogleTag from './GoogleTag';

const OurServices = () => {
    const services = [
        {
            title: 'Business Transfer',
            image: '/images/gallery/Img for Gallery-600x400px-02.jpg',
            link: 'page-service-details.html',
            description: "Space meets style in the Maruti Suzuki Ertiga—ideal for family and city journeys."
        },
        {
            title: 'Online Booking',
            image: '/images/gallery/Img for Gallery-600x400px-01.jpg',
            link: 'page-service-details.html',
            description: 'Effortlessly stylish, endlessly comfortable—experience the elegance of the Swift Dzire.'
        },
        {
            title: 'City Transport',
            image: '/images/gallery/Img for Gallery-600x400px-06.jpg',
            link: 'page-service-details.html',
            description: 'Where luxury meets adventure—elevate every journey with the Innova Crysta.'
        }
    ];

    return (

<div>
<GoogleTag/>
        <Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://www.saitirthcabs.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

        <section className="service-section-five">
            <div className="bg bg-image"></div>
            <div className="auto-container">
                <div className="sec-title text-center">
                    <span className="sub-title">What We Offer                    </span>
                    <h2 className="letters-slide-up text-split">Our Vehicle Fleet</h2>
                </div>
                <div className="row g-0" >
                    {services.map((service, index) => (
                        <div className="col-lg-4 col-sm-6 " key={index}>
                            {/* Service Block */}
                            <div className="service-block-five borderrr">
                                <div className="inner-box borderrr">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src={service.image} alt={service.title} />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <a href={service.link}>{service.title}</a>
                                        </h4>
                                        <div className="text">{service.description}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </div>
    );
};

export default OurServices;
