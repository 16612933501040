
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Ertigaonrentinshirdi() {



  const cardData =
  {
    keyword: ' Ertiga On Rent in Shirdi',
    heading: 'Saitirth Travels: Ertiga On Rent in Shirdi',
    headingDescription: 'Exploring the sacred town of Shirdi is made easy and comfortable with the availability of Ertiga on rent. This spacious and versatile MPV is ideal for families or groups, providing ample space for both passengers and luggage. Saitirth Travels ensures a seamless experience, whether you are here for a pilgrimage or to explore the local attractions.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The Sai Baba Temple is the heart of Shirdi and one of the most significant pilgrimage sites in India. Dedicated to the revered saint Sai Baba, the temple is a magnificent structure that can accommodate thousands of devotees at a time. The main shrine houses a beautiful idol of Sai Baba, often adorned with vibrant flowers and offered aarti (worship) several times a day. The temple complex features spacious courtyards, intricately carved pillars, and a serene atmosphere, allowing devotees to immerse themselves in prayer and reflection. Visitors can participate in various rituals and ceremonies throughout the day, including special events during religious festivals. The evening aarti is particularly enchanting, filled with devotion and spirituality, and draws large crowds who come to witness the fervent expressions of faith."
        },
        {
            "title": "Dwarkamai",
            "description": "Dwarkamai is an ancient mosque that holds immense historical and spiritual significance. It is believed to be where Sai Baba lived for most of his life and performed many miracles. The mosque features the sacred fire, Dhuni, that Baba lit and which still burns to this day, symbolizing the eternal spirit of his teachings. Visitors can admire the intricate calligraphy and photographs adorning the walls, illustrating Baba's life and the many devotees he touched. The tranquil ambiance here provides a perfect setting for meditation and reflection on Baba’s wisdom and teachings, making it a favored spot for spiritual seekers."
        },
        {
            "title": "Chavadi",
            "description": "Located close to Dwarkamai, Chavadi is another significant place associated with Sai Baba. It served as a resting place for Baba, who was known to spend alternate nights here, sharing his wisdom with devoted followers. The structure features a beautiful portrait of Sai Baba, surrounded by an atmosphere of reverence. The wooden architecture, reminiscent of traditional styles, adds to the charm of this location. The annual festival held here attracts numerous visitors, marked by special prayers and celebrations, making it a vibrant site of devotion and community gathering."
        },
        {
            "title": "Lendi Garden",
            "description": "Lendi Garden is a beautiful and serene spot for visitors seeking solace in nature. This garden was once frequented by Sai Baba for meditation and reflection, and it features a variety of plants, trees, and blooming flowers, making it an ideal place for a leisurely stroll or a quiet moment of contemplation. There are benches and shaded areas where visitors can sit and relax, enjoying the soothing sounds of nature. The garden is also home to a lovely well, believed to have been used by Sai Baba himself, adding to its charm as a place of tranquility and peace."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "The Shri Khandoba Mandir is a significant temple dedicated to Lord Khandoba, an incarnation of Lord Shiva. Situated atop a hill, the temple offers panoramic views of the surrounding landscape, making it a popular pilgrimage site. The impressive architecture features intricate carvings and sculptures, reflecting the devotion of its builders. Pilgrims visit this temple, especially during festivals, to seek blessings and participate in vibrant celebrations. Its elevated location not only provides breathtaking views but also creates a serene environment ideal for prayer and reflection."
        },
        {
            "title": "Maruti Mandir",
            "description": "Maruti Mandir is dedicated to Lord Hanuman and is a peaceful temple located near the Sai Baba Temple. It is a favored site among devotees who come to offer prayers and seek blessings for strength and courage. The temple features a serene ambiance, adorned with beautiful idols and traditional decor, creating an inviting atmosphere. Visitors often find this temple to be a perfect spot for quiet reflection and devotion, fostering a sense of inner peace and connection with the divine."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan holds a special place in the hearts of devotees, as it is believed to be where Sai Baba was first discovered by the villagers. The site features a small shrine with a portrait of Sai Baba and a sacred tree where many pilgrims tie threads to fulfill their wishes. The atmosphere is filled with spirituality and devotion, making it a must-visit for anyone seeking a deeper connection with Sai Baba’s teachings. It serves as a reminder of Baba’s humble beginnings and his journey as a spiritual leader."
        },
        {
            "title": "Sai Heritage Village",
            "description": "Sai Heritage Village is a cultural center that showcases the life and teachings of Sai Baba. This attraction features a series of exhibits, including replicas of historical structures, displays of traditional crafts, and interactive experiences for visitors. Guides share captivating stories of Baba’s life, making it an educational experience for all ages. The village aims to preserve the cultural heritage of the region while providing insights into the profound impact of Sai Baba on society, making it an enlightening stop for all visitors."
        },
        {
            "title": "Khandoba Temple",
            "description": "Khandoba Temple, located a short distance from Shirdi, is dedicated to Khandoba, a revered local deity. The temple is renowned for its beautiful architecture and serene surroundings, making it a popular destination for devotees. It attracts many visitors, especially during the annual fairs held in honor of Khandoba, where traditional rituals are performed. Pilgrims can witness these vibrant celebrations and participate in the festive atmosphere, experiencing the rich local culture and spirituality of the area."
        },
        {
            "title": "Sainath Mahal",
            "description": "Sainath Mahal is a guesthouse managed by the Sai Baba Sansthan Trust, providing comfortable accommodation for visitors coming to Shirdi. The mahal is conveniently located, making it easy for pilgrims to access the main attractions. The environment is calm and welcoming, with various facilities to ensure a pleasant stay. Staying at Sainath Mahal offers travelers a unique opportunity to immerse themselves in the spiritual ambiance of Shirdi, fostering a sense of community among fellow pilgrims."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Ertiga On Rent in Shirdi",
        "description": "When it comes to family travel or group outings, the Suzuki Ertiga offers the perfect blend of comfort, space, and affordability. Our Ertiga rental service in Shirdi is tailored to meet the diverse needs of travelers, ensuring a smooth and enjoyable journey."
    },
    {
        "name": "Book Ertiga Car in Shirdi",
        "description": "Booking an Ertiga car in Shirdi is easy with SaitirthTours & Travels. Whether you need a vehicle for a day trip or extended travel, our booking process is user-friendly, allowing you to secure your ride effortlessly."
    },
    {
        "name": "Book/Hire Suzuki ERTIGA in Shirdi",
        "description": "Experience the convenience of hiring a Suzuki Ertiga in Shirdi for your transportation needs. This versatile vehicle is designed to accommodate families and groups while providing ample space for luggage."
    },
    {
        "name": "Best Ertiga Taxi Service in Shirdi",
        "description": "We pride ourselves on offering the best Ertiga taxi service in Shirdi. Our professional drivers are experienced and knowledgeable about the area, ensuring you receive top-notch service throughout your journey."
    },
    {
        "name": "Ertiga Cab on Rent in Shirdi",
        "description": "Our Ertiga cab rental service in Shirdi is perfect for those looking to travel with comfort and style. The spacious interior and comfortable seating make it ideal for long drives or family trips."
    },
    {
        "name": "Ertiga Taxi Service in Shirdi",
        "description": "Choose our Ertiga taxi service in Shirdi for a reliable and convenient way to get around. Whether you’re visiting local attractions or need transportation to and from the airport, we've got you covered."
    },
    {
        "name": "Ertiga Cab Booking in Shirdi for Corporate",
        "description": "For businesses in need of transportation for employees, we offer specialized Ertiga cab booking services in Shirdi. Ensure your corporate travel is efficient and comfortable with our reliable service."
    },
    {
        "name": "Ertiga Cab for Wedding Car Rental",
        "description": "Make your special day even more memorable by renting an Ertiga for wedding transportation. Its spacious interior is perfect for bridal parties or family members, ensuring everyone arrives in style."
    },
    {
        "name": "Ertiga Rent Per Km in Shirdi",
        "description": "Our competitive pricing for Ertiga rentals is designed to fit any budget. We offer transparent rates, including rent per kilometer, so you can plan your journey without any surprises."
    },
    {
        "name": "Ertiga Cab for Wedding Car Rental Shirdi",
        "description": "Whether it’s for the bride and groom or family members, our Ertiga cab service for wedding car rentals in Shirdi guarantees a comfortable and stylish ride on your special day."
    },
    {
        "name": "Shirdi to Mumbai Ertiga Cab",
        "description": "Traveling from Shirdi to Mumbai? Our Ertiga cabs provide a comfortable and convenient option for this journey, ensuring you enjoy the ride with plenty of space for passengers and luggage."
    },
    {
        "name": "Ertiga On Rent in Shirdi MIDC",
        "description": "If you're located in the MIDC area, our Ertiga on rent service is readily available to meet your transportation needs. Enjoy the spaciousness and comfort of the Ertiga for all your travel requirements."
    },
    {
        "name": "Shirdi to Mumbai Airport Ertiga Taxi Service",
        "description": "Need transportation to Mumbai Airport? Our Ertiga taxi service offers a reliable and comfortable option for airport transfers, ensuring you reach on time and in style."
    },
    {
        "name": "Shirdi to Aurangabad Ertiga Cab",
        "description": "Our Ertiga cabs are also available for journeys from Shirdi to Aurangabad. Experience a smooth ride with our professional drivers, making your trip enjoyable from start to finish."
    },
    {
        "name": "SaitirthTours & Travels",
        "description": "Choose SaitirthTours & Travels for your Ertiga rental needs in Shirdi. We are committed to providing exceptional service and well-maintained vehicles to enhance your travel experience."
    },
    {
        "name": "Ertiga On Rent in Shirdi Contact Information",
        "description": "For bookings or inquiries, contact SaitirthTours & Travels at +91 9923879009 / +91 9922479009. We look forward to serving you and making your journey unforgettable!"
    }
],

tableData: [
    ["- Book Ertiga Car in Shirdi", "- Book/Hire Suzuki ERTIGA in Shirdi"],
    ["- Best Ertiga Taxi Service in Shirdi", "- Ertiga Cab on Rent in Shirdi"],
    ["- Ertiga Taxi Service in Shirdi", "- Ertiga Cab Booking in Shirdi for Corporate"],
    ["- Ertiga Cab For Wedding Car Rental", "- Ertiga Rent Per Km in Shirdi"],
    ["- Ertiga Cab for Wedding Car Rental Shirdi", "- Shirdi to Mumbai Ertiga Cab"],
    ["- Ertiga On Rent in Shirdi MIDC", "- Shirdi to Mumbai Airport Ertiga Taxi Service"],
    ["- Shirdi to Aurangabad Ertiga Cab", "- SaitirthTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Comfortable Family Vehicle",
      "WhyChoosedescription": "The Suzuki Ertiga is the ideal vehicle for families and small groups looking for a combination of comfort and affordability. With ample space for passengers and luggage, it offers the perfect balance between practicality and style. Whether you're heading out on a family vacation, corporate trip, or attending a wedding, the Ertiga ensures that your journey is comfortable and enjoyable, making it an excellent choice for both short and long trips around Shirdi and beyond."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "At SaitirthTours & Travels, we offer competitive rental rates for the Suzuki Ertiga, ensuring you get the best value for your money. We believe that quality service should be accessible, and our pricing is designed to provide affordability without compromising on comfort or safety. Whether you're renting for a day, a weekend, or an extended trip, our flexible pricing options ensure that you only pay for what you need."
    },
    {
      "WhyChooseheading": "Well-Maintained and Clean",
      "WhyChoosedescription": "We take great pride in maintaining the cleanliness and condition of our vehicles. Each Suzuki Ertiga is thoroughly inspected, cleaned, and sanitized after every trip to ensure that it meets our high standards of comfort and hygiene. Whether you're renting the vehicle for a corporate event, wedding, or family outing, you can trust that your ride will be in pristine condition, providing a pleasant and hygienic travel experience."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our team of experienced drivers is knowledgeable about Shirdi’s routes and local attractions, ensuring a smooth and efficient travel experience. They are well-trained in providing exceptional service, ensuring your safety and comfort throughout the journey. Whether you're traveling for leisure, business, or religious purposes, our drivers will ensure you reach your destination safely and on time, making your experience both enjoyable and stress-free."
    },
    {
      "WhyChooseheading": "Flexible Rental Plans",
      "WhyChoosedescription": "We understand that every trip is unique, which is why we offer flexible rental plans to suit your specific needs. Whether you're planning a short one-way trip, a full-day rental for sightseeing, or a longer journey to a nearby city, we provide tailored rental packages that allow you to plan your travel itinerary with ease. Our flexibility ensures that you only pay for the time you need, offering maximum convenience and value."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking your Suzuki Ertiga rental is a quick and easy process with our user-friendly online booking system or phone booking options. You can secure your ride in advance and enjoy peace of mind knowing your vehicle will be ready for pickup when you need it. We also offer personalized assistance for any special requests, making your booking experience seamless and hassle-free."
    },
    {
      "WhyChooseheading": "Punctuality and Reliability",
      "WhyChoosedescription": "At SaitirthTours & Travels, we understand the importance of timeliness, which is why we guarantee punctual pickups and drop-offs. Whether you have an early morning flight, a business meeting, or a scheduled wedding, we make sure our vehicles arrive on time to meet your needs. Our commitment to punctuality and reliability ensures that you can depend on us to stick to your schedule, making your travel experience stress-free."
    },
    {
      "WhyChooseheading": "24/7 Support",
      "WhyChoosedescription": "Our dedicated support team is available around the clock to assist with any questions, changes to your booking, or additional requests you may have. Whether you need help with your reservation or require assistance during your trip, we are here to provide you with timely support and ensure your experience with us is smooth and hassle-free. With our 24/7 availability, you can always rely on us for help whenever you need it."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What is the rental rate for the Ertiga in Shirdi?",
        answer: "Rental rates depend on trip duration and distance. Contact us for an accurate quote."
    },
    {
        question: "Can I rent the Ertiga for outstation trips?",
        answer: "Yes, we offer outstation rental options for the Ertiga. Reach out to learn about the packages."
    },
    {
        question: "Is a driver provided with the Ertiga rental?",
        answer: "Yes, our rentals include professional drivers familiar with the local and outstation routes."
    },
    {
        question: "Is fuel cost included in the rental price?",
        answer: "Generally, fuel is not included. However, we can customize a fuel-inclusive package for you."
    },
    {
        question: "Can I extend my rental duration during the trip?",
        answer: "Yes, extensions are available depending on vehicle availability, with adjusted rates."
    },
    {
        question: "What documents are required to rent the Ertiga?",
        answer: "A valid ID is necessary. For self-drive options (if available), a valid driver’s license is required."
    },
    {
        question: "Is the Ertiga suitable for long-distance journeys?",
        answer: "Yes, the Ertiga is known for its comfort and efficiency, making it ideal for longer trips."
    },
    {
        question: "Are discounts available for multi-day rentals?",
        answer: "Yes, we offer discounts for extended bookings. Please contact us for details."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We offer a flexible cancellation policy. For more information, contact our support team."
    },
    {
        question: "Is local pickup and drop within Shirdi included?",
        answer: "Yes, we provide convenient pickup and drop services within Shirdi and nearby locations."
    }
];

const testimonials = [
    {
        name: "Mr. Vikram Singh",
        text: "Renting the Ertiga from Saitirth Travels for my trip to Shirdi was a fantastic experience! The car was spacious and comfortable, making our journey enjoyable. The team was friendly and helpful, ensuring everything went smoothly from start to finish. I highly recommend Saitirth Travels for anyone looking to rent a car in Shirdi!"
    },
    {
        name: "Ms. Anjali Rao",
        text: "I had a great time using Saitirth Travels for my Ertiga rental while visiting Shirdi. The vehicle was well-maintained and perfect for my family. The booking process was easy, and the staff was very professional. I will definitely use their services again for my future trips!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Ertiga On Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels</title>
    <meta name="description" content="Rent Suzuki Ertiga in Shirdi with SaitirthTours & Travels. Offering Ertiga rental services for weddings, corporate events, and outstation trips including to Mumbai, Aurangabad, and more." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Ertiga On Rent in Shirdi | Best Taxi Service | SaitirthTours & Travels" />
    <meta property="og:description" content="Rent Suzuki Ertiga in Shirdi with SaitirthTours & Travels. Offering Ertiga rental services for weddings, corporate events, and outstation trips including to Mumbai, Aurangabad, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/ertiga-on-rent-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/ertiga-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Rent Suzuki Ertiga in Shirdi with SaitirthTours & Travels. Offering Ertiga rental services for weddings, corporate events, and outstation trips including to Mumbai, Aurangabad, and more.",
            "url": "https://www.saitirthcabs.com/ertiga-on-rent-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/ertiga-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Book Ertiga Car in Shirdi",
                "Book/Hire Suzuki ERTIGA in Shirdi",
                "Best Ertiga Taxi Service in Shirdi",
                "Ertiga Cab on Rent in Shirdi",
                "Ertiga Taxi Service in Shirdi",
                "Ertiga Cab Booking in Shirdi for Corporate",
                "Ertiga Cab For Wedding Car Rental",
                "Ertiga Rent Per Km in Shirdi",
                "Ertiga Cab for Wedding Car Rental Shirdi",
                "Shirdi to Mumbai Ertiga Cab",
                "Ertiga On Rent in Shirdi MIDC",
                "Shirdi to Mumbai Airport Ertiga Taxi Service",
                "Shirdi to Aurangabad Ertiga Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/75.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Ertigaonrentinshirdi;