
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditooutstation() {



  const cardData =
  {
    keyword: ' Shirdi to Outstation Cabs',
    heading: 'Saitirth Travels: Shirdi to Outstation Cabs',
    headingDescription: 'Planning an outstation trip from Shirdi? Saitirth Travels offers convenient and reliable outstation cab services to explore popular destinations in Maharashtra and beyond. Enjoy a comfortable ride with professional drivers who ensure a smooth journey, whether for pilgrimage, leisure, or business. With options for one-way and round trips, our service is flexible to your travel needs.',

    top: 'Top Outstation Destinations from Shirdi with Saitirth Travels',

    topPlaces: [
      {
          "title": "Mumbai",
          "description": "Discover the vibrant city of Mumbai with its bustling streets, iconic Gateway of India, Marine Drive, and famous temples. Our cab service ensures a hassle-free journey, covering approximately 240 km in around 5 hours."
      },
      {
          "title": "Pune",
          "description": "Explore Pune's rich cultural heritage, including Shaniwar Wada, Aga Khan Palace, and numerous gardens. Pune is about 183 km from Shirdi, making it a quick 4-hour drive with our comfortable cabs."
      },
      {
          "title": "Nashik",
          "description": "Known for its vineyards, Nashik is a great destination for wine lovers and pilgrims alike. Visit the Sula Vineyards, Trimbakeshwar Temple, and Pandav Leni Caves. The journey is about 90 km, taking around 2 hours."
      },
      {
          "title": "Aurangabad",
          "description": "A historical city, Aurangabad is home to the famous Ajanta and Ellora Caves, Bibi Ka Maqbara, and Daulatabad Fort. The distance from Shirdi to Aurangabad is 108 km, which takes about 2.5 hours."
      },
      {
          "title": "Trimbak",
          "description": "Ideal for pilgrims, Trimbak is known for the revered Trimbakeshwar Temple, one of the 12 Jyotirlingas. Located 117 km away, the journey takes roughly 3 hours."
      },
      {
          "title": "Mahabaleshwar",
          "description": "Escape to the hill station of Mahabaleshwar, famous for its lush green valleys, strawberry farms, and viewpoints like Arthur’s Seat and Elephant's Head. This scenic route is around 300 km, taking about 6-7 hours."
      },
      {
          "title": "Bhandardara",
          "description": "For nature lovers, Bhandardara offers waterfalls, lakes, and trekking trails. The journey to this peaceful retreat is approximately 70 km, which takes around 2 hours."
      },
      {
          "title": "Alibaug",
          "description": "Known for its pristine beaches and forts, Alibaug is perfect for a relaxing getaway. Located about 280 km from Shirdi, the drive takes roughly 6 hours."
      },
      {
          "title": "Lonavala",
          "description": "Enjoy the lush green hills, waterfalls, and forts in Lonavala, an ideal place for a relaxing day trip. The distance of about 200 km is covered in around 4.5 hours."
      },
      {
          "title": "Pandharpur",
          "description": "Visit the famous Vitthal-Rukmini Temple in Pandharpur, a spiritual site for pilgrims. The journey is approximately 330 km, taking around 7 hours."
      }
  ],  
    
            
        
"services": [
    {
        "name": "Shirdi to Pune Outstation Cab",
        "description": "Travel from Shirdi to Pune effortlessly with our outstation cab services. Enjoy a smooth ride with experienced drivers who know the best routes, making your journey quick and enjoyable."
    },
    {
        "name": "Shirdi to Mumbai Taxi Service",
        "description": "Our Shirdi to Mumbai taxi service offers you a convenient way to travel to the city. With flexible booking options, you can choose the time that best suits your schedule."
    },
    {
        "name": "Shirdi to Nasik Cab Booking",
        "description": "Planning a trip to Nasik? Book your cab with us for a hassle-free experience. Our competitive rates and reliable service make us the preferred choice for travelers."
    },
    {
        "name": "Best Cab from Shirdi to Shegaon",
        "description": "Experience the best in travel with our cabs from Shirdi to Shegaon. We offer comfortable vehicles and friendly drivers to ensure a pleasant journey."
    },
    {
        "name": "Cheap Taxi Shirdi to Surat",
        "description": "Looking for affordable transportation? Our cheap taxi service from Shirdi to Surat provides great value without compromising on comfort or safety."
    },
    {
        "name": "Book Cab Shirdi to Nashik",
        "description": "Booking a cab from Shirdi to Nashik has never been easier. Our online booking system allows you to reserve your vehicle in just a few clicks."
    },
    {
        "name": "One-way Taxi Shirdi to Verul",
        "description": "Our one-way taxi service from Shirdi to Verul is perfect for those who need a straightforward journey without the hassle of returning. Enjoy the freedom of travel with us."
    },
    {
        "name": "Round Trip Cab Shirdi to Nagpur",
        "description": "Planning a round trip? Our cab service from Shirdi to Nagpur ensures you have reliable transportation for your entire journey, whether it's for business or leisure."
    },
    {
        "name": "Car Rental Shirdi to Akola",
        "description": "For travelers heading to Akola, our car rental service offers a variety of vehicles to suit your needs. Enjoy the flexibility of driving at your own pace."
    },
    {
        "name": "Reliable Cab Shirdi to Satara",
        "description": "Trust our reliable cab service for your journey from Shirdi to Satara. Our well-maintained vehicles and professional drivers guarantee a safe and pleasant ride."
    },
    {
        "name": "Shirdi to Outstation Cabs Booking",
        "description": "Booking an outstation cab from Shirdi is simple and efficient. With a wide selection of vehicles, including sedans and SUVs, we cater to all types of travelers."
    },
    {
        "name": "Best Taxi Service in Shirdi",
        "description": "We pride ourselves on being the best taxi service in Shirdi, offering prompt pickups, clean vehicles, and friendly drivers."
    },
    {
        "name": "Top Rated Cab Service in Shirdi",
        "description": "Our commitment to customer satisfaction has earned us a reputation as the top-rated cab service in Shirdi. Join the many satisfied customers who choose us for their travel needs."
    },
    {
        "name": "Outstation Online Cab in Shirdi",
        "description": "Booking an outstation cab online in Shirdi is quick and easy. Visit our website or call us to reserve your vehicle today!"
    },
    {
        "name": "Outstation Taxi Service Ahmednagar",
        "description": "Our outstation taxi service extends to Ahmednagar, providing travelers with reliable transportation options for various destinations."
    },
    {
        "name": "Outstation Taxi Service Ahilyanagar",
        "description": "Choose our outstation taxi service for trips to Ahilyanagar, and enjoy seamless travel experiences tailored to your itinerary."
    },
    {
        "name": "Outstation Cab Service in Shani Shingnapur",
        "description": "We also offer outstation cab services in Shani Shingnapur, making it easy for pilgrims and travelers to explore this popular destination."
    },
    {
        "name": "Outstation Tempo Traveller in Shirdi",
        "description": "For larger groups, our outstation tempo traveller service in Shirdi is an excellent option. Enjoy spacious seating and comfortable rides for all your adventures."
    },
    {
        "name": "Innova Taxi Rates for Outstation",
        "description": "Our Innova taxis are perfect for outstation trips, offering ample space and comfort. Contact us for competitive rates tailored to your travel requirements."
    },
    {
        "name": "Shirdi to Outstation Cabs Contact Information",
        "description": "For more information or to book your Shirdi to outstation cab, contact SaitirthTours & Travels at +91 9923879009 / 9922479009. We are dedicated to providing you with a seamless travel experience, ensuring your journey is both enjoyable and memorable."
    }
],
"tableData": [
    ["- Shirdi to Pune Outstation Cab", "- Shirdi to Mumbai Taxi Service"],
    ["- Shirdi to Nasik Cab Booking", "- Best Cab from Shirdi to Shegaon"],
    ["- Cheap Taxi Shirdi to Surat", "- Book Cab Shirdi to Nashik"],
    ["- One-way Taxi Shirdi to Verul", "- Round Trip Cab Shirdi to Nagpur"],
    ["- Car Rental Shirdi to Akola", "- Reliable Cab Shirdi to Satara"],
    ["- Shirdi to Outstation Cabs", "- Best Taxi Service in Shirdi"],
    ["- Top Rated Cab Service in Shirdi", "- Outstation Online Cab in Shirdi"],
    ["- Outstation Taxi Service Ahmednagar", "- Outstation Taxi Service Ahilyanagar"],
    ["- Outstation Cab Service in Shani Shingnapur", "- SaitirthTours & Travels Shirdi"],
    ["- Outstation Tempo Traveller in Shirdi", "- Innova Taxi Rates for Outstation"]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Wide Range of Vehicles",
      "WhyChoosedescription": "We provide a diverse fleet of well-maintained vehicles suitable for outstation travel, including sedans, SUVs, and vans to accommodate any group size. Whether you’re traveling alone or with a group, our selection ensures comfort and convenience."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are well-trained and knowledgeable about various routes, ensuring a safe and comfortable journey to your destination. They prioritize your safety and are committed to providing a pleasant travel experience."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We offer easy and flexible booking options, allowing you to schedule your trip as per your convenience, whether it’s for a day trip or a longer journey. Our booking process is hassle-free and customizable to suit your needs."
    },
    {
      "WhyChooseheading": "Competitive Pricing",
      "WhyChoosedescription": "Our cab services are competitively priced, providing value for money without compromising on quality and comfort. Enjoy an affordable yet premium travel experience with transparent pricing and no hidden costs."
    },
    {
      "WhyChooseheading": "Punctual Service",
      "WhyChoosedescription": "Timeliness is our commitment. We ensure that our drivers arrive on time for pick-up, helping you stay on schedule during your travels. Our focus on punctuality gives you peace of mind knowing you’ll arrive on time."
    },
    {
      "WhyChooseheading": "Hassle-Free Experience",
      "WhyChoosedescription": "Our team is dedicated to providing a seamless travel experience from booking to drop-off, so you can focus on enjoying your journey. Let us handle the details while you relax and make the most of your trip."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers have excellent knowledge of local attractions and can suggest interesting stops or detours along your route for a more enjoyable experience. Enhance your journey with insider tips on must-visit spots."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our customer service team is available around the clock to assist you with any queries or changes regarding your booking. Count on us for responsive support whenever you need it."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "We prioritize your safety with regular vehicle maintenance and adherence to all safety regulations, ensuring a worry-free journey. Travel with confidence knowing that your well-being is our top priority."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "We take pride in our positive reviews and testimonials from previous clients who appreciated our commitment to quality service. Join our satisfied customers and experience our trusted and highly-rated cab service."
    }
  ]




        
  }


  const faqData = [
    {
        question: "What types of vehicles are available for outstation cabs?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and larger vans, to cater to different group sizes and preferences."
    },
    {
        question: "How do I book an outstation cab?",
        answer: "You can easily book through our website or by calling our customer service team for assistance."
    },
    {
        question: "Are drivers included in the cab service?",
        answer: "Yes, all our outstation cabs come with experienced drivers who will manage the transportation for your journey."
    },
    {
        question: "Is it possible to customize the itinerary?",
        answer: "Yes, we can customize your itinerary based on your preferences and travel plans."
    },
    {
        question: "What are the payment options available?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online payment options."
    },
    {
        question: "Can I cancel or reschedule my booking?",
        answer: "Yes, you can cancel or reschedule your booking, but please inform us as early as possible to avoid cancellation fees."
    },
    {
        question: "Are there any additional charges for outstation trips?",
        answer: "Additional charges may apply based on distance and time, which will be communicated during the booking process."
    },
    {
        question: "Do you provide services for long-distance outstation trips?",
        answer: "Yes, we cater to both short and long-distance outstation trips, ensuring comfort throughout your journey."
    },
    {
        question: "What if I need a cab for multiple days?",
        answer: "We offer flexible packages for multiple-day rentals, which can be customized to suit your travel needs."
    },
    {
        question: "How can I contact you for more information?",
        answer: "You can reach out to us via phone or email for any inquiries or further information regarding our outstation cab services."
    }
];

const testimonials = [
    {
        name: "Mr. Rahul Mehta",
        text: "I booked an outstation cab with Saitirth Travels for a family trip, and the service was excellent! The car was spacious and comfortable, and our driver was friendly and professional. Highly recommend for anyone needing reliable transportation!"
    },
    {
        name: "Mrs. Neha Desai",
        text: "Our experience with Saitirth Travels for our outstation trip was fantastic. The booking process was easy, and our driver was very punctual and knowledgeable about the route. It made our journey stress-free and enjoyable!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | SaitirthTours & Travels | Outstation Cabs from Shirdi | Best Taxi Services for Outstation Travel</title>
    <meta name="description" content="Book reliable outstation cabs from Shirdi to Pune, Mumbai, Nasik, Surat, and more with SaitirthTours & Travels. Offering affordable one-way and round-trip taxi services, including Innova, Tempo Traveller, and more!" />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthTours & Travels | Outstation Cabs from Shirdi | Best Taxi Services for Outstation Travel" />
    <meta property="og:description" content="Book reliable outstation cabs from Shirdi to Pune, Mumbai, Nasik, Surat, and more with SaitirthTours & Travels. Offering affordable one-way and round-trip taxi services, including Innova, Tempo Traveller, and more!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-outstation-cabs" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/outstation-cabs-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book reliable outstation cabs from Shirdi to Pune, Mumbai, Nasik, Surat, and more with SaitirthTours & Travels. Offering affordable one-way and round-trip taxi services, including Innova, Tempo Traveller, and more!",
            "url": "https://www.saitirthcabs.com/shirdi-to-outstation-cabs",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/outstation-cabs-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Pune Outstation cab",
                "Shirdi to Mumbai Taxi Service",
                "Shirdi to Nasik Cab Booking",
                "Best Cab from Shirdi to Shegaon",
                "Cheap Taxi Shirdi to Surat",
                "Book Cab Shirdi to Nashik",
                "One-way Taxi Shirdi to Verul",
                "Round Trip Cab Shirdi to Nagpur",
                "Car Rental Shirdi to Akola",
                "Reliable Cab Shirdi to Satara",
                "Shirdi to Outstation Cabs Booking",
                "Best Taxi Service in Shirdi",
                "Top Rated Cab Service in Shirdi",
                "Outstation Online Cab in Shirdi",
                "Outstation Taxi Service Ahmednagar",
                "Outstation Taxi Service Ahilyanagar",
                "Outstation Cab Service in Shani Shingnapur",
                "SaitirthTours & Travels Shirdi",
                "Outstation Tempo Traveller in Shirdi",
                "Innova Taxi Rates for Outstation"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/64.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditooutstation;