
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdicarrental() {



  const cardData =
  {
    keyword: ' Shirdi Car Rental',
    heading: 'Saitirth Travels: Shirdi Car Rental',
    headingDescription: 'When it comes to exploring the divine town of Shirdi, Saitirth Travels offers premium car rental services that cater to your every travel need. Whether you are visiting for pilgrimage, tourism, or business, our extensive fleet of well-maintained vehicles ensures that you travel in comfort and style. We provide flexible rental options that allow you to explore Shirdi and its surroundings at your own pace.',
    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

    topPlaces: [
        {
            "title": "1. Shirdi Sai Baba Temple",
            "description": "The Shirdi Sai Baba Temple is the heart of Shirdi, attracting millions of devotees from around the world. This sacred shrine is built around the samadhi (resting place) of Sai Baba, who is revered as a saint by followers of various faiths. The temple complex features beautiful architecture, spacious courtyards, and intricate carvings that enhance the spiritual ambiance. Visitors can witness the daily aarti (ritual) and partake in various religious ceremonies, all while soaking in the profound atmosphere that promotes inner peace and devotion."
        },
        {
            "title": "2. Samadhi Mandir",
            "description": "The Samadhi Mandir is an essential pilgrimage site for devotees, as it houses the final resting place of Sai Baba. The serene atmosphere envelops visitors, providing a sanctuary for reflection and prayer. The temple is adorned with beautiful floral decorations, and devotees often gather to chant prayers and seek blessings. Special ceremonies, especially during festivals, create an emotional experience, allowing visitors to feel the divine presence of Sai Baba."
        },
        {
            "title": "3. Khandoba Mandir",
            "description": "Khandoba Mandir, dedicated to Lord Khandoba, is one of the oldest temples in Shirdi. Known for its unique architectural style, this temple is set in a tranquil environment, making it a great spot for spiritual gatherings and worship. The temple is especially vibrant during festivals, drawing in large crowds of devotees. The historical significance of Khandoba's connection to Sai Baba adds to its importance, as it is believed to be the first place where Sai Baba was recognized as 'Sai.'"
        },
        {
            "title": "4. Shirdi Village",
            "description": "Exploring the charming Shirdi Village provides visitors with an authentic glimpse into the local culture and traditions. The village is home to warm and hospitable residents who often share stories of Sai Baba's life. Visitors can engage with local artisans, sample traditional snacks, and buy handicrafts that reflect the rich cultural heritage of the region. Walking through the village enhances the spiritual journey, connecting visitors to the historical and cultural roots of Sai Baba's teachings."
        },
        {
            "title": "5. Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust plays a vital role in managing the temple and ensuring the well-being of devotees. It offers various facilities, including accommodation, free meals, and medical aid, embodying the spirit of community service promoted by Sai Baba. The trust also organizes events and educational programs that delve into the life and teachings of Sai Baba, helping visitors understand the impact of his message of love, compassion, and unity across different faiths."
        },
        {
            "title": "6. Dwarkamai",
            "description": "Dwarkamai is a significant mosque where Sai Baba lived for much of his life. This historical site is filled with relics that connect visitors to Baba’s legacy, including the sacred fire that Baba kept burning throughout his life. The atmosphere at Dwarkamai is one of profound peace and unity, welcoming people from all walks of life. Visitors often spend time here reflecting on Sai Baba’s teachings of love and compassion, making it a deeply spiritual experience."
        },
        {
            "title": "7. Chavadi",
            "description": "Chavadi is where Sai Baba would rest every alternate night, making it a revered site for devotees. The Chavadi procession, which re-enacts Baba's nightly journey, is a major attraction for visitors. The simple structure embodies the humility and simplicity of Sai Baba's life, and the peaceful surroundings provide a perfect setting for meditation and contemplation. This historical site holds significant meaning for devotees, allowing them to connect deeply with Baba's teachings."
        },
        {
            "title": "8. Nanda Deep",
            "description": "Nanda Deep is an eternal flame located near the Samadhi Mandir, symbolizing Sai Baba's presence and his eternal teachings. Many devotees come to this sacred spot to meditate and pray, finding solace in the flickering flame that represents hope and enlightenment. The atmosphere around Nanda Deep is one of tranquility, allowing visitors to pause, reflect, and connect with their spirituality in a meaningful way."
        },
        {
            "title": "9. Shirdi Water Park",
            "description": "Shirdi Water Park provides a fun and refreshing break from the spiritual activities in the town. It features a variety of water rides, wave pools, and relaxation areas suitable for all ages, making it an ideal destination for families. After a day of devotion and exploration, visitors can enjoy the thrilling rides and create joyful memories with loved ones, adding a playful aspect to their Shirdi experience."
        },
        {
            "title": "10. Local Markets",
            "description": "The bustling local markets of Shirdi are a vibrant tapestry of colors and sounds, where visitors can immerse themselves in the culture. From religious souvenirs and local handicrafts to traditional snacks, the markets offer a variety of items that reflect the region's heritage. Engaging with local vendors, trying street food, and shopping for unique items are all part of the experience, making it a great way to connect with the local community and take home a piece of Shirdi."
        },
        {
            "title": "11. Nearby Attractions",
            "description": "For those interested in exploring further, nearby attractions like the Ajanta and Ellora Caves offer stunning ancient rock-cut temples and intricate sculptures, showcasing the artistry of ancient India. These UNESCO World Heritage Sites are a must-visit for history buffs and provide an opportunity to delve deeper into the rich cultural heritage of the region. Day trips to these sites can greatly enrich the Shirdi experience, combining spirituality with historical exploration."
        }
    ],
    
    services: [
        {
            "name": "Best Cab Service in Shirdi",
            "description": "When it comes to exploring the spiritual haven of Shirdi, SaitirthTours & Travels offers the best cab service tailored to your travel needs. Our cabs are comfortable and reliable, ensuring a smooth journey whether you're visiting the iconic Shirdi Sai Baba Temple or exploring local attractions. Experience our commitment to excellence and convenience throughout your travels."
          },
          {
            "name": "Reliable Taxi Service in Shirdi",
            "description": "Our reliable taxi service in Shirdi guarantees punctuality and safety, making us the preferred choice for both pilgrims and tourists. With experienced drivers who know the local routes, you can rest assured that you will reach your destination on time and enjoy a stress-free journey."
          },
          {
            "name": "Affordable Cab Service in Shirdi",
            "description": "Traveling on a budget? Our affordable cab service in Shirdi is designed to provide quality transportation without breaking the bank. We offer a variety of packages that cater to diverse needs, allowing you to experience the best of Shirdi without worrying about high transportation costs."
          },
          {
            "name": "Shirdi Car Rental Options",
            "description": "For those who prefer flexibility, our Shirdi car rental options allow you to hire a vehicle for a day or longer. Explore Shirdi at your own pace with our well-maintained cars that ensure a comfortable and safe driving experience."
          },
          {
            "name": "Top Cab Company in Shirdi",
            "description": "As the top cab company in Shirdi, we prioritize customer satisfaction above all else. Our fleet includes various vehicles suitable for solo travelers, families, and groups, all equipped with modern amenities to enhance your journey."
          },
          {
            "name": "Taxi Booking in Shirdi",
            "description": "Booking a taxi in Shirdi has never been easier! Our online taxi booking service allows you to reserve your ride with just a few clicks, ensuring a seamless travel experience from start to finish."
          },
          {
            "name": "Shirdi Cab Transportation Service",
            "description": "Our Shirdi cab transportation service covers all essential routes and attractions, from bustling local markets to serene temples. We ensure you visit all the highlights of Shirdi with ease and comfort."
          },
          {
            "name": "Comfortable Cabs in Shirdi",
            "description": "Comfort is our priority. Our comfortable cabs in Shirdi are designed for a relaxing journey for every passenger. Whether you’re traveling alone or with companions, expect a smooth ride and friendly service."
          },
          {
            "name": "Shirdi Taxi Hire",
            "description": "Looking for a Shirdi taxi hire service? We offer flexible hiring options to accommodate your travel itinerary, ensuring our dedicated team is ready to assist with all your transportation needs."
          },
          {
            "name": "Best Taxi Service in Shirdi",
            "description": "At SaitirthTours & Travels, we take pride in being the best taxi service in Shirdi. Our knowledgeable drivers are familiar with local attractions, ensuring you get the most out of your visit while enjoying reliability and convenience."
          },
          {
            "name": "Innova Crysta on Rent in Shirdi",
            "description": "Traveling with a group? Our Innova Crysta on rent in Shirdi is an excellent choice for larger parties. This spacious vehicle allows families to travel together comfortably, enjoying ample legroom and modern amenities."
          },
          {
            "name": "Tempo Traveller on Rent in Shirdi",
            "description": "For larger groups, we offer the best tempo traveller on rent in Shirdi. Ideal for family outings or group pilgrimages, our tempo travellers are well-equipped to ensure a comfortable journey for everyone."
          },
          {
            "name": "Online Cab Booking in Shirdi",
            "description": "Experience the convenience of our online cab service in Shirdi. With just a few clicks, you can book a cab that suits your travel needs, allowing you to focus on enjoying your spiritual journey."
          },
          {
            "name": "Outstation Taxi in Shirdi",
            "description": "Planning to explore nearby destinations? Our outstation taxi in Shirdi service is perfect for day trips or extended excursions. Whether you want to visit Nasik, Aurangabad, or any other nearby location, we provide comfortable and reliable transportation."
          },
          {
            "name": "Contact Information for Best Cab Service in Shirdi",
            "description": "Contact SaitirthTours & Travels at +91 9923879009 or +91 9922479009 for prompt and efficient cab service in Shirdi. We ensure a smooth and enjoyable ride for all our customers, making your journey memorable."
          }
          
    ],
   "tableData": [
    ["- Shirdi Car Rental", "- Rent a car in Shirdi with Driver"],
    ["- SaitirthTours & Travels Shirdi", "- Shirdi Taxi Rental for Local Sightseeing"],
    ["- Car Hire Shirdi", "- Affordable Car Rental Shirdi"],
    ["- Rental Car Shirdi Sai Baba Temple", "- Best Car Rental Shirdi"],
    ["- Shirdi Airport Car Rental", "- Shirdi Car Rental"],
    ["- Shirdi Cab Service", "- Cab Booking in Shirdi"],
    ["- Car Hire in Shirdi Rent a car for Shirdi", "- Shirdi Car Hire for Outstation Cabs"],
    ["- Shirdi Taxi Cab Service", "- Travel Agents in Shirdi"],
    ["- Tours and Travels in Shirdi", "- Best Travel Agency in Shirdi"],
    ["- Shirdi Cab Booking Service", "- Shirdi Saibaba Travels"]
  ],

  "whychoose": [
    {
      "WhyChooseheading": "Why Choose Saitirth Travels Shirdi Car Rental?",
      "WhyChoosedescription": "At Saitirth Travels, we strive to provide an exceptional car rental experience in Shirdi, ensuring that your journey is as memorable as your destination. Our commitment to customer satisfaction and quality service makes us a trusted choice for travelers. Here’s why you should choose us:"
    },
    {
      "WhyChooseheading": "Wide Range of Vehicles:",
      "WhyChoosedescription": "We offer a diverse fleet of well-maintained cars, ranging from economical sedans to luxurious SUVs, allowing you to select the perfect vehicle tailored to your specific needs and budget. Whether you're traveling alone or with a group, we have options that provide comfort, style, and reliability for every type of journey, including city tours, long-distance travel, or special occasions."
    },
    {
      "WhyChooseheading": "Affordable Rates:",
      "WhyChoosedescription": "Our competitive pricing structure ensures you receive the best value for your money without compromising on the quality of service. We believe that excellent transportation should be accessible to everyone, which is why we offer transparent pricing with no hidden fees. Our pricing options are flexible, accommodating different budgets while ensuring a high level of service that enhances your travel experience."
    },
    {
      "WhyChooseheading": "Flexible Rental Periods:",
      "WhyChoosedescription": "Whether you need a car for a few hours, a day, or an extended rental period, we provide flexible options designed to accommodate your travel plans. You can customize your rental duration based on your itinerary, allowing you the freedom to explore Shirdi and surrounding areas at your own pace without any constraints."
    },
    {
      "WhyChooseheading": "Easy Booking Process:",
      "WhyChoosedescription": "Our user-friendly online booking system makes reserving your car straightforward and hassle-free. You can complete your reservation in just a few clicks, with options to choose your vehicle, rental duration, and additional services. For added convenience, our customer support team is available via phone or chat to assist you with any questions or modifications you may need during the booking process."
    },
    {
      "WhyChooseheading": "Transparent Policies:",
      "WhyChoosedescription": "We pride ourselves on our clear and transparent rental policies, ensuring you know exactly what to expect without any surprises. Our agreements are straightforward, with all fees clearly outlined upfront, so you can plan your budget without worries. This commitment to transparency helps us build trust with our customers and ensures a smooth rental experience from start to finish."
    },
    {
      "WhyChooseheading": "24/7 Customer Support:",
      "WhyChoosedescription": "Our dedicated support team is available around the clock to assist you with any questions or concerns you might have before, during, and after your rental. Whether you need help with your booking, have inquiries about our services, or require assistance during your rental, our friendly and knowledgeable staff are just a call away, ensuring you receive prompt and efficient support."
    },
    {
      "WhyChooseheading": "Safety and Maintenance:",
      "WhyChoosedescription": "The safety of our customers is our top priority. All our vehicles undergo regular maintenance checks and thorough cleaning to provide you with a safe and comfortable ride throughout your journey. We conduct comprehensive inspections of our fleet to ensure each car meets our high standards for safety and reliability, allowing you to travel with peace of mind."
    }
  ]
  }


  const faqData = [
    {
      question: "What types of cars are available for rent?",
      answer: "We offer a variety of vehicles, including hatchbacks, sedans, SUVs, and luxury cars, catering to different preferences and budgets."
    },
    {
      question: "How do I book a car rental?",
      answer: "You can book your car rental easily through our website or by contacting our customer support team for assistance."
    },
    {
      question: "What is the minimum rental period?",
      answer: "The minimum rental period is typically 4 hours, but we also offer full-day rentals and long-term options."
    },
    {
      question: "Are there any mileage restrictions?",
      answer: "Yes, we provide a set number of kilometers for your rental, with additional charges applicable for exceeding this limit. Please check our terms for specifics."
    },
    {
      question: "What documents are required for renting a car?",
      answer: "You will need a valid driver’s license, identification proof, and a credit/debit card for the booking process."
    },
    {
      question: "Can I return the car at a different location?",
      answer: "We can arrange for drop-off at a different location upon request, subject to availability and additional charges."
    },
    {
      question: "What happens if I return the car late?",
      answer: "Late returns may incur additional charges, so we recommend notifying us in advance if you expect to be late."
    },
    {
      question: "Is insurance included in the rental price?",
      answer: "Yes, basic insurance coverage is included in the rental price. Additional coverage options are also available for peace of mind."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Sanjay Mehta",
      text: "I rented a car from Saitirth Travels during my visit to Shirdi, and the experience was fantastic! The vehicle was in great condition, and the staff were very helpful throughout the process."
    },
    {
      name: "Mrs. Anjali Rao",
      text: "Saitirth Travels made my car rental experience smooth and stress-free. The online booking was easy, and the car was delivered on time. Highly recommend for anyone visiting Shirdi!"
    }
  ];
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Car Rental | SaitirthTours & Travels</title>
    <meta name="description" content="Reliable and affordable car rental service in Shirdi. Rent a car with a driver for local sightseeing, outstation travel, and more. Experience comfort with SaitirthTours & Travels." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Car Rental | SaitirthTours & Travels" />
    <meta property="og:description" content="Reliable and affordable car rental service in Shirdi. Rent a car with a driver for local sightseeing, outstation travel, and more. Experience comfort with SaitirthTours & Travels." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-car-rental" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-car-rental.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Reliable and affordable car rental service in Shirdi. Rent a car with a driver for local sightseeing, outstation travel, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-car-rental",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-car-rental.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Car Rental", 
                "Rent a car in Shirdi with Driver", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Taxi Rental for Local Sightseeing", 
                "Car Hire Shirdi", 
                "Affordable Car Rental Shirdi", 
                "Rental car Shirdi Sai Baba Temple", 
                "Best Car Rental Shirdi", 
                "Shirdi Airport Car Rental", 
                "Shirdi Cab Service", 
                "Cab Booking in Shirdi", 
                "Car Hire in Shirdi", 
                "Rent a car for Shirdi", 
                "Shirdi Car Hire for Outstation Cabs", 
                "Shirdi Taxi Cab Service", 
                "Travel Agents in Shirdi", 
                "Tours and Travels in Shirdi", 
                "Best Travel Agency in Shirdi", 
                "Shirdi Cab Booking Service", 
                "Shirdi Saibaba Travels", 
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/17.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdicarrental;