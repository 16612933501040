
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditomahabaleshwar() {



  const cardData =
  {
    keyword: ' Shirdi to Mahabaleshwar Cab',
    heading: 'Saitirth Travels: Shirdi to Mahabaleshwar Cab',
    headingDescription: 'When it comes to traveling from Shirdi to Mahabaleshwar, Saitirth Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Mahabaleshwar.',

    top: 'Top Places to Visit in Mahabaleshwar with Saitirth Travels',

  "topPlaces": [
    {
        "title": "1. Venna Lake",
        "description": "Venna Lake is a picturesque destination surrounded by lush greenery, offering a variety of boating facilities. Visitors can enjoy pedal boats and horse rides while taking in the serene views. The lake is a popular picnic spot, making it ideal for families and couples seeking a peaceful retreat."
    },
    {
        "title": "2. Pratapgad Fort",
        "description": "Built in the 17th century, Pratapgad Fort is a historic site that provides breathtaking views of the surrounding valleys and hills. With its impressive architecture and rich history, the fort is a must-visit for history enthusiasts and trekkers looking to explore its cultural significance."
    },
    {
        "title": "3. Arthur’s Seat",
        "description": "Known as the 'Queen of the Points,' Arthur's Seat offers stunning panoramic views of the valley below. This viewpoint is famous for its unique geological formations and is a popular spot for photography and nature lovers wishing to capture the beauty of the landscape."
    },
    {
        "title": "4. Mahabaleshwar Temple",
        "description": "This ancient temple dedicated to Lord Shiva is one of the most significant religious sites in the region. The temple's beautiful architecture and spiritual ambiance attract numerous pilgrims and tourists, making it a central part of Mahabaleshwar's cultural heritage."
    },
    {
        "title": "5. Kate's Point",
        "description": "Kate's Point is another popular viewpoint that provides breathtaking views of the Western Ghats and the Krishna Valley. It is an ideal spot for watching the sunset and enjoying the natural beauty of the region, attracting both tourists and local visitors alike."
    },
    {
        "title": "6. Mapro Garden",
        "description": "A well-known strawberry farm and garden, Mapro Garden is famous for its delicious strawberry-based products. Visitors can indulge in fresh strawberries, ice creams, and various snacks while exploring the beautiful surroundings of the garden, making it a delightful stop for food lovers."
    },
    {
        "title": "7. Lingmala Waterfall",
        "description": "Lingmala Waterfall is a stunning natural attraction located amidst dense forests. Particularly captivating during the monsoon season, the trek to the waterfall is refreshing, and the sight of the cascading water is mesmerizing, making it a perfect spot for nature enthusiasts."
    },
    {
        "title": "8. Elephant's Head Point",
        "description": "Named for its resemblance to an elephant's head, this viewpoint offers breathtaking vistas of the surrounding hills and valleys. It's a great spot for a leisurely walk and capturing stunning photographs of the natural beauty that defines Mahabaleshwar."
    },
    {
        "title": "9. Tapola",
        "description": "Often referred to as the 'Mini Mahabaleshwar,' Tapola is a serene village located near Mahabaleshwar. Known for its scenic beauty, it is the perfect destination for boating and enjoying the tranquility of nature, making it a favored spot for those looking to unwind."
    },
    {
        "title": "10. Dhobi Waterfall",
        "description": "Dhobi Waterfall is a beautiful waterfall situated just a few kilometers from Mahabaleshwar. Its lush surroundings and the sound of flowing water create a peaceful atmosphere, making it an ideal spot for picnics and nature walks for families and groups."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to Mahabaleshwar Taxi Service",
        "description": "Experience a comfortable journey from Shirdi to the hill station of Mahabaleshwar with SaitirthTours & Travels, providing reliable and smooth travel."
    },
    {
        "name": "Shirdi to Mahabaleshwar Cab Fare",
        "description": "We offer competitive and transparent cab fare options from Shirdi to Mahabaleshwar, ensuring affordability without compromising comfort."
    },
    {
        "name": "Taxi Fare from Shirdi to Mahabaleshwar",
        "description": "Our taxi fares are upfront and transparent, allowing you to plan your trip without any hidden charges."
    },
    {
        "name": "Shirdi Mahabaleshwar Car Rental",
        "description": "Choose from a variety of rental cars, including spacious sedans and SUVs, for a convenient ride from Shirdi to Mahabaleshwar."
    },
    {
        "name": "Shirdi to Mahabaleshwar One-Way Taxi",
        "description": "Enjoy one-way travel from Shirdi to Mahabaleshwar, paying only for the distance you cover with no round-trip obligation."
    },
    {
        "name": "Shirdi to Mahabaleshwar Cab Booking",
        "description": "Our easy online cab booking system lets you reserve your Shirdi to Mahabaleshwar ride conveniently, ensuring a hassle-free experience."
    },
    {
        "name": "Shirdi to Mahabaleshwar Travel by Taxi",
        "description": "Our professional taxi service ensures a relaxing journey to Mahabaleshwar, allowing you to enjoy the scenic route."
    },
    {
        "name": "Shirdi Mahabaleshwar Cab Rates",
        "description": "We provide competitive rates for cabs from Shirdi to Mahabaleshwar, offering budget-friendly options to suit all travelers."
    },
    {
        "name": "Shirdi to Mahabaleshwar Taxi Fare per km",
        "description": "Our per-kilometer rate is fair and affordable, so you can easily calculate the travel cost for your trip."
    },
    {
        "name": "Affordable Shirdi Mahabaleshwar Cab",
        "description": "SaitirthTours & Travels offers affordable cab options from Shirdi to Mahabaleshwar, maintaining quality service at economical prices."
    },
    {
        "name": "Shirdi to Mahabaleshwar Cab",
        "description": "Our cab service from Shirdi to Mahabaleshwar ensures a comfortable journey, with various vehicle options tailored to your needs."
    },
    {
        "name": "Shirdi to Panchgani Cab Booking",
        "description": "Extend your journey with our cab service from Shirdi to Panchgani, providing reliable and convenient travel options."
    },
    {
        "name": "Shirdi to Panchgani Taxi Service",
        "description": "We offer dependable taxi services from Shirdi to Panchgani, ensuring a comfortable ride to this scenic destination."
    },
    {
        "name": "Shirdi to Tapola Taxi Service",
        "description": "Explore Tapola with our taxi service, providing a relaxing journey from Shirdi to this peaceful destination."
    },
    {
        "name": "Shirdi to Mahabaleshwar Tempo Traveller on Rent",
        "description": "Ideal for group travel, our Tempo Traveller rental offers spacious seating for a convenient trip to Mahabaleshwar."
    },
    {
        "name": "Shirdi to Mahabaleshwar Innova on Rent",
        "description": "Our Innova rentals provide comfortable travel for families and small groups from Shirdi to Mahabaleshwar."
    },
    {
        "name": "Shirdi to Mahabaleshwar Ertiga on Rent",
        "description": "Travel in comfort with our Ertiga rental, perfect for groups looking for a spacious and comfortable journey."
    },
    {
        "name": "Shirdi to Mahabaleshwar 2 Days Package",
        "description": "Our 2-day package from Shirdi to Mahabaleshwar includes transport and sightseeing options, ideal for an extended stay."
    },
    {
        "name": "Shirdi to Mahabaleshwar Online Cab Booking",
        "description": "Book your Shirdi to Mahabaleshwar cab online with ease, offering flexibility and convenience in planning your journey."
    },
    {
        "name": "Contact Information",
        "description": "For bookings and inquiries, contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Enjoy a comfortable journey from Shirdi to Mahabaleshwar with our dedicated taxi services."
    }
],
"tableData": [
    ["- Shirdi to Mahabaleshwar Taxi Service", "- Shirdi to Mahabaleshwar Cab Fare"],
    ["- Taxi Fare from Shirdi to Mahabaleshwar", "- Shirdi Mahabaleshwar Car Rental"],
    ["- Shirdi to Mahabaleshwar One Way Taxi", "- Shirdi to Mahabaleshwar Cab Booking"],
    ["- Shirdi to Mahabaleshwar Travel by Taxi", "- Shirdi Mahabaleshwar Cab Rates"],
    ["- Shirdi to Mahabaleshwar Taxi Fare Per KM", "- Affordable Shirdi Mahabaleshwar Cab"],
    ["- Shirdi to Mahabaleshwar Cab", "- Shirdi to Mahabaleshwar Taxi Fare"],
    ["- Shirdi to Panchgani Cab Booking", "- Shirdi to Panchgani Taxi Service"],
    ["- Shirdi to Tapola Taxi Service", "- Shirdi to Mahabaleshwar Tempo Traveller on Rent"],
    ["- Shirdi to Mahabaleshwar Innova on Rent", "- SaitirthTours & Travels Shirdi"],
    ["- Shirdi to Mahabaleshwar Ertiga on Rent", "- Shirdi to Mahabaleshwar 2 Days Package"],
    ["- Shirdi to Mahabaleshwar Online Cab Booking"]
],
"whychoose": [
    {
        "WhyChooseheading": "Affordable Pricing:",
        "WhyChoosedescription": "At Saitirth Travels, we believe in offering excellent service without compromising affordability. Our fares from Shirdi to Mahabaleshwar are highly competitive, providing transparency in pricing so you can travel worry-free. We offer value-driven fares that cater to both individual travelers and families, ensuring you stay within budget. Additionally, we guarantee no hidden charges, allowing you to fully enjoy the journey with peace of mind."
    },
    {
        "WhyChooseheading": "Comfort and Convenience:",
        "WhyChoosedescription": "We prioritize your comfort on the road, providing a fleet of spacious, clean, and well-maintained vehicles. Our vehicles are designed for a relaxed travel experience, with options from compact cars to large SUVs and Tempo Travellers, suitable for individuals, families, and larger groups. With plush seating, ample luggage space, and climate control, every ride from Shirdi to Mahabaleshwar is a comfortable journey, allowing you to arrive at your destination refreshed and ready to explore."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only professional and courteous but also knowledgeable about the best routes and notable sights along the way. With extensive experience on the Shirdi to Mahabaleshwar route, they ensure a safe and smooth journey while offering valuable local insights about Mahabaleshwar, Panchgani, and Tapola. Whether you’re curious about historical spots or scenic viewpoints, our drivers are happy to enhance your travel experience with their local expertise."
    },
    {
        "WhyChooseheading": "Simple Booking Process:",
        "WhyChoosedescription": "We value your time and aim to make booking your taxi as simple as possible. You can conveniently book your cab from Shirdi to Mahabaleshwar through our user-friendly online platform, available on both desktop and mobile. For travelers who prefer personalized assistance, our dedicated customer support team is just a phone call away, ready to assist with special requests, custom itineraries, and any questions. Our streamlined booking system ensures an easy, hassle-free experience."
    },
    {
        "WhyChooseheading": "Flexible Scheduling:",
        "WhyChoosedescription": "We understand that travel schedules vary, which is why we offer 24/7 availability for trips from Shirdi to Mahabaleshwar. With complete flexibility in choosing your departure and return times, we accommodate early morning trips, late-night returns, or any schedule that suits you. Whether you’re planning a quick getaway or a leisurely journey, our service is designed to adapt to your plans, providing convenience at every step."
    },
    {
        "WhyChooseheading": "Safety Measures:",
        "WhyChoosedescription": "Your safety is our top priority. All our vehicles are rigorously maintained and regularly sanitized to ensure a clean and safe travel environment. We follow strict hygiene practices to make sure you can relax and enjoy your journey without concern. Our drivers also adhere to all safety regulations, offering a safe and secure ride. With Saitirth Travels, you can travel confidently, knowing that we prioritize your well-being at every mile."
    }
]


        
  }


  const faqData = [
    {
        question: "What is the fare for a cab from Shirdi to Mahabaleshwar?",
        answer: "The fare usually ranges from INR 4,500 to INR 6,000, depending on the vehicle type and demand. Please check our website for the latest pricing."
    },
    {
        question: "How long does the journey take?",
        answer: "The journey from Shirdi to Mahabaleshwar typically takes about 5 to 6 hours, depending on traffic conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Extra charges may apply for additional stops or if you select a larger vehicle for your trip."
    },
    {
        question: "How can I book a cab for this route?",
        answer: "You can easily book your cab through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment options, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking by contacting customer support or through our website, following our cancellation policy."
    },
    {
        question: "Is the cab sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request extra stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mr. Rahul Joshi",
        text: "I had an amazing experience traveling from Shirdi to Mahabaleshwar with Saitirth Travels. The driver was punctual and the car was comfortable. Highly recommended!"
    },
    {
        name: "Mrs. Neeta Sharma",
        text: "Saitirth Travels made our trip to Mahabaleshwar stress-free. The booking process was smooth, and our driver shared great stories about the region. Would definitely book again!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mahabaleshwar Cab | SaitirthTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable taxi from Shirdi to Mahabaleshwar with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Mahabaleshwar Cab | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable taxi from Shirdi to Mahabaleshwar with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-mahabaleshwar-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-mahabaleshwar-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a reliable and affordable taxi from Shirdi to Mahabaleshwar with SaitirthTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online.",
            "url": "https://www.saitirthcabs.com/shirdi-to-mahabaleshwar-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-mahabaleshwar-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Mahabaleshwar taxi service",
                "Shirdi to Mahabaleshwar cab fare",
                "Taxi fare from Shirdi to Mahabaleshwar",
                "Shirdi Mahabaleshwar car rental",
                "Shirdi to Mahabaleshwar one way taxi",
                "Shirdi to Mahabaleshwar cab booking",
                "Shirdi to Mahabaleshwar travel by taxi",
                "Shirdi Mahabaleshwar cab rates",
                "Shirdi to Mahabaleshwar taxi fare per km",
                "Affordable Shirdi Mahabaleshwar cab",
                "Shirdi to Mahabaleshwar cab",
                "Shirdi to Mahabaleshwar taxi fare",
                "Shirdi to Panchgani cab booking",
                "Shirdi to Panchgani taxi service",
                "Shirdi to Tapola taxi service",
                "Shirdi to Mahabaleshwar tempo traveller on rent",
                "Shirdi to Mahabaleshwar Innova on rent",
                "Shirdi to Mahabaleshwar Ertiga on rent",
                "Shirdi to Mahabaleshwar 2 days package",
                "Shirdi to Mahabaleshwar online cab booking"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/36.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomahabaleshwar;