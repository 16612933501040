
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirdiairport() {



  const cardData =
  {
    keyword: '  Shirdi Airport to Shirdi Temple Taxi Fare',
    heading: 'Saitirth Travels: Shirdi Airport to Shirdi Temple Taxi Fare ',
    headingDescription: 'Traveling from Shirdi Airport to the Shirdi Temple is a seamless experience with Saitirth Travels. Our reliable taxi services ensure you reach one of the most revered pilgrimage sites in India comfortably and conveniently. We prioritize your satisfaction, providing affordable fares and excellent service, making your journey a memorable part of your visit to Shirdi.',

    top: 'Top Places to Visit Near Shirdi Temple with Saitirth Travels',

    "topPlaces": [
        {
            "title": "1. Shirdi Sai Baba Temple",
            "description": "This iconic temple is the main attraction in Shirdi, dedicated to the revered saint Sai Baba. Known for its grand architecture and spiritual ambiance, the temple draws millions of devotees each year. Visitors can witness the daily rituals, participate in prayers, and experience the serene atmosphere that Sai Baba created through his teachings of love and compassion."
        },
        {
            "title": "2. Dwarkamai Masjid",
            "description": "Located close to the Sai Baba Temple, Dwarkamai Masjid was where Sai Baba spent a significant part of his life. This mosque features a sacred fire that has been burning for over a century, symbolizing the eternal presence of Sai Baba. The site is revered for its historical significance and offers a glimpse into the life and teachings of the saint."
        },
        {
            "title": "3. Chavadi",
            "description": "Just a short distance from the temple, Chavadi is another important site associated with Sai Baba. It served as his residence for many years, and visitors can see the wooden platform where he would sleep. The Chavadi is also the site of a special procession every Thursday, where Baba’s portrait is carried to honor his memory."
        },
        {
            "title": "4. Samadhi Mandir",
            "description": "The Samadhi Mandir is where Sai Baba was laid to rest, making it one of the most sacred spots for devotees. The temple houses a marble statue of Sai Baba, and the ambiance is filled with devotion and spirituality. Visitors often spend time meditating and reflecting in this tranquil environment."
        },
        {
            "title": "5. Lendi Garden",
            "description": "A peaceful garden situated near the Sai Baba Temple, Lendi Garden is an excellent spot for relaxation and reflection. The garden features beautiful landscaping, fountains, and seating areas. It’s a great place to take a break from the hustle and bustle of the temple activities."
        },
        {
            "title": "6. Khandoba Temple",
            "description": "Located on the outskirts of Shirdi, Khandoba Temple is dedicated to Lord Khandoba, an incarnation of Lord Shiva. This temple holds historical significance as it is believed that Sai Baba was welcomed to Shirdi by the local deity Khandoba. The temple’s architecture and spiritual ambiance make it a popular stop for visitors."
        },
        {
            "title": "7. Shri Saibaba Sansthan Trust",
            "description": "This organization manages the Sai Baba Temple and its activities, including the distribution of prasad and accommodations for pilgrims. The trust also runs various charitable initiatives, ensuring that Sai Baba’s teachings of service and compassion are upheld. Visitors can learn more about the trust's efforts to support the community and the temple’s operations."
        },
        {
            "title": "8. Sai Heritage Village",
            "description": "A cultural attraction that offers insights into the life of Sai Baba, Sai Heritage Village is designed to showcase the traditions and customs of the time. Visitors can explore replicas of historical structures and participate in various activities that reflect the lifestyle during Sai Baba's era."
        },
        {
            "title": "9. Siddhivinayak Ganapati Temple",
            "description": "This temple, dedicated to Lord Ganesh, is located near the Sai Baba Temple and is frequented by devotees seeking blessings before embarking on their spiritual journeys. The temple’s serene environment and beautiful idol of Ganesh make it a peaceful spot for worship and reflection."
        },
        {
            "title": "10. Shopping Areas",
            "description": "Shirdi has various markets and shops where visitors can purchase religious souvenirs, books, and local handicrafts. Engaging with local vendors offers a glimpse into the culture of Shirdi, and you can take home unique items to remember your visit."
        }
    ],        
        
    
    "services": [
        {
            "name": "Shirdi Airport to Shirdi Temple Taxi Fare",
            "description": "Traveling from Shirdi Airport to Shirdi Temple is a vital part of many visitors' spiritual journeys. At SaitirthTours & Travels, we offer convenient and reliable taxi services tailored to meet your needs, ensuring a smooth and comfortable transition from the airport to the temple. Our experienced drivers are well-acquainted with the area, providing you with valuable insights along the way. Whether you are arriving for a pilgrimage or a leisurely visit, our taxi service guarantees a pleasant journey."
        },
        {
            "name": "Shirdi Airport to Shirdi Temple Taxi Service",
            "description": "Our Shirdi Airport to Shirdi Temple taxi service promises a comfortable ride, equipped with well-maintained vehicles that prioritize passenger comfort and safety. Each vehicle is fitted with air conditioning and modern amenities to enhance your travel experience. With our professional drivers at the helm, you can relax and enjoy the scenic views, knowing you are in capable hands as you make your way to the revered temple."
        },
        {
            "name": "Shirdi Airport to Shirdi Temple Cab Fare",
            "description": "Understanding the Shirdi Airport to Shirdi Temple cab fare is essential for your trip planning. We offer competitive rates, ensuring transparency with a clear pricing structure and no hidden charges. This clarity allows you to budget effectively for your travel, making your pilgrimage more focused on spiritual fulfillment rather than financial worries."
        },
        {
            "name": "Taxi Fare from Shirdi Airport to Shirdi Temple",
            "description": "The taxi fare from Shirdi Airport to Shirdi Temple is designed to be affordable while maintaining high-quality service standards. Our transparent pricing ensures you know exactly what you are paying for your ride, allowing you to make informed choices. We pride ourselves on providing value for money without compromising the quality of your experience."
        },
        {
            "name": "Shirdi Airport to Sai Baba Temple Cab Rates",
            "description": "For visitors specifically heading to the Sai Baba Temple, our Shirdi Airport to Sai Baba Temple cab rates are highly competitive. We offer dedicated service that prioritizes your comfort and convenience, ensuring a seamless journey that allows you to focus on your spiritual experience. Our commitment to customer satisfaction means that every ride is handled with care and attention."
        },
        {
            "name": "Shirdi Airport to Shirdi Temple Cab Booking",
            "description": "Booking your Shirdi Airport to Shirdi Temple cab is simple and straightforward. You can use our user-friendly online platform or contact us directly to secure your ride in advance. This ensures that you have reliable transportation waiting for you upon your arrival, allowing you to start your spiritual journey without delay."
        },
        {
            "name": "Shirdi Airport to Shirdi Temple Taxi Tariff",
            "description": "Our Shirdi Airport to Shirdi Temple taxi tariff offers excellent value for money. We pride ourselves on providing transparent pricing with no unexpected costs, enabling you to plan your travel expenses accurately. Whether you are traveling solo or in a group, our tariffs cater to different budgets while ensuring top-notch service."
        },
        {
            "name": "Affordable Shirdi Airport to Shirdi Temple Cab",
            "description": "Looking for an affordable Shirdi Airport to Shirdi Temple cab? Our services are priced to be budget-friendly without compromising on quality. We believe everyone should have access to reliable transportation, especially during significant spiritual visits. Our commitment to affordability ensures you receive excellent service at competitive rates."
        },
        {
            "name": "Best Taxi Service from Shirdi Airport to Shirdi Temple",
            "description": "We strive to be recognized as the best taxi service from Shirdi Airport to Shirdi Temple. Our commitment to customer satisfaction, punctuality, and safety sets us apart from the competition. We continuously refine our services to meet and exceed our customers' expectations, ensuring that your travel experience is nothing short of exceptional."
        },
        {
            "name": "Shirdi Airport Cabs",
            "description": "Our Shirdi Airport cabs service offers a range of vehicles to suit your travel needs. Whether you require a standard taxi for a solo trip or a larger vehicle for a group pilgrimage, we have options available to meet your requirements. Each vehicle is maintained to the highest standards, ensuring a safe and comfortable ride."
        },
        {
            "name": "Shirdi Airport Taxi Services",
            "description": "With our Shirdi Airport taxi services, you can expect a seamless travel experience. Our drivers are professional and knowledgeable, ensuring you reach your destination efficiently and comfortably. Our focus on punctuality and reliability means that you can count on us for timely pickups and drop-offs."
        },
        {
            "name": "Shirdi Airport to Aurangabad Cab Service",
            "description": "If you're considering a trip to Aurangabad as part of your travel itinerary, we also provide a Shirdi Airport to Aurangabad cab service. Our network ensures that your travel plans can be easily accommodated, allowing you to explore the historical and cultural sites of Aurangabad with ease."
        },
        {
            "name": "Shirdi Airport to Manmad Cab Fare",
            "description": "For those traveling further, our Shirdi Airport to Manmad cab fare is designed to be competitive and affordable. We believe in providing exceptional value to our customers, ensuring that you can travel comfortably and conveniently to your next destination."
        },
        {
            "name": "Shirdi Airport to Ahmednagar Cab Service",
            "description": "Additionally, we offer a Shirdi Airport to Ahmednagar cab service, providing flexibility for travelers wishing to explore the nearby region. Our drivers are well-versed in the routes and can help you make the most of your trip."
        },
        {
            "name": "Shirdi Airport Cab Booking",
            "description": "Booking your Shirdi Airport cab has never been easier. With our user-friendly platform, you can secure your transportation quickly and efficiently. Enjoy a stress-free experience by reserving your cab in advance, ensuring that you have a reliable ride ready for you upon arrival."
        },
        {
            "name": "Shirdi Airport to Shani Shingnapur Cab",
            "description": "If your travels include a visit to Shani Shingnapur, we also provide Shirdi Airport to Shani Shingnapur cab services. Our drivers will take you to this popular destination efficiently, allowing you to experience the revered site at your convenience."
        },
        {
            "name": "Shirdi Airport to Shirdi Temple Taxi Fare Contact Number",
            "description": "For prompt and efficient service inquiries regarding Shirdi Airport to Shirdi Temple taxi fare, contact Shirdi Saibaba Travels at +91 9923879009 or +91 9922479009. Our dedicated team is here to ensure you have a smooth and enjoyable ride."
        }
    ],    
    "tableData": [
        ["- Shirdi Airport to Shirdi Temple Taxi Service", "- SaitirthTours & Travels Shirdi"],
        ["- Shirdi Airport to Shirdi Temple Cab Fare", "- Taxi Fare from Shirdi Airport to Shirdi Temple"],
        ["- Shirdi Airport to Sai Baba Temple Cab Rates", "- Shirdi Airport to Shirdi Temple Cab Booking"],
        ["- Shirdi Airport to Shirdi Temple Taxi Tariff", "- Affordable Shirdi Airport to Shirdi Temple Cab"],
        ["- Best Taxi Service from Shirdi Airport to Shirdi Temple", "- Shirdi Airport Cabs"],
        ["- Shirdi Airport Taxi Services", "- Shirdi Airport to Aurangabad Cab Service"],
        ["- Shirdi Airport to Manmad Cab Fare", "- Shirdi Airport to Shirdi Temple Cab"],
        ["- Shirdi Airport to Ahmednagar Cab Service", "- Shirdi Airport Cab Booking"],
        ["- Shirdi Airport to Shani Shingnapur Cab", ""]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Convenient Transportation:",
            "WhyChoosedescription": "We provide direct and hassle-free taxi service from Shirdi Airport to Shirdi Temple, ensuring a smooth transition from your flight to your spiritual destination. Our drivers will meet you at the airport, assist you with your luggage, and guide you to your vehicle, making your arrival as stress-free as possible."
        },
        {
            "WhyChooseheading": "Reliable and Timely Service:",
            "WhyChoosedescription": "Our taxis are always on time, so you can count on us for prompt pickup and drop-off, helping you make the most of your visit. We monitor flight schedules to ensure your driver is ready when you land, so there’s no need to worry about delays or waiting times."
        },
        {
            "WhyChooseheading": "Experienced Drivers:",
            "WhyChoosedescription": "Our professional drivers are familiar with the local area and traffic conditions, ensuring a safe and efficient ride. They are trained to provide excellent customer service and are always ready to offer assistance or local insights, enhancing your travel experience."
        },
        {
            "WhyChooseheading": "Comfortable Vehicles:",
            "WhyChoosedescription": "Enjoy a comfortable ride in our well-maintained and spacious taxis, equipped to accommodate your luggage and provide a relaxing travel experience. Each vehicle is designed to ensure you arrive at your destination feeling refreshed and ready to explore."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "We offer clear pricing with no hidden fees, allowing you to know the cost upfront and plan your budget effectively. Our rates are competitive and reflect the quality of service you receive, so you can travel without financial stress."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our customer service team is available around the clock to assist you with any inquiries or support during your journey. Whether you need to modify your booking, ask about our services, or have questions during your ride, we’re here to help."
        },
        {
            "WhyChooseheading": "Variety of Vehicle Options:",
            "WhyChoosedescription": "We provide a range of vehicle options to meet your specific needs, whether you are traveling solo, with family, or in a group. From standard taxis to larger vehicles, we ensure your comfort and convenience, accommodating all group sizes and luggage requirements."
        },
        {
            "WhyChooseheading": "Local Expertise:",
            "WhyChoosedescription": "Our drivers are local experts who can share valuable information about the Shirdi area, including the best times to visit the temple, nearby attractions, and dining options. Their insights can enhance your spiritual journey and help you discover the rich culture of the region."
        },
        {
            "WhyChooseheading": "Commitment to Safety:",
            "WhyChoosedescription": "Your safety is our priority. All our vehicles undergo regular maintenance and safety checks, and our drivers are trained in safe driving practices. We adhere to strict safety protocols to ensure you have a secure and worry-free travel experience."
        },
        {
            "WhyChooseheading": "Flexible Itineraries:",
            "WhyChoosedescription": "We understand that travel plans can change, so we offer flexible itineraries to suit your needs. Whether you require a one-way trip, round trip, or additional stops along the way, we can accommodate your preferences and ensure a personalized travel experience."
        },
        {
            "WhyChooseheading": "Sustainable Travel Practices:",
            "WhyChoosedescription": "We are committed to promoting environmentally friendly travel practices. Our vehicles are maintained for optimal fuel efficiency, and we strive to minimize our carbon footprint. By choosing our services, you contribute to a more sustainable travel experience."
        }
    ]
        
  }


  const faqData = [
    {
      question: "What is the fare for a taxi from Shirdi Airport to Shirdi Temple?",
      answer: "The fare typically ranges from INR 500 to INR 700, depending on the type of vehicle and current traffic conditions. Please contact us for the latest pricing."
    },
    {
      question: "How long does the journey take?",
      answer: "The trip from Shirdi Airport to Shirdi Temple usually takes about 30 minutes, depending on traffic."
    },
    {
      question: "Are there any additional charges?",
      answer: "Additional charges may apply for nighttime travel (after 10 PM) or for extra stops along the way."
    },
    {
      question: "How can I book a taxi from Shirdi Airport to Shirdi Temple?",
      answer: "You can book your taxi easily online through our website or by calling our customer service team."
    },
    {
      question: "What payment options do you accept?",
      answer: "We accept various payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
      question: "Can I cancel my booking?",
      answer: "Yes, we provide flexible cancellation options. Please review our cancellation policy on the website for more details."
    },
    {
      question: "Is the taxi sanitized before each trip?",
      answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
      question: "Can I request a specific vehicle type?",
      answer: "Yes, you can specify your preferred vehicle type while making your booking, subject to availability."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Rajesh Kumar",
      text: "I used Saitirth Travels for my trip from Shirdi Airport to Shirdi Temple, and I was very pleased with the service. The driver was punctual and friendly, and the taxi was clean and comfortable. Highly recommended!"
    },
    {
      name: "Ms. Kavita Sharma",
      text: "Saitirth Travels made my arrival in Shirdi so smooth! The taxi was waiting for me at the airport, and the ride to the temple was quick and pleasant. I appreciated the reliable service and will definitely use them again!"
    }
  ];
  
  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi Airport to Shirdi Temple Taxi Fare | SaitirthTours & Travels</title>
    <meta name="description" content="Book a comfortable and affordable taxi from Shirdi Airport to Shirdi Temple with SaitirthTours & Travels. Enjoy a hassle-free ride to Sai Baba Temple with competitive rates and reliable service." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi Airport to Shirdi Temple Taxi Fare | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a comfortable and affordable taxi from Shirdi Airport to Shirdi Temple with SaitirthTours & Travels. Enjoy a hassle-free ride to Sai Baba Temple with competitive rates and reliable service." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-airport-to-shirdi-temple-taxi-fare" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-airport-to-shirdi-temple-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a comfortable and affordable taxi from Shirdi Airport to Shirdi Temple with SaitirthTours & Travels. Enjoy a hassle-free ride to Sai Baba Temple with competitive rates and reliable service.",
            "url": "https://www.saitirthcabs.com/shirdi-airport-to-shirdi-temple-taxi-fare",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-airport-to-shirdi-temple-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Airport to Shirdi Temple Taxi Service", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi Airport to Shirdi Temple Cab Fare", 
                "Taxi Fare from Shirdi Airport to Shirdi Temple", 
                "Shirdi Airport to Sai Baba Temple Cab Rates", 
                "Shirdi Airport to Shirdi Temple Cab Booking", 
                "Shirdi Airport to Shirdi Temple Taxi Tariff", 
                "Affordable Shirdi Airport to Shirdi Temple Cab", 
                "Best Taxi Service from Shirdi Airport to Shirdi Temple", 
                "Shirdi Airport Cabs", 
                "Shirdi Airport Taxi Services", 
                "Shirdi Airport to Aurangabad Cab Service", 
                "Shirdi Airport to Manmad Cab Fare", 
                "Shirdi Airport to Shirdi Temple Cab", 
                "Shirdi Airport to Ahmednagar Cab Service", 
                "Shirdi Airport Cab Booking", 
                "Shirdi Airport to Shani Shingnapur Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/26.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdiairport;