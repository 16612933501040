import React from 'react';

const ContactButtons = () => {
  return (
    <div 
      className="contact-buttons" 
      style={{
        position: 'fixed', 
        bottom: '20px', 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: '0 20px', 
        zIndex: 1000,
        pointerEvents: 'none', // Allow clicks to pass through this container
      }}
    >
      
      <a 
        href="tel:+919923879009" 
        className="call-button" 
        style={{
          backgroundColor: '#EE1623', 
          borderRadius: '50%',
          padding: '15px', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s',
          zIndex: 1001, // Ensure button is above the container
          pointerEvents: 'auto', // Allow clicks on this specific button
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#EE1623')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#EE1623')}
      >
        <img src="/images/phone.png" alt="Call" style={{ width: '30px', height: '30px' }} />
      </a>

      <a 
        href="https://wa.me/+919923879009?text=Hello%20Saitirth%20Travels%20Team,%20I%20want%20to%20Book%20Cabs/Taxi/Mini%20Bus%20and%20Bus%20Service/Shirdi%20Package%20With%20Saitirth%20Cabs.%20Request%20you%20Please%20Send%20More%20Details%20or%20Call%20Me.%20Thank%20you!
"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          backgroundColor: '#25D366', 
          borderRadius: '50%', 
          padding: '15px', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s',
          zIndex: 1001,
          pointerEvents: 'auto', 
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1DAE3E')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#25D366')}
      >
        <img src="/images/whatsapp.png" alt="WhatsApp" style={{ width: '30px', height: '30px' }} />
      </a>

    </div>
  );
};

export default ContactButtons;







// import React from 'react';

// const ContactButtons = () => {
//   return (
//     <div className="contact-buttons" style={{ position: 'fixed', bottom: '20px', width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0 20px', zIndex: 1000 }}>
      
//       <a 
//         href="tel:+919923879009" 
//         className="call-button" 
//         style={{
//           backgroundColor: '#EE1623', 
//           borderRadius: '50%',
//           padding: '15px', 
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//           transition: 'background-color 0.3s',
//         }}
//         onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#EE1623')}
//         onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#')}
//       >
//         <img src="./images/phone.png" alt="Call" style={{ width: '30px', height: '30px' }} />
//       </a>

//       <a 
//   href="https://wa.me/+919923879009?text=Hello%20Saitirth%20Travels%20Team,%20I%20want%20to%20Book%20Maruti%20Suzuki%20Swift%20Dzire,%20Toyota%20Etios,%20Maruti%20Suzuki%20Ciaz,%20Hyundai%20Aura,%20Hyundai%20Verna,%20Toyota%20Innova%20Crysta,%20Innova%20Cab,%20Mahindra%20Scorpio,%20Maruti%20Suzuki%20Ertiga,%20Kia%20Carens,%20Tavera%20With%20Saitirth%20Travels.%20Request%20you%20Please%20Send%20More%20Details%20or%20Call%20Me.%20Thank%20you!"
//   className="whatsapp-button"
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   <div
//     style={{
//       backgroundColor: '#25D366',
//       borderRadius: '50%',
//       padding: '15px',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//       transition: 'background-color 0.3s',
//     }}
//     onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1DAE3E')}
//     onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#25D366')}
//   >
//     <img src="./images/Whatsapp.png" alt="WhatsApp" style={{ width: '30px', height: '30px' }} />
//   </div>
// </a>
//     </div>
//   );
// };

// export default ContactButtons;
