
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Nashikairport() {



  const cardData =
  {
    keyword: 'Nasik Airport to Shirdi Taxi',
    heading: 'Saitirth Travels: Nasik Airport to Shirdi Taxi',
    headingDescription: 'Saitirth Travels provides exceptional taxi services from Nasik Airport to Shirdi, ensuring a comfortable and hassle-free journey for all travelers. Our dedicated team is committed to delivering safe, reliable, and punctual transportation, allowing you to focus on your pilgrimage or leisure activities in Shirdi.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

 "topPlaces": [
    {
        "title": "Sai Baba Samadhi Mandir",
        "description": "The Sai Baba Samadhi Mandir stands as the heart of Shirdi, serving as a pilgrimage site for millions of devotees. This sacred temple houses the Samadhi of Sai Baba, enveloped in a serene ambiance that creates a perfect environment for prayer and meditation. Visitors are drawn here to connect with the spiritual legacy of Sai Baba and seek his blessings."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a historic mosque where Sai Baba lived for many years. This revered site features a sacred flame (Dhuni) that has been continuously burning since Baba’s time, symbolizing his eternal presence. The spiritually uplifting atmosphere attracts devotees who come to seek blessings and reflect on Baba’s profound teachings."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is another significant site associated with Sai Baba, where he would rest every alternate night. This simple structure holds great historical importance, showcasing photographs and artifacts that narrate the life of Sai Baba and his remarkable journey. Visitors can immerse themselves in the legacy of Sai Baba while experiencing the tranquil environment."
    },
    {
        "title": "Shri Khandoba Temple",
        "description": "Perched on a hill, Shri Khandoba Temple is an ancient site dedicated to Lord Khandoba, offering panoramic views of the stunning landscape. This temple is particularly vibrant during festivals, reflecting the rich local culture and devotion of the community. The serene surroundings make it a wonderful place to visit for both worship and sightseeing."
    },
    {
        "title": "Gurusthan",
        "description": "Gurusthan is a revered site believed to be where Sai Baba first arrived in Shirdi. Visitors can pay homage at this sacred spot, marked by a neem tree and a tomb-like structure. The tranquil atmosphere fosters a sense of peace and contemplation, making it an ideal location for prayer and reflection."
    },
    {
        "title": "Shirdi Wet N Joy Water Park",
        "description": "Shirdi Wet N Joy Water Park is a delightful destination for families, offering a wide range of water rides and attractions. After visiting the spiritual sites in Shirdi, families can unwind and enjoy some fun in the sun at this exciting water park, creating lasting memories together."
    },
    {
        "title": "Sainagar Railway Station",
        "description": "Sainagar Railway Station serves as a well-connected gateway for travelers coming to Shirdi. This essential transport hub provides various facilities for passengers and enhances connectivity to major cities, ensuring a convenient travel experience for pilgrims and tourists alike."
    },
    {
        "title": "Nashik",
        "description": "Just a short drive from Shirdi, Nashik is renowned for its rich tapestry of temples and vineyards. Visitors can explore the religious sites in the Panchavati area and indulge in wine tours at local vineyards, offering a unique blend of spirituality and leisure for an enriching experience."
    },
    {
        "title": "Ahmednagar Fort",
        "description": "Ahmednagar Fort is a historic marvel that attracts history enthusiasts. Visitors can explore the fort's impressive ruins and learn about its significance in Maharashtra's rich history. The fort's architectural beauty and strategic importance provide a captivating glimpse into the region's past."
    },
    {
        "title": "Bhandardara",
        "description": "Bhandardara is a picturesque getaway known for its breathtaking natural beauty, making it a paradise for nature lovers. The area features serene landscapes, stunning waterfalls, and the tranquil Arthur Lake, offering a perfect place for relaxation and rejuvenation after a spiritual journey."
    }
],
    
            
        
"services": [
    {
        "name": "Nasik Airport to Shirdi Taxi",
        "description": "Make your journey from Nasik Airport to Shirdi smooth and comfortable with our reliable taxi service. Our Nasik Airport to Shirdi Taxi ensures a safe and enjoyable travel experience, with experienced drivers who prioritize punctuality and comfort. Enjoy the scenic views of the Maharashtra landscape as you travel to one of India's most revered pilgrimage sites."
    },
    {
        "name": "Nasik Airport Shirdi Taxi Fare",
        "description": "We offer affordable and transparent Nasik Airport Shirdi Taxi fare options, making it easy to travel between these two destinations. Our pricing is upfront, so there are no surprises, ensuring a cost-effective solution for your travel needs. We also provide flexible payment options for your convenience."
    },
    {
        "name": "Best Taxi from Nasik Airport to Shirdi",
        "description": "Our service aims to provide the best taxi from Nasik Airport to Shirdi, with a fleet of well-maintained vehicles and professional drivers. We offer various car options to suit all preferences and budgets, ensuring you get the ideal ride for your journey. From economy sedans to luxury SUVs, we have the perfect vehicle to meet your needs."
    },
    {
        "name": "Cheap Taxi Nasik Airport Shirdi",
        "description": "For those seeking economical travel options, our cheap taxi from Nasik Airport to Shirdi is perfect. Get a comfortable, budget-friendly ride without compromising on quality or safety. Our vehicles are regularly serviced and sanitized to ensure a safe journey, making us an ideal choice for all types of travelers."
    },
    {
        "name": "Book Taxi Nasik Airport to Shirdi",
        "description": "Booking a taxi from Nasik Airport to Shirdi is quick and simple. With our easy online and offline booking options, you can reserve your taxi in advance to ensure a seamless travel experience. Our user-friendly platform allows you to select your preferred vehicle and receive instant confirmation, so you can plan your trip with confidence."
    },
    {
        "name": "One-Way Taxi Nasik Airport Shirdi",
        "description": "Our one-way taxi service from Nasik Airport to Shirdi is convenient for travelers who only need a single trip. Whether you're on a pilgrimage or a quick visit, our one-way option provides flexibility and value for your travel needs. Enjoy direct transport to Shirdi without the hassle of arranging a return trip."
    },
    {
        "name": "Round Trip Taxi Nasik Airport Shirdi",
        "description": "For those planning a return journey, our round trip taxi from Nasik Airport to Shirdi offers an all-in-one solution. Plan your visit to Shirdi and back to Nasik Airport with ease, knowing both legs of your trip are covered. Enjoy the convenience of having a dedicated driver waiting for you when it’s time to head back."
    },
    {
        "name": "Car Rental Nasik Airport to Shirdi",
        "description": "Our car rental service from Nasik Airport to Shirdi allows you to explore at your own pace. Choose from various car options, including sedans, SUVs, and luxury models, making it easy to find the right vehicle for a personalized travel experience. This service is perfect for those who wish to make spontaneous stops along the way or explore Shirdi at their leisure."
    },
    {
        "name": "Reliable Taxi Nasik Airport Shirdi",
        "description": "With safety and dependability as our top priorities, our reliable taxi service from Nasik Airport to Shirdi ensures that you arrive comfortably and on time. Our drivers are well-versed with the route, providing a hassle-free travel experience. Each vehicle is equipped with modern amenities, ensuring a pleasant journey."
    },
    {
        "name": "Distance Nasik Airport Shirdi Taxi",
        "description": "The distance between Nasik Airport and Shirdi is approximately 85 kilometers, which can be covered comfortably in about 2 hours. Our Nasik Airport to Shirdi Taxi service guarantees a smooth and direct route to help you reach your destination promptly, allowing you to relax and enjoy the beautiful surroundings."
    },
    {
        "name": "Nasik Airport to Shirdi Innova Taxi",
        "description": "For larger groups or those seeking extra comfort, we offer Innova taxis from Nasik Airport to Shirdi. Enjoy ample space and a premium travel experience with this popular vehicle choice. The Innova is perfect for families or friends traveling together, providing a comfortable and spacious environment for everyone."
    },
    {
        "name": "Nashik Airport to Shirdi Ertiga Cabs",
        "description": "Our Ertiga cabs from Nashik Airport to Shirdi are ideal for families or small groups. Enjoy a spacious, comfortable ride with our Ertiga option, perfect for making your journey enjoyable and relaxing. With features designed for comfort, the Ertiga ensures a pleasant experience for all passengers."
    },
    {
        "name": "Ozar Airport to Shirdi Taxi Service",
        "description": "If you’re traveling from Ozar Airport to Shirdi, we provide a convenient and reliable taxi service for this route. Our experienced drivers will ensure a safe journey, making it easy to travel between Ozar Airport and Shirdi. This service is perfect for those looking to connect directly to Shirdi from Ozar Airport."
    },
    {
        "name": "Nasik Airport to Shirdi Sedan Cab",
        "description": "For those seeking a comfortable and stylish ride, our sedan cabs from Nasik Airport to Shirdi offer a premium travel experience. Enjoy a smooth and enjoyable trip with our well-maintained sedans, perfect for business and leisure travelers alike. With plush interiors and modern features, our sedans provide an excellent travel experience."
    },
    {
        "name": "Nasik Airport to Shirdi Taxi Contact Information",
        "description": "For more details or to book your Nasik Airport to Shirdi Taxi, reach out to SaitirthTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to assist you with any inquiries or booking needs, ensuring you have a seamless and pleasant travel experience. Let us help make your journey from Nasik Airport to Shirdi as smooth and enjoyable as possible."
    }
],
"tableData": [
    ["- Nasik Airport to Shirdi Taxi", "- Nasik Airport Shirdi Taxi Fare"],
    ["- Best Taxi from Nasik Airport to Shirdi", "- Cheap Taxi Nasik Airport Shirdi"],
    ["- Book Taxi Nasik Airport to Shirdi", "- One-Way Taxi Nasik Airport Shirdi"],
    ["- Round Trip Taxi Nasik Airport Shirdi", "- Car Rental Nasik Airport to Shirdi"],
    ["- Reliable Taxi Nasik Airport Shirdi", "- Distance Nasik Airport Shirdi Taxi"],
    ["- Nasik Airport to Shirdi Innova Taxi", "- Nashik Airport to Shirdi Ertiga Cabs"],
    ["- Ozar Airport to Shirdi Taxi Service", "- Nasik Airport to Shirdi Sedan Cab"],
    ["- SaitirthTours & Travels Shirdi", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Efficient Transfers:",
        "WhyChoosedescription": "Our service is designed to provide seamless travel from Nasik Airport to Shirdi, ensuring you reach your destination without unnecessary delays. We understand that after a long flight, you want a smooth transition to your next destination. Our team actively monitors flight schedules, so whether your flight arrives early or late, your cab will be ready to take you directly to Shirdi, making your journey as hassle-free as possible."
    },
    {
        "WhyChooseheading": "Comfortable Journey:",
        "WhyChoosedescription": "Travel in style and comfort with our modern fleet of vehicles that are regularly serviced to ensure reliability. Each cab is equipped with plush seating, air conditioning, and ample space for your luggage. Whether you are traveling alone or with a group, we guarantee a relaxing and enjoyable ride, allowing you to unwind as you travel to Shirdi."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only experienced but also trained to provide excellent customer service. They possess a thorough understanding of the local area, ensuring that you take the best routes to avoid traffic delays and enjoy the scenic views along the way. Their commitment to safety and passenger comfort means you can sit back and relax, knowing you're in capable hands."
    },
    {
        "WhyChooseheading": "24/7 Service:",
        "WhyChoosedescription": "We recognize that travel needs can arise at any time, which is why our taxi service operates 24/7. Whether you have a late-night flight or need an early morning pick-up, we are always available to accommodate your travel plans. You can book your taxi at your convenience, ensuring that we are here whenever you need us, day or night."
    },
    {
        "WhyChooseheading": "Transparent Pricing:",
        "WhyChoosedescription": "With us, you can rest assured that there will be no hidden fees or unexpected charges. We provide a detailed breakdown of the taxi fare before your trip, allowing you to make informed decisions. Our competitive pricing structure is designed to offer great value without sacrificing service quality. We believe in fair pricing that reflects the quality of service you receive."
    },
    {
        "WhyChooseheading": "Quick and Easy Booking:",
        "WhyChoosedescription": "We have streamlined our booking process to ensure that you can reserve your taxi in just a few minutes. Our user-friendly website allows you to select your pick-up and drop-off locations, choose your vehicle type, and receive a fare estimate—all within a few clicks. Alternatively, our responsive customer service team is ready to assist you over the phone, making it easy for you to schedule your ride while you focus on your travel plans."
    },
    {
        "WhyChooseheading": "Punctual Pick-Up:",
        "WhyChoosedescription": "We understand the importance of being on time, especially when it comes to airport transfers. Our drivers will be waiting for you at the arrivals area, ready to assist you with your luggage. We monitor flight arrivals to adjust our schedules, so even if your flight is delayed, your driver will be there to ensure you have a smooth transition from the airport to your final destination."
    },
    {
        "WhyChooseheading": "Flexible Payment Methods:",
        "WhyChoosedescription": "We offer multiple payment options to make your travel experience as convenient as possible. Whether you prefer to pay in cash, with a credit or debit card, or via online payment systems, we accommodate your choice. This flexibility allows you to choose the payment method that best suits your preferences, ensuring a hassle-free experience that fits into your travel plans."
    },
    {
        "WhyChooseheading": "Focus on Customer Satisfaction:",
        "WhyChoosedescription": "Your satisfaction is our top priority. Our dedicated customer service team is always available to address any concerns or special requests you may have. We actively seek feedback to continuously improve our services and exceed your expectations, ensuring that every aspect of your journey is tailored to your needs. We believe that happy customers are our best advertisement, and we strive to create positive experiences for everyone."
    },
    {
        "WhyChooseheading": "Positive Feedback:",
        "WhyChoosedescription": "We take pride in our strong reputation built on reliability and quality service. Many of our customers return for their travel needs and recommend us to others. Our numerous positive reviews reflect our commitment to excellence and customer care, reassuring you that you are choosing a trusted provider for your transportation needs. We believe that every journey with us is an opportunity to create lasting impressions and relationships."
    }
]




        
  }


  const faqData = [
    {
        question: "How do I book a taxi from Nasik Airport to Shirdi?",
        answer: "You can easily book your taxi through our website or by calling our customer service team."
    },
    {
        question: "What is the fare for the taxi service?",
        answer: "Fares vary based on vehicle type and distance. Please contact us for a detailed quote."
    },
    {
        question: "Can I select a specific vehicle for my journey?",
        answer: "Yes, we provide a range of vehicles to meet your preferences and group size."
    },
    {
        question: "What happens if my flight is delayed?",
        answer: "Our drivers track flight arrivals and will adjust the pick-up time accordingly."
    },
    {
        question: "Are there any additional charges for the service?",
        answer: "We maintain transparent pricing, and any potential extra charges will be discussed before booking."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept cash, credit/debit cards, and online payment options for your convenience."
    },
    {
        question: "Are your drivers licensed and professional?",
        answer: "Yes, all our drivers are licensed, trained, and committed to providing a safe ride."
    },
    {
        question: "Is the taxi service available during late hours?",
        answer: "Yes, we operate 24/7, so you can book a taxi whenever you need it."
    },
    {
        question: "How can I contact customer support?",
        answer: "You can reach our customer support by phone or email for any inquiries."
    },
    {
        question: "What is the travel time from Nasik Airport to Shirdi?",
        answer: "The journey usually takes around 2 to 3 hours, depending on traffic conditions."
    }
];

const testimonials = [
    {
        name: "Mr. Sanjay Patil",
        text: "I had a great experience with Saitirth Travels for my ride from Nasik Airport to Shirdi. The driver was on time, and the car was very comfortable. The whole process was smooth, and I appreciated their professionalism. Highly recommended!"
    },
    {
        name: "Mrs. Neha Joshi",
        text: "The taxi service from Nasik Airport to Shirdi was excellent. The driver was friendly and helpful, making our journey enjoyable. The car was clean and spacious, which was perfect for our family. I will definitely book with Saitirth Travels again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Nasik Airport to Shirdi Taxi | Affordable & Reliable Taxi Service</title>
    <meta name="description" content="Affordable and reliable taxi services from Nasik Airport to Shirdi. Book one-way, round-trip taxis, or car rentals for a comfortable journey. Book your ride now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Nasik Airport to Shirdi Taxi | Affordable & Reliable Taxi Service" />
    <meta property="og:description" content="Affordable and reliable taxi services from Nasik Airport to Shirdi. Book one-way, round-trip taxis, or car rentals for a comfortable journey. Book your ride now!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/nasik-airport-to-shirdi-taxi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/nasik-airport-to-shirdi-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Affordable and reliable taxi services from Nasik Airport to Shirdi. Book one-way, round-trip taxis, or car rentals for a comfortable journey. Book your ride now!",
            "url": "https://www.saitirthcabs.com/nasik-airport-to-shirdi-taxi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/nasik-airport-to-shirdi-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Nasik Airport to Shirdi Taxi",
                "Shirdi taxi service",
                "Nasik to Shirdi car rental",
                "Nasik Airport to Shirdi one-way taxi",
                "Round-trip taxi Nasik to Shirdi",
                "Reliable taxi Nasik to Shirdi",
                "Nasik Airport to Shirdi Innova taxi",
                "Nasik to Shirdi Ertiga cabs",
                "Nasik to Shirdi sedan cabs",
                "Ozar Airport to Shirdi taxi",
                "Affordable Nasik to Shirdi taxi service",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/59.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Nashikairport;