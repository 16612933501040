
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditokonkan() {



  const cardData =
  {
    keyword: ' Shirdi to Konkan Darshan Cab',
    heading: 'Saitirth Travels: Shirdi to Konkan Darshan Cab',
    headingDescription: 'When it comes to traveling from Shirdi to Konkan, Saitirth Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time exploring the beautiful Konkan region.',

    top: 'Top Places to Visit in Konkan with Saitirth Travels',

 "topPlaces": [
    {
        "title": "1. Alibaug Beach",
        "description": "Known for its clean sands and beautiful views, Alibaug Beach is a perfect spot for relaxation. Visitors can enjoy various water sports like jet skiing and banana boat rides, or simply take a leisurely stroll along the shore. The beach is dotted with quaint beachside shacks offering delicious local seafood, making it an excellent spot for a sunset dinner. The nearby Kolaba Fort adds a touch of historical significance, which you can explore during low tide."
    },
    {
        "title": "2. Kashid Beach",
        "description": "This stunning beach features golden sands and clear waters, making it an ideal destination for sunbathing and swimming. The nearby hills provide a picturesque backdrop, perfect for photography enthusiasts. Visitors can indulge in activities such as parasailing and jet skiing. The tranquil atmosphere is enhanced by the serene sound of waves, making it a popular spot for picnics and family outings. The beach is less crowded, ensuring a peaceful day in the sun."
    },
    {
        "title": "3. Murud-Janjira Fort",
        "description": "An impressive sea fort built in the 15th century, Murud-Janjira is known for its strong architecture and historical significance. Accessible by boat from Murud village, the fort's towering walls and bastions stand tall against the Arabian Sea. Inside, you can explore ancient cannons and enjoy panoramic views of the coastline. The fort is surrounded by myths and legends, adding an air of mystery, making it a fascinating place for history buffs and adventure seekers alike."
    },
    {
        "title": "4. Sindhudurg Fort",
        "description": "Located on an island near Malvan, Sindhudurg Fort is a historical marvel surrounded by the Arabian Sea. Built under the reign of Chhatrapati Shivaji Maharaj, the fort showcases impressive architecture and strategic importance. Visitors can explore the fort's ramparts, temples, and the famous carved stone of Shivaji's footprint. The fort's location offers breathtaking views of the sea and surrounding landscapes, making it a must-visit for history enthusiasts and nature lovers."
    },
    {
        "title": "5. Ganpatipule",
        "description": "Famous for its serene beach and the revered Ganapati temple, Ganpatipule is a spiritual and scenic getaway. The pristine beach, with its soft sand and gentle waves, is perfect for relaxation and family outings. The Ganapati temple, known for its unique idol of Lord Ganesha facing the sea, attracts numerous devotees throughout the year. Visitors can also explore nearby attractions like the stunning Ganpatipule Beach and partake in local festivals that celebrate the cultural richness of the area."
    },
    {
        "title": "6. Dapoli",
        "description": "Known as the 'Mini Mahabaleshwar,' Dapoli boasts scenic hills, beaches, and historical sites. The hill station offers a cool climate, lush green landscapes, and beautiful beaches like Ladghar and Murud. Visitors can enjoy water sports, visit ancient temples, and explore local flora and fauna, including dolphins on boat rides. Dapoli's charming villages and agritourism opportunities provide a glimpse into the region's culture and lifestyle, making it a perfect retreat for nature and history lovers."
    },
    {
        "title": "7. Ratnagiri Fort",
        "description": "This historic fort provides panoramic views of the Arabian Sea and the surrounding landscape. Built during the reign of the Marathas, the fort features ancient structures and ruins that tell tales of its glorious past. It's an excellent spot for photography and exploration, with walking paths leading to various vantage points. The nearby beaches and lush greenery further enhance the fort's appeal, making it a great destination for both history enthusiasts and nature lovers."
    },
    {
        "title": "8. Diveagar Beach",
        "description": "A tranquil beach known for its natural beauty and serene ambiance, Diveagar Beach is perfect for those looking to escape the crowds. The beach is famous for its golden sea shells, and visitors can spend hours collecting these treasures along the shoreline. The lush greenery surrounding the beach provides a picturesque setting for picnics and relaxation. Local eateries offer delicious seafood, and the nearby attractions include the ancient Ganesh temple, enhancing the overall experience for visitors."
    },
    {
        "title": "9. Kunkeshwar Temple",
        "description": "Located near the beach of the same name, this ancient temple dedicated to Lord Shiva is a peaceful pilgrimage site. The temple's stunning architecture, adorned with intricate carvings and sculptures, draws visitors seeking spiritual solace. Its coastal location provides a serene backdrop for reflection and meditation. The tranquil atmosphere, combined with the sound of the waves crashing against the shore, makes it an ideal place for spiritual reflection and quiet contemplation."
    },
    {
        "title": "10. Harihareshwar",
        "description": "This coastal town is home to a revered temple dedicated to Lord Shiva, attracting pilgrims and tourists alike. The scenic beauty of the area, with its lush hills and pristine beaches, enhances its spiritual significance. Visitors can explore the temple's intricate architecture, participate in local festivals, and enjoy the serene beaches. The town's tranquility and spiritual ambiance make it a must-visit destination for those seeking both adventure and peace."
    }
],

    
            
        
"services": [
        {
            "name": "Shirdi to Palghar Cab Booking",
            "description": "Book your cab from Shirdi to Palghar easily with SaitirthTours & Travels, ensuring a hassle-free travel experience. We provide a range of vehicles to suit your needs, from compact cars to spacious SUVs, making your journey comfortable and enjoyable. Our user-friendly online booking system allows you to secure your ride in just a few clicks, and our dedicated customer service team is always available to assist you with any inquiries or changes to your booking."
        },
        {
            "name": "Shirdi to Raigad Taxi Price",
            "description": "Explore competitive pricing for our taxi services from Shirdi to Raigad. We offer transparent and affordable fares, ensuring you get value for your money while enjoying a comfortable ride. With clear fare structures, you can rest assured there are no hidden charges. Our experienced drivers are well-versed in the route, providing you with insights and local knowledge to enhance your journey."
        },
        {
            "name": "Shirdi to Ratnagiri Taxi Fare",
            "description": "Get the best taxi fare for your journey from Shirdi to Ratnagiri. Our pricing is designed to be cost-effective, making your travel plans more manageable without compromising on quality. We pride ourselves on providing clean, well-maintained vehicles and drivers who prioritize your comfort and safety throughout the ride."
        },
        {
            "name": "Shirdi to Sindhudurg by Cab",
            "description": "Travel comfortably to Sindhudurg from Shirdi with our reliable cab service. Our experienced drivers ensure a safe and enjoyable journey, allowing you to relax and take in the scenery along the way. We understand the importance of a stress-free travel experience, which is why we handle everything from navigation to vehicle maintenance, leaving you free to enjoy the ride."
        },
        {
            "name": "Shirdi to Thane Cab",
            "description": "Choose our dependable cab service for a smooth trip from Shirdi to Thane. Whether for business or leisure, our professional drivers and well-maintained vehicles guarantee a pleasant experience. Our focus on punctuality means you can rely on us to get you to your destination on time, making your travel experience seamless and enjoyable."
        },
        {
            "name": "Shirdi to Alibag Taxi Fare",
            "description": "Plan your travel to Alibag from Shirdi with our affordable taxi fares. We prioritize budget-friendly options without compromising comfort, ensuring you arrive at your destination refreshed. With our transparent pricing and commitment to excellent service, you can travel confidently, knowing you are getting the best value for your money."
        },
        {
            "name": "Shirdi to Tarkarli Beach Taxi",
            "description": "Enjoy a delightful journey to Tarkarli Beach from Shirdi with our specialized taxi services. Perfect for beach lovers, we ensure a comfortable ride, allowing you to unwind before you even reach the sand. Our drivers are knowledgeable about the area and can recommend local attractions and eateries to enhance your beach experience."
        },
        {
            "name": "Shirdi to Konkan Darshan Taxi Service",
            "description": "Experience the beauty of Konkan with our dedicated taxi service from Shirdi. Our customizable tours offer flexibility, allowing you to explore the region at your own pace. We curate unique itineraries that highlight the best of Konkan's natural beauty, cultural landmarks, and culinary delights, ensuring a memorable trip tailored just for you."
        },
        {
            "name": "Shani Shingnapur to Konkan Darshan",
            "description": "Take a spiritual journey from Shani Shingnapur to Konkan with our reliable taxi services. We make your pilgrimage seamless, providing comfort and convenience throughout the journey. Our drivers are not only skilled but also respectful of the spiritual significance of your travel, ensuring a meaningful experience as you connect with the divine."
        },
        {
            "name": "Shirdi to Konkan Darshan Cab",
            "description": "Book a cab for your Konkan Darshan trip from Shirdi. Our experienced drivers ensure a comfortable travel experience, guiding you through the beautiful landscapes of Konkan. With our focus on customer satisfaction, we provide personalized service, adapting to your travel preferences and needs."
        },
        {
            "name": "Shirdi to Diveagar Cab",
            "description": "Travel to the serene beaches of Diveagar from Shirdi with our convenient cab services. Ideal for a relaxing getaway, we offer flexible booking options to suit your schedule. Our vehicles are equipped with amenities to ensure a comfortable ride, and our drivers can suggest the best times to visit local attractions, ensuring you make the most of your trip."
        },
        {
            "name": "Shirdi to Ratnagiri Innova Crysta Taxi Fare",
            "description": "Opt for our spacious Innova Crysta for your Ratnagiri trip from Shirdi. Offering a premium travel experience at reasonable rates, it's perfect for families and groups seeking comfort. Our Innova Crysta vehicles come with modern features and ample luggage space, making them ideal for longer journeys or family outings."
        },
        {
            "name": "Shirdi to Konkan Darshan Tempo Traveller on Rent",
            "description": "For group trips to Konkan, rent our Tempo Traveller from Shirdi. With ample space and comfort, it's an excellent choice for larger families or friends traveling together. We provide all the necessary amenities to ensure a pleasant experience, and our drivers are trained to handle group dynamics, making your journey enjoyable and fun."
        },
        {
            "name": "Shirdi to Konkan Darshan Innova on Rent",
            "description": "Enjoy the luxury of traveling in an Innova for your Konkan Darshan from Shirdi. This option is perfect for families and groups looking for a comfortable and stylish way to travel. With our emphasis on quality service, you can expect a well-maintained vehicle and a knowledgeable driver to enhance your travel experience."
        },
        {
            "name": "SaitirthTours & Travels Shirdi",
            "description": "For all your travel needs, rely on SaitirthTours & Travels for exceptional service and affordable rates. Contact us for bookings at +91 9923879009 / 9922479009 and experience smooth, reliable transportation tailored to your journey. Our team is dedicated to ensuring your satisfaction every step of the way, offering assistance with travel planning and personalized recommendations for a truly memorable trip."
        }
    ],
    "tableData": [
        ["- Shirdi to Palghar Cab Booking", "- Shirdi to Raigad Taxi Price"],
        ["- Shirdi to Ratnagiri Taxi Fare", "- Shirdi to Sindhudurg by Cab"],
        ["- Shirdi to Thane Cab", "- Shirdi to Alibag Taxi Fare"],
        ["- Shirdi to Tarkarli Beach Taxi", "- Shirdi to Konkan Darshan Taxi Service"],
        ["- Shani Shingnapur to Konkan Darshan", "- Shirdi to Diveagar Cab"],
        ["- Shirdi to Palghar Cab Booking", "- Shirdi to Raigad Taxi Price"],
        ["- Shirdi to Ratnagiri Innova Crysta Taxi Fare", "- Shirdi to Sindhudurg by Cab"],
        ["- Shirdi to Thane Cab", "- Pune to Kalyan Cab"],
        ["- Shirdi to Alibag Taxi Fare", "- Shirdi to Tarkarli Beach Taxi"],
        ["- Shirdi to Konkan Darshan Taxi Service", "- Shirdi to Konkan Darshan Tempo Traveller on Rent"],
        ["- Shirdi to Konkan Darshan Innova on Rent", "- Shani Shingnapur to Konkan Darshan"],
        ["- Shirdi to Konkan Darshan Innova Crysta", "- SaitirthTours & Travels Shirdi"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Affordable Pricing:",
            "WhyChoosedescription": "We provide competitive rates for our Shirdi to Konkan Darshan cab services, ensuring that you receive exceptional value for your travel needs without hidden costs. Our transparent pricing model allows you to plan your budget effectively, and we frequently offer promotional discounts and packages, making it easier for you to enjoy a memorable trip without overspending."
        },
        {
            "WhyChooseheading": "Comfortable and Spacious Vehicles:",
            "WhyChoosedescription": "Our fleet consists of well-maintained vehicles that offer ample space and comfort, perfect for soaking in the stunning views of the Konkan coast during your journey. Whether you are traveling alone or with a group, our cabs are designed to ensure a pleasant ride, complete with comfortable seating, air conditioning, and room for your luggage, making every trip enjoyable."
        },
        {
            "WhyChooseheading": "Expert Drivers:",
            "WhyChoosedescription": "Our skilled drivers are not only experienced but also familiar with the best routes to the Konkan region, guaranteeing a smooth and safe ride. They are trained to handle various driving conditions and are adept at navigating local traffic. Additionally, they can share valuable insights about the scenic spots, historical sites, and hidden gems along the way, enhancing your travel experience."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking your cab is straightforward and hassle-free through our user-friendly website or mobile app. You can make reservations in just a few clicks, and our responsive customer support team is available to assist you at any time, whether you have questions about your booking or need help selecting the right vehicle for your trip. We strive to make your booking experience seamless and enjoyable."
        },
        {
            "WhyChooseheading": "Flexible Travel Options:",
            "WhyChoosedescription": "We operate 24/7, providing you with the freedom to choose your departure time based on your schedule. Whether you prefer an early morning start to catch the sunrise or a late-night ride after exploring the region, we are here to accommodate your needs. Our flexible travel options also include the ability to customize your itinerary, allowing you to make the most of your journey."
        },
        {
            "WhyChooseheading": "Commitment to Safety:",
            "WhyChoosedescription": "Your safety is our top priority. All vehicles are thoroughly sanitized before each trip, and we adhere to strict maintenance protocols to ensure that our cabs are in top condition. We equip our vehicles with necessary safety features, including seat belts and first aid kits, to guarantee your well-being during the ride. Our drivers follow all safety guidelines to provide you with a secure and comfortable travel environment."
        }
    ]

        
  }


  const faqData = [
    {
        question: "What is the fare for a cab from Shirdi to Konkan?",
        answer: "The fare typically ranges from INR 6,000 to INR 8,000, depending on the type of vehicle and the season. Please check our website for updated pricing."
    },
    {
        question: "How long does the journey take?",
        answer: "The journey from Shirdi to Konkan usually takes about 8 to 10 hours, depending on traffic and road conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for extra stops or if you opt for a larger vehicle."
    },
    {
        question: "How can I book a cab for this route?",
        answer: "You can easily book your cab through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment options are available?",
        answer: "We accept multiple payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Can I change or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking by reaching out to customer support or through our website, adhering to our cancellation policy."
    },
    {
        question: "Are the cabs cleaned before each trip?",
        answer: "Yes, all our vehicles are thoroughly sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request additional stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mr. Vinay Kulkarni",
        text: "My trip from Shirdi to Konkan was amazing thanks to Saitirth Travels! The driver was professional, and the car was comfortable. I highly recommend their service for a scenic journey!"
    },
    {
        name: "Mrs. Sneha Joshi",
        text: "Saitirth Travels provided an excellent cab service for our Konkan Darshan. The whole experience was seamless, and our driver made the journey enjoyable with his friendly attitude. Great job!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Konkan Darshan Taxi | SaitirthTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable taxi for Konkan Darshan from Shirdi. We offer one-way, round-trip, and comfortable journey options for various Konkan destinations like Tarkarli, Alibag, and more. Book online now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Konkan Darshan Taxi | SaitirthTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable taxi for Konkan Darshan from Shirdi. We offer one-way, round-trip, and comfortable journey options for various Konkan destinations like Tarkarli, Alibag, and more. Book online now!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-konkan-darshan-cab" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-konkan-darshan-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Book a reliable and affordable taxi for Konkan Darshan from Shirdi. We offer one-way, round-trip, and comfortable journey options for various Konkan destinations like Tarkarli, Alibag, and more. Book online now!",
            "url": "https://www.saitirthcabs.com/shirdi-to-konkan-darshan-cab",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-konkan-darshan-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Konkan Darshan taxi service",
                "Shirdi to Palghar cab booking",
                "Shirdi to Raigad taxi price",
                "Shirdi to Ratnagiri taxi fare",
                "Shirdi to Sindhudurg by cab",
                "Shirdi to Thane cab",
                "Shirdi to Alibag taxi fare",
                "Shirdi to Tarkarli Beach taxi",
                "Shirdi to Konkan Darshan taxi service",
                "Shani Shingnapur to Konkan Darshan",
                "Shirdi to Diveagar cab",
                "Shirdi to Palghar cab booking",
                "Shirdi to Raigad taxi price",
                "Shirdi to Ratnagiri Innova Crysta taxi fare",
                "Shirdi to Sindhudurg by cab",
                "Shirdi to Thane cab",
                "Pune to Kalyan cab",
                "Shirdi to Alibag taxi fare",
                "Shirdi to Tarkarli Beach taxi",
                "Shirdi to Konkan Darshan tempo traveller on rent",
                "Shirdi to Konkan Darshan Innova on rent",
                "Shirdi to Konkan Darshan Innova Crysta",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/39.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditokonkan;