
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Seaterbus() {



  const cardData =
  {
    keyword: '14, 17, and 25 Seater Bus on Rent in Shirdi',
    heading: 'Saitirth Travels:14, 17, and 25 Seater Bus on Rent in Shirdi',
    headingDescription: 'Saitirth Travels offers reliable bus rental services in Shirdi, perfect for group travel, pilgrimages, corporate outings, and family trips. Our fleet includes 14, 17, and 25-seater buses, ensuring comfort and convenience for all passengers.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

"topPlaces": [
    {
        "title": "Sai Baba Samadhi Mandir",
        "description": "The Sai Baba Samadhi Mandir is the main pilgrimage site in Shirdi, housing the sacred Samadhi of Sai Baba. It attracts millions of devotees seeking blessings in a serene environment, perfect for prayer and reflection."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "A historic mosque where Sai Baba spent a significant part of his life. It features a sacred flame (Dhuni) that has been burning since his time, creating a spiritually enriching atmosphere for visitors."
    },
    {
        "title": "Chavadi",
        "description": "This simple structure holds historical significance as Sai Baba’s resting place every alternate night. It showcases photographs and artifacts related to Baba’s life, offering a glimpse into his teachings and legacy."
    },
    {
        "title": "Shri Khandoba Temple",
        "description": "An ancient temple dedicated to Lord Khandoba, located on a hill with stunning views of the surroundings. It is a popular spot during festivals, reflecting the rich cultural heritage of the area."
    },
    {
        "title": "Gurusthan",
        "description": "Gurusthan is a sacred site believed to be where Sai Baba first arrived in Shirdi. Visitors can pay their respects at the neem tree and tomb-like structure, experiencing the tranquility of this significant location."
    },
    {
        "title": "Shirdi Wet N Joy Water Park",
        "description": "A fun-filled destination for families, this water park features various rides and attractions, providing an enjoyable experience for both children and adults after spiritual visits."
    },
    {
        "title": "Sainagar Railway Station",
        "description": "A well-connected station that serves as a gateway to Shirdi, offering essential facilities for passengers and enhancing connectivity to nearby cities."
    },
    {
        "title": "Nashik",
        "description": "Located a short drive away, Nashik is famous for its temples and vineyards. Visitors can explore religious sites in Panchavati and enjoy wine tours, enriching their travel experience."
    },
    {
        "title": "Ahmednagar Fort",
        "description": "A historic fort ideal for history enthusiasts. Visitors can explore the fort's ruins and learn about its significance in Maharashtra's history."
    },
    {
        "title": "Bhandardara",
        "description": "Known for its stunning natural beauty, Bhandardara is perfect for nature lovers. Featuring serene landscapes, waterfalls, and Arthur Lake, it offers a peaceful retreat after visiting Shirdi."
    }
],            
        
"services": [
    {
        "name": "14, 17, 25, 32 Seater Bus On Rent in Shirdi",
        "description": "If you're planning a group trip to Shirdi, our 14, 17, 25, and 32 seater buses on rent offer the perfect solution for comfortable and spacious travel. Whether it's a pilgrimage, family outing, or corporate event, we provide well-maintained vehicles equipped with modern amenities such as air conditioning, reclining seats, and entertainment systems. Our professional drivers prioritize safety and punctuality, ensuring a smooth journey so you can focus on enjoying your trip."
    },
    {
        "name": "14 Seater Tempo Traveller On Rent",
        "description": "Our 14 seater Tempo Traveller on rent is ideal for small groups seeking comfort and convenience. With spacious interiors, ample legroom, and modern amenities including air conditioning and music systems, this vehicle ensures a pleasant journey to and from Shirdi. Perfect for family trips or small gatherings, it offers a cozy atmosphere for conversation and relaxation while you travel."
    },
    {
        "name": "17 Seater Tempo Traveller On Rent Shirdi",
        "description": "For slightly larger groups, our 17 seater Tempo Traveller on rent in Shirdi provides ample space without compromising comfort. This option is perfect for family gatherings, group tours, or corporate outings, offering features like comfortable seating, sufficient luggage space, and a smooth ride to your destination. Enjoy the flexibility to make stops along the way, enhancing your travel experience."
    },
    {
        "name": "25 Seater Tempo Traveller On Rent Shirdi",
        "description": "If you need to accommodate a bigger group, our 25 seater Tempo Traveller on rent in Shirdi is the ideal choice. This vehicle combines space and comfort, making it perfect for longer journeys. With spacious interiors, a well-designed seating arrangement, and amenities such as air conditioning and entertainment systems, it ensures that everyone travels together comfortably, making your trip enjoyable."
    },
    {
        "name": "32 Seater Tempo Traveller On Rent Shirdi",
        "description": "For large gatherings, our 32 seater Tempo Traveller on rent in Shirdi is perfect for ensuring that everyone can travel together. This option is great for school trips, corporate events, or large family outings, providing a spacious and comfortable travel experience. Equipped with essential amenities such as large windows for sightseeing and comfortable seating, it caters to the needs of larger groups effectively."
    },
    {
        "name": "14, 17, 25, 32 Seater Mini Bus On Rent in Shirdi",
        "description": "Our range of 14, 17, 25, and 32 seater mini buses on rent in Shirdi is tailored to fit groups of various sizes. Each vehicle is equipped with modern features such as comfortable seating, air conditioning, and entertainment options, ensuring a pleasant ride for your entire group. These mini buses are perfect for local sightseeing, events, and family outings, providing a reliable and enjoyable travel solution."
    },
    {
        "name": "Tempo Traveller Hire in Shirdi",
        "description": "Choose our Tempo Traveller hire in Shirdi for a reliable and comfortable option for your travels. Our vehicles are regularly serviced and maintained, providing peace of mind during your journey. With spacious interiors and good luggage capacity, our Tempo Travellers are designed for comfort, making them ideal for both short and long trips, whether for leisure or business purposes."
    },
    {
        "name": "Shirdi Airport Minibus Hire",
        "description": "Arriving at Shirdi Airport? Our minibus hire from Shirdi Airport offers a convenient transport solution, ensuring you reach your destination without any hassle. Book in advance to ensure availability, and enjoy a smooth transfer with our professional drivers ready to assist you. This service is designed to cater to your group's needs directly from the airport, providing an efficient start to your trip."
    },
    {
        "name": "Tempo Traveller on Rent in Shirdi Airport",
        "description": "Our Tempo Traveller on rent in Shirdi Airport is perfect for groups arriving by air. Enjoy a comfortable ride to your destination with our experienced drivers familiar with the best routes. This service is designed to cater to your group travel needs directly from the airport, ensuring a hassle-free transfer that allows you to relax and unwind after your flight."
    },
    {
        "name": "32 Seater Bus Hire in Shirdi",
        "description": "For larger groups, our 32 seater bus hire in Shirdi ensures that everyone can travel together without any hassle. This option is suitable for various events, including weddings, corporate outings, and more. With comfortable seating, ample luggage space, and additional features like entertainment systems, it is the ideal choice for group travel, ensuring that your journey is as enjoyable as your destination."
    },
    {
        "name": "14, 17, 25, 32 Seater Tempo Traveller on Rent",
        "description": "We offer flexible options for 14, 17, 25, and 32 seater Tempo Travellers on rent, accommodating groups of any size. Our fleet is perfect for sightseeing trips, airport transfers, and more, ensuring you have a comfortable and enjoyable journey no matter where you go. Each vehicle is equipped with essential amenities to enhance your travel experience."
    },
    {
        "name": "Shirdi Airport to Nashik Tempo Traveller",
        "description": "Traveling from Shirdi Airport to Nashik? Our Tempo Traveller service ensures a comfortable and efficient ride. Experience a stress-free journey with our experienced drivers who know the best routes, making your transfer seamless and enjoyable. This service is perfect for those looking to explore Nashik or attend events in the area right after landing."
    },
    {
        "name": "Shirdi to Ajanta Ellora Tempo Traveller on Rent",
        "description": "Plan a day trip from Shirdi to the Ajanta Ellora Caves with our Tempo Traveller on rent. This is an excellent option for exploring these historical sites comfortably with your group. Our vehicles are well-equipped for a long journey, providing amenities that ensure your comfort and enjoyment as you explore these UNESCO World Heritage sites."
    },
    {
        "name": "14, 17, 25, and 32 Seater Buses on Rent in Shirdi Contact Information",
        "description": "For bookings and inquiries about 14, 17, 25, and 32 Seater buses on rent in Shirdi, contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Our experienced team is here to assist you in planning your group travel, ensuring a comfortable and enjoyable journey tailored to meet your specific needs. We look forward to providing you with excellent service and a memorable travel experience."
    }
],
"tableData": [
    ["- 14 Seater Tempo Traveller On Rent", "- 17 Seater Tempo Traveller On Rent Shirdi"],
    ["- 25 Seater Tempo Traveller On Rent Shirdi", "- 32 Seater Tempo Traveller On Rent Shirdi"],
    ["- 14, 17, 25, 32 Seater Mini Bus On Rent in Shirdi", "- Tempo Traveller Hire in Shirdi"],
    ["- Shirdi Airport Minibus Hire", "- Tempo Traveller On Rent in Shirdi Airport"],
    ["- 32 Seater Bus Hire in Shirdi", "- Shirdi Airport to Nashik Tempo Traveller"],
    ["- Shirdi to Ajanta Ellora Tempo Traveller On Rent", "- SaitirthTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Diverse Fleet:",
        "WhyChoosedescription": "We take pride in offering a versatile range of vehicles to accommodate your specific group size and travel requirements. Our fleet includes 14, 17, 25, and 32-seater buses, ensuring that you can easily select the right vehicle for your needs, whether you're planning a family trip, corporate outing, or a pilgrimage journey. Each vehicle is meticulously maintained to provide a safe, comfortable, and reliable travel experience, giving you peace of mind on the road."
    },
    {
        "WhyChooseheading": "Comfort and Convenience:",
        "WhyChoosedescription": "Travel in comfort with our spacious and well-equipped buses, designed to enhance your journey regardless of the distance. Our vehicles are outfitted with comfortable seating, generous legroom, and modern amenities such as air conditioning, entertainment systems, and storage space for luggage. This thoughtful design ensures that all passengers enjoy a pleasant ride, whether embarking on a short excursion or a longer journey, allowing everyone to relax and unwind."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Safety and professionalism are at the forefront of our services. All our drivers are highly trained and have extensive experience in navigating various road conditions and traffic regulations. They are dedicated to providing a smooth and enjoyable trip while prioritizing your safety and adhering to all necessary safety protocols. Their friendly demeanor and extensive local knowledge make them an asset, as they can assist with directions and recommendations during your travels."
    },
    {
        "WhyChooseheading": "Flexible Booking Options:",
        "WhyChoosedescription": "Booking with us is designed to be hassle-free and flexible. Whether you need transportation for a family gathering, corporate event, or religious pilgrimage, our team can accommodate your needs. We offer straightforward booking procedures that allow you to reserve your bus effortlessly, ensuring you have the right vehicle ready for your occasion. Additionally, we provide options for last-minute bookings, making it easy to adjust your plans as needed."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "We understand the importance of budget-friendly travel options. Our competitive pricing ensures you receive excellent value without compromising on quality or service. We provide transparent quotes with no hidden costs, allowing you to plan your budget effectively and enjoy your trip without financial stress. Our goal is to make reliable transportation accessible to everyone, regardless of their travel budget."
    },
    {
        "WhyChooseheading": "Timely Services:",
        "WhyChoosedescription": "We value your time and are committed to punctuality. Our buses are scheduled to arrive on time, ensuring you stay on track with your itinerary. Whether it's a scheduled pickup or drop-off, our team works diligently to ensure timely services so you can travel with peace of mind. We track real-time traffic conditions and adapt our schedules accordingly to minimize delays, helping you reach your destination as planned."
    },
    {
        "WhyChooseheading": "24/7 Availability:",
        "WhyChoosedescription": "Our services are available around the clock, giving you the flexibility to book our buses at any time of day or night. Whether you have a last-minute request or need to plan for an early morning journey, our team is here to assist you, ensuring you have access to reliable transportation whenever you need it. This round-the-clock availability is part of our commitment to providing exceptional service tailored to your schedule."
    },
    {
        "WhyChooseheading": "Customizable Packages:",
        "WhyChoosedescription": "We recognize that every travel group is unique, which is why we offer customizable travel packages tailored to your specific itinerary and preferences. Our team will work closely with you to create a personalized travel experience that meets your needs, whether that includes additional stops along the route, specific departure times, or special requests. This flexibility ensures that your travel experience is not only enjoyable but also tailored to fit your group's dynamics."
    },
    {
        "WhyChooseheading": "Focus on Safety:",
        "WhyChoosedescription": "Your safety is our top priority. We conduct regular maintenance checks on all our vehicles to ensure they are in optimal condition for your journey. Our drivers are trained in safety protocols, and we implement strict hygiene standards to provide a safe travel environment for all passengers. Additionally, we follow local regulations and guidelines to ensure that your travel experience is both safe and comfortable, allowing you to focus on enjoying your trip."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "Our commitment to quality service has earned us numerous positive reviews from satisfied customers. We strive to provide a high level of professionalism and reliability, which is reflected in the feedback we receive. Choosing us means selecting a service that has been tried and trusted by many travelers, ensuring a reliable and enjoyable experience. Our clients appreciate our attention to detail and our willingness to go above and beyond to meet their needs, making us a preferred choice for group travel."
    }
]




        
  }


  const faqData = [
    {
        question: "How do I book a bus rental?",
        answer: "You can book our bus service through our website or by contacting our customer service team directly."
    },
    {
        question: "What are the rental rates for the buses?",
        answer: "Rates vary depending on the bus size and duration of hire. Please reach out for a detailed quote."
    },
    {
        question: "Are the buses equipped with amenities?",
        answer: "Yes, our buses come with various amenities such as air conditioning, comfortable seating, and entertainment options."
    },
    {
        question: "Can I choose the specific bus type for my rental?",
        answer: "Yes, you can select from our available options of 14, 17, 25, or 32-seater buses based on your group size."
    },
    {
        question: "What if I need the bus for a longer duration?",
        answer: "We offer flexible rental terms and can accommodate long-term bookings upon request."
    },
    {
        question: "Are your drivers experienced and licensed?",
        answer: "Absolutely! All our drivers are licensed and have extensive experience in driving passenger vehicles."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please inquire about the specific terms when booking."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment options, including cash, credit/debit cards, and online transfers for your convenience."
    },
    {
        question: "Can I modify my booking after it’s confirmed?",
        answer: "Yes, modifications can be made based on availability and may be subject to additional charges."
    },
    {
        question: "What is the maximum capacity of the buses?",
        answer: "Our buses are available in sizes that seat 14, 17, 25, and 32 passengers, accommodating different group sizes effectively."
    }
];

const testimonials = [
    {
        name: "Mr. Rajesh Thakur",
        text: "I rented a 25-seater bus for a family trip to Shirdi, and the experience was fantastic! The bus was spacious and comfortable, and our driver was very professional. We felt safe and well taken care of throughout the journey. Highly recommend Saitirth Travels for group bookings!"
    },
    {
        name: "Ms. Anita Mehta",
        text: "Saitirth Travels provided an excellent bus service for our corporate outing. The 32-seater bus was perfect for our team, and the amenities made the trip enjoyable. The driver was punctual and accommodating, which added to our positive experience. I will definitely book with them again!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | 14, 17, 25, 32 Seater Bus on Rent in Shirdi | Affordable Tempo Traveller Rentals</title>
    <meta name="description" content="Rent 14, 17, 25, or 32 seater buses and tempo travellers in Shirdi. Affordable rates for mini buses, tempo travellers for airport transfers, tours & more. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | 14, 17, 25, 32 Seater Bus on Rent in Shirdi | Affordable Tempo Traveller Rentals" />
    <meta property="og:description" content="Rent 14, 17, 25, or 32 seater buses and tempo travellers in Shirdi. Affordable rates for mini buses, tempo travellers for airport transfers, tours & more. Book now!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/14-17-25-32-seater-bus-on-rent-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/14-17-25-32-seater-bus-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Rent 14, 17, 25, or 32 seater buses and tempo travellers in Shirdi. Affordable rates for mini buses, tempo travellers for airport transfers, tours & more. Book now!",
            "url": "https://www.saitirthcabs.com/14-17-25-32-seater-bus-on-rent-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/14-17-25-32-seater-bus-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "14 Seater Tempo Traveller On Rent Shirdi",
                "17 Seater Tempo Traveller On Rent Shirdi",
                "25 Seater Tempo Traveller On Rent Shirdi",
                "32 Seater Tempo Traveller On Rent Shirdi",
                "Mini Bus On Rent in Shirdi",
                "Tempo Traveller Hire in Shirdi",
                "Shirdi Airport Minibus Hire",
                "Shirdi Airport to Nashik Tempo Traveller",
                "Shirdi to Ajanta Ellora Tempo Traveller",
                "Shirdi 14 17 25 32 Seater Bus on Rent",
                "Affordable Tempo Traveller Rentals Shirdi",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/60.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seaterbus;