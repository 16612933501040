
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Shirditoaurangabad() {



  const cardData =
  {
    keyword: ' Shirdi to Aurangabad (Sambhaji Nagar) Taxi Fare',
    heading: 'Saitirth Travels: Shirdi to Aurangabad (Sambhaji Nagar) Taxi Fare ',
    headingDescription: 'When it comes to traveling from Shirdi to Aurangabad (Sambhaji Nagar), Saitirth Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Aurangabad.',

    top: 'Top Places to Visit in Aurangabad with Saitirth Travels',

    "topPlaces": [
        {
            "title": "1. Ajanta Caves",
            "description": "A UNESCO World Heritage Site, the Ajanta Caves are famous for their stunning rock-cut sculptures and paintings that date back to the 2nd century BCE. These caves showcase the artistic brilliance of ancient India and depict various stories from Buddhist teachings, offering a glimpse into the rich cultural heritage and history of the region."
        },
        {
            "title": "2. Ellora Caves",
            "description": "Another UNESCO World Heritage Site, the Ellora Caves are renowned for their magnificent rock-cut temples that represent Hindu, Buddhist, and Jain traditions. The intricate carvings, especially the Kailasa temple, highlight the architectural genius of ancient India, making these caves a must-visit for those interested in artistry and history."
        },
        {
            "title": "3. Bibi Ka Maqbara",
            "description": "Often referred to as the 'Taj of the Deccan,' Bibi Ka Maqbara is a beautiful mausoleum built in the 17th century in memory of Aurangzeb's wife, Dilras Banu Begum. Its striking resemblance to the Taj Mahal and picturesque gardens make it a must-visit, complemented by its intricate marble work and rich historical context."
        },
        {
            "title": "4. Daulatabad Fort",
            "description": "This formidable hill fortress, built in the 12th century, offers panoramic views of the surrounding landscape. Exploring the fort involves a trek through ancient pathways, revealing its strategic significance and stunning architecture, making it a fascinating historical site."
        },
        {
            "title": "5. Shankar Ghole Dargah",
            "description": "A prominent religious site, Shankar Ghole Dargah attracts devotees and visitors alike. Its serene ambiance and beautiful architecture provide a peaceful retreat for reflection and spirituality, exemplifying the communal harmony and cultural diversity of Aurangabad."
        },
        {
            "title": "6. Aurangabad Caves",
            "description": "These rock-cut caves, dating back to the 2nd and 6th centuries, are lesser-known compared to Ajanta and Ellora but offer remarkable sculptures and ancient monasteries. The peaceful environment and historical significance make them a worthwhile visit for history enthusiasts."
        },
        {
            "title": "7. Jijamata Udyan",
            "description": "This well-maintained garden is perfect for a leisurely stroll or family outing. The lush greenery and vibrant flowerbeds create a picturesque setting, allowing visitors to relax and enjoy the natural beauty while learning about the local flora."
        },
        {
            "title": "8. Khadki Village",
            "description": "Known for its ancient temples and beautiful landscape, Khadki Village offers a glimpse into the rural lifestyle of Maharashtra. Visitors can explore the local culture and traditions, making it a great stop for those interested in authentic experiences."
        },
        {
            "title": "9. Panchakki",
            "description": "An engineering marvel, Panchakki is a water mill built in the 17th century, showcasing the innovative hydraulic engineering of the time. Using water from a nearby spring to power the mill, the surrounding gardens and serene atmosphere make it a lovely place to visit."
        },
        {
            "title": "10. Soneri Mahal",
            "description": "This 17th-century palace, known for its beautiful architecture and historical significance, is a great spot to learn about local history. Its intricate designs and serene environment appeal to history buffs and architecture enthusiasts alike."
        }
    ],    
        
    
    "services": [
    {
        "name": "Shirdi to Aurangabad Cab Service",
        "description": "When planning your journey from Shirdi to Aurangabad, our cab service offers a convenient and comfortable travel solution. SaitirthTours & Travels is committed to providing a seamless travel experience, with a fleet of well-maintained vehicles that prioritize passenger safety and comfort. Our experienced drivers are well-versed in the best routes, ensuring you reach your destination efficiently and hassle-free."
    },
    {
        "name": "Shirdi to Aurangabad Taxi Fare",
        "description": "Our taxi fare from Shirdi to Aurangabad is structured to offer great value without compromising on quality. We believe in transparent pricing, with no hidden charges, allowing you to plan your budget effectively. The fare varies depending on the vehicle type and additional services you may require, giving you the flexibility to choose an option that fits your travel needs."
    },
    {
        "name": "Shirdi to Aurangabad Cab Booking",
        "description": "Booking a cab from Shirdi to Aurangabad is quick and easy with our user-friendly online platform. Simply select your preferred vehicle, choose your travel date, and confirm your booking. Our team is always available to assist with any special requests or queries you may have, ensuring a personalized and enjoyable travel experience."
    },
    {
        "name": "Shirdi to Aurangabad One Way Cab",
        "description": "For travelers looking for flexibility, our one-way cab service from Shirdi to Aurangabad is the perfect choice. You can book your ride without the need for a return trip, making it ideal for those with specific travel plans. This option provides a straightforward solution for your journey, allowing you to focus on your travel without any added complications."
    },
    {
        "name": "Shirdi to Aurangabad Airport Cab",
        "description": "Our Shirdi to Aurangabad airport cab service ensures timely arrival for your flights. We understand the importance of punctuality, and our professional drivers will monitor flight schedules to ensure you arrive at the airport on time. With comfortable seating and ample luggage space, your journey to the airport will be both pleasant and efficient."
    },
    {
        "name": "Shirdi to Aurangabad Tour Package",
        "description": "Explore the historical and cultural richness of Aurangabad with our exclusive tour packages. The Shirdi to Aurangabad tour package includes not only transportation but also guided visits to key attractions such as the Ajanta and Ellora Caves, ensuring an enriching experience. Our drivers can provide insights into the local area, making your journey more informative and enjoyable."
    },
    {
        "name": "Taxi from Shirdi to Sambhaji Nagar",
        "description": "Our taxi service from Shirdi to Sambhaji Nagar is designed to cater to your specific travel needs, whether for business or leisure. We offer a variety of vehicle options, from economical sedans to spacious SUVs, accommodating groups of all sizes. Our drivers are familiar with the region, ensuring a smooth and efficient ride to your destination."
    },
    {
        "name": "Shirdi to Aurangabad Sightseeing Cab",
        "description": "Enhance your journey with our Shirdi to Aurangabad sightseeing cab service. This option allows you to explore various attractions along the route, including popular pilgrimage sites and historical landmarks. Our knowledgeable drivers can guide you to the best spots, ensuring you make the most of your travel experience."
    },
    {
        "name": "Shirdi to Aurangabad Cab Fare Structure",
        "description": "Understanding the fare structure for your Shirdi to Aurangabad trip is essential for planning your budget. We provide a clear and upfront breakdown of the costs involved, including base fare, distance charges, and any additional services you might request. This transparency helps you make informed decisions and avoid any surprises during your journey."
    },
    {
        "name": "Shirdi to Aurangabad Luxury Cab",
        "description": "For those looking for a more premium travel experience, our luxury cab service from Shirdi to Aurangabad provides high-end vehicles equipped with top-notch amenities. Enjoy plush seating, advanced entertainment systems, and complimentary refreshments as you travel in style and comfort, making your journey truly special."
    },
    {
        "name": "Contact for Shirdi to Aurangabad Taxi Booking",
        "description": "For more information about our Shirdi to Aurangabad taxi services or to make a booking, please contact SaitirthTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is ready to assist you with any inquiries, help you choose the right service for your needs, and ensure a seamless travel experience from start to finish."
    }
],
   "tableData": [
    ["- Shirdi to Aurangabad Taxi Fare", "- Affordable cab fare options from Shirdi to Aurangabad."],
    ["- Shirdi Aurangabad Cab Fare", "- Transparent pricing for your Shirdi to Aurangabad journey."],
    ["- Taxi Fare from Shirdi to Sambhaji Nagar", "- Competitive rates for trips to Sambhaji Nagar."],
    ["- Shirdi Sambhaji Nagar Cab Fare", "- Cost-effective transportation to Sambhaji Nagar."],
    ["- Cab Fare from Shirdi to Sambhaji Nagar", "- Clear fare structure for Shirdi to Sambhaji Nagar."],
    ["- Shirdi to Aurangabad Taxi Cost", "- Detailed breakdown of taxi costs for your journey."],
    ["- SaitirthTours & Travels Shirdi", "- Reliable cab service provider for Shirdi travel."],
    ["- Shirdi to Sambhaji Nagar Cab Cost", "- Affordable cab rates for your trip to Sambhaji Nagar."],
    ["- Shirdi to Aurangabad Taxi", "- Convenient taxi services for travel to Aurangabad."],
    ["- Shirdi to Aurangabad Taxi Fare", "- Transparent and competitive pricing for taxi rides."],
    ["- Shirdi to Aurangabad Cab", "- Quality cab services for your journey."],
    ["- Shirdi to Aurangabad Cab Fare", "- Affordable cab fare for your trip to Aurangabad."],
    ["- Taxi Fare from Shirdi to Aurangabad via Shani Shingnapur", "- Explore route options with competitive taxi fares."],
    ["- Shirdi to Aurangabad Airport Cab", "- Timely airport cab services from Shirdi to Aurangabad."],
    ["- Shirdi to Ajanta Ellora Taxi Fare", "- Special pricing for trips to Ajanta and Ellora Caves."],
    ["- Shirdi to Ajanta Ellora Caves Package", "- Complete package deals for visits to Ajanta and Ellora."],
    ["- Shirdi to Sambhaji Nagar Cab Package", "- All-inclusive cab packages for Sambhaji Nagar."],
    ["- Shirdi to Sambhaji Nagar One Way Cab", "- One-way travel options for your convenience."],
    ["- Shirdi to Grishneshwar Cab", "- Affordable cab services to Grishneshwar Temple."],
    ["- Shirdi to Aurangabad Airport Cab", "- Convenient airport transportation from Shirdi to Aurangabad."]
],
   "whychoose": [
    {
        "WhyChooseheading": "Why Choose Saitirth Travels for Your Shirdi to Aurangabad Taxi?",
        "WhyChoosedescription": "At Saitirth Travels, we are committed to providing an exceptional travel experience for your journey from Shirdi to Aurangabad. Our focus on customer satisfaction and quality service makes us a trusted choice for travelers."
    },
    {
        "WhyChooseheading": "Transparent Fare Structure:",
        "WhyChoosedescription": "We provide clear and upfront taxi fares from Shirdi to Aurangabad, ensuring no hidden costs or surprises at the end of your journey."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our experienced drivers are knowledgeable about the best routes to Aurangabad, guaranteeing a safe and efficient travel experience."
    },
    {
        "WhyChooseheading": "Comfortable Vehicles:",
        "WhyChoosedescription": "Our fleet consists of well-maintained and comfortable vehicles that are perfect for long-distance travel, allowing you to relax during the ride."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "We offer competitive pricing for our taxi services, making it easy for you to travel without breaking the bank."
    },
    {
        "WhyChooseheading": "Flexible Booking Options:",
        "WhyChoosedescription": "You can book your taxi easily through our website or by contacting our support team, with flexible cancellation policies to suit your needs."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any queries or support during your journey."
    }
]

        
  }


  const faqData = [
    {
      question: "What is the taxi fare from Shirdi to Aurangabad?",
      answer: "The fare varies based on the type of vehicle and specific requirements. Please contact us for the most accurate and up-to-date pricing."
    },
    {
      question: "How long is the journey from Shirdi to Aurangabad?",
      answer: "The drive typically takes about 4 to 5 hours, depending on traffic and road conditions."
    },
    {
      question: "Are there any additional charges?",
      answer: "Additional charges may apply for nighttime travel (between 10 PM and 6 AM) and any specific requests like extra stops."
    },
    {
      question: "How can I book a taxi from Shirdi to Aurangabad?",
      answer: "You can easily book a taxi online via our website or contact our customer service for assistance."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept a variety of payment methods, including credit/debit cards, UPI, and cash, for your convenience."
    },
    {
      question: "Can I cancel my booking?",
      answer: "Yes, we offer flexible cancellation policies. Please review our cancellation terms on the website for more information."
    },
    {
      question: "Is the taxi sanitized before the trip?",
      answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before each journey to ensure your safety."
    },
    {
      question: "Can I request additional stops along the route?",
      answer: "Yes, you can request reasonable stops for refreshments or sightseeing during your journey."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Vinay Patil",
      text: "I booked a taxi with Saitirth Travels for my trip from Shirdi to Aurangabad, and the experience was excellent. The driver was punctual and friendly, and the ride was very comfortable. I appreciated the clear pricing and hassle-free booking process. Highly recommend!"
    },
    {
      name: "Ms. Neha Joshi",
      text: "Saitirth Travels made my journey from Shirdi to Aurangabad very enjoyable. The cab was clean and well-maintained, and our driver was knowledgeable about the area. I will definitely use their services again for my future trips!"
    }
  ];
  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Shirdi to Aurangabad Taxi Fare | SaitirthTours & Travels</title>
    <meta name="description" content="Check the affordable and reliable taxi fare from Shirdi to Aurangabad (Sambhaji Nagar). Book a cab for a comfortable journey with SaitirthTours & Travels. Taxi services available for Ajanta Ellora, Shani Shingnapur, and more." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Shirdi to Aurangabad Taxi Fare | SaitirthTours & Travels" />
    <meta property="og:description" content="Check the affordable and reliable taxi fare from Shirdi to Aurangabad (Sambhaji Nagar). Book a cab for a comfortable journey with SaitirthTours & Travels. Taxi services available for Ajanta Ellora, Shani Shingnapur, and more." />
    <meta property="og:url" content="https://www.saitirthcabs.com/shirdi-to-aurangabad-taxi-fare" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/shirdi-to-aurangabad-taxi-fare.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Check the affordable and reliable taxi fare from Shirdi to Aurangabad (Sambhaji Nagar). Book a cab for a comfortable journey with SaitirthTours & Travels. Taxi services available for Ajanta Ellora, Shani Shingnapur, and more.",
            "url": "https://www.saitirthcabs.com/shirdi-to-aurangabad-taxi-fare",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/shirdi-to-aurangabad-taxi-fare.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Aurangabad Taxi Fare", 
                "Shirdi Aurangabad Cab Fare", 
                "Taxi Fare from Shirdi to Sambhaji Nagar", 
                "Shirdi Sambhaji Nagar Cab Fare", 
                "Cab Fare from Shirdi to Sambhaji Nagar", 
                "Shirdi to Aurangabad Taxi Cost", 
                "SaitirthTours & Travels Shirdi", 
                "Shirdi to Sambhaji Nagar Cab Cost", 
                "Shirdi to Aurangabad Taxi", 
                "Shirdi to Aurangabad Taxi Fare", 
                "Shirdi to Aurangabad Cab", 
                "Shirdi to Aurangabad Cab Fare", 
                "Taxi Fare from Shirdi to Aurangabad via Shani Shingnapur", 
                "Shirdi to Aurangabad Airport Cab", 
                "Shirdi to Ajanta Ellora Taxi Fare", 
                "Shirdi to Ajanta Ellora Caves Package", 
                "Shirdi to Sambhaji Nagar Cab Package", 
                "Shirdi to Sambhaji Nagar One Way Cab", 
                "Shirdi to Grishneshwar Cab", 
                "Shirdi to Aurangabad Airport Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/21.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoaurangabad;