import React, { useState } from 'react';
import '../css/faq.css';
import { Helmet } from 'react-helmet';

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "1. How can I book a taxi for my trip with Saitirth Travels?",
            answer: "You can easily book a taxi by visiting our website or by contacting our customer support team. We offer a simple online booking form and 24/7 customer service."
        },
        {
            question: "2. How do I download the Saitirth Travels app?",
            answer: "You can download the Saitirth Travels app from the Google Play Store or Apple App Store by searching for 'Saitirth Travels'. Once installed, you can book your rides directly from the app."
        },
        {
            question: "3. How do I hire a driver for my journey?",
            answer: "To hire a driver for your trip, simply select the 'Driver Hire' option when booking your taxi. You can also call our support team to assist you with driver selection."
        },
        {
            question: "4. Can I book a taxi for a city tour with Saitirth Travels?",
            answer: "Yes, you can book a taxi for a city tour. Our team will help you plan your city sightseeing tour and arrange a comfortable ride to explore the best attractions."
        },
        {
            question: "5. What types of vehicles are available with Saitirth Travels?",
            answer: "We offer a variety of vehicles including sedans, SUVs, tempo travelers, and luxury cars to suit your travel needs. You can choose the vehicle type while booking based on your preferences and group size."
        },
    ];
    
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faqs-section">
            <div className="auto-container">
                <div className="row g-0">
                    <div className="faq-column col-xl-5 col-lg-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="sub-title">Have you any question?</span>
                                <h2 className="letters-slide-up text-split">Frequently Asked Questions?</h2>
                            </div>
                            <ul className="accordion-box style-two">
                                {faqs.map((faq, index) => (
                                    <li className={`accordion block ${activeIndex === index ? 'active-block' : ''}`} key={index}>
                                        <div className={`acc-btn ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                                            {faq.question}
                                            <div className="icon fa fa-chevron-right"></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === index ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">{faq.answer}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="image-column col-xl-7">
                        <div className="inner-column">
                            <figure className="image reveall">
                                <img src="images/resource/faq1-1.jpg" alt="Image" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
