
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GoogleTag from './GoogleTag';

function Corporatcabservice() {



  const cardData =
  {
    keyword: '  Corporate Cab Services in Shirdi',
    heading: 'Saitirth Travels: Corporate Cab Services in Shirdi',
    headingDescription: 'At Saitirth Travels, we understand the importance of reliable transportation for corporate clients. Our Corporate Cab Services in Shirdi are tailored to meet the needs of businesses and professionals, ensuring that you have a comfortable and efficient travel experience. Whether you are planning a business meeting, a corporate event, or airport transfers, our cab services are designed to provide punctuality, safety, and convenience.',

    top: 'Top Places to Visit in Shirdi with Saitirth Travels',

  "topPlaces": [
    {
        "title": "Shri Saibaba Samadhi Mandir",
        "description": "This sacred shrine is the main attraction in Shirdi, drawing devotees from around the globe to pay their respects to Sai Baba. The temple complex houses the samadhi, or final resting place, of Sai Baba and exudes a serene atmosphere that invites meditation and prayer. With its beautifully maintained gardens and peaceful ambiance, visitors often find themselves deeply moved, reflecting on Baba’s teachings and seeking spiritual solace."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai is an ancient mosque where Sai Baba spent a significant part of his life, making it a vital historical and spiritual site for his followers. The site features a sacred fire known as the Dhuni, which Baba lit and maintained throughout his life, symbolizing eternal spiritual warmth. Visitors can observe numerous offerings made by devotees over the years, each reflecting the deep reverence and love for Sai Baba, creating a vibrant spiritual energy that enhances the experience of all who visit."
    },
    {
        "title": "Chavadi",
        "description": "The Chavadi is a simple yet profound structure where Sai Baba would rest on alternate nights. It holds great significance for his followers, adorned with fresh flowers and offering a glimpse into Baba's humble lifestyle. The Chavadi serves as a poignant reminder of Sai Baba's teachings on humility and devotion, allowing visitors to connect with the essence of his life and reflect on the simple yet profound lessons he imparted."
    },
    {
        "title": "Shirdi Saibaba Museum",
        "description": "The Shirdi Saibaba Museum is home to an impressive collection of artifacts and personal belongings of Sai Baba, offering valuable insights into his life and teachings. The displays include photographs, clothing, and items that Baba used, making it an informative and reflective stop for visitors. This museum not only preserves the history of Sai Baba but also serves as a source of inspiration, allowing guests to learn more about his journey and impact on spirituality."
    },
    {
        "title": "Lendi Baug",
        "description": "Lendi Baug is a beautifully landscaped garden located near the Samadhi Mandir, providing a peaceful spot where Sai Baba often meditated. With its lush greenery, small well, and tranquil surroundings, the garden serves as an ideal location for relaxation and contemplation. Visitors are drawn to the serene atmosphere, often finding it a perfect place to reflect on their spiritual journey and connect with nature."
    },
    {
        "title": "Shri Saibaba Sansthan Temple Trust",
        "description": "The Shri Saibaba Sansthan Temple Trust is the organization responsible for managing the affairs of the Sai Baba temple, offering various services for devotees. Visitors can explore the history and activities of the trust, which plays a crucial role in maintaining the sanctity of this important pilgrimage site. The trust also organizes community service programs and spiritual initiatives, allowing guests to engage with the ongoing legacy of Sai Baba’s teachings and contribute to the community."
    },
    {
        "title": "Khandoba Mandir",
        "description": "Situated a short distance from the main temple, Khandoba Mandir is dedicated to Lord Khandoba, a deity worshipped by Sai Baba. Known for its stunning architecture and peaceful ambiance, this temple attracts numerous devotees seeking blessings. The vibrant energy and spiritual atmosphere make it a perfect location for worship and reflection, allowing visitors to immerse themselves in the rich cultural heritage surrounding Sai Baba."
    },
    {
        "title": "Maalik Dham",
        "description": "Maalik Dham is a temple dedicated to Sai Baba’s guru, Abdul Baba. This peaceful sanctuary offers a tranquil setting for meditation and prayer, allowing visitors to connect with the spiritual essence of Shirdi. The atmosphere is one of calm and introspection, providing a perfect backdrop for those seeking solace and guidance from the teachings of their spiritual ancestors, fostering a deeper understanding of Sai Baba's journey."
    },
    {
        "title": "Sainath Nagar",
        "description": "Sainath Nagar is a lively residential area that has developed around the main temple complex. It offers a range of accommodations and dining options for visitors, making it a convenient spot for those looking to immerse themselves in the local culture. The area is bustling with shops, eateries, and cultural experiences, providing guests with a taste of the vibrant community life surrounding the sacred sites."
    },
    {
        "title": "Shri Sai Baba's Chavadi",
        "description": "Shri Sai Baba's Chavadi holds special significance as it exemplifies the simplicity of Sai Baba’s life. Often adorned with flowers, this humble structure serves as a reminder of Baba’s teachings on humility and compassion. Visitors are drawn to the Chavadi not only to witness its historical importance but also to reflect on the profound lessons imparted by Sai Baba through his everyday existence, making it an integral part of their spiritual pilgrimage."
    }
],
    
            
        
"services": [
    {
      "name": "Shirdi Corporate Taxi Service",
      "description": "Our Shirdi corporate taxi service is designed specifically for business travelers who prioritize comfort and punctuality. With our professional drivers, we guarantee timely pickups and drop-offs for all your meetings, ensuring you arrive at your destination stress-free and ready to conduct business."
    },
    {
      "name": "Corporate Car Rental Shirdi",
      "description": "We provide a diverse fleet of vehicles for corporate car rental, offering flexibility to suit your business needs. Whether you require a vehicle for a day or an extended period, our options include sedans, SUVs, and luxury cars, all maintained to the highest standards for your comfort and safety."
    },
    {
      "name": "Shirdi Executive Cab Services",
      "description": "For top executives, our premium cab services deliver a high standard of comfort and professionalism. Each vehicle is equipped with plush interiors and necessary amenities, allowing for a pleasant travel experience that reflects the stature of your business."
    },
    {
      "name": "Corporate Transportation Shirdi",
      "description": "Our comprehensive corporate transportation services cover all your travel needs, including airport transfers and daily office commutes. We pride ourselves on reliability, ensuring that all transportation is handled with professionalism, so you can focus on your work."
    },
    {
      "name": "Shirdi Corporate Shuttle Service",
      "description": "If your company requires regular shuttle services for employees, our Shirdi corporate shuttle service is the ideal solution. We can arrange scheduled pickups and drop-offs at designated points, making daily commutes convenient and ensuring your team arrives on time."
    },
    {
      "name": "Business Cab Services Shirdi",
      "description": "Our business cab services are tailored to provide convenient and cost-effective transportation solutions for companies of all sizes. Whether you need a single ride for a client or multiple vehicles for an event, we offer reliable service that meets your requirements."
    },
    {
      "name": "Affordable Corporate Cab Shirdi",
      "description": "Understanding the need for budget management, our affordable corporate cab services in Shirdi provide high-quality transportation at competitive prices. With transparent pricing structures and no hidden fees, you can plan your corporate travel without financial concerns."
    },
    {
      "name": "Monthly Car Rental in Shirdi for Outstation",
      "description": "For businesses that require regular outstation travel, our monthly car rental service offers tailored packages that deliver flexibility and convenience for your employees. Enjoy the ease of a dedicated vehicle ready whenever you need it."
    },
    {
      "name": "Cab Service in Shirdi MIDC",
      "description": "Our cab service in Shirdi MIDC is specifically tailored for industrial clients who need reliable transportation for their employees and visitors. We ensure that all trips are conducted with professionalism, maintaining high service standards at all times."
    },
    {
      "name": "Best Cab Service in Shirdi",
      "description": "Recognized as one of the best cab services in Shirdi, we take pride in our commitment to quality and customer satisfaction. Our fleet is regularly maintained, and our drivers are trained to provide safe, efficient, and courteous service for all your transportation needs."
    },
    {
      "name": "Corporate Cab Services for Office Employees",
      "description": "Our corporate cab services for office employees ensure a smooth and reliable commute, allowing your team to focus on their work without the hassle of transportation logistics. We provide personalized solutions to fit the unique needs of your workforce."
    },
    {
      "name": "Monthly Pick and Drop Service Shirdi",
      "description": "Designed for companies that require consistent transportation, our monthly pick and drop service in Shirdi offers flexible scheduling options. We ensure hassle-free commuting for your employees, enhancing their productivity and job satisfaction."
    },
    {
      "name": "Corporate Taxi Services in Shirdi",
      "description": "Our corporate taxi services in Shirdi are geared towards providing reliable and comfortable travel for all business-related purposes. Whether for client meetings or employee transport, we have tailored solutions to meet your needs effectively."
    },
    {
      "name": "Corporate Cab Services in Shirdi Airport",
      "description": "For businesses requiring transportation to and from the airport, our corporate cab services at Shirdi Airport ensure seamless travel arrangements. We monitor flight schedules to guarantee timely pickups, so you can avoid delays and travel with peace of mind."
    },
    {
      "name": "Corporate Cab Services in Shirdi Contact Number",
      "description": "For prompt and efficient corporate cab services in Shirdi, contact Shirdi Saibaba Travels at +91 9923879009 / 9922479009. Our dedicated team is ready to assist you with your transportation needs, ensuring a smooth and enjoyable ride."
    }
  ],


  "tableData": [
        ["- Corporate Cab Services Shirdi", "- Shirdi Corporate Taxi Service"],
        ["- Corporate Car Rental Shirdi", "- Shirdi Executive Cab Services"],
        ["- Corporate Transportation Shirdi", "- Shirdi Corporate Shuttle Service"],
        ["- Business Cab Services Shirdi", "- Shirdi Corporate Car Hire"],
        ["- Corporate Travel Services Shirdi", "- Affordable Corporate Cab Shirdi"],
        ["- Corporate Cab Services in Shirdi", "- Corporate Cab Services in Shirdi"],
        ["- Monthly Car Rental in Shirdi for Outstation", "- Cab Service in Shirdi MIDC"],
        ["- Cab Service in Shirdi MIDC for Outstation", "- Corporate Cab Services in Shirdi"],
        ["- Corporate Taxi Services in Shirdi", "- Event Management Companies in Shirdi"],
        ["- Corporate Cab Services for Office Employees", "- Monthly Pick and Drop Service Shirdi"],
        ["- Cab Services in Shirdi for Industrial", "- Best Cab Service in Shirdi"],
        ["- Corporate Cab Services in Shirdi Airport", "- SaitirthTours & Travels Shirdi"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Professional Drivers",
            "WhyChoosedescription": "Our corporate cab service features a team of well-trained, professional drivers dedicated to ensuring punctuality and customer satisfaction. They are not only skilled in navigating the best routes and traffic patterns but also trained to deliver exceptional service. This focus on professionalism ensures that you arrive on time for your business meetings and events, while also providing a comfortable and pleasant travel experience."
        },
        {
            "WhyChooseheading": "Fleet of Executive Vehicles",
            "WhyChoosedescription": "We offer a diverse fleet of executive vehicles tailored for corporate travel. Whether you need luxury sedans for client meetings, spacious SUVs for group outings, or vans for larger teams, we have the perfect vehicle for your needs. All our vehicles are meticulously maintained and equipped with modern amenities, such as Wi-Fi and climate control, ensuring a comfortable and professional environment throughout your journey."
        },
        {
            "WhyChooseheading": "Flexible Booking Options",
            "WhyChoosedescription": "Our booking process is designed to be as user-friendly and flexible as possible. You can easily schedule rides for individual employees or arrange transport for corporate events with just a few clicks. We understand that plans can change, which is why we accommodate last-minute adjustments to your travel arrangements, ensuring seamless and efficient service whenever you need it."
        },
        {
            "WhyChooseheading": "Competitive Pricing",
            "WhyChoosedescription": "Our corporate cab services provide exceptional value without compromising on quality. We offer competitive pricing and customized packages tailored to meet your specific corporate travel needs. This approach enables you to effectively manage your transportation budget while ensuring your team receives top-notch service every time they travel."
        },
        {
            "WhyChooseheading": "24/7 Availability",
            "WhyChoosedescription": "Whether it’s early morning pickups or late-night drop-offs, our corporate cab services are available around the clock. You can rely on us for any last-minute transportation needs, ensuring that your team is always on the move, regardless of the time of day. This commitment to 24/7 availability ensures that your transportation needs are met promptly and efficiently."
        },
        {
            "WhyChooseheading": "Safety and Hygiene Standards",
            "WhyChoosedescription": "Your safety and well-being are our top priorities. We adhere to strict safety and hygiene standards, ensuring that all our vehicles undergo regular sanitization before and after each trip. Our drivers also follow comprehensive safety protocols to provide a secure travel environment. You can travel with peace of mind, knowing that your health and safety are our primary concerns."
        },
        {
            "WhyChooseheading": "Dedicated Account Management",
            "WhyChoosedescription": "For businesses that require ongoing transportation solutions, we offer dedicated account management services. This personalized approach ensures that your corporate travel needs are met efficiently, allowing for effective communication and tailored service. Our account managers work closely with you to understand your specific requirements, ensuring that we provide the best possible service for your organization."
        },
        {
            "WhyChooseheading": "Real-Time Tracking",
            "WhyChoosedescription": "Our corporate cab service includes real-time tracking for all rides, allowing you to monitor your vehicle’s location and estimated arrival time. This feature not only provides peace of mind but also ensures transparency and reliability in your travel arrangements. With real-time tracking, you can stay informed and make necessary adjustments to your schedule as needed."
        }
    ]

        
  }


  const faqData = [
    {
        question: "What types of vehicles do you offer for corporate cab services?",
        answer: "We provide a range of vehicles, including luxury sedans, SUVs, and vans, to accommodate different group sizes and travel needs."
    },
    {
        question: "How can I book a corporate cab?",
        answer: "You can book our corporate cab services through our website or by contacting our customer support team. We recommend advance booking, especially for corporate events."
    },
    {
        question: "Do you offer corporate accounts?",
        answer: "Yes, we offer corporate accounts for businesses requiring regular transportation. This includes personalized service, flexible booking, and dedicated account management."
    },
    {
        question: "Are there any cancellation fees?",
        answer: "We have a flexible cancellation policy. You can cancel your booking without any charges up to 24 hours before the scheduled ride. A small cancellation fee may apply for later cancellations."
    },
    {
        question: "Can I customize my corporate transportation package?",
        answer: "Absolutely! We can customize our corporate transportation packages to meet your specific requirements, including additional services like meet-and-greet, airport transfers, or specific vehicle requests."
    },
    {
        question: "Is there a minimum or maximum booking period?",
        answer: "There is no strict minimum or maximum booking period. We cater to both short-term and long-term corporate transportation needs."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment options, including credit/debit cards, UPI transfers, and cash, making it convenient for your business transactions."
    },
    {
        question: "What measures are in place for passenger safety?",
        answer: "We follow strict safety and hygiene protocols, including regular vehicle sanitization and adherence to health guidelines, ensuring a safe travel experience for all passengers."
    },
    {
        question: "Can I track my cab in real-time?",
        answer: "Yes, our corporate cab service offers real-time tracking, allowing you to monitor the location of your vehicle and estimated arrival times."
    },
    {
        question: "What should I do if my plans change unexpectedly?",
        answer: "If your plans change, please contact our customer support team as soon as possible. We will do our best to accommodate your needs and make necessary adjustments to your booking."
    }
];

const testimonials = [
    {
        name: "Mr. Vikram Sharma",
        text: "Saitirth Travels has been our go-to service for corporate travel in Shirdi. Their drivers are always professional and punctual, making it easy for our team to get to meetings on time."
    },
    {
        name: "Ms. Neha Rao",
        text: "We regularly book cabs for client meetings and events with Saitirth Travels. The service is consistently reliable, and our clients appreciate the comfort and professionalism."
    },
    {
        name: "Mr. Arjun Patil",
        text: "Having a dedicated account manager has made our corporate transportation effortless. Saitirth Travels understands our needs and always delivers exceptional service."
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthcabs.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/19.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
      <GoogleTag/>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirth Travels | Corporate Cab Services in Shirdi | SaitirthTours & Travels | Affordable Corporate Transportation</title>
    <meta name="description" content="Get reliable and affordable corporate cab services in Shirdi. Offering executive car rentals, corporate shuttle services, office employee pickups & drops, and more. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirth Travels | Corporate Cab Services in Shirdi | SaitirthTours & Travels | Affordable Corporate Transportation" />
    <meta property="og:description" content="Get reliable and affordable corporate cab services in Shirdi. Offering executive car rentals, corporate shuttle services, office employee pickups & drops, and more. Book now!" />
    <meta property="og:url" content="https://www.saitirthcabs.com/corporate-cab-services-shirdi" />
    <meta property="og:image" content="https://www.saitirthcabs.com/images/corporate-cab-services-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthTours & Travels",
            "description": "Get reliable and affordable corporate cab services in Shirdi. Offering executive car rentals, corporate shuttle services, office employee pickups & drops, and more. Book now!",
            "url": "https://www.saitirthcabs.com/corporate-cab-services-shirdi",
            "logo": "https://www.saitirthcabs.com/images/logo.png",
            "image": "https://www.saitirthcabs.com/images/corporate-cab-services-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Corporate cab services Shirdi",
                "Shirdi corporate taxi service",
                "Corporate car rental Shirdi",
                "Shirdi executive cab services",
                "Corporate transportation Shirdi",
                "Shirdi corporate shuttle service",
                "Business cab services Shirdi",
                "Shirdi corporate car hire",
                "Corporate travel services Shirdi",
                "Affordable corporate cab Shirdi",
                "Corporate Cab Services in Shirdi",
                "Monthly car rental in Shirdi for outstation",
                "Cab service in Shirdi MIDC",
                "Cab service in Shirdi MIDC for outstation",
                "Corporate taxi services in Shirdi",
                "Event management companies in Shirdi",
                "Corporate cab services for office employees",
                "Monthly pick and drop service Shirdi",
                "Cab services in Shirdi for industrial",
                "Best cab service in Shirdi",
                "Corporate cab services in Shirdi airport",
                "SaitirthTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/42.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            <h3 className='pt-md-3'>{cardData.keyword + " Rates"}</h3>
            <BusRatesTable />

              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirth Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthcabs.com" className=" text-white d-block">
                          booking@saitirthcabs.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirth Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Corporatcabservice;